import { ContentBox } from '../../../components/atoms/ContentBox/index';
export const ContentSection = ({
  heading,
  content,
}: {
  heading: string;
  content?: Array<any>;
}) => {
  return (
    <div className="heading-box">
      <div className="title">{heading}</div>
      <div className="content flex text-sm">
        {content.map((item, i) => {
          return (
            <ContentBox key={i} lineCount={i + 1}>
              {item}
            </ContentBox>
          );
        })}
      </div>
    </div>
  );
};
