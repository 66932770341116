export const GlobalReducer = (
  state,
  action: { type: string; payload: any },
): any => {
  switch (action.type) {
    case 'SET_PRICE_DATA':
      return {
        ...state,
        priceData: action.payload,
      };
    case 'SET_LENDING_DATA':
      return {
        ...state,
        lendingData: action.payload,
      };
    case 'SET_VAULT_DATA':
      return {
        ...state,
        vaultData: action.payload,
      };
    case 'SET_STAKING_DATA':
      return {
        ...state,
        stakingData: action.payload,
      };

    case 'SET_BORROW_DATA':
      return {
        ...state,
        borrowData: action.payload,
      };
    case 'SET_GRAPH_DATA':
      return {
        ...state,
        graphData: action.payload,
      };
    case 'SET_TOKENS_DATA':
      return {
        ...state,
        tokensData: action.payload,
      };
    case 'SET_TOKEN_LIST':
      return {
        ...state,
        tokenList: action.payload,
      };
    case 'SET_ACTIVITY':
      return {
        ...state,
        activity: action.payload,
      };
    case 'SET_CHART_DATA':
      return {
        ...state,
        chartData: {
          ...state.chartData,
          [action.payload.asset]: action.payload.assetData,
        },
      };
    case 'SET_ACTIONS':
      return {
        ...state,
        actions: action.payload,
      };
    case 'SET_CONTRACTS':
      return {
        ...state,
        contracts: action.payload,
      };
    default:
      return state;
  }
};

export const UserReducer = (
  state,
  action: { type: string; payload: any },
): any => {
  switch (action.type) {
    case 'SET_DOMAIN':
      return {
        ...state,
        domain: action.payload,
      };
    case 'SET_WALLET':
      return {
        ...state,
        wallet: action.payload,
      };
    case 'SET_LENDING_DATA': {
      return {
        ...state,
        userLendingData: action.payload,
      };
    }
    case 'SET_BALANCES':
      return {
        ...state,
        balances: action.payload,
      };
    case 'SET_VAULT_DATA':
      return {
        ...state,
        userVaultData: action.payload,
      };
    case 'SET_STAKING_DATA':
      return {
        ...state,
        userStakingData: action.payload,
      };
    case 'SET_BORROW_DATA':
      return {
        ...state,
        userBorrowData: action.payload,
      };
    case 'SET_EARNINGS_CLAIMED':
      return {
        ...state,
        earningsClaimed: action.payload,
      };
    case 'SET_TRY_AGAIN':
      return {
        ...state,
        tryAgain: action.payload,
      };
    default:
      return state;
  }
};
