import Slider from 'react-rangeslider';

export const RangeSlider = ({
  value,
  type,
  labels,
  format,
  handleChange,
  handleSliderComplete,
  sliderValue,
  tooltip,
  step,
  style,
}: {
  value?: any;
  type?: 'horizontal' | 'vertical';
  labels?: any;
  format?: any;
  handleChange?: any;
  handleSliderComplete?: any;
  sliderValue?: any;
  tooltip?: boolean;
  step?: number;
  style?: 'default' | 'style-2';
}) => {
  return (
    <div className={`slider-box slider-theme-${style}`}>
      <Slider
        value={value}
        orientation={type}
        labels={labels}
        format={format}
        onChange={handleChange}
        onChangeComplete={handleSliderComplete}
        handleLabel={sliderValue}
        tooltip={tooltip}
        step={step}
      />
    </div>
  );
};
