import './index.scss';

import { icons } from '../Icons';

export const SectionArrow = ({ target }: { target: string }) => {
  return (
    <div className="arrow-full">
      <a href={target} className="arrow-box">
        <i className="icon-arrow">{icons.arrowDown}</i>
      </a>
    </div>
  );
};
