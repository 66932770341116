import React, { createContext, useReducer, useState } from 'react';
import { WagmiConfig } from 'wagmi';

import { Toast, TypeEnum } from '../components/organisms/Toast';
import { client } from '../connectors';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { GlobalInitialState } from '../interfaces';
import { GlobalReducer, UserReducer } from './reducers';

const initialState: GlobalInitialState = {
  graphData: {
    activities: [],
    totalData: {},
  },
  priceData: {},
  tokenList: [],
  borrowData: [],
  stakingData: [],
  vaultData: [],
  tokensData: [],
  earningsClaimed: 0,
  actions: null,
  contracts: {},
};

const initialUserData = {
  userVaultData: [],
  userLendingData: { data: { investments: [] } },
  userStakingData: [],
  userBorrowData: {
    loans: [],
    aave: { maxLTV: 0, totalBorrowedUSD: 0, totalCollateralUSD: 0, userLTV: 0 },
    cream: {
      maxLTV: 0,
      totalBorrowedUSD: 0,
      totalCollateralUSD: 0,
      userLTV: 0,
    },
  },
  balances: { wallet: [], account: [] },
  wallet: '',
  earningsClaimed: 0,
};

export const UserContext = createContext<{
  state: {
    userVaultData: any;
    userStakingData: any;
    userBorrowData: any;
    userLendingData: any;
    balances: { wallet: any; account: any };
    wallet: string;
    earningsClaimed: number;
  };
  dispatch: React.Dispatch<{
    type: string;
    payload: any;
  }>;
  transactions: any;
  setTransactions: any;
}>({
  state: initialUserData,
  transactions: {},
  setTransactions: () => {
    return null;
  },
  dispatch: () => {
    return null;
  },
});

export const UserProvider = ({
  children,
}: {
  children: React.ReactChild | React.ReactChild[];
}) => {
  const [state, dispatch] = useReducer(UserReducer, initialUserData);
  const [transactions, setTransactions] = useLocalStorage(
    'transactions_registry',
    {},
  );

  /* This is just for cleaning the old transactions, if they have no receipt */
  for (const txKey in transactions) {
    if (!transactions[txKey].receipt) {
      transactions[txKey].receipt = null;
    }
  }

  return (
    <UserContext.Provider
      value={{ state, dispatch, transactions, setTransactions }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const GlobalContext = createContext<{
  state: GlobalInitialState;
  dispatch: React.Dispatch<{
    type: string;
    payload: any;
  }>;
  setToast: React.Dispatch<
    React.SetStateAction<{
      type: string;
      title: string;
      message?: string | JSX.Element;
      timer: number;
      open: boolean;
    }>
  >;
}>({
  state: initialState,
  dispatch: () => null,
  setToast: () => null,
});

export const GlobalProvider = ({
  children,
}: {
  children: React.ReactChild | React.ReactChild[];
}) => {
  const [state, dispatch] = useReducer(GlobalReducer, initialState);
  const [toast, setToast] = useState<{
    type: TypeEnum;
    title: string;
    message?: string;
    timer: number;
    open: boolean;
  }>({
    type: TypeEnum.Success,
    title: '',
    message: '',
    timer: 0,
    open: false,
  });

  return (
    <WagmiConfig client={client}>
      <GlobalContext.Provider value={{ state, dispatch, setToast }}>
        {toast.open && (
          <Toast
            type={toast.type}
            title={toast.title}
            message={toast.message}
            timer={toast.timer || 5000}
            close={() => setToast({ ...toast, open: false })}
          />
        )}
        {children}
      </GlobalContext.Provider>
    </WagmiConfig>
  );
};
