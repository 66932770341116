import React, { useContext } from 'react';
import { useNetwork } from 'wagmi';

import { UserContext } from '../../../../context';
import ErrorTx from '../../../../styles/img/error-tx.svg';
import PendingTx from '../../../../styles/img/pending-clock.svg';
import SuccessCheck from '../../../../styles/img/success-check.svg';
import { Button } from '../../../atoms/Button';
import { CustomScrollbar } from '../../../atoms/CustomScrollbar/index';
import { Modal } from '../../../atoms/Modal/index';

type Props = {
  close: () => void;
  transactionsView: boolean;
};

export const TransactionsView: React.FC<Props> = ({ close }) => {
  const { transactions, setTransactions } = useContext(UserContext);
  const { chain } = useNetwork();
  const transactionsHash = Object.keys(transactions);
  const transactionsHashWithIndex = transactionsHash.map((hashItem, index) => ({
    hashItem,
    index,
  }));

  return (
    <Modal close={close} closeButton>
      <div className="heading-box-view flex">
        <div className="heading flex-auto">Recent transactions</div>
        <div>
          <span
            className="btn-link"
            onClick={() => {
              setTransactions({});
              localStorage.clear();
            }}
          >
            Clear All
          </span>
        </div>
      </div>
      <div className="transaction-view-box">
        {transactionsHashWithIndex.length > 0 ? (
          <CustomScrollbar
            height={'320px'}
            theme={'light'}
            isScrollable={transactionsHashWithIndex.length > 5 ? true : false}
          >
            <div className="transation-list-box">
              {transactionsHashWithIndex
                .sort((a, b) => b.index - a.index)
                .map((hashItem, index) => {
                  return (
                    <div key={index} className="transation-card">
                      <div className="flex justify-between items-center">
                        {transactions[hashItem.hashItem].receipt !==
                        undefined ? (
                          <>
                            {transactions[hashItem.hashItem].receipt !==
                            null ? (
                              <>
                                <div className="flex items-center">
                                  <span className="mr-2">
                                    <img
                                      src={SuccessCheck}
                                      className="h-6 w-6"
                                    />
                                  </span>
                                  <span>
                                    {transactions[hashItem.hashItem].titleTx}
                                  </span>
                                </div>
                                <Button
                                  to={`${chain.blockExplorers['default'].url}/tx/${hashItem.hashItem}`}
                                  target={'_blank'}
                                  rel="noreferrer"
                                  type={'link'}
                                  size={'xs'}
                                >
                                  Check on Polygon
                                </Button>
                              </>
                            ) : (
                              <>
                                <div className="flex items-center">
                                  <span className="mr-2">
                                    <img src={ErrorTx} className="h-8 w-8" />
                                  </span>
                                  <span>
                                    {transactions[hashItem.hashItem].titleTx}
                                  </span>
                                </div>
                                <Button
                                  to={`${chain.blockExplorers['default'].url}/tx/${hashItem.hashItem}`}
                                  target={'_blank'}
                                  rel="noreferrer"
                                  type={'link'}
                                  size={'xs'}
                                >
                                  Check on Polygon
                                </Button>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <div className="flex items-center">
                              <span className="mr-2">
                                <img src={PendingTx} className="h-6 w-6" />
                              </span>
                              <span>
                                {transactions[hashItem.hashItem].titleTx}
                              </span>
                            </div>
                            <div className="btn-default btn-sm-link items-center opacity-50 p-3 pointer-events-none">
                              Pending...
                              <div
                                style={{
                                  borderTopColor: 'transparent',
                                }}
                                className="w-6 h-6 ml-3 border-4 border-blue-400 border-solid rounded-full animate-spin"
                              ></div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          </CustomScrollbar>
        ) : (
          <div className="text-center no-transaction-box">
            <div className="bg-gradient p-5 rounded-md">
              No transactions to show...
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};
