import { useCallback, useContext, useEffect, useState } from 'react';
import { useNetwork } from 'wagmi';

import { GlobalContext, UserContext } from '../context';
import { ChainIdType, GraphData, RefetchState } from '../interfaces';
import {
  aaveClient,
  creamClient,
  subgraphClientByNetwork,
} from '../subgraph/client';
import {
  USER_AAVE_BORROW_HISTORY,
  USER_CREAM_BORROW_HISTORY,
  userQueryByNetwork,
} from '../subgraph/queries';

export default function useGraphData(): [RefetchState, GraphData] {
  const { chain } = useNetwork();
  const { dispatch } = useContext(GlobalContext);
  const {
    state: { wallet },
  } = useContext(UserContext);

  const [graphData, setGraphData] = useState<GraphData>({
    activities: [],
    totalData: {},
  });
  const [loadingGraphData, setLoadingGraphData] = useState<RefetchState>({
    isLoading: false,
    error: null,
    refetch: null,
  });

  const fetchGraphData = useCallback(async () => {
    const graphData = { activities: [], totalData: {} };

    if (wallet === '' && !chain) return graphData;

    const { smartWallets } = await subgraphClientByNetwork[chain.id].request(
      userQueryByNetwork[chain.id](wallet.toLowerCase()),
    );

    if (smartWallets.length === 0) {
      return graphData;
    }

    const activities = smartWallets[0].activities;
    graphData.activities = activities;

    if (chain.id === ChainIdType.POLYGON) {
      const { user } = await aaveClient.request(
        USER_AAVE_BORROW_HISTORY(wallet.toLowerCase()),
      );

      if (user && user.borrowHistory) {
        for (const activity of user.borrowHistory) {
          activities.push({
            type: 'Borrow',
            transactionHash: activity.id.split(':')[0],
            timestamp: activity.timestamp,
            amount: activity.amount / 10 ** activity.reserve.decimals,
            symbol: activity.reserve.symbol,
            token: activity.reserve.underlyingAsset,
          });
        }
      }

      const { borrowEvents } = await creamClient.request(
        USER_CREAM_BORROW_HISTORY(wallet.toLowerCase()),
      );

      for (const event of borrowEvents) {
        activities.push({
          type: 'Borrow',
          transactionHash: event.id.split('-')[0],
          timestamp: event.blockTime,
          amount: event.amount,
          symbol: event.underlyingSymbol,
        });
      }

      const totalData = {};

      for (const data of smartWallets[0].totalData) {
        totalData[data.symbol] = {
          ...data,
        };
      }

      graphData.totalData = totalData;
    }

    return graphData;
  }, [chain, wallet]);

  useEffect(() => {
    setLoadingGraphData((pastLoadingGraphData) => ({
      ...pastLoadingGraphData,
      isLoading: true,
    }));

    fetchGraphData()
      .then((graphData) => {
        setGraphData(graphData);
        dispatch({
          type: 'SET_GRAPH_DATA',
          payload: graphData,
        });
        setLoadingGraphData((pastLoadingGraphData) => ({
          ...pastLoadingGraphData,
          refetch: fetchGraphData,
          isLoading: false,
        }));
      })
      .catch(() => {
        const errorToShow = new Error('Error fetching graph data');
        setLoadingGraphData((pastLoadingGraphData) => ({
          ...pastLoadingGraphData,
          refetch: fetchGraphData,
          error: errorToShow,
        }));
      });
  }, [chain, fetchGraphData]);

  return [loadingGraphData, graphData];
}
