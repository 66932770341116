import { ReactNode } from 'react';

import { Card } from '../Card/index';

export const NoRecord = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <Card className={'no-record-card'}>{children}</Card>
    </>
  );
};
