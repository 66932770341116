import React, { useContext, useEffect, useState } from 'react';

// CONTEXT
import { GlobalContext } from '../../../../context';
import {
  formatCurrency,
  formatDecimals,
  formatRate,
  formatWeiSymbol,
  getIcon,
  getTokenDecimals,
  getTokenInfo,
} from '../../../../context/utils';
import { Modal } from '../../../atoms/Modal';
// COMPONENTS
import { DecimalAssetSelect } from '../../../molecules/AssetsSelect/Decimal';

type Props = {
  close: () => void;
  symbol: string;
  dataBorrow: any;
};

const Borrowing: React.FC<Props> = ({ close, symbol, dataBorrow }) => {
  const {
    state: { priceData, tokensData, tokenList, actions },
  } = useContext(GlobalContext);
  const [selected, setSelected] = useState({
    symbol: '',
    icon: '',
    decimals: 0,
    protocol: '',
    amount: 0,
    balance: 0,
  });
  const [selectedProtocol, setSelectedProtocol] = useState<string>('aave');
  const [amount, setAmount] = useState<number>(0);
  const [amountWei, setAmountWei] = useState<string>('');
  const [pending, setPending] = useState<boolean>(false);
  const [loanToValue, setLoanToValue] = useState<number>(0);
  const [noBalance, setNoBalance] = useState<boolean>(false);
  const [protection, setProtection] = useState<number>(0.1);
  const [disabled, setDisabled] = useState<boolean>(false);

  const _selected = dataBorrow[selectedProtocol];
  const _symbol = symbol === 'MATIC' ? 'WMATIC' : symbol;

  const handleAmount = (amt) => {
    if (amt === -1) {
      //User actions related
      const _amount = selected.balance / 10 ** selected.decimals;
      setAmount(_amount);
      setAmountWei(String(selected.balance));
      setLoanToValue(
        (_selected.totalBorrowedUSD + _amount * priceData[_symbol]) /
          _selected.totalCollateralUSD,
      );
    } else {
      setAmount(amt);
      setAmountWei(formatDecimals(symbol, amt, tokenList));

      setLoanToValue(
        (_selected.totalBorrowedUSD + amt * priceData[symbol]) /
          _selected.totalCollateralUSD,
      );

      // Balance check
      if (amt > formatWeiSymbol(symbol, selected.balance, tokenList))
        setNoBalance(true);
      else setNoBalance(false);
    }
  };

  const executeBorrow = async () => {
    //  User related action
    try {
      setPending(true);
      await actions.handleBorrow({
        token: getTokenInfo(selected.symbol, tokenList).address,
        amount: amountWei,
        protocol: selectedProtocol,
      });
      setPending(false);
      //await fetchUserBorrowData();
      close();
    } catch (error) {
      setPending(false);
    }
  };

  const updateSelected = () => {
    setAmount(0);

    setLoanToValue(_selected.userLTV);

    const availableBorrowUSD =
      _selected.totalCollateralUSD * (_selected.maxLTV - protection) -
      _selected.totalBorrowedUSD;

    setSelected({
      symbol,
      icon: getIcon(symbol, tokenList),
      decimals: getTokenDecimals(symbol, tokenList),
      protocol: selectedProtocol,
      amount: 0,
      balance: Number(
        formatDecimals(
          symbol,
          availableBorrowUSD / priceData[_symbol],
          tokenList,
        ),
      ),
    });
  };
  const handleBorrowRate = (symbol) => {
    const itemFiltered = tokensData.filter((item) => {
      if (item.symbol === symbol) {
        return item;
      }
    });
    if (selectedProtocol === 'aave') {
      return formatRate(itemFiltered[0].aaveData.borrowRate);
    } else return formatRate(itemFiltered[0].creamData.borrowRate);
  };

  const handleRadio = (e) => {
    setSelectedProtocol(e.target.value);
  };

  const handleCurrentBorrowed = (_symbol) => {
    const aaveLoan = dataBorrow.loans.filter(
      (element) => element.symbol === _symbol && element.protocol === 'aave',
    )[0];
    const creamLoan = dataBorrow.loans.filter(
      (element) => element.symbol === _symbol && element.protocol === 'cream',
    )[0];

    if (selectedProtocol === 'aave' && aaveLoan !== undefined) {
      return aaveLoan.amountUSD;
    }

    if (selectedProtocol === 'cream' && creamLoan !== undefined) {
      return creamLoan.amountUSD;
    }

    return 0;
  };

  useEffect(() => {
    updateSelected();
  }, [selectedProtocol]);

  useEffect(() => {
    if (disabled) {
      setProtection(0);
    } else {
      setProtection(0.1);
    }
    updateSelected();
  }, [disabled, protection]);

  return (
    <Modal close={close} closeButton size={'sm'} pending={pending}>
      <div className="heading">Borrow {symbol}</div>
      <div className="swap-modal-box">
        <div className="group">
          <div className="borrow-section items-start">
            <div className="flex-1">
              <div className="info-box">
                <label className="label-text">Protocol</label>
                <div className="select-layout">
                  <div className="select-orientation">
                    <div className="radio-check-outer">
                      <label className="radio-check">
                        <input
                          type="radio"
                          name="protocol"
                          value={'aave'}
                          defaultChecked={selectedProtocol === 'aave'}
                          onChange={handleRadio}
                        />{' '}
                        <span>Aave V2</span>{' '}
                      </label>
                    </div>
                    <div className="radio-check-outer">
                      <label className="radio-check">
                        <input
                          type="radio"
                          name="protocol"
                          value={'cream'}
                          defaultChecked={selectedProtocol === 'cream'}
                          onChange={handleRadio}
                        />{' '}
                        <span>Cream</span>{' '}
                      </label>
                    </div>
                  </div>
                </div>
              </div>{' '}
              <div className="info-box">
                <label className="label-text">
                  Borrowed amount
                  <span className="hint-box hidden">
                    <i className="hint-box-icon">?</i>
                  </span>
                </label>
                <span>
                  {formatCurrency(handleCurrentBorrowed(symbol), 2)} USD
                </span>
              </div>
            </div>
            <div className="flex-1">
              <div className="info-box">
                <label className="label-text">
                  Borrow APY
                  <span
                    className="hint-box"
                    data-tooltip={
                      'The Borrow APY represents the current interest rate you pay for borrowing assets. The interest rate derives from the lending and borrowing ratio of an Asset.'
                    }
                  >
                    <i className="hint-box-icon">?</i>
                  </span>
                </label>
                <span>{handleBorrowRate(symbol)}%</span>
              </div>
              <div className="info-box">
                <label className="label-text">
                  Total Collateral
                  <span
                    className="hint-box"
                    data-tooltip={
                      'The total collateral represents your total assets deposited into the underlying lending market. Certain assets can be used as a hedge to borrow up to 85% of their value for a different asset.'
                    }
                  >
                    <i className="hint-box-icon">?</i>
                  </span>
                </label>
                <span>
                  {formatCurrency(_selected.totalCollateralUSD, 2)} USD
                </span>
              </div>
              <div className="info-box">
                <label className="label-text">
                  Total Borrowed
                  <span
                    className="hint-box"
                    data-tooltip={
                      'The total borrowed amount represents the total amount you have borrowed from the underlying lending market.'
                    }
                  >
                    <i className="hint-box-icon">?</i>
                  </span>
                </label>
                <span>{formatCurrency(_selected.totalBorrowedUSD, 2)} USD</span>
              </div>
            </div>
          </div>
          <div className="borrow-box">
            <div className="from-wallet-box">
              <div className="from-option-box">
                <DecimalAssetSelect
                  //placeholder={'Amount to borrow'}
                  amount={amount}
                  selected={selected}
                  isInput
                  showBalance
                  handleAmount={handleAmount}
                />
              </div>
            </div>
            <div className="divider-line"></div>
            <div className="borrow-projected-info">
              <div className="title-new2 text-center">
                Projected Debt Position
              </div>
              <div className="info-box-group">
                <div className="info-box">
                  <label className="label-text">
                    Status (Max. {formatRate(_selected.maxLTV)}
                    %){' '}
                  </label>
                  <div className="status-info-box flex">
                    <span className="color-white">
                      {formatRate(loanToValue)}%
                    </span>
                    <span className="status-info-bar">
                      <span
                        style={{
                          width: `${
                            loanToValue > 1
                              ? _selected.maxLTV * 100
                              : (loanToValue / _selected.maxLTV) * 100
                          }%`,
                          backgroundColor:
                            loanToValue > _selected.maxLTV * 0.75
                              ? 'red'
                              : 'green',
                        }}
                      ></span>
                    </span>
                  </div>
                  <div className="checkbox-box borrowing-checkbox ">
                    <input
                      className="checkbox"
                      type="checkbox"
                      name="protection"
                      value={disabled.toString()}
                      onClick={() => {
                        setDisabled(!disabled);
                      }}
                    />
                    <label htmlFor="protection">
                      Disable protection
                      <span
                        className="hint-box"
                        data-tooltip={
                          'The protection function provides a 10% cushion to mitigate immediate liquidation when maximizing the utilization rate of your collateral for borrowing an asset.'
                        }
                      >
                        <i className="hint-box-icon">?</i>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="swap-button-box">
            <div className="btn-outer">
              <button
                type="button"
                className={`btn-main lg  ${
                  pending || noBalance ? 'disabled' : 'full'
                }`}
                onClick={executeBorrow}
                disabled={pending || noBalance}
              >
                <span>
                  {pending
                    ? 'BORROWING'
                    : noBalance
                    ? 'Not enough funds!'
                    : 'BORROW'}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Borrowing;
