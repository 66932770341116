import { useState } from 'react';

import { Radio, RadioItem } from '../../atoms/RadioButton';

type Props = {
  propertyOne: string;
  propertyTwo: string;
  propertyOneView?: string;
  propertyTwoView?: string;
  userView: string;
  setUserView: (arg0: any) => void;
};

const Selector = ({
  propertyOne,
  propertyTwo,
  propertyOneView = '',
  propertyTwoView = '',
  userView,
  setUserView,
}: Props) => {
  const [selected, setSelected] = useState(userView);

  const handleRadio = (e) => {
    setSelected(e.target.value);
    setUserView(e.target.value);
  };

  return (
    <>
      <Radio theme={'button'}>
        <RadioItem
          name="assets"
          value={propertyOne}
          defaultChecked={selected === propertyOne}
          handleRadio={handleRadio}
        >
          {propertyOneView === '' ? propertyOne : propertyOneView}
        </RadioItem>
        <RadioItem
          name="assets"
          value={propertyTwo}
          defaultChecked={selected === propertyTwo}
          handleRadio={handleRadio}
        >
          {propertyTwoView === '' ? propertyTwo : propertyTwoView}
        </RadioItem>
      </Radio>
    </>
  );
};

export default Selector;
