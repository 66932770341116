import { Contract } from 'ethers';

import { Actions } from '../context/actions';

export enum UserWallet {
  smartWallet = 'smartWallet',
  web3Wallet = 'web3Wallet',
}

export enum UserView {
  lock = 'lock',
  claim = 'claim',
}
export enum UserIncreaseView {
  increaseAmount = 'Increase Amount',
  increaseTime = 'Increase Time',
}

export enum ChainIdType {
  POLYGON = 137,
  AVALANCHE = 43114,
}

export interface DepositToken {
  _id: string;
  symbol: string;
  icon: string;
  decimals: number;
  address: string;
  name?: string;
}

export interface GraphData {
  activities: any[];
  totalData: any;
}

export interface VaultVersion {
  distributionType: string;
  isCompounding: boolean;
  isLp: boolean;
}

export interface PriceData {
  [key: string]: number;
}

export interface Token {
  address: string;
  chainId: ChainIdType;
  name: string;
  symbol: string;
  decimals: number;
  id: string;
  logoURI: string;
  isStableCoin?: boolean;
  custom?: boolean;
}

export interface GlobalInitialState {
  tokenList: Token[] | [];
  graphData: GraphData | null;
  priceData: PriceData;
  tokensData: TokenData[];
  borrowData: BorrowData[] | [];
  stakingData: Stake[] | [];
  vaultData: Vault[] | [];
  earningsClaimed: number;
  actions: Actions | null;
  contracts: { [key: string]: Contract };
}

export interface Vault {
  type: string;
  version: VaultVersion;
  rewards: string[];
  vaultAddress: string;
  deposit: DepositToken[];
  ethaPrice: number;
  vaultDeposits: number;
  feeApy: number;
  rapy: number;
  apy: number;
  dApy: number;
  performanceFee: number;
  withdrawalFee: number;
  stakingToken: string;
  earn: string;
  earnIcon: string;
  chainId: string;
  distributionAddress: string;
  volatility: string;
  protocol: string;
  active: boolean;
  strategyAddress: string;
  name?: string;
  id: string;
}
export interface Stake {
  id: number;
  deposit: DepositToken[];
  earn: string;
  earnIcon: string;
  volatility: string;
  stakingToken: string;
  stakingContract: string;
  claimTime: number;
  type: string;
  apy: number;
  totalStaked: number;
  reserves: any | null;
  daysToClaim?: string;
  claim?: boolean;
  name?: string;
}

export interface ProtocolParams {
  supplyRate?: number;
  borrowRate: number;
  liquidity?: number;
  ltv: number;
  threshold: number;
  isCollateral: boolean;
  totalBorrowed: number;
  totalBorrowedUSD?: number;
  borrowRewardRate?: number;
  name?: string;
}
export interface Aggregate {
  liquidity: number;
  liquidityUsd: number;
  supplyRate: number;
  borrowRate: number;
  rewardsRate: number;
  distributionRate: number;
}
export interface TokenData {
  aaveData: ProtocolParams;
  creamData: ProtocolParams;
  aggregate: Aggregate;
  symbol: string;
  name: string;
}

export interface BorrowData {
  symbol: string;
  icon: string;
  protocol: string;
  aaveData: ProtocolParams;
  creamData: ProtocolParams;
  token: string;
  name: string;
  loans?: any[];
}

export interface LoadingState {
  isLoading: boolean;
  error: unknown;
}
export interface GlobalLoading {
  loader: boolean;
  loadingStakeData: RefetchState;
  loadingTokenListData: RefetchState;
  loadingVaultData: RefetchState;
  loadingLendingData: RefetchState;
  loadingPriceData: RefetchState;
  loadingBorrowingData: RefetchState;
}

export interface RefetchState extends LoadingState {
  refetch: () => void;
}

export interface UserData {
  loadingUserVaultData: RefetchState;
  loadingUserLendingData: RefetchState;
  loadingUserStakeData: RefetchState;
  loadingUserBorrowingData: RefetchState;
}

export interface BorrowModal {
  open: boolean;
  dataBorrow?: BorrowData | undefined;
  protocol?: string;
  symbol?: string;
}
