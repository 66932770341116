import gql from 'graphql-tag';

export const USER_DATA_POLYGON = (wallet) => {
  const queryString = `
    query  {
      smartWallets(where: {address: "${wallet}"}) {
				activities {
					type
					token
					token1
					symbol
					symbol1
					amount
					timestamp
					transactionHash
				}
				totalData{
					totalRedeemed
					totalSupplied
					totalEarned
					symbol
				}
      }
    }`;
  return gql(queryString);
};

export const USER_DATA_AVAX = (wallet) => {
  const queryString = `
    query  {
      smartWallets(where: {address: "${wallet}"}) {
				activities {
					type
					token
					token1
					symbol
					symbol1
					amount
					timestamp
					transactionHash
				}
      }
    }`;
  return gql(queryString);
};

export const userQueryByNetwork = {
  137: USER_DATA_POLYGON,
  43114: USER_DATA_AVAX,
};

export const PAIR_DATA = (pair) => {
  const queryString = `
    query  {
        pairs(where:{id:"${pair}"}) {
            id
            token0{id symbol}
            token1{id symbol}
            reserve0
            reserve1
            totalSupply
						token0Price
						token1Price
        }
      }`;
  return gql(queryString);
};

export const USER_CREAM_DATA = (account) => {
  const queryString = `
    query  {
			account(id:"${account}") {
				tokens{
					id
					symbol
					enteredMarket
					totalUnderlyingSupplied
					totalUnderlyingRedeemed
					totalUnderlyingBorrowed
					totalUnderlyingRepaid
					storedBorrowBalance
					market{
						collateralFactor
						totalBorrows
						underlyingAddress
					}
				}
				hasBorrowed
			}
		}`;
  return gql(queryString);
};

export const USER_AAVE_DATA = (account) => {
  const queryString = `
    query  {
			user(id:"${account}") {
				reserves{
					reserve{
						symbol
						decimals
						usageAsCollateralEnabled
						price{
							priceInEth
						}
					}
					currentATokenBalance
					currentVariableDebt
				}
			}
		}`;
  return gql(queryString);
};

export const GLOBAL_CREAM_DATA = () => {
  const queryString = `
    query  {
			markets(where:{underlyingSymbol_in:["WMATIC","DAI","USDC","USDT","WETH","WBTC"]}) {
				underlyingAddress
				underlyingSymbol
				underlyingDecimals
				underlyingPriceUSD
				collateralFactor
				borrowRate
				totalBorrows
			}
		}`;
  return gql(queryString);
};

export const GLOBAL_AAVE_DATA = () => {
  const queryString = `
    query  {
			reserves(where:{symbol_in:["WMATIC","DAI","USDC","USDT","WETH","WBTC"]}) {
				underlyingAsset
				symbol
				decimals
				baseLTVasCollateral
				usageAsCollateralEnabled
				reserveLiquidationThreshold
				variableBorrowRate
				totalCurrentVariableDebt
			}
		}`;
  return gql(queryString);
};

export const USER_AAVE_BORROW_HISTORY = (account) => {
  const queryString = `
    query  {
			user(id:"${account}") {
				borrowHistory{
					id
					amount
					reserve{
						symbol
						decimals
						underlyingAsset
					}
					timestamp
				}
			}
		}`;
  return gql(queryString);
};

export const USER_CREAM_BORROW_HISTORY = (account) => {
  const queryString = `
    query  {
			borrowEvents(where:{borrower:"${account}"}) {
				id
				amount
				underlyingSymbol
				blockTime
			}
		}`;
  return gql(queryString);
};
