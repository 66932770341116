import QRCode from 'qrcode.react';
import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useNetwork } from 'wagmi';

import { Address } from '../../atoms/Address/index';
import { Tooltip, TooltipContent, TooltipLabel } from '../../atoms/Tooltip';
import QRCodeView from '../../organisms/Modals/QrCodeView';

export const AddressQR = ({
  wallet,
  isTrim,
  isHeader,
}: {
  wallet: any;
  isTrim?: boolean;
  isHeader?: boolean;
}) => {
  const { chain } = useNetwork();
  const [qrExpandView, setQrExpandView] = useState<boolean>(false);
  const [copied, setCopied] = useState<boolean>(false);
  const handleQrExpand = () => {
    setQrExpandView(!qrExpandView);
  };

  return (
    <>
      <div className="barcode-box flex items-center">
        <div className="barcode-left" onClick={handleQrExpand}>
          <QRCode value={wallet || ''} />
        </div>
        <a
          href={
            chain
              ? `${chain.blockExplorers['default']}/address/${wallet}`
              : `https://polygonscan.com/address/${wallet}`
          }
          target={'_blank'}
          className="barcode-midlle flex-auto"
          rel="noreferrer"
        >
          <Address wallet={wallet} isTrim={isTrim} />
        </a>
        <div className="barcode-right">
          {copied ? (
            <Tooltip>
              <TooltipLabel>
                <i className="icon-copy icon-copy-success"></i>
              </TooltipLabel>
              <TooltipContent>Copied!</TooltipContent>
            </Tooltip>
          ) : (
            <Tooltip>
              <TooltipLabel>
                <CopyToClipboard text={wallet} onCopy={() => setCopied(true)}>
                  <i className="icon-copy"></i>
                </CopyToClipboard>
              </TooltipLabel>
              <TooltipContent>Copy to clipboard</TooltipContent>
            </Tooltip>
          )}
        </div>
      </div>
      {qrExpandView && (
        <div
          className={
            isHeader ? 'header-barcode-expand' : 'deposit-barcode-expand'
          }
        >
          <QRCodeView close={handleQrExpand} />
        </div>
      )}
    </>
  );
};
