import { useState } from 'react';

import styles from './styles.module.scss';

type Props = {
  propertyOne: string;
  propertyTwo: string;
  propertyOneView?: string;
  propertyTwoView?: string;
  userView: string;
  setUserView: (arg0: any) => void;
};

const Tab = ({
  propertyOne,
  propertyTwo,
  propertyOneView = '',
  propertyTwoView = '',
  userView,
  setUserView,
}: Props) => {
  const [selected, setSelected] = useState(userView);

  const handleRadio = (e) => {
    setSelected(e.target.value);
    setUserView(e.target.value);
  };

  return (
    <div className={styles.tabContainer}>
      <div
        className={`${styles.tab} ${
          selected === propertyOne ? styles.checked : ''
        }`}
      >
        <input
          type="radio"
          name="tabs"
          id={propertyOne}
          value={propertyOne}
          onClick={(e) => handleRadio(e)}
        />
        <label htmlFor={propertyOne}>{propertyOneView}</label>
      </div>
      <div
        className={`${styles.tab} ${
          selected === propertyTwo ? styles.checked : ''
        }`}
      >
        <input
          type="radio"
          id={propertyTwo}
          name="tabs"
          value={propertyTwo}
          onClick={(e) => handleRadio(e)}
          className={styles.tab}
        />
        <label htmlFor={propertyTwo}>{propertyTwoView}</label>
      </div>
    </div>
  );
};

export default Tab;
