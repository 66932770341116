import { ContractTransaction } from 'ethers';
import React, { useContext, useEffect, useState } from 'react';
import { useNetwork, useProvider } from 'wagmi';

import { GlobalContext, UserContext } from '../../../../context';
import { AAVE_DEBT_TOKENS } from '../../../../context/constants';
import {
  formatNumber,
  formatWei,
  fromWei,
  getAddressToken,
  getTokenDecimals,
  getTokenIcon,
} from '../../../../context/utils';
import { useContract } from '../../../../hooks/useContract';
import { ChainIdType } from '../../../../interfaces';
import { Button } from '../../../atoms/Button';
import { Modal } from '../../../atoms/Modal';
import { Symbol } from '../../../atoms/Symbol';
import SimpleAssetSelect from '../../../molecules/AssetsSelect/Simple';
import { TypeEnum } from '../../Toast';

type Props = {
  close: () => void;
  reFetch?: () => void;
  data: any;
};

const Claim: React.FC<Props> = ({ close, reFetch, data }) => {
  const {
    state: { priceData, tokenList, actions, contracts },
    setToast,
    dispatch: globalDispatch,
  } = useContext(GlobalContext);
  const {
    state: { wallet, earningsClaimed },
    dispatch,
    transactions,
    setTransactions,
  } = useContext(UserContext);
  const { chain } = useNetwork();
  const provider = useProvider();

  const [selected, setSelected] = useState<any>({
    ethaRewards: 0,
    symbol: '',
    type: '',
    tokens: [],
    token: [],
    decimals: 0,
    earn: '',
    earnIcon: '',
    balance: 0,
    icon: '',
  });
  const [amount, setAmount] = useState<string>('');
  const [pending, setPending] = useState(false);

  const executeClaim = async () => {
    try {
      setPending(true);
      let initialTx: ContractTransaction;

      if (selected.type === 'Lending') {
        initialTx = await actions.handleLendingClaim(
          selected.tokens,
          amount,
          selected.token,
        );

        setToast({
          open: true,
          type: TypeEnum.Pending,
          title: 'Pending transaction',
          timer: 14000,
          message: (
            <a
              href={
                chain
                  ? `${chain.blockExplorers['default'].url}/tx/${initialTx.hash}`
                  : `https://polygonscan.com/tx/${initialTx.hash}`
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              Check on block explorer
            </a>
          ),
        });

        transactions[initialTx.hash] = {
          from: initialTx.from,
          hash: initialTx.hash,
          titleTx: 'Claiming in Lending',
        };

        setTransactions(transactions);

        const receiptTx = await initialTx.wait();

        transactions[initialTx.hash].receipt = {
          blockHash: receiptTx.blockHash,
          blockNumber: receiptTx.blockNumber,
          contractAddress: receiptTx.contractAddress,
          from: receiptTx.from,
          status: receiptTx.status,
          to: receiptTx.to,
          transactionHash: receiptTx.transactionHash,
          transactionIndex: receiptTx.transactionIndex,
        };

        setTransactions(transactions);

        dispatch({
          type: 'SET_EARNINGS_CLAIMED',
          payload:
            earningsClaimed +
            (priceData['MATIC'] * Number(amount) +
              priceData['ETHA'] * Number(selected.ethaRewards)),
        });
      }

      if (selected.type === 'Vault') {
        initialTx = await actions.handleVaultClaim(
          selected,
          selected.ethaRewards,
        );

        setToast({
          open: true,
          type: TypeEnum.Pending,
          title: 'Pending transaction',
          timer: 14000,
          message: (
            <a
              href={`https://polygonscan.com/tx/${initialTx.hash}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Check on block explorer
            </a>
          ),
        });

        transactions[initialTx.hash] = {
          from: initialTx.from,
          hash: initialTx.hash,
          titleTx: 'Claiming in eVault',
        };

        setTransactions(transactions);

        const receiptTx = await initialTx.wait();

        reFetch();

        transactions[initialTx.hash].receipt = {
          blockHash: receiptTx.blockHash,
          blockNumber: receiptTx.blockNumber,
          contractAddress: receiptTx.contractAddress,
          from: receiptTx.from,
          status: receiptTx.status,
          to: receiptTx.to,
          transactionHash: receiptTx.transactionHash,
          transactionIndex: receiptTx.transactionIndex,
        };

        setTransactions(transactions);

        dispatch({
          type: 'SET_EARNINGS_CLAIMED',
          payload:
            earningsClaimed +
            (priceData[selected.earn] * Number(amount) +
              priceData['ETHA'] * Number(selected.ethaRewards)),
        });
      }

      if (selected.type === 'Staking') {
        initialTx = await actions.handleStakingClaim(selected);

        setToast({
          open: true,
          type: TypeEnum.Pending,
          title: 'Pending transaction',
          timer: 14000,
          message: (
            <a
              href={
                chain
                  ? `${chain.blockExplorers['default'].url}/tx/${initialTx.hash}`
                  : `https://polygonscan.com/tx/${initialTx.hash}`
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              Check on block explorer
            </a>
          ),
        });

        transactions[initialTx.hash] = {
          from: initialTx.from,
          hash: initialTx.hash,
          titleTx: 'Claiming in Staking',
        };

        setTransactions(transactions);

        const receiptTx = await initialTx.wait();

        reFetch();

        transactions[initialTx.hash].receipt = {
          blockHash: receiptTx.blockHash,
          blockNumber: receiptTx.blockNumber,
          contractAddress: receiptTx.contractAddress,
          from: receiptTx.from,
          status: receiptTx.status,
          to: receiptTx.to,
          transactionHash: receiptTx.transactionHash,
          transactionIndex: receiptTx.transactionIndex,
        };

        setTransactions(transactions);
      }

      if (selected.type === 'Borrowing') {
        initialTx = await actions.handleBorrowingClaim(selected.tokens, amount);

        setToast({
          open: true,
          type: TypeEnum.Pending,
          title: 'Pending transaction',
          timer: 14000,
          message: (
            <a
              href={
                chain
                  ? `${chain.blockExplorers['default'].url}/tx/${initialTx.hash}`
                  : `https://polygonscan.com/tx/${initialTx.hash}`
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              Check on block explorer
            </a>
          ),
        });

        await initialTx.wait();

        dispatch({
          type: 'SET_EARNINGS_CLAIMED',
          payload: earningsClaimed + priceData['MATIC'] * Number(amount),
        });
      }

      setToast({
        open: true,
        type: TypeEnum.Success,
        title: 'Success in the claiming',
        timer: 14000,
      });

      setPending(false);
      close();
    } catch (error) {
      setToast({
        open: true,
        type: TypeEnum.Error,
        title: 'Error in the claiming...',
        timer: 14000,
        message: error.message,
      });
      console.error(error);
      setPending(false);
    }
  };

  const fetchRewards = async () => {
    const memory = useContract(
      'Memory',
      provider,
      contracts,
      globalDispatch,
      true,
      ChainIdType.POLYGON,
    );
    const aaveIncentives = useContract(
      'AdapterAaveIncentives',
      provider,
      contracts,
      globalDispatch,
      true,
      ChainIdType.POLYGON,
    );

    try {
      if (data.type === 'Lending') {
        const aToken = await memory.getAToken(data.token);
        const _rewards = await aaveIncentives.getRewardsBalance(
          [aToken],
          wallet,
        );

        setAmount(String(data.rewards));

        setSelected({
          ...data,
          balance: _rewards,
          decimals: 18,
          symbol: 'MATIC',
          icon: getTokenIcon('MATIC', tokenList),
          tokens: [aToken],
          ethaRewards: data.ethaRewards,
        });
      }

      if (data.type === 'Vault') {
        if (!data?.version?.isCompounding) {
          setAmount(String(data.earned));
          setSelected({
            ...data,
            balance: data.earned,
            decimals: getTokenDecimals(data.earn, tokenList),
            symbol: data.earn,
            icon: getTokenIcon(data.earn, tokenList),
            ethaRewards: data.ethaRewards,
          });
        } else {
          setAmount(String(data.ethaRewards));
          setSelected({
            ...data,
            balance: data.ethaRewards,
            decimals: getTokenDecimals('ETHA', tokenList),
            symbol: 'ETHA',
            icon: getTokenIcon('ETHA', tokenList),
            ethaRewards: data.ethaRewards,
          });
        }
      }

      if (data.type === 'Staking') {
        setAmount(fromWei(data.earned));
        setSelected({
          ...data,
          balance: data.earned,
          decimals: getTokenDecimals(data.earn, tokenList),
          symbol: data.earn,
          icon: getTokenIcon(data.earn, tokenList),
          ethaRewards: 0,
        });
      }
      if (data.type === 'Borrowing') {
        const dToken = AAVE_DEBT_TOKENS[data.symbol];

        const _rewards = await aaveIncentives.getRewardsBalance(
          [dToken],
          wallet,
        );

        setAmount(
          String(
            formatWei(getAddressToken('MATIC', tokenList), _rewards, tokenList),
          ),
        );

        setSelected({
          ...data,
          balance: _rewards,
          decimals: 18,
          symbol: 'MATIC',
          icon: getTokenIcon('MATIC', tokenList),
          tokens: [dToken],
        });
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    fetchRewards();
  }, []);

  const buttonDisabled =
    pending || (Number(amount) === 0 && selected.ethaRewards === 0);

  return (
    <Modal close={close} closeButton size={'sm'} pending={pending}>
      <div className="heading text-center">Claim Rewards</div>
      <div className="swap-modal-box">
        <div className="group">
          <div className="from-wallet-box">
            <div className="from-option-box">
              <SimpleAssetSelect
                label={'Amount'}
                options={[]}
                amount={amount}
                selected={selected}
              />
            </div>
          </div>
          {data.type !== 'Staking' &&
            data.type !== 'Borrowing' &&
            !data?.version?.isCompounding &&
            chain.id === ChainIdType.POLYGON && (
              <div className="select-info">
                <span className="color-green">
                  +{formatNumber(selected.ethaRewards)} ETHA
                </span>
                <Symbol
                  src={getTokenIcon('ETHA', tokenList)}
                  alt={'ETHA'}
                  size={'xs'}
                />
              </div>
            )}
          <div className="swap-button-box">
            <Button
              className={buttonDisabled ? 'disabled' : 'full'}
              handleClick={executeClaim}
              disabled={buttonDisabled}
              size={'lg'}
            >
              {pending ? 'Claiming' : 'Claim'}{' '}
              {data.type === 'Lending'
                ? 'MATIC'
                : data?.version?.isCompounding
                ? 'ETHA'
                : data.earn}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Claim;
