import React from 'react';
import { useAccount, useNetwork, UserRejectedRequestError } from 'wagmi';

import { connectorsByName } from '../../../../connectors';
import { switchChains } from '../../../../context/utils';
import { ChainIdType } from '../../../../interfaces';
import GnosisIcon from '../../../../styles/img/gnosis_safe.png';
import ImTokenIcon from '../../../../styles/img/imtoken.svg';
import MetamaskIcon from '../../../../styles/img/metamask.svg';
import UDIcon from '../../../../styles/img/ud.svg';
import WalletConnectIcon from '../../../../styles/img/walletconnect.svg';
import { Button } from '../../../atoms/Button';
import { Modal } from '../../../atoms/Modal/index';

type Props = {
  close: () => void;
  connectionWallet: boolean;
  error: Error;
  loading: boolean;
  setUsedConnector: React.Dispatch<React.SetStateAction<string>>;
  pendingConnector: any;
};

export const WalletButton: React.FC<Props> = ({
  close,
  setUsedConnector,
  pendingConnector,
  error,
  loading,
}) => {
  const { connector, isConnected, address: account } = useAccount();
  const { chain } = useNetwork();

  function showIsConnectedConnector() {
    switch (connector) {
      case connectorsByName['Metamask']:
        return (
          <>
            <div className="heading-new">Connected to Metamask</div>
            <div className="wallet-button-box">
              {chain?.unsupported ? (
                <>
                  <ul>
                    <li className="wallet-connect-error">
                      <div className="wallet-info-text">
                        Unsupported network, please change it.
                      </div>
                    </li>
                    <li className="wallet-btn-outer">
                      <Button
                        className="default full"
                        size={'md'}
                        handleClick={() =>
                          switchChains(ChainIdType.POLYGON, close)
                        }
                      >
                        Switch Network
                      </Button>
                    </li>
                  </ul>
                  <ul className="wallet-btn-list">
                    <li>
                      <div className="wallet-btn-item">
                        <span>MetaMask</span>
                        <img src={MetamaskIcon} alt="MetaMask" />
                      </div>
                    </li>
                  </ul>
                </>
              ) : (
                <ul className="wallet-btn-list">
                  <li>
                    <div
                      className="wallet-btn-item"
                      onClick={() => setUsedConnector('WalletConnect')}
                    >
                      <span>WalletConnect</span>
                      <img src={WalletConnectIcon} alt="WalletConnect" />
                    </div>
                  </li>
                  <li>
                    <div
                      className="wallet-btn-item"
                      onClick={() => setUsedConnector('ImToken')}
                    >
                      <span>imToken</span>
                      <img src={ImTokenIcon} alt="ImToken" />
                    </div>
                  </li>
                  <li>
                    <div
                      className="wallet-btn-item"
                      onClick={() => setUsedConnector('GnosisSafe')}
                    >
                      <span>GnosisSafe</span>
                      <img src={GnosisIcon} alt="GnosisSafe" />
                    </div>
                  </li>
                  <li>
                    <div
                      className="wallet-btn-item"
                      onClick={() => setUsedConnector('UAuth')}
                    >
                      <span>Login with Unstoppable</span>
                      <img src={UDIcon} alt="UnstoppableDomain" />
                    </div>
                  </li>
                </ul>
              )}
            </div>
            {!chain?.unsupported && (
              <div className="wallet-info-text">
                Wallets are provided by External Providers and by selecting you
                agree to Terms of those Providers. Your access to the wallet
                might be reliant on the External Provider being operational.
              </div>
            )}
          </>
        );
      case connectorsByName['WalletConnect']:
        return (
          <>
            <div className="heading-new">Connected to WalletConnect</div>
            <div className="wallet-button-box">
              {chain?.unsupported ? (
                <>
                  <ul>
                    <li className="wallet-connect-error">
                      <div className="wallet-info-text">
                        Unsupported network, please change it.
                      </div>
                    </li>
                    <li className="wallet-btn-outer">
                      <Button
                        className="default full"
                        size={'md'}
                        handleClick={() => switchChains(137, close)}
                      >
                        Switch Network
                      </Button>
                    </li>
                  </ul>
                  <ul className="wallet-btn-list">
                    <li>
                      <div className="wallet-btn-item">
                        <span>WalletConnect</span>
                        <img src={WalletConnectIcon} alt="WalletConnect" />
                      </div>
                    </li>
                  </ul>
                </>
              ) : (
                <ul className="wallet-btn-list">
                  <li>
                    <div
                      className="wallet-btn-item"
                      onClick={() => setUsedConnector('Metamask')}
                    >
                      <span>MetaMask</span>
                      <img src={MetamaskIcon} alt="MetaMask" />
                    </div>
                  </li>
                  <li>
                    <div
                      className="wallet-btn-item"
                      onClick={() => setUsedConnector('ImToken')}
                    >
                      <span>imToken</span>
                      <img src={ImTokenIcon} alt="ImToken" />
                    </div>
                  </li>
                  <li>
                    <div
                      className="wallet-btn-item"
                      onClick={() => setUsedConnector('GnosisSafe')}
                    >
                      <span>GnosisSafe</span>
                      <img src={GnosisIcon} alt="GnosisSafe" />
                    </div>
                  </li>
                  <li>
                    <div
                      className="wallet-btn-item"
                      onClick={() => setUsedConnector('UAuth')}
                    >
                      <span>Login with Unstoppable</span>
                      <img src={UDIcon} alt="UnstoppableDomain" />
                    </div>
                  </li>
                </ul>
              )}
            </div>
            {!chain?.unsupported && (
              <div className="wallet-info-text">
                Wallets are provided by External Providers and by selecting you
                agree to Terms of those Providers. Your access to the wallet
                might be reliant on the External Provider being operational.
              </div>
            )}
          </>
        );
      case connectorsByName['ImToken']:
        return (
          <>
            <div className="heading-new">Connected to imToken</div>
            <div className="wallet-button-box">
              {chain?.unsupported ? (
                <>
                  <ul>
                    <li className="wallet-connect-error">
                      <div className="wallet-info-text">
                        Unsupported network, please change it.
                      </div>
                    </li>
                    <li className="wallet-btn-outer">
                      <Button
                        className="default full"
                        size={'md'}
                        handleClick={() => switchChains(137, close)}
                      >
                        Switch Network
                      </Button>
                    </li>
                  </ul>
                  <ul className="wallet-btn-list">
                    <li>
                      <div className="wallet-btn-item">
                        <span>imToken</span>
                        <img src={ImTokenIcon} alt="ImToken" />
                      </div>
                    </li>
                  </ul>
                </>
              ) : (
                <ul className="wallet-btn-list">
                  <li>
                    <div
                      className="wallet-btn-item"
                      onClick={() => setUsedConnector('Metamask')}
                    >
                      <span>MetaMask</span>
                      <img src={MetamaskIcon} alt="MetaMask" />
                    </div>
                  </li>

                  <li>
                    <div
                      className="wallet-btn-item"
                      onClick={() => setUsedConnector('WalletConnect')}
                    >
                      <span>WalletConnect</span>
                      <img src={WalletConnectIcon} alt="WalletConnect" />
                    </div>
                  </li>
                  <li>
                    <div
                      className="wallet-btn-item"
                      onClick={() => setUsedConnector('GnosisSafe')}
                    >
                      <span>GnosisSfae</span>
                      <img src={GnosisIcon} alt="GnosisSafe" />
                    </div>
                  </li>
                  <li>
                    <div
                      className="wallet-btn-item"
                      onClick={() => setUsedConnector('UAuth')}
                    >
                      <span>Login with Unstoppable</span>
                      <img src={UDIcon} alt="UnstoppableDomain" />
                    </div>
                  </li>
                </ul>
              )}
            </div>
            {!chain?.unsupported && (
              <div className="wallet-info-text">
                Wallets are provided by External Providers and by selecting you
                agree to Terms of those Providers. Your access to the wallet
                might be reliant on the External Provider being operational.
              </div>
            )}
          </>
        );

      case connectorsByName['GnosisSafe']:
        return (
          <>
            <div className="heading-new">Connected to GnosisSafe</div>
            <div className="wallet-button-box">
              {chain?.unsupported ? (
                <>
                  <ul>
                    <li className="wallet-connect-error">
                      <div className="wallet-info-text">
                        Unsupported network, please change it.
                      </div>
                    </li>
                    <li className="wallet-btn-outer">
                      <Button
                        className="default full"
                        size={'md'}
                        handleClick={() => switchChains(137, close)}
                      >
                        Switch Network
                      </Button>
                    </li>
                  </ul>
                  <ul className="wallet-btn-list">
                    <li>
                      <div className="wallet-btn-item">
                        <span>GnosisSafe</span>
                        <img src={GnosisIcon} alt="GnosisSafe" />
                      </div>
                    </li>
                  </ul>
                </>
              ) : (
                <ul className="wallet-btn-list">
                  <li>
                    <div
                      className="wallet-btn-item"
                      onClick={() => setUsedConnector('Metamask')}
                    >
                      <span>MetaMask</span>
                      <img src={MetamaskIcon} alt="MetaMask" />
                    </div>
                  </li>
                  <li>
                    <div
                      className="wallet-btn-item"
                      onClick={() => setUsedConnector('ImToken')}
                    >
                      <span>imToken</span>
                      <img src={ImTokenIcon} alt="ImToken" />
                    </div>
                  </li>
                  <li>
                    <div
                      className="wallet-btn-item"
                      onClick={() => setUsedConnector('WalletConnect')}
                    >
                      <span>WalletConnect</span>
                      <img src={WalletConnectIcon} alt="WalletConnect" />
                    </div>
                  </li>
                  <li>
                    <div
                      className="wallet-btn-item"
                      onClick={() => setUsedConnector('UAuth')}
                    >
                      <span>Login with Unstoppable</span>
                      <img src={UDIcon} alt="UnstoppableDomain" />
                    </div>
                  </li>
                </ul>
              )}
            </div>
            {!chain?.unsupported && (
              <div className="wallet-info-text">
                Wallets are provided by External Providers and by selecting you
                agree to Terms of those Providers. Your access to the wallet
                might be reliant on the External Provider being operational.
              </div>
            )}
          </>
        );
    }
  }

  function showActivatingConnector() {
    if (pendingConnector === connectorsByName['Metamask']) {
      return (
        <div className="wallet-button-outer">
          <div className="heading-new">Connecting to MetaMask</div>
          <div className="wallet-button-box">
            {loading ? (
              <ul>
                <li>
                  <div className="wallet-loading-box">
                    <span>Initializing... </span>
                    <div>
                      <div
                        style={{ borderTopColor: 'transparent' }}
                        className="w-6 h-6 border-4 border-blue-400 border-solid rounded-full animate-spin"
                      ></div>
                    </div>
                  </div>
                </li>
                <ul className="wallet-btn-list">
                  <li>
                    <div className="wallet-btn-item">
                      <span>MetaMask</span>
                      <img src={MetamaskIcon} alt="MetaMask" />
                    </div>
                  </li>
                </ul>
              </ul>
            ) : (
              <ul>
                {!(error instanceof UserRejectedRequestError) &&
                (chain ? chain.unsupported : false) ? (
                  <>
                    <li className="wallet-connect-error">
                      <div className="wallet-info-text">
                        Unsupported network, please change it.
                      </div>
                    </li>
                    <li className="wallet-btn-outer">
                      <Button
                        className="default full"
                        size={'md'}
                        handleClick={() =>
                          switchChains(ChainIdType.POLYGON, close)
                        }
                      >
                        Switch Network
                      </Button>
                    </li>
                  </>
                ) : (
                  <li className="wallet-btn-outer btn-try-again">
                    <Button
                      className={'default full'}
                      size={'md'}
                      handleClick={() => setUsedConnector('Metamask')}
                    >
                      Error connection, try again?
                    </Button>
                  </li>
                )}

                <ul className="wallet-btn-list">
                  <li>
                    <div className="wallet-btn-item">
                      <span>MetaMask</span>
                      <img src={MetamaskIcon} alt="MetaMask" />
                    </div>
                  </li>
                </ul>
              </ul>
            )}
          </div>
        </div>
      );
    }

    if (pendingConnector === connectorsByName['GnosisSafe']) {
      return (
        <div className="wallet-button-outer">
          <div className="heading-new">Connecting to Gnosis</div>
          <div className="wallet-button-box">
            {loading ? (
              <ul>
                <li>
                  <div className="wallet-loading-box">
                    <span>Initializing... </span>
                    <div>
                      <div
                        style={{ borderTopColor: 'transparent' }}
                        className="w-6 h-6 border-4 border-blue-400 border-solid rounded-full animate-spin"
                      ></div>
                    </div>
                  </div>
                </li>
                <ul className="wallet-btn-list">
                  <li>
                    <div className="wallet-btn-item">
                      <span>GnosisSafe</span>
                      <img src={GnosisIcon} alt="GnosisSafe" />
                    </div>
                  </li>
                </ul>
              </ul>
            ) : (
              <ul>
                {!(error instanceof UserRejectedRequestError) &&
                (chain ? chain.unsupported : false) ? (
                  <>
                    <li className="wallet-connect-error">
                      <div className="wallet-info-text">
                        Unsupported network, please change it.
                      </div>
                    </li>
                    <li className="wallet-btn-outer">
                      <Button
                        className="default full"
                        size={'md'}
                        handleClick={() =>
                          switchChains(ChainIdType.POLYGON, close)
                        }
                      >
                        Switch Network
                      </Button>
                    </li>
                  </>
                ) : (
                  <li className="wallet-btn-outer btn-try-again">
                    <Button
                      className={'default full'}
                      size={'md'}
                      handleClick={() => setUsedConnector('GnosisSafe')}
                    >
                      Error connection, try again?
                    </Button>
                  </li>
                )}

                <ul className="wallet-btn-list">
                  <li>
                    <div className="wallet-btn-item">
                      <span>GnosisSafe</span>
                      <img src={GnosisIcon} alt="GnosisSafe" />
                    </div>
                  </li>
                </ul>
              </ul>
            )}
          </div>
        </div>
      );
    }

    if (pendingConnector === connectorsByName['ImToken']) {
      return (
        <div className="wallet-button-outer">
          <div className="heading-new text-center">
            Connecting <br /> to imToken
          </div>
          <div className="wallet-button-box">
            {loading ? (
              <ul>
                <li>
                  <div className="wallet-loading-box">
                    <span>Initializing... </span>
                    <div>
                      <div
                        style={{ borderTopColor: 'transparent' }}
                        className="w-6 h-6 border-4 border-blue-400 border-solid rounded-full animate-spin"
                      ></div>
                    </div>
                  </div>
                </li>
                <ul className="wallet-btn-list">
                  <li>
                    <div className="wallet-btn-item">
                      <span>imToken</span>
                      <img src={ImTokenIcon} alt="WalletConnect" />
                    </div>
                  </li>
                </ul>
              </ul>
            ) : (
              <ul>
                {!(error instanceof UserRejectedRequestError) &&
                (chain ? chain.unsupported : false) ? (
                  <>
                    <li>{error.message}</li>
                    <li
                      className="btn-default btn-full text-center"
                      onClick={() => switchChains(137, close)}
                    >
                      <span>Switch Network</span>
                    </li>
                  </>
                ) : (
                  <li className="wallet-btn-outer btn-try-again">
                    <Button
                      className="default full"
                      size={'md'}
                      handleClick={() => setUsedConnector('ImToken')}
                    >
                      Error connection, try again?
                    </Button>
                  </li>
                )}
                <ul className="wallet-btn-list">
                  <li>
                    <div className="wallet-btn-item">
                      <span>imToken</span>
                      <img src={ImTokenIcon} alt="ImToken" />
                    </div>
                  </li>
                </ul>
              </ul>
            )}
          </div>
        </div>
      );
    }

    if (pendingConnector === connectorsByName['WalletConnect']) {
      return (
        <div className="wallet-button-outer">
          <div className="heading-new text-center">
            Connecting <br /> to WalletConnect
          </div>
          <div className="wallet-button-box">
            {loading ? (
              <ul>
                <li>
                  <div className="wallet-loading-box">
                    <span>Initializing... </span>
                    <div>
                      <div
                        style={{ borderTopColor: 'transparent' }}
                        className="w-6 h-6 border-4 border-blue-400 border-solid rounded-full animate-spin"
                      ></div>
                    </div>
                  </div>
                </li>
                <ul className="wallet-btn-list">
                  <li>
                    <div className="wallet-btn-item">
                      <span>WalletConnect</span>
                      <img src={WalletConnectIcon} alt="WalletConnect" />
                    </div>
                  </li>
                </ul>
              </ul>
            ) : (
              <ul>
                {!(error instanceof UserRejectedRequestError) &&
                (chain ? chain.unsupported : false) ? (
                  <>
                    <li>{error.message}</li>
                    <li
                      className="btn-default btn-full text-center"
                      onClick={() => switchChains(137, close)}
                    >
                      <span>Switch Network</span>
                    </li>
                  </>
                ) : (
                  <li className="wallet-btn-outer btn-try-again">
                    <Button
                      className="default full"
                      size={'md'}
                      handleClick={() => setUsedConnector('WalletConnect')}
                    >
                      Error connection, try again?
                    </Button>
                  </li>
                )}
                <ul className="wallet-btn-list">
                  <li>
                    <div className="wallet-btn-item">
                      <span>WalletConnect</span>
                      <img src={WalletConnectIcon} alt="WalletConnect" />
                    </div>
                  </li>
                </ul>
              </ul>
            )}
          </div>
        </div>
      );
    }
  }

  return (
    <Modal close={close} size={'xs'} verticalAlign={'center'} closeButton>
      {pendingConnector ? (
        showActivatingConnector()
      ) : (
        <div className="wallet-button-outer">
          {isConnected && account ? (
            showIsConnectedConnector()
          ) : (
            <>
              <div className="heading-new">Connect a wallet</div>
              <div className="wallet-button-box">
                <ul className="wallet-btn-list">
                  <li>
                    <div
                      className="wallet-btn-item"
                      onClick={() => setUsedConnector('Metamask')}
                    >
                      <span>MetaMask</span>
                      <img src={MetamaskIcon} alt="MetaMask" />
                    </div>
                  </li>
                  <li>
                    <div
                      className="wallet-btn-item"
                      onClick={() => setUsedConnector('ImToken')}
                    >
                      <span>imToken</span>
                      <img src={ImTokenIcon} alt="ImToken" />
                    </div>
                  </li>
                  <li>
                    <div
                      className="wallet-btn-item"
                      onClick={() => setUsedConnector('GnosisSafe')}
                    >
                      <span>GnosisSafe</span>
                      <img src={GnosisIcon} alt="GnosisSafe" />
                    </div>
                  </li>
                  <li>
                    <div
                      className="wallet-btn-item"
                      onClick={() => setUsedConnector('WalletConnect')}
                    >
                      <span>WalletConnect</span>
                      <img src={WalletConnectIcon} alt="WalletConnect" />
                    </div>
                  </li>
                  <li>
                    <div
                      className="wallet-btn-item"
                      onClick={() => setUsedConnector('UAuth')}
                    >
                      <span>Login with Unstoppable</span>
                      <img src={UDIcon} alt="UnstoppableDomain" />
                    </div>
                  </li>
                </ul>
              </div>
              <div className="wallet-info-text">
                Wallets are provided by External Providers and by selecting you
                agree to Terms of those Providers. Your access to the wallet
                might be reliant on the External Provider being operational.
              </div>
            </>
          )}
        </div>
      )}
    </Modal>
  );
};
