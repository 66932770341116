import axios from 'axios';

export const api = axios.create({
  baseURL:
    process.env.REACT_APP_API_URL ||
    'https://urchin-app-ozh5w.ondigitalocean.app/api',
});

export const coingecko = axios.create({
  baseURL: 'https://api.coingecko.com/api/v3',
});

export const paraswap = axios.create({
  baseURL: 'https://apiv5.paraswap.io',
});

export const curve = axios.create({
  baseURL: 'https://stats.curve.fi/raw-stats-polygon/apys.json',
});

export const aave = axios.create({
  baseURL:
    'https://aave-api-v2.aave.com/data/markets-data/0xd05e3e715d945b59290df0ae8ef85c1bdb684744',
});
