import { ReactNode } from 'react';

import { Card } from '../../atoms/Card';
import { Tooltip, TooltipContent, TooltipIcon } from '../../atoms/Tooltip';

export const PortfolioCard = ({
  title,
  label,
  tooltip,
  color,
}: {
  title?: any;
  label?: any;
  tooltip?: ReactNode;
  color?: 'default' | 'green' | 'yellow' | 'red';
}) => {
  return (
    <Card className={'port-card'}>
      <div className={`title color-${color}`}>{title}</div>
      <div className="flex justify-center items-center port-card-inner">
        <span className="text-sm font-light">{label}</span>
        {tooltip && (
          <Tooltip>
            <TooltipIcon />
            <TooltipContent>{tooltip}</TooltipContent>
          </Tooltip>
        )}
      </div>
    </Card>
  );
};
