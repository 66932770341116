import { useState } from 'react';

import { Radio, RadioItem } from '../../atoms/RadioButton';
import styles from './styles.module.scss';

type Props = {
  legend?: string;
  propertyOne: string;
  propertyTwo: string;
  propertyOneView?: string;
  propertyTwoView?: string;
  radioUserView: string;
  setRadioUserView: (arg0: any) => void;
};

const RadioSelector = ({
  legend,
  propertyOne,
  propertyTwo,
  propertyOneView = '',
  propertyTwoView = '',
  radioUserView,
  setRadioUserView,
}: Props) => {
  const [selected, setSelected] = useState(radioUserView);

  const handleRadio = (e) => {
    setSelected(e.target.value);
    setRadioUserView(e.target.value);
  };

  return (
    <div className={styles.radioContent}>
      {legend && <span className="margin-y-10">{legend}</span>}
      <Radio theme={'radio'} classNames={styles.radioWrapper} size={'sm'}>
        <RadioItem
          name="radio"
          value={propertyOne}
          defaultChecked={selected === propertyOne}
          handleRadio={handleRadio}
        >
          {propertyOneView === '' ? propertyOne : propertyOneView}
        </RadioItem>
        <RadioItem
          name="radio"
          value={propertyTwo}
          defaultChecked={selected === propertyTwo}
          handleRadio={handleRadio}
        >
          {propertyTwoView === '' ? propertyTwo : propertyTwoView}
        </RadioItem>
      </Radio>
    </div>
  );
};

export default RadioSelector;
