import { ReactNode, useContext, useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useLocation } from 'react-router-dom';
import { useNetwork } from 'wagmi';

import { Button } from '../../components/atoms/Button/index';
import {
  Col,
  Row,
  Table,
  TableBody,
  TableHead,
} from '../../components/atoms/ListTable/index';
import { Symbol } from '../../components/atoms/Symbol/index';
import { SymbolGroup } from '../../components/atoms/SymbolGroup/index';
import {
  Tooltip,
  TooltipContent,
  TooltipIcon,
  TooltipLabel,
} from '../../components/atoms/Tooltip/index';
import { Search } from '../../components/molecules/Search';
import VaultsSkeleton from '../../components/Skeleton/Vault';
// CONTEXT
import { GlobalContext } from '../../context';
import {
  formatRate,
  getIcon,
  nFormatter,
  renderDepositSymbols,
} from '../../context/utils';
import { ChainIdType, Vault } from '../../interfaces';

const EvaultsPage = ({
  handleExpand,
  limit,
  scrollable,
  loading,
}: {
  children?: ReactNode;
  handleExpand?: any;
  limit?: number;
  scrollable?: boolean;
  loading: boolean;
  error: unknown;
}) => {
  const location = useLocation();
  const {
    state: { vaultData, tokenList },
  } = useContext(GlobalContext);

  const { chain } = useNetwork();

  const [vaultAssets, setVaultAssets] = useState<Vault[]>(
    vaultData ? vaultData : [],
  );
  const [search, setSearch] = useState<string>('');
  const [searchActive, setSearchActive] = useState<boolean>(false);

  const renderDepositName = (item) => {
    const stablecoin = item.deposit.every((t) => t.isStableCoin);

    if (item?.version?.isCompounding) return 'Compounded';

    return stablecoin ? 'Stablecoins' : 'Volatile';
  };

  const renderEarningName = (item) => {
    if (
      item?.version?.isCompounding &&
      item?.version?.isLp &&
      item?.deposit?.length > 1
    )
      return 'LP';
    if (
      item?.version?.isCompounding &&
      !item?.version?.isLp &&
      item?.deposit?.length === 1
    )
      return item.deposit[0]['symbol'];

    return item.earn;
  };

  const compareAPY = (a, b) => {
    return Number(b.apy) - Number(a.apy);
  };

  const compareActive = (a, b) => {
    return a.active === b.active ? 0 : a.active ? -1 : 1;
  };

  useEffect(() => {
    if (location.hash) {
      const link = document.getElementById(
        location.hash.replace('#', '') + '_link',
      );
      link.click();
    }
  }, []);

  useEffect(() => {
    setVaultAssets(
      limit
        ? vaultData.sort(compareAPY).sort(compareActive).slice(0, limit)
        : vaultData,
    );
  }, [vaultData]);

  const handleSearch = (e) => {
    const searchText = e.target.value.toLowerCase();

    const filtered = vaultData.filter((item: Vault) => {
      if (item.earn && item.deposit.length > 0) {
        return (
          item.earn.toLowerCase().search(searchText) !== -1 ||
          item.deposit.filter(
            (item) => item.symbol.toLowerCase().search(searchText) !== -1,
          ).length > 0
        );
      }
    });

    setSearch(searchText);
    setVaultAssets(filtered.slice(0, limit));
    setSearchActive(true);
  };

  return (
    <>
      <Table className={'table-vault'}>
        <TableHead>
          <Row className="">
            <Col className="col-1">
              <div className="table-search-box">
                <Search
                  value={search}
                  handleSearch={handleSearch}
                  placeholder={'Search'}
                  loading={loading}
                />
              </div>
            </Col>
            <Col className="col-2">
              <div className="th-item flex">
                {loading ? (
                  <span className="th-text flex-1">
                    <span className="skeleton-text-area skeleton">&nbsp;</span>
                  </span>
                ) : (
                  <>
                    <span className="th-text">Current APY</span>
                    <Tooltip>
                      <TooltipIcon />
                      <TooltipContent>
                        The APY represents the current net annualized returns,
                        including rewards based on the activity of the
                        underlying DeFi protocols and eVaults
                      </TooltipContent>
                    </Tooltip>
                  </>
                )}
              </div>
            </Col>
            <Col className="col-3">
              <div className="th-item flex">
                {loading ? (
                  <span className="th-text flex-1">
                    <span className="skeleton-text-area skeleton">&nbsp;</span>
                  </span>
                ) : (
                  <span className="th-text">Invested amounts</span>
                )}
              </div>
            </Col>
            <Col className="col-4">
              <div className="th-item flex">
                {loading ? (
                  <span className="th-text flex-1">
                    <span className="skeleton-text-area skeleton">&nbsp;</span>
                  </span>
                ) : (
                  <>
                    <span className="th-text">Volatility</span>
                    <Tooltip>
                      <TooltipIcon />
                      <TooltipContent>
                        The volatility status (low, medium, or high) indicates
                        the rapid price movements of the asset supplied and the
                        exposure probability to impermanent losses
                      </TooltipContent>
                    </Tooltip>
                  </>
                )}
              </div>
            </Col>
            <Col className="col-5">
              {vaultData?.length > limit && (
                <div className="table-btn-box">
                  <Button className={'view-all'} handleClick={handleExpand}>
                    View All
                  </Button>
                </div>
              )}
            </Col>
          </Row>
        </TableHead>
        {/* VAULT TABLE */}
        <div
          className={limit ? 'scrollbar-box hide-scrollbar' : 'scrollbar-box'}
        >
          <Scrollbars
            style={{ width: '100%' }}
            autoHeight
            autoHeightMin={0}
            autoHeightMax={scrollable ? window.innerHeight - 350 : 800}
          >
            <TableBody>
              {vaultData?.length > 0 ? (
                vaultAssets
                  .sort(compareAPY)
                  .sort(compareActive)
                  .filter((item) => item.active)
                  .map((item, i) => {
                    return (
                      <Row className="list-card" key={i}>
                        <Col className="col-1 list-col-1">
                          <div className="asset-group-outer">
                            <SymbolGroup data={item.deposit} />
                            <div className="asset-info">
                              <Symbol
                                src={`${getIcon(item.earn, tokenList)}`}
                                alt={item.earn}
                              />
                              <span className="asset-title xs">
                                {renderDepositName(item)} + <br />
                                earnings in {renderEarningName(item)}
                              </span>
                            </div>
                          </div>
                        </Col>
                        {item.vaultDeposits > 0 ? (
                          <Col
                            className="col-2 list-col-2"
                            title={'Current APY'}
                          >
                            <Tooltip>
                              <TooltipLabel>
                                <span className="color-green">
                                  {item.rapy > 10
                                    ? '>1000%'
                                    : formatRate(item.rapy) + '%'}
                                </span>
                              </TooltipLabel>
                              <TooltipContent>
                                <div className="title-tooltip">Yields</div>
                                {item.feeApy > 0 && (
                                  <div>
                                    LP Rewards: {formatRate(item.feeApy)}% APY
                                  </div>
                                )}
                                <div>
                                  {item.version.isCompounding
                                    ? renderDepositSymbols(item.deposit)
                                    : item.earn}{' '}
                                  Rewards: {formatRate(item.rapy)}%{' '}
                                  {item?.version?.isCompounding ? 'APY' : 'APR'}
                                </div>
                                {item.dApy > 0 && (
                                  <div>
                                    ETHA Rewards:{' '}
                                    {item.dApy > 10
                                      ? '>1000%'
                                      : formatRate(item.dApy)}
                                    % APR
                                  </div>
                                )}
                                <div>Protocol: {item.protocol}</div>
                              </TooltipContent>
                            </Tooltip>
                          </Col>
                        ) : (
                          <Col
                            className="col-2 list-col-2"
                            title={'Current APY'}
                          >
                            <div className="tooltip-box">
                              <span className="color-green">{' - %'}</span>{' '}
                            </div>
                          </Col>
                        )}
                        <Col
                          className="col-3 list-col-3"
                          title={'Value locked'}
                        >
                          <span>{`$${nFormatter(item.vaultDeposits, 3)}`}</span>
                        </Col>
                        <Col className="col-4 list-col-4" title={'Volatility'}>
                          <span
                            className={
                              item.volatility === 'High'
                                ? 'color-red'
                                : item.volatility === 'Medium'
                                ? 'color-yellow'
                                : ''
                            }
                          >
                            {item.volatility}
                          </span>
                        </Col>

                        <Col className="col-5 list-col-5 btn-col">
                          <Button
                            type={'link'}
                            to={`/invest/vault/${item.id}`}
                            className={
                              !item.active && !(window as any).activeAll
                                ? 'disabled'
                                : ''
                            }
                            handleClick={() => {
                              if (chain && chain.id === ChainIdType.POLYGON) {
                                document.body.style.overflow = 'visible';
                              }
                            }}
                          >
                            Invest
                          </Button>
                        </Col>
                      </Row>
                    );
                  })
              ) : (
                <VaultsSkeleton />
              )}
              {searchActive && vaultAssets.length == 0 && (
                <Row className="list-card">
                  <div className="flex-1 text-center">
                    There are no records to display
                  </div>
                </Row>
              )}
            </TableBody>
          </Scrollbars>
        </div>
      </Table>
    </>
  );
};

export default EvaultsPage;
