import './styles.module.scss';
import 'chartjs-plugin-piechart-outlabels';

import React from 'react';
import ChartComponent from 'react-chartjs-2';

type Props = {
  chartData: {
    dataLabels: string[];
    dataValues: number[];
    dataColors: string[];
  };
};

const PortfolioChart: React.FC<Props> = ({ chartData }) => {
  const data = {
    labels: chartData?.dataLabels,
    datasets: [
      {
        data: chartData.dataValues,
        backgroundColor: chartData.dataColors,
        bordercolor: 'none',
      },
    ],
  };

  const options = {
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    legend: false,
    plugins: {
      outlabels: {
        backgroundColor: 'transparent', // Background color of Label
        color: 'white', // Font color
        display: true,
        font: {
          resizable: true,
          minSize: 10.5,
          maxSize: 10.5,
          family: 'Roboto',
        },
        lineWidth: 2,
        stretch: 10, // The length between chart arc and Label
        textAlign: 'center',
      },
    },
  };

  return (
    <>
      <ChartComponent
        type="outlabeledPie"
        data={data}
        options={options}
        width={650}
        height={380}
      />
    </>
  );
};

export default PortfolioChart;
