import React, { useEffect, useState } from 'react';

import { nativeTokenByNetwork } from '../../../context/constants';
import {
  formatCurrency,
  formatNumber,
  formatRate,
  renderDepositSymbols,
} from '../../../context/utils';
import { ChainIdType } from '../../../interfaces';
import { Button } from '../../atoms/Button';
import { Card } from '../../atoms/Card';
import { Radio, RadioItem } from '../../atoms/RadioButton';
import { Symbol } from '../../atoms/Symbol';
import { Tooltip, TooltipContent, TooltipIcon } from '../../atoms/Tooltip';
import ProjectedLoading from '../../Skeleton/ProjectReturns';

export const ProjectedReturns = ({
  handleInvest,
  investment,
  amount,
  priceData,
  disabled,
  selected,
  loadingVaults,
  loadingTokensData,
  chainId,
}: {
  handleInvest: any;
  investment: any;
  amount: any;
  priceData: any;
  disabled: any;
  selected: any;
  loadingVaults: any;
  loadingTokensData: any;
  chainId: ChainIdType;
}) => {
  const [interest, setInterest] = useState('');
  const [timeframe, setTimeframe] = useState('yearly');
  const [usdValue, setUsdValue] = useState(0);

  const handleRadio = (e) => {
    setTimeframe(e.target.value);
  };

  useEffect(() => {
    if (investment.apy && priceData) {
      const div = timeframe === 'daily' ? 365 : 1;

      const realSymbol =
        investment.symbol === nativeTokenByNetwork[chainId].symbol
          ? nativeTokenByNetwork[chainId].wrappedSymbol
          : investment.symbol;
      let factor = 1;
      let rate;

      if (investment.type === 'Vault') {
        factor =
          investment.type === 'Vault'
            ? priceData[realSymbol] /
              (investment.version.isCompounding
                ? investment.depositTokenPrice
                : priceData[investment.earn])
            : 1;

        rate = investment.rapy;
      } else rate = investment.apy;

      setInterest(formatNumber(((amount * rate) / div) * factor));
      setUsdValue(amount * rate * priceData[realSymbol]);
    }
  }, [investment, priceData, amount, timeframe]);

  return (
    <Card theme={'style-2'}>
      <div className="card-project-return">
        {!loadingVaults &&
        !loadingTokensData &&
        investment !== undefined &&
        selected !== undefined ? (
          <>
            <div className="card-top flex">
              <div className="title3 flex-auto">Projected returns</div>
              <Radio theme={'button'} size={'sm'}>
                <RadioItem
                  value={'daily'}
                  name={'returns'}
                  handleRadio={handleRadio}
                >
                  Daily
                </RadioItem>
                <RadioItem
                  value={'yearly'}
                  name={'returns'}
                  handleRadio={handleRadio}
                  defaultChecked
                >
                  Yearly
                </RadioItem>
              </Radio>
            </div>
            <div className="group-outer">
              <div className="group">
                <div className="asset-info-box">
                  <Symbol
                    src={
                      investment.type === 'Vault'
                        ? investment.earnIcon
                        : investment.icon
                    }
                    alt={'ETH'}
                  />
                  <div className="asset-info-title">
                    {' '}
                    +{interest}{' '}
                    {investment.type === 'Vault' &&
                    !investment.version.isCompounding
                      ? investment.earn
                      : investment.deposit &&
                        renderDepositSymbols(investment.deposit)}{' '}
                    <br />
                    per {timeframe === 'yearly' ? 'year' : 'day'}{' '}
                    <span>est.</span>
                    <Tooltip>
                      <TooltipIcon size={'lg'} />
                      <TooltipContent></TooltipContent>
                    </Tooltip>
                  </div>
                </div>
                <div className="asset-info-bot flex">
                  <span className="flex-auto color-light">
                    (
                    {formatCurrency(
                      timeframe === 'daily' ? usdValue / 365 : usdValue,
                      4,
                    )}
                    )
                  </span>
                </div>
              </div>
              <div className="group">
                <div className="fee-info flex">
                  <div className="flex-1 fee-info-left">
                    <div className="title5">Deposit fee</div>
                    <div className="fee-row flex">
                      <div className="fee-col">
                        <div className="title4">0%</div>
                        <div className="color-light">Protocol</div>
                      </div>
                    </div>
                  </div>
                  <div className="flex-1 fee-info-right">
                    <div className="title5">Withdraw Fee</div>
                    <div className="fee-row flex">
                      <div className="fee-col">
                        <div className="title4">
                          {formatRate(investment.fee)}%
                        </div>
                        <span className="color-light">Protocol</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Button
              className={`btn-invest-main ${
                disabled || !amount ? 'disabled' : ''
              }`}
              handleClick={handleInvest}
              disabled={disabled || !amount}
              size={'lg'}
            >
              Invest <br /> {amount > 0 && formatNumber(amount)}{' '}
              {investment.type === 'Vault' ? selected.symbol : investment.earn}
            </Button>
          </>
        ) : (
          <ProjectedLoading />
        )}
      </div>
    </Card>
  );
};
