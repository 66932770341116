import React from 'react';

const GovernanceClaimLoading = () => {
  return (
    <div className="table-body">
      <div className="table-row list-card activity-list-row governance-list-row">
        <div className="table-col col-1 list-col-1">
          <div className="asset-info">
            <span className="symbol">
              <img alt="ETHA" className="hidden-text" />
            </span>
            <span className="asset-title">
              <span className="skeleton inline-block" style={{ width: '50px' }}>
                &nbsp;{' '}
              </span>
            </span>
          </div>
        </div>
        <div className="table-col col-2 list-col-2">
          <div className="flex items-center color-green">
            <span className="skeleton inline-block" style={{ width: '50px' }}>
              &nbsp;{' '}
            </span>
          </div>
        </div>
        <div className="table-col col-3 list-col-3">
          <div className="flex items-center color-green">
            <span className="skeleton inline-block" style={{ width: '50px' }}>
              &nbsp;{' '}
            </span>
          </div>
        </div>
        <div className="table-col col-4 list-col-4">
          <div className="flex items-center color-green">
            <span className="skeleton inline-block" style={{ width: '150px' }}>
              &nbsp;{' '}
            </span>
          </div>
        </div>
      </div>
      <div className="table-row list-card activity-list-row governance-list-row">
        <div className="table-col col-1 list-col-1">
          <div className="asset-info">
            <span className="symbol">
              <img alt="ETHA" className="hidden-text" />
            </span>
            <span className="asset-title">
              <span className="skeleton inline-block" style={{ width: '50px' }}>
                &nbsp;{' '}
              </span>
            </span>
          </div>
        </div>
        <div className="table-col col-2 list-col-2">
          <div className="flex items-center color-green">
            <span className="skeleton inline-block" style={{ width: '50px' }}>
              &nbsp;{' '}
            </span>
          </div>
        </div>
        <div className="table-col col-3 list-col-3">
          <div className="flex items-center color-green">
            <span className="skeleton inline-block" style={{ width: '50px' }}>
              &nbsp;{' '}
            </span>
          </div>
        </div>
        <div className="table-col col-4 list-col-4">
          <div className="flex items-center color-green">
            <span className="skeleton inline-block" style={{ width: '150px' }}>
              &nbsp;{' '}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default GovernanceClaimLoading;
