import './index.scss';

import { ethers } from 'ethers';
import { useContext, useEffect, useState } from 'react';
import { useAccount, useNetwork, useProvider, useSigner } from 'wagmi';

import { api } from '../../axios';
import { Button } from '../../components/atoms/Button';
import {
  Col,
  Row,
  Table,
  TableBody,
  TableHead,
} from '../../components/atoms/ListTable';
import { NoRecord } from '../../components/atoms/NoRecord';
import { RangeSlider } from '../../components/atoms/Slider';
import { Symbol } from '../../components/atoms/Symbol';
import {
  Tooltip,
  TooltipContent,
  TooltipIcon,
} from '../../components/atoms/Tooltip';
import SimpleAssetSelect from '../../components/molecules/AssetsSelect/Simple';
import RadioSelector from '../../components/molecules/RadioSelector';
import Selector from '../../components/molecules/Selector';
import Tab from '../../components/molecules/Tab';
import InfoModal from '../../components/organisms/Modals/Info';
import WarningWithdraw from '../../components/organisms/Modals/WarningWithdraw';
import { TypeEnum } from '../../components/organisms/Toast';
import GovernanceLoading from '../../components/Skeleton/Governance';
import GovernanceClaimLoading from '../../components/Skeleton/GovernanceClaim';
import { GlobalContext, UserContext } from '../../context/index';
import {
  formatNumber,
  formatWeiSymbol,
  fromWei,
  getAddressToken,
  getERC20Contract,
  getTokenDecimals,
  getTokenIcon,
  getTokenSymbol,
} from '../../context/utils';
import { useContract } from '../../hooks/useContract';
import {
  ChainIdType,
  GlobalLoading,
  RefetchState,
  UserIncreaseView,
  UserView,
  UserWallet,
} from '../../interfaces';

const sliderLabels = {
  0: '1 Month',
  20: '3 Months',
  40: '6 Months',
  60: '1 Year',
  80: '2 Years',
  100: 'MAX',
};

const Governance = ({
  loadingGlobal,
  loadingBalances,
}: {
  loadingGlobal: GlobalLoading;
  loadingBalances: RefetchState;
}) => {
  const {
    state: { actions, tokenList, priceData, contracts },
    setToast,
    dispatch,
  } = useContext(GlobalContext);
  const {
    state: { wallet, balances },
  } = useContext(UserContext);
  const provider = useProvider();
  const { data: signer } = useSigner();
  const { chain } = useNetwork();
  const { address: account } = useAccount();

  const [lockedBalance, setLockedBalance] = useState<number>(0);
  const [lockedEBalance, setLockedEBalance] = useState<any>(0);
  const [ethaAvailable, setEthaAvailable] = useState<any>();
  const [amountToStake, setAmountToStake] = useState<any>('');
  const [pending, setPending] = useState<boolean>(false);
  const [lockTime, setLockTime] = useState<string>('');
  const [userView, setUserView] = useState<UserView>(UserView.lock);
  const [userIncreaseView, setUserIncreaseView] = useState<UserIncreaseView>(
    UserIncreaseView.increaseAmount,
  );
  const [userWallet, setUserWallet] = useState<UserWallet>(
    UserWallet.smartWallet,
  );
  const [sharedPercentage, setSharedPercentage] = useState<number>(0);
  const [endTime, setEndTime] = useState<string>('');
  const [msEndTime, setMsEndTime] = useState<number>(0);
  const [loadingRewards, setLoadingRewards] = useState<boolean>(false);
  const [rewards, setRewards] = useState<any[]>([]);
  const [withdrawModal, setWithdrawModal] = useState<boolean>(false);
  const [daysLeft, setDaysLeft] = useState<number>(0);
  const [sliderValue, setSliderValue] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [penalty, setPenalty] = useState<number>(0);
  const [minAmountToLock, setMinAmountToLock] = useState<number>(0);
  const [minLockInfoModal, setMinLockInfoModal] = useState<boolean>(false);
  const [hasAllowance, setHasAllowance] = useState<boolean>(false);
  const [totalEthaLocked, setTotalEthaLocked] = useState('');
  const [totalRewardsApr, setTotalRewardsApr] = useState(0);
  const [refreshBalances, setRefreshBalances] = useState<boolean>(false);
  const msToday = Date.now();

  const ethaToken = {
    address: '0x56bA9a2f00A4F581a05bfE9Fd0b16277eD130349',
    chainId: 137,
    name: 'ETHA',
    symbol: 'ETHA',
    decimals: 18,
    id: 'etha-lend',
    icon: 'https://tokens.1inch.exchange/0x59e9261255644c411afdd00bd89162d09d862e38.png',
  };

  const handleTime = (days) => {
    const daysToMiliseconds = Number(days) * 60 * 60 * 24 * 1000;
    const daysLeftToMiliseconds = Number(daysLeft) * 60 * 60 * 24 * 1000;
    const now = Date.now();
    let claimingDate: Date;

    if (daysLeft > 0) {
      claimingDate = new Date(now + daysToMiliseconds + daysLeftToMiliseconds);
    } else {
      claimingDate = new Date(now + daysToMiliseconds);
    }
    setEndTime(claimingDate.toDateString());
  };

  const handleApprove = async () => {
    if (wallet === '') return;
    const ethaTokenInstance = getERC20Contract(
      getAddressToken('ETHA', tokenList),
      signer,
    );

    setPending(true);

    try {
      const tx = await ethaTokenInstance.approve(
        wallet,
        ethers.constants.MaxUint256,
      );
      await tx.wait();
      setPending(false);
      setHasAllowance(true);
    } catch (e) {
      console.error(e);
      setPending(false);
    }
  };

  const handleLock = async () => {
    if (wallet === '') return;

    const minAmount = formatWeiSymbol(
      ethaToken.symbol,
      minAmountToLock,
      tokenList,
    );

    if (
      amountToStake < minAmount &&
      userIncreaseView === UserIncreaseView.increaseAmount
    ) {
      setMinLockInfoModal(true);
      return;
    }

    setPending(true);
    try {
      const tx = await actions.handleLock(amountToStake, lockTime, userWallet);
      setToast({
        open: true,
        type: TypeEnum.Pending,
        title: 'Pending transaction',
        timer: 14000,
        message: (
          <a
            href={`${chain.blockExplorers['default'].url}/tx/${tx.hash}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Check on block explorer
          </a>
        ),
      });
      await tx.wait();

      loadingBalances.refetch();
      setPending(false);
      setAmountToStake('');
      setLockTime('');
      handleTime('');
    } catch (error) {
      setPending(false);
      console.error(error);
    } finally {
      setRefreshBalances(true);
    }
  };

  const handleClaimAll = async () => {
    if (wallet === '') return;

    setPending(true);
    try {
      const tx = await actions.handleFeeClaim();
      setToast({
        open: true,
        type: TypeEnum.Pending,
        title: 'Pending transaction',
        timer: 14000,
        message: (
          <a
            href={`${chain.blockExplorers['default'].url}/tx/${tx.hash}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Check on block explorer
          </a>
        ),
      });
      await tx.wait();

      loadingBalances.refetch();
      setPending(false);
    } catch (error) {
      setPending(false);
      console.error(error);
    } finally {
      setRefreshBalances(true);
    }
  };

  const handleWithdraw = async () => {
    if (msToday < msEndTime) {
      setWithdrawModal(true);
    } else {
      setPending(true);

      try {
        const tx = await actions.handleLockWithdraw(endTime);
        setToast({
          open: true,
          type: TypeEnum.Pending,
          title: 'Pending transaction',
          timer: 14000,
          message: (
            <a
              href={`${chain.blockExplorers['default'].url}/tx/${tx.hash}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Check on block explorer
            </a>
          ),
        });
        await tx.wait();

        setPending(false);
        loadingBalances.refetch();
      } catch (error) {
        setPending(false);
        console.error(error);
      } finally {
        setRefreshBalances(true);
      }
    }
  };

  const handleWithdrawModal = (state) => {
    setWithdrawModal(!withdrawModal);
    return state;
  };
  const handleInfoModal = (state) => {
    setMinLockInfoModal(!minLockInfoModal);
    return state;
  };

  const handleAmount = (amt) => {
    if (amt === -1) {
      setAmountToStake(
        ethaAvailable &&
          formatNumber(ethaAvailable.balance / 10 ** ethaAvailable.decimals),
      );
    } else {
      setAmountToStake(amt);
    }
  };

  const shouldButtonBeDisabled = () => {
    return (
      (amountToStake === '' && lockTime === '') ||
      pending ||
      wallet === '' ||
      daysLeft + Number(lockTime) >= 1093
    );
  };

  const renderTextLock = () => {
    if (Number(lockedEBalance) === 0) {
      return 'Create lock';
    } else if (
      (userIncreaseView === UserIncreaseView.increaseAmount ||
        amountToStake > 0) &&
      lockTime === ''
    ) {
      return 'Increase amount to lock';
    }

    return 'Increase lock time';
  };

  const handleSlider = (val) => {
    if (val == 0) {
      if (daysLeft + 30 < 1095) {
        setSliderValue(val);
        setLockTime('30');
        handleTime('30');
      }
    }

    if (val == 20) {
      if (daysLeft + 90 < 1095) {
        setSliderValue(val);
        setLockTime('90');
        handleTime('90');
      }
    }

    if (val == 40) {
      if (daysLeft + 180 < 1095) {
        setSliderValue(val);
        setLockTime('180');
        handleTime('180');
      }
    }

    if (val == 60) {
      if (daysLeft + 365 < 1095) {
        setSliderValue(val);
        setLockTime('365');
        handleTime('365');
      }
    }

    if (val == 80) {
      if (daysLeft + 730 < 1095) {
        setSliderValue(val);
        setLockTime('730');
        handleTime('730');
      }
    }

    if (val == 100) {
      if (1093 - daysLeft > 0 && daysLeft + (1093 - daysLeft) !== 1093) {
        setSliderValue(val);
        setLockTime(String(1093 - daysLeft));
        handleTime(String(1093 - daysLeft));
      }
    }
  };

  const fetchUserRewards = async (): Promise<void> => {
    const multiFeeContract = useContract(
      'MultiFeeDistribution',
      provider,
      contracts,
      dispatch,
      true,
      ChainIdType.POLYGON,
    );

    setLoadingRewards(true);

    try {
      const { data } = await api.get('/multifeeApy');
      const rawRewards = await multiFeeContract.claimableRewards(wallet);
      const fullRewards = [];
      const totalRewards = [];
      const { data: allRewardsApy } = data;
      const { TOTAL, ...rewardsApy } = allRewardsApy;

      setTotalRewardsApr(TOTAL * 100);

      for (const reward of rawRewards) {
        const _symbol = getTokenSymbol(reward.token, tokenList);
        fullRewards.push({
          symbol: _symbol,
          token: reward.token,
          tokenRewards:
            reward.amount / 10 ** getTokenDecimals(_symbol, tokenList),
          rewardUser:
            (reward.amount / 10 ** getTokenDecimals(_symbol, tokenList)) *
            priceData[_symbol],
          icon: getTokenIcon(_symbol, tokenList),
          apy: rewardsApy[_symbol],
        });
        totalRewards.push(Number(reward.amount));
      }

      setRewards(fullRewards);
      setLoadingRewards(false);
    } catch (error) {
      console.error(error);
      setLoadingRewards(false);
    }
  };

  const fetchUserLockedBalance = async (): Promise<void> => {
    const voteEscrowContract = useContract(
      'VoteEscrow',
      provider,
      contracts,
      dispatch,
      true,
      ChainIdType.POLYGON,
    );

    try {
      const { amount } = await voteEscrowContract.locked(wallet);
      const timeLeft = await voteEscrowContract.locked__end(wallet);
      const eBalance = await voteEscrowContract.balanceOf(wallet);
      const releaseDate = Number(timeLeft.mul(1000));
      const dateFormatted = new Date(releaseDate);
      const today = Date.now();
      const rest = releaseDate - today;
      const result = Math.floor(rest / 86400000);

      if (Number(timeLeft) === 0) {
        setEndTime('No lock');
      } else {
        setEndTime(dateFormatted.toDateString());
      }

      setDaysLeft(result > 0 ? result : 0);
      setLockedBalance(Number(amount) / 10 ** 18);
      setLockedEBalance(Number(ethers.utils.formatEther(eBalance)).toFixed(2));
      setMsEndTime(new Date(dateFormatted.toDateString()).getTime());
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const fetchUserGovernanceInfo = async () => {
    setLoading(true);
    const globalAdapter = useContract(
      'GlobalAdapter',
      provider,
      contracts,
      dispatch,
      true,
      ChainIdType.POLYGON,
    );

    const { info } = await globalAdapter.getGovernanceInfo(
      ethaToken.address,
      wallet,
    );
    const { minLockedAmount, penaltyRate, totalEthaLocked } = info;
    setPenalty(Number(penaltyRate) / 1000);
    setMinAmountToLock(Number(minLockedAmount));
    setTotalEthaLocked(String(totalEthaLocked));
  };

  useEffect(() => {
    async function fetchData() {
      const ethaTokenInstance = getERC20Contract(
        getAddressToken('ETHA', tokenList),
        provider,
      );

      const allowance = await ethaTokenInstance.allowance(account, wallet);

      if (allowance.gt(0)) setHasAllowance(true);
    }

    if (provider && account && wallet && !loadingGlobal) {
      fetchData();
    }
  }, [provider, account, wallet]);

  useEffect(() => {
    const ethaBalance =
      userWallet === UserWallet.smartWallet
        ? balances.wallet.filter((item) => item.symbol === 'ETHA')[0]
        : balances.account.filter((item) => item.symbol === 'ETHA')[0];
    setEthaAvailable(ethaBalance);
  }, [balances, userWallet, loadingBalances.isLoading]);

  useEffect(() => {
    if (provider && wallet) {
      fetchUserLockedBalance();
      fetchUserRewards();
      fetchUserGovernanceInfo();
    }

    return () => {
      setRewards([]);
      setUserView(UserView.lock);
      setSliderValue(0);
      setLockedEBalance(0);
      setLockedBalance(0);
      setDaysLeft(0);
      setLockTime('');
    };
  }, [account, wallet, loadingBalances.isLoading, priceData]);

  useEffect(() => {
    if (provider && wallet) {
      setTimeout(() => {
        fetchUserLockedBalance();
        fetchUserRewards();
      }, 5000);
    }
  }, [account, wallet, loadingBalances.isLoading, priceData, refreshBalances]);

  useEffect(() => {
    const fetchData = async () => {
      const voteEscrowContract = useContract(
        'VoteEscrow',
        provider,
        contracts,
        dispatch,
        true,
        ChainIdType.POLYGON,
      );

      let veEthaTokenExpected = '0';

      const resTotalSupply = await voteEscrowContract.totalSupply();

      if (amountToStake && lockTime) {
        veEthaTokenExpected = await voteEscrowContract.voting_power_locked_days(
          ethers.utils.parseEther(amountToStake),
          lockTime,
        );
      }

      if (amountToStake && !lockTime) {
        veEthaTokenExpected = await voteEscrowContract.voting_power_locked_days(
          ethers.utils.parseEther(amountToStake),
          daysLeft,
        );
      }

      if (!amountToStake && lockTime) {
        veEthaTokenExpected = await voteEscrowContract.voting_power_locked_days(
          ethers.utils.parseEther(String(lockedBalance)),
          lockTime,
        );
      }

      setSharedPercentage(
        (Number(fromWei(String(veEthaTokenExpected))) +
          Number(lockedEBalance)) /
          (Number(fromWei(String(resTotalSupply))) +
            Number(fromWei(String(veEthaTokenExpected)))),
      );
    };

    provider && fetchData();
  }, [amountToStake, lockedEBalance, lockTime]);

  useEffect(() => {
    if (
      lockedBalance > 0 &&
      userIncreaseView === UserIncreaseView.increaseAmount
    ) {
      setLockTime('');
      handleTime('');
    }
    if (
      lockedBalance > 0 &&
      userIncreaseView === UserIncreaseView.increaseTime
    ) {
      setAmountToStake('');
      setLockTime('30');
      handleTime('30');
      setSliderValue(0);
    }
  }, [userIncreaseView]);

  useEffect(() => {
    setTimeout(() => {
      if (lockedBalance === 0) {
        handleTime('30');
      }
    }, 50);
  }, []);

  return (
    <>
      <main className="page-main">
        <div className="container">
          <div className="page-holder">
            <div className="page-title">
              <div className="heading-main">Governance</div>
            </div>
            {!loading ? (
              <div className="portfolio-body activity-body governance-body">
                <div className="portfolio-body-top">
                  <div className="invested-area flex">
                    <div className="invested-col">
                      {wallet !== '' && (
                        <Selector
                          propertyOne={UserView.lock}
                          propertyTwo={UserView.claim}
                          propertyOneView={'Lock'}
                          propertyTwoView={'Claim'}
                          userView={userView}
                          setUserView={setUserView}
                        />
                      )}
                    </div>
                    <div className="invested-col flex-auto">
                      <div className="invest-top-right flex">
                        <div className="flex-auto"></div>
                        <div className="invest-btn-outer">
                          {userView === UserView.claim && (
                            <Button
                              className={`${pending && 'disabled'}`}
                              size={'md'}
                              handleClick={handleClaimAll}
                            >
                              <span>
                                {pending ? 'Claiming...' : 'Claim All'}
                              </span>
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {userView === UserView.lock ? (
                  <>
                    <div className="my-4">
                      {lockedBalance && lockedBalance > 0 ? (
                        <>
                          <Tab
                            propertyOne={UserIncreaseView.increaseAmount}
                            propertyTwo={UserIncreaseView.increaseTime}
                            propertyOneView={'Increase Amount'}
                            propertyTwoView={'Increase Time'}
                            userView={userIncreaseView}
                            setUserView={setUserIncreaseView}
                          />
                          <div className="governance_reinvest_body">
                            {userIncreaseView ===
                            UserIncreaseView.increaseAmount ? (
                              <>
                                {' '}
                                <div className="input margin-y-10">
                                  <SimpleAssetSelect
                                    label="Boost Lock"
                                    amount={amountToStake}
                                    handleAmount={handleAmount}
                                    selected={ethaToken}
                                    isInput={true}
                                    placeholder={formatWeiSymbol(
                                      ethaToken.symbol,
                                      minAmountToLock,
                                      tokenList,
                                    )}
                                  />
                                  {wallet !== '' && (
                                    <div className="margin-y-10">
                                      Balance:{' '}
                                      {ethaAvailable &&
                                        formatNumber(
                                          ethaAvailable.balance /
                                            10 ** ethaAvailable.decimals,
                                        ) +
                                          ' ' +
                                          ethaAvailable.symbol}
                                    </div>
                                  )}
                                  {wallet !== '' && (
                                    <RadioSelector
                                      legend={'Deposit From:'}
                                      propertyOne={UserWallet.smartWallet}
                                      propertyTwo={UserWallet.web3Wallet}
                                      propertyOneView={'ETHA Wallet'}
                                      propertyTwoView={'Web3 Wallet'}
                                      radioUserView={userWallet}
                                      setRadioUserView={setUserWallet}
                                    />
                                  )}
                                </div>
                              </>
                            ) : (
                              <>
                                <div
                                  className={`governance-slider-box ${
                                    lockTime == '' ? 'disabled' : ''
                                  }`}
                                >
                                  <RangeSlider
                                    value={sliderValue}
                                    type={'horizontal'}
                                    labels={sliderLabels}
                                    step={20}
                                    tooltip={false}
                                    handleChange={(val) => handleSlider(val)}
                                    style={'style-2'}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        </>
                      ) : (
                        <div className="governance_reinvest_body">
                          <div className="input">
                            {' '}
                            <SimpleAssetSelect
                              label="Boost Lock"
                              amount={amountToStake}
                              handleAmount={handleAmount}
                              selected={ethaToken}
                              isInput={true}
                            />
                            {wallet !== '' && (
                              <div className="margin-y-10">
                                Balance:{' '}
                                {ethaAvailable &&
                                  formatNumber(
                                    ethaAvailable.balance /
                                      10 ** ethaAvailable.decimals,
                                  ) +
                                    ' ' +
                                    ethaAvailable.symbol}
                              </div>
                            )}
                            {wallet !== '' && (
                              <RadioSelector
                                legend={'Deposit From:'}
                                propertyOne={UserWallet.smartWallet}
                                propertyTwo={UserWallet.web3Wallet}
                                propertyOneView={'ETHA Wallet'}
                                propertyTwoView={'Web3 Wallet'}
                                radioUserView={userWallet}
                                setRadioUserView={setUserWallet}
                              />
                            )}
                            <div
                              className={`governance-slider-box ${
                                lockTime == '' ? 'disabled' : ''
                              }`}
                            >
                              <RangeSlider
                                value={sliderValue}
                                type={'horizontal'}
                                labels={sliderLabels}
                                step={20}
                                tooltip={false}
                                handleChange={(val) => handleSlider(val)}
                                style={'style-2'}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="governance_lockedInfo_body">
                        <div className="my-4 governance-info-body">
                          <div className="flex justify-between my-4">
                            <span>My ETHA Locked</span>
                            <span>{lockedBalance.toFixed(2)}</span>
                          </div>
                          <div className="flex justify-between my-4">
                            <span>
                              <span>Current APR</span>
                              <Tooltip>
                                <TooltipIcon />
                                <TooltipContent>
                                  Current reward rate for veETHA holders
                                </TooltipContent>
                              </Tooltip>
                            </span>
                            <span>{totalRewardsApr.toFixed(2)}%</span>
                          </div>
                          <div className="flex justify-between my-4">
                            <span>Total ETHA Locked</span>
                            <span>
                              {formatNumber(
                                Number(totalEthaLocked) /
                                  10 ** ethaToken.decimals,
                              )}
                            </span>
                          </div>

                          <div className="flex justify-between my-4">
                            <span>My veETHA balance</span>
                            <span>{lockedEBalance}</span>
                          </div>

                          <div className="flex justify-between my-4">
                            <span>Lock ends on</span>
                            <span>{endTime}</span>
                          </div>

                          <div className="flex justify-between my-4">
                            <span>% share of protocol fees</span>
                            <span>
                              {isNaN(sharedPercentage)
                                ? 0
                                : (sharedPercentage * 100).toFixed(2)}
                              %
                            </span>
                          </div>

                          <div className="btn-group flex">
                            <Button
                              handleClick={
                                !hasAllowance &&
                                userWallet === UserWallet.web3Wallet
                                  ? handleApprove
                                  : handleLock
                              }
                              className={shouldButtonBeDisabled() && 'disabled'}
                            >
                              {!hasAllowance &&
                              userWallet === UserWallet.web3Wallet
                                ? 'Approve ETHA'
                                : renderTextLock()}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="portfolio-body activity-body">
                      <Table className="table-listing-view table-activity">
                        <TableHead>
                          <Row className="list-card activity-list-row governance-list-row">
                            <Col className="col-1 list-col-1">
                              <div className="th-item flex">
                                <span className="th-text">Asset</span>
                              </div>
                            </Col>
                            <Col className="col-2 list-col-2">
                              <div className="th-item flex">
                                <span className="th-text">Current APR</span>
                              </div>
                            </Col>
                            <Col className="col-3 list-col-3">
                              <div className="th-item flex">
                                <span className="th-text">Token Amount</span>
                              </div>
                            </Col>

                            <Col className="col-4 list-col-4">
                              <div className="th-item flex">
                                <span className="th-text">Rewards</span>
                              </div>
                            </Col>
                          </Row>
                        </TableHead>
                        {!loadingRewards ? (
                          <TableBody>
                            <>
                              {rewards?.length ? (
                                rewards.map((item, i) => {
                                  return (
                                    <Row
                                      className="list-card activity-list-row governance-list-row"
                                      key={i}
                                    >
                                      <Col className="col-1 list-col-1">
                                        <div className="asset-info">
                                          <Symbol
                                            src={item.icon}
                                            alt={item.symbol}
                                          />
                                          <span className="asset-title">
                                            {item.symbol}
                                          </span>
                                        </div>
                                      </Col>
                                      <Col className="col-2 list-col-2">
                                        <span className="flex items-center color-green">
                                          {`${Math.floor(
                                            Number(item.apy) * 100,
                                          )}%`}
                                        </span>
                                      </Col>
                                      <Col className="col-3 list-col-3">
                                        <span className="flex items-center color-green">
                                          {item.tokenRewards.toFixed(2)}
                                        </span>
                                      </Col>
                                      <Col className="col-4 list-col-4">
                                        <div className="flex items-center color-green">
                                          <span className="">
                                            $ {item.rewardUser.toFixed(4)} USD{' '}
                                          </span>
                                        </div>
                                      </Col>
                                    </Row>
                                  );
                                })
                              ) : (
                                <GovernanceClaimLoading />
                              )}
                            </>
                          </TableBody>
                        ) : (
                          <NoRecord>
                            <div className="no-record flex justify-center">
                              <div className="mr-3">Loading rewards...</div>
                              <div
                                style={{ borderTopColor: 'transparent' }}
                                className="animate-spin"
                              ></div>
                            </div>
                          </NoRecord>
                        )}
                      </Table>
                      <Button
                        handleClick={handleWithdraw}
                        className={
                          (lockedBalance === 0 || withdrawModal || pending) &&
                          'disabled'
                        }
                      >
                        {' '}
                        Withdraw All
                      </Button>
                    </div>
                  </>
                )}
              </div>
            ) : (
              <GovernanceLoading />
            )}
          </div>
        </div>
      </main>
      {withdrawModal && (
        <WarningWithdraw
          close={() => handleWithdrawModal(false)}
          endTime={endTime}
          setPending={(value: boolean) => setPending(value)}
          pending={pending}
          penalty={penalty}
        />
      )}
      {minLockInfoModal && (
        <InfoModal
          close={() => handleInfoModal(false)}
          infoText={`The minimum amount of tokens to lock is ${formatWeiSymbol(
            ethaToken.symbol,
            minAmountToLock,
            tokenList,
          )}${' '}ETHA`}
        />
      )}
    </>
  );
};

export default Governance;
