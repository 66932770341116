export const FloatingMenu = ({
  items,
  sectionActive,
}: {
  items?: Array<any>;
  sectionActive: number;
}) => {
  return (
    <ul className="floating-menu">
      {items?.map((item, i) => {
        return (
          <li className={sectionActive === item.active ? 'active' : ''} key={i}>
            <a href={item.href} id={item.id}>
              {item.label}
            </a>
          </li>
        );
      })}
    </ul>
  );
};
