import { useContext, useEffect } from 'react';
import { useNetwork } from 'wagmi';

import { UserContext } from '../../context';
import { useAutoConnect } from '../../hooks/useAutoConnect';

export default function Web3ReactManager({
  children,
}: {
  children: JSX.Element;
}) {
  const { dispatch } = useContext(UserContext);
  const { chain } = useNetwork();
  useAutoConnect();

  useEffect(() => {
    if (chain) {
      if (chain.unsupported) {
        // this should clear the wallet, when we change the network
        // and should be only available when the user is disconnected
        dispatch({ type: 'SET_WALLET', payload: '' });
      }
    } else {
      dispatch({ type: 'SET_WALLET', payload: '' });
    }
  }, [chain]);

  return children;
}
