import './index.scss';
const search = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.0499 0.900025C9.40884 0.899793 10.7397 1.28683 11.8866 2.0158C13.0334 2.74476 13.9488 3.78547 14.5255 5.01598C15.1021 6.2465 15.3162 7.61587 15.1425 8.96366C14.9689 10.3115 14.4147 11.5819 13.545 12.626L18.9101 17.9898C19.0253 18.1045 19.0933 18.2581 19.1008 18.4204C19.1083 18.5827 19.0548 18.742 18.9507 18.8668C18.8466 18.9916 18.6996 19.0729 18.5385 19.0946C18.3775 19.1164 18.2142 19.077 18.0807 18.9843L17.9897 18.9102L12.6259 13.5451C11.7435 14.2797 10.6971 14.791 9.57526 15.0357C8.45344 15.2805 7.28919 15.2514 6.18097 14.951C5.07276 14.6507 4.05318 14.0878 3.20847 13.3101C2.36376 12.5324 1.71877 11.5627 1.32806 10.4831C0.937346 9.40337 0.812401 8.24548 0.96379 7.1073C1.11518 5.96913 1.53845 4.88415 2.1978 3.94414C2.85715 3.00414 3.73319 2.23674 4.75182 1.70688C5.77045 1.17701 6.9017 0.90026 8.0499 0.900025ZM8.0499 2.20002C6.49839 2.20002 5.01042 2.81636 3.91333 3.91345C2.81624 5.01054 2.1999 6.49851 2.1999 8.05002C2.1999 9.60154 2.81624 11.0895 3.91333 12.1866C5.01042 13.2837 6.49839 13.9 8.0499 13.9C9.60142 13.9 11.0894 13.2837 12.1865 12.1866C13.2836 11.0895 13.8999 9.60154 13.8999 8.05002C13.8999 6.49851 13.2836 5.01054 12.1865 3.91345C11.0894 2.81636 9.60142 2.20002 8.0499 2.20002Z"
      fill="#B3B6CB"
    />
  </svg>
);
const info = (
  <div className="info-icon-box">
    <i className="info-icon">?</i>
  </div>
);
const line = (
  <svg
    width="10"
    height="17"
    viewBox="0 0 10 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 16L9 1"
      stroke="url(#paint0_linear)"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="1"
        y1="8.5"
        x2="9"
        y2="8.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A894F8" />
        <stop offset="1" stopColor="#5458F6" />
      </linearGradient>
    </defs>
  </svg>
);
const close = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99752 3.33252L3.33252 9.99752"
      stroke="#D2D6EF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.33252 3.33252L9.99752 9.99752"
      stroke="#D2D6EF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const expand = (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.625 22.0001H0.458333C0.205221 22.0001 0 21.7949 0 21.5417V12.3751C0 12.122 0.205221 11.9167 0.458333 11.9167H9.625C9.87811 11.9167 10.0833 12.122 10.0833 12.3751V21.5417C10.0833 21.7949 9.87811 22.0001 9.625 22.0001ZM0.916667 21.0834H9.16667V12.8334H0.916667V21.0834Z"
      fill="url(#paint01_linear_313_660)"
    />
    <path
      d="M21.5417 22H12.375C12.1219 22 11.9167 21.7948 11.9167 21.5417C11.9167 21.2886 12.1219 21.0833 12.375 21.0833H21.0833V0.916667H0.916667V9.625C0.916667 9.87811 0.711446 10.0833 0.458333 10.0833C0.205221 10.0833 0 9.87811 0 9.625V0.458333C0 0.205221 0.205221 0 0.458333 0H21.5417C21.7948 0 22 0.205221 22 0.458333V21.5417C22 21.7948 21.7948 22 21.5417 22Z"
      fill="url(#paint1_linear_313_660)"
    />
    <path
      d="M9.62502 12.8333C9.50775 12.8333 9.39048 12.7886 9.30096 12.6991C9.12193 12.52 9.12193 12.23 9.30096 12.0509L15.7176 5.63428C15.8967 5.45524 16.1867 5.45524 16.3657 5.63428C16.5448 5.81331 16.5448 6.10335 16.3657 6.28239L9.94908 12.6991C9.85956 12.7886 9.74229 12.8333 9.62502 12.8333Z"
      fill="url(#paint2_linear_313_660)"
    />
    <path
      d="M16.0417 10.0833C15.7886 10.0833 15.5834 9.87811 15.5834 9.625V6.41667H12.375C12.1219 6.41667 11.9167 6.21145 11.9167 5.95833C11.9167 5.70522 12.1219 5.5 12.375 5.5H16.5V9.625C16.5 9.87811 16.2948 10.0833 16.0417 10.0833Z"
      fill="url(#paint3_linear_313_660)"
    />
    <defs>
      <linearGradient
        id="paint01_linear_313_660"
        x1="5.04167"
        y1="11.9167"
        x2="5.04167"
        y2="22.0001"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FF69" />
        <stop offset="1" stopColor="#FF975D" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_313_660"
        x1="11"
        y1="0"
        x2="11"
        y2="22"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FF69" />
        <stop offset="1" stopColor="#FF975D" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_313_660"
        x1="12.8334"
        y1="5.5"
        x2="12.8334"
        y2="12.8333"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FF69" />
        <stop offset="1" stopColor="#FF975D" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_313_660"
        x1="14.2084"
        y1="5.5"
        x2="14.2084"
        y2="10.0833"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FF69" />
        <stop offset="1" stopColor="#FF975D" />
      </linearGradient>
    </defs>
  </svg>
);
const minimize = (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.375 -8.10623e-05L21.5417 -8.10623e-05C21.7948 -8.10623e-05 22 0.205139 22 0.458252L22 9.62492C22 9.87803 21.7948 10.0833 21.5417 10.0833L12.375 10.0833C12.1219 10.0833 11.9167 9.87803 11.9167 9.62492L11.9167 0.458252C11.9167 0.205139 12.1219 -8.10623e-05 12.375 -8.10623e-05ZM21.0833 0.916586L12.8333 0.916586L12.8333 9.16659L21.0833 9.16659L21.0833 0.916586Z"
      fill="url(#paint00_linear_313_1441)"
    />
    <path
      d="M0.458332 0L9.625 0C9.87811 0 10.0833 0.205219 10.0833 0.458332C10.0833 0.711445 9.87811 0.916666 9.625 0.916666L0.916666 0.916666L0.916666 21.0833L21.0833 21.0833L21.0833 12.375C21.0833 12.1219 21.2886 11.9167 21.5417 11.9167C21.7948 11.9167 22 12.1219 22 12.375L22 21.5417C22 21.7948 21.7948 22 21.5417 22L0.458332 22C0.205219 22 0 21.7948 0 21.5417L0 0.458332C0 0.205219 0.205219 0 0.458332 0Z"
      fill="url(#paint12_linear_313_1441)"
    />
    <path
      d="M12.3749 9.16667C12.4922 9.16667 12.6095 9.21143 12.699 9.30094C12.878 9.47998 12.878 9.77002 12.699 9.94906L6.28231 16.3657C6.10327 16.5448 5.81323 16.5448 5.6342 16.3657C5.45516 16.1867 5.45516 15.8966 5.6342 15.7176L12.0509 9.30094C12.1404 9.21143 12.2576 9.16667 12.3749 9.16667Z"
      fill="url(#paint22_linear_313_1441)"
    />
    <path
      d="M5.95825 11.9167C6.21136 11.9167 6.41659 12.1219 6.41659 12.375L6.41659 15.5833L9.62492 15.5833C9.87803 15.5833 10.0833 15.7886 10.0833 16.0417C10.0833 16.2948 9.87803 16.5 9.62492 16.5H5.49992L5.49992 12.375C5.49992 12.1219 5.70514 11.9167 5.95825 11.9167Z"
      fill="url(#paint33_linear_313_1441)"
    />
    <defs>
      <linearGradient
        id="paint00_linear_313_1441"
        x1="16.9583"
        y1="10.0833"
        x2="16.9583"
        y2="-7.98603e-05"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FF69" />
        <stop offset="1" stopColor="#FF975D" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_313_1441"
        x1="11"
        y1="22"
        x2="11"
        y2="2.6226e-06"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FF69" />
        <stop offset="1" stopColor="#FF975D" />
      </linearGradient>
      <linearGradient
        id="paint22_linear_313_1441"
        x1="9.16659"
        y1="16.5"
        x2="9.16659"
        y2="9.16667"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FF69" />
        <stop offset="1" stopColor="#FF975D" />
      </linearGradient>
      <linearGradient
        id="paint33_linear_313_1441"
        x1="7.79159"
        y1="16.5"
        x2="7.79159"
        y2="11.9167"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FF69" />
        <stop offset="1" stopColor="#FF975D" />
      </linearGradient>
    </defs>
  </svg>
);
const arrowDown = (
  <svg
    width="25"
    height="14"
    viewBox="0 0 25 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.209887 0.224027C0.0765743 0.356253 0.00111198 0.535931 2.86102e-05 0.723693C-0.000843048 0.816878 0.0173187 0.90926 0.0534039 0.995179C0.089489 1.0811 0.142738 1.15874 0.209887 1.22336L11.992 13.0055C12.0573 13.0717 12.1351 13.1242 12.2209 13.1601C12.3067 13.1959 12.3987 13.2144 12.4917 13.2144C12.5847 13.2144 12.6767 13.1959 12.7625 13.1601C12.8482 13.1242 12.926 13.0717 12.9913 13.0055L24.776 1.22336C24.8452 1.15858 24.9007 1.08052 24.9392 0.993843C24.9777 0.907165 24.9983 0.813633 24.9999 0.718814C25.0015 0.623995 24.984 0.529827 24.9484 0.441914C24.9129 0.354001 24.86 0.27414 24.7929 0.207083C24.7259 0.140027 24.646 0.0871457 24.5581 0.0515866C24.4702 0.0160274 24.376 -0.00148261 24.2812 9.82541e-05C24.1864 0.00167912 24.0928 0.0223186 24.0062 0.0607886C23.9195 0.0992586 23.8414 0.154773 23.7766 0.224027L12.4917 11.5065L1.20922 0.224027C1.14374 0.158158 1.06589 0.105886 0.980141 0.0702156C0.894388 0.0345455 0.802427 0.0161822 0.709553 0.0161822C0.616678 0.0161822 0.524721 0.0345455 0.438969 0.0702156C0.353216 0.105886 0.275362 0.158158 0.209887 0.224027Z"
      fill="url(#paint02_linear)"
    />
    <defs>
      <linearGradient
        id="paint02_linear"
        x1="25"
        y1="6.60719"
        x2="1.07288e-06"
        y2="6.60719"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FF69" />
        <stop offset="1" stopColor="#FF975D" />
      </linearGradient>
    </defs>
  </svg>
);
const plus = (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 0C4.44772 0 4 0.447715 4 1V4L1 4C0.447715 4 0 4.44771 0 5C0 5.55228 0.447715 6 1 6H4V9C4 9.55229 4.44772 10 5 10C5.55228 10 6 9.55228 6 9V6H9C9.55228 6 10 5.55228 10 5C10 4.44772 9.55228 4 9 4L6 4V1C6 0.447715 5.55228 0 5 0Z"
      fill="#D2D6EF"
      fillOpacity="0.3"
    />
  </svg>
);
const downArrow = (
  <svg
    width="12"
    height="19"
    viewBox="0 0 12 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.00139 0.000488337C6.71148 0.000488306 7.28711 0.576122 7.28711 1.2862L7.28711 15.9906C7.28711 16.7007 6.71148 17.2763 6.0014 17.2763C5.29132 17.2763 4.71568 16.7007 4.71568 15.9906L4.71568 1.2862C4.71568 0.576122 5.29131 0.000488368 6.00139 0.000488337Z"
      fill="#D2D6EF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6234 11.4386C12.1255 11.9407 12.1255 12.7548 11.6234 13.2569L6.90914 17.9711C6.40704 18.4733 5.59297 18.4733 5.09086 17.9711L0.376577 13.2569C-0.125527 12.7548 -0.125526 11.9407 0.376577 11.4386C0.878679 10.9365 1.69275 10.9365 2.19485 11.4386L6 15.2437L9.80515 11.4386C10.3073 10.9365 11.1213 10.9365 11.6234 11.4386Z"
      fill="#D2D6EF"
    />
  </svg>
);
const bgArtwork = (
  <svg
    width="2817"
    height="2578"
    viewBox="0 0 2817 2578"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.3">
      <path
        d="M537.695 1484.26C690.832 1638.96 792.291 1885.46 871.977 2079.05C1008.72 2411.26 1005.39 2553.55 1076.64 2574.72C1219.71 2617.22 1271.06 2054.75 1576.93 1803.69C1891.56 1545.43 2192.57 1848.93 2261.41 1645.81C2318.99 1475.88 2178.67 1055.83 1965.78 926.185C1648.81 733.154 1446.42 1351.66 1047.08 1241.94C701.329 1146.94 629.121 621.781 353.499 683.866C144.615 730.912 -29.7573 1081.17 5.5756 1234.6C42.3463 1394.28 279.162 1223.09 537.695 1484.26Z"
        stroke="#FF8B5B"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0 0"
      />
      <path
        d="M898.101 2062.85C1038.47 2380.53 1030.16 2525.57 1100.56 2545.78C1242.33 2586.09 1294.04 2035.51 1594.62 1789.46C1903.92 1536.33 2199.09 1832.03 2268.54 1631.79C2328.5 1458.93 2192.36 1036.53 1981.98 907.615C1669.4 716.309 1467.37 1334.86 1067.64 1228.63C725.931 1136.12 652.749 620.768 380.977 680.345C174.65 725.307 3.60736 1066.62 35.2552 1219.03C59.8295 1337.38 192.542 1281.92 364.67 1354.43C425.006 1379.85 490.34 1421.19 557.443 1489.09C611.62 1543.94 659.882 1608.75 702.955 1677.04C783.064 1804.28 844.73 1942.06 898.101 2062.85Z"
        stroke="#FF8D5C"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0 0"
      />
      <path
        d="M924.279 2046.64C1068.05 2349.51 1054.98 2497.57 1124.54 2516.83C1264.99 2554.94 1317.08 2016.25 1612.37 1775.22C1916.33 1527.22 2205.72 1815.16 2275.73 1617.74C2338.08 1441.96 2206.09 1017.22 1998.23 889.032C1690.04 699.449 1488.19 1319.97 1088.26 1215.3C750.42 1126.89 676.435 619.74 408.505 676.816C204.74 719.693 37.0194 1052.17 64.9892 1203.45C86.8659 1321.79 214.874 1274.51 384.374 1353.5C444.32 1381.44 509.73 1425.47 577.238 1493.9C632.099 1549.56 681.433 1613.94 725.427 1680.25C807.117 1803.82 869.229 1930.67 924.279 2046.64Z"
        stroke="#FF915C"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0 0"
      />
      <path
        d="M950.518 2030.54C1097.41 2318.37 1079.85 2469.96 1148.57 2487.99C1287.66 2524.48 1340.17 1997.11 1630.18 1761.1C1928.81 1518.21 2212.47 1798.43 2282.99 1603.81C2347.74 1425.1 2219.9 998.027 2014.54 870.556C1710.74 682.698 1509.17 1304.19 1108.93 1202.08C775.068 1116.91 700.18 618.822 436.099 673.395C234.892 714.188 70.4792 1037.96 94.7848 1187.98C113.975 1306.42 237.303 1267.33 404.151 1352.68C463.72 1383.15 529.19 1429.87 597.102 1498.83C652.647 1555.29 703.055 1619.24 747.961 1683.56C831.235 1803.48 893.616 1919.04 950.518 2030.54Z"
        stroke="#FF935D"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0 0"
      />
      <path
        d="M976.825 2014.31C1126.49 2286.98 1104.79 2442.04 1172.68 2459.02C1310.44 2493.49 1363.33 1977.83 1648.05 1746.84C1941.35 1509.09 2219.34 1781.61 2290.31 1589.75C2357.49 1408.11 2233.76 978.697 2030.92 851.954C1731.51 665.825 1530.23 1288.28 1129.68 1188.74C799.787 1106.77 723.991 617.77 463.756 669.848C265.11 708.555 103.974 1023.75 124.65 1172.39C141.153 1291.07 259.819 1260.12 423.987 1351.73C483.2 1384.77 548.705 1434.14 617.026 1503.63C673.255 1560.9 724.738 1624.41 770.561 1686.75C855.419 1803 917.884 1906.94 976.825 2014.31Z"
        stroke="#FF975D"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0 0"
      />
      <path
        d="M1003.21 1998.07C1155.24 2255.53 1129.82 2414.1 1196.86 2430.05C1333.29 2462.5 1386.57 1958.56 1666.01 1732.58C1953.96 1499.95 2226.34 1764.81 2297.7 1575.69C2367.35 1391.11 2247.71 959.37 2047.37 833.347C1752.35 648.937 1551.38 1272.34 1150.5 1175.4C824.586 1096.59 747.943 617.565 491.49 666.296C295.451 703.556 137.512 1009.66 154.59 1156.79C168.41 1275.84 282.438 1253.01 443.905 1350.77C502.769 1386.41 568.305 1438.4 637.027 1508.42C693.944 1566.5 746.575 1629.42 793.239 1689.94C879.828 1802.23 942.035 1894.48 1003.21 1998.07Z"
        stroke="#FB995E"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0 0"
      />
      <path
        d="M1029.67 1981.83C1153.4 2265.69 1154.93 2386.15 1221.12 2401.07C1356.23 2431.5 1409.89 1939.27 1684.04 1718.31C1966.66 1490.81 2233.47 1748.05 2305.18 1561.62C2377.3 1374.1 2261.74 940.021 2063.91 814.732C1773.28 632.051 1572.61 1256.36 1171.41 1162.04C849.476 1086.36 771.922 616.664 519.312 662.735C325.834 698.028 171.078 995.688 184.614 1141.18C195.732 1260.74 305.154 1246 463.903 1349.8C522.432 1388.08 587.985 1442.66 657.115 1513.2C714.712 1572.09 768.432 1634.54 816 1693.11C904.188 1801.69 955.737 1902.27 1029.67 1981.83Z"
        stroke="#F79C5E"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0 0"
      />
      <path
        d="M1056.23 1965.75C1177.78 2241.56 1180.13 2358.38 1245.48 2372.25C1379.27 2400.66 1433.31 1920.15 1702.17 1704.2C1979.46 1481.83 2240.74 1731.48 2312.75 1547.71C2387.37 1357.26 2275.85 920.849 2080.53 796.28C1794.3 615.329 1593.94 1240.53 1192.4 1148.85C874.466 1076.26 795.993 615.915 547.222 659.338C356.309 692.66 204.675 982.015 214.726 1125.73C223.136 1245.93 327.976 1239.27 483.992 1349.01C542.193 1389.94 607.753 1447.07 677.292 1518.15C735.573 1577.85 790.371 1639.84 838.85 1696.45C928.639 1801.31 978.615 1893.93 1056.23 1965.75Z"
        stroke="#F39F5F"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0 0"
      />
      <path
        d="M1082.88 1949.5C1202.26 2217.25 1205.43 2330.42 1269.93 2343.26C1402.4 2369.64 1456.81 1900.85 1720.39 1689.92C1992.34 1472.68 2248.14 1714.77 2320.41 1533.63C2397.56 1340.27 2290.06 901.497 2097.26 777.656C1815.41 598.429 1615.37 1224.51 1213.49 1135.48C899.553 1065.95 820.152 614.999 575.219 655.774C386.871 687.124 238.291 968.286 244.932 1110.11C250.599 1231.08 350.893 1232.48 504.173 1348.04C562.056 1391.67 627.62 1451.33 697.564 1522.93C756.525 1583.43 812.405 1644.96 861.794 1699.62C953.182 1800.76 1001.58 1885.41 1082.88 1949.5Z"
        stroke="#EEA15F"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0 0"
      />
      <path
        d="M1109.63 1933.41C1226.85 2193.1 1230.82 2302.62 1294.47 2314.43C1425.63 2338.77 1480.42 1881.72 1738.71 1675.8C2005.33 1463.69 2255.68 1698.25 2328.17 1519.72C2407.87 1323.43 2304.38 882.314 2114.08 759.193C1836.63 581.691 1636.91 1208.61 1234.68 1122.28C924.749 1055.77 844.414 614.24 603.323 652.367C417.536 681.744 271.922 954.822 275.238 1094.66C278.131 1216.5 373.915 1225.97 524.456 1347.22C582.029 1393.59 647.586 1455.73 717.934 1527.86C777.583 1589.18 834.541 1650.25 884.841 1702.95C977.82 1800.37 1024.65 1877.06 1109.63 1933.41Z"
        stroke="#EBA560"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0 0"
      />
      <path
        d="M1134.88 1917.15C1249.93 2168.79 1254.72 2274.65 1317.52 2285.44C1447.37 2307.74 1502.52 1862.42 1755.52 1661.52C2016.81 1454.53 2261.75 1681.6 2334.43 1505.63C2416.7 1306.44 2317.35 862.309 2129.4 740.558C1856.58 563.838 1656.96 1192.53 1254.36 1108.91C948.444 1045.38 867.169 613.307 629.924 648.786C446.699 676.197 303.968 941.291 304.045 1079.04C304.118 1201.87 395.432 1219.41 543.237 1346.24C600.496 1395.39 666.046 1459.97 736.802 1532.63C797.135 1594.75 855.172 1655.36 906.379 1706.11C1000.95 1799.82 1046.22 1868.53 1134.88 1917.15Z"
        stroke="#E7A760"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0 0"
      />
      <path
        d="M1161.85 1901.05C1274.73 2144.63 1280.33 2246.83 1342.29 2256.6C1470.82 2276.86 1526.35 1843.28 1774.06 1647.39C2030.01 1445.53 2269.59 1665.12 2342.42 1491.7C2427.27 1289.63 2331.9 843.052 2146.44 722.084C1878.03 547.009 1678.73 1176.57 1275.77 1095.7C973.869 1035.11 891.648 612.525 658.245 645.367C477.581 670.795 337.632 928.006 334.575 1063.57C331.776 1187.51 418.665 1213.1 563.738 1345.42C620.693 1397.37 686.232 1464.37 757.394 1537.55C818.407 1600.48 877.519 1660.64 929.647 1709.43C1025.81 1799.43 1069.51 1860.17 1161.85 1901.05Z"
        stroke="#E2AA61"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0 0"
      />
      <path
        d="M1187.37 1884.78C1298.08 2120.3 1304.51 2218.84 1365.61 2227.59C1492.84 2245.8 1548.73 1823.96 1791.16 1633.1C2041.77 1436.36 2276 1648.52 2348.95 1477.6C2436.43 1272.65 2345 823.627 2162.04 703.442C1898.03 530.024 1699.06 1160.42 1295.73 1082.31C997.85 1024.64 914.677 611.569 685.119 641.786C507.013 665.225 369.751 914.627 363.651 1047.93C357.924 1173.06 440.444 1206.74 582.795 1344.43C639.436 1399.22 704.969 1468.6 776.535 1542.31C838.236 1606.05 898.422 1665.75 951.461 1712.58C1049.21 1798.87 1091.35 1851.63 1187.37 1884.78Z"
        stroke="#DEAD60"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0 0"
      />
      <path
        d="M1214.65 1868.66C1323.2 2096.13 1330.44 2191 1390.69 2198.73C1516.61 2214.89 1572.86 1804.8 1810.01 1618.95C2055.29 1427.34 2284.21 1632.09 2357.25 1463.65C2447.36 1255.84 2359.85 804.363 2179.39 684.955C1919.8 513.182 1721.16 1144.39 1317.46 1069.08C1023.6 1014.27 939.469 610.763 713.757 638.348C538.206 659.803 403.526 901.459 394.494 1032.44C385.777 1158.83 463.967 1200.64 603.609 1343.59C659.943 1401.25 725.465 1472.98 797.44 1547.22C859.821 1611.76 921.082 1671 975.043 1715.87C1074.37 1798.47 1114.95 1843.25 1214.65 1868.66Z"
        stroke="#DAAF61"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0 0"
      />
      <path
        d="M1244.91 1861.63C1347.13 2074.47 1355.79 2163.25 1414.36 2169.87C1538.98 2183.96 1595.59 1785.63 1827.45 1604.8C2067.39 1418.32 2291.03 1615.68 2364.13 1449.7C2456.91 1239.05 2373.3 785.09 2195.33 666.459C1940.15 496.349 1741.85 1128.32 1337.76 1055.85C1047.94 1003.87 962.843 609.942 740.98 634.908C567.99 654.374 435.787 888.327 423.919 1016.96C412.138 1144.66 486.056 1194.64 623.012 1342.74C679.031 1403.32 744.55 1477.35 816.929 1552.13C879.998 1617.48 942.331 1676.27 997.209 1719.17C1096.94 1797.15 1136.22 1834 1236.95 1851.91"
        stroke="#D5B161"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0 0"
      />
      <path
        d="M1272.63 1850.94C1372.66 2055.75 1382.13 2140.88 1439.82 2146.5C1563.14 2158.52 1620.11 1771.96 1846.69 1596.14C2081.3 1414.78 2299.68 1604.78 2372.82 1441.24C2468.28 1227.77 2388.54 771.32 2213.07 653.456C1962.3 485.007 1764.35 1117.71 1359.87 1048.1C1074.09 998.923 988.013 614.602 770.002 636.959C599.567 654.431 469.741 880.715 455.147 1006.96C440.228 1136.02 509.924 1194.21 644.217 1347.39C699.909 1410.92 765.434 1487.22 838.222 1562.52C901.971 1628.68 965.375 1687.02 1021.17 1727.96C1122.5 1802.31 1160.28 1831.31 1264.6 1841.56"
        stroke="#D1B562"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0 0"
      />
      <path
        d="M1298.97 1842.01C1396.82 2038.8 1407.09 2120.26 1463.91 2124.9C1585.93 2134.84 1643.26 1760.05 1864.55 1589.25C2093.82 1413.02 2306.98 1595.68 2380.12 1434.54C2478.28 1218.27 2402.41 759.307 2229.44 642.21C1983.08 475.44 1785.48 1108.83 1380.6 1042.13C1098.86 995.7 1011.81 621.025 797.645 640.768C629.764 656.245 502.22 874.878 484.995 998.726C466.855 1129.17 532.388 1195.64 664.039 1353.8C719.403 1420.32 784.939 1498.85 858.135 1574.67C922.568 1641.64 987.039 1699.53 1043.76 1738.51C1146.68 1809.24 1182.94 1830.36 1290.87 1832.96"
        stroke="#CDB762"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0 0"
      />
      <path
        d="M1325.55 1833.24C1421.21 2022 1432.29 2099.8 1488.24 2103.45C1608.96 2111.31 1666.64 1748.29 1882.65 1582.51C2106.59 1411.41 2314.52 1586.75 2387.65 1428C2488.56 1208.96 2416.51 747.457 2246.03 631.126C2004.08 466.024 1806.84 1100.07 1401.57 1036.3C1123.87 992.6 1035.83 627.605 825.524 644.744C660.197 658.216 534.834 869.192 515.079 990.651C493.635 1122.48 555.06 1197.32 684.101 1360.37C739.119 1429.9 804.68 1510.64 878.28 1586.99C943.398 1654.77 1008.94 1712.21 1066.58 1749.21C1171.1 1816.32 1205.85 1829.57 1317.39 1824.49"
        stroke="#C8BA63"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0 0"
      />
      <path
        d="M1352.38 1824.27C1445.86 2005.03 1457.74 2079.16 1512.82 2081.82C1632.24 2087.6 1690.28 1736.36 1901 1575.6C2119.6 1409.62 2322.35 1577.68 2395.45 1421.29C2499.11 1199.49 2430.86 735.437 2262.88 619.872C2025.34 456.444 1828.47 1091.1 1422.79 1030.3C1149.14 989.291 1060.11 633.998 853.656 648.54C690.881 660.005 567.611 863.319 545.417 982.394C520.585 1115.61 577.958 1198.89 704.41 1366.76C759.079 1439.34 824.675 1522.25 898.68 1599.14C964.485 1667.72 1031.1 1724.71 1089.65 1759.75C1195.77 1823.22 1229 1828.59 1344.17 1815.84"
        stroke="#C4BC63"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0 0"
      />
      <path
        d="M1377.89 1815.29C1469.18 1988.03 1481.86 2058.5 1536.06 2060.18C1654.19 2063.85 1712.58 1724.42 1918.02 1568.67C2131.29 1407.82 2328.87 1568.62 2401.91 1414.56C2508.35 1190.03 2443.88 723.398 2278.4 608.593C2045.27 446.85 1848.77 1082.07 1442.67 1024.28C1173.08 985.922 1083.06 640.365 880.456 652.321C720.235 661.776 598.979 857.398 574.422 974.124C546.11 1108.7 599.488 1200.53 723.393 1373.14C777.695 1448.79 843.34 1533.85 917.753 1611.26C984.235 1680.65 1051.91 1737.21 1111.4 1770.26C1219.1 1830.12 1250.82 1827.59 1369.63 1807.15"
        stroke="#C0BF64"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0 0"
      />
      <path
        d="M1405.27 1806.41C1494.38 1971.23 1507.87 2040.63 1561.19 2038.65C1678 2034.31 1736.77 1712.58 1936.91 1561.85C2144.85 1406.13 2337.29 1559.68 2410.25 1407.94C2519.5 1180.72 2458.79 711.48 2295.8 597.43C2067.07 437.371 1870.95 1073.11 1464.44 1018.38C1198.9 982.63 1107.88 646.837 909.137 656.207C751.466 663.65 632.144 851.554 605.308 965.969C573.434 1101.85 622.868 1202.34 744.253 1379.63C798.172 1458.38 863.879 1545.55 938.701 1623.49C1005.87 1693.69 1074.61 1749.81 1135.02 1780.89C1244.32 1837.13 1274.52 1826.68 1396.97 1798.57"
        stroke="#BAC263"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0 0"
      />
      <path
        d="M1432.96 1797.48C1519.88 1954.3 1534.19 2020.08 1586.6 2017.07C1702.08 2010.46 1761.24 1700.7 1956.1 1554.98C2158.71 1404.39 2346.01 1550.72 2418.88 1401.27C2530.97 1171.39 2473.98 699.51 2313.49 586.221C2089.16 427.846 1893.43 1064.05 1486.5 1012.43C1225.02 979.252 1132.99 653.252 938.106 660.053C782.983 665.467 665.531 845.613 636.483 957.763C600.959 1094.9 646.502 1204.16 765.402 1386.07C818.927 1467.95 884.718 1557.21 959.938 1635.68C1027.79 1706.69 1097.6 1762.36 1158.93 1791.47C1269.82 1844.09 1298.51 1825.72 1424.6 1789.91"
        stroke="#B6C464"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0 0"
      />
      <path
        d="M1459.33 1788.5C1544.07 1937.34 1559.2 1999.48 1610.71 1995.47C1724.85 1986.6 1784.4 1688.79 1973.98 1548.09C2171.25 1402.62 2353.44 1541.76 2426.2 1394.58C2541.15 1162.06 2487.85 687.525 2329.88 574.985C2109.95 418.307 1914.6 1054.93 1507.24 1006.45C1249.83 975.815 1156.8 659.635 965.766 663.872C813.194 667.258 697.55 839.589 666.349 949.531C627.097 1087.84 668.792 1206.01 785.245 1392.48C838.358 1477.53 904.24 1568.85 979.871 1647.84C1048.41 1719.65 1119.28 1774.9 1181.54 1802.02C1294.02 1851.03 1321.18 1824.72 1450.93 1781.22"
        stroke="#B1C764"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0 0"
      />
      <path
        d="M1481.1 1771.21C1567.96 1918.11 1583.45 1976.11 1635.12 1973.91C1748.09 1969.09 1807.87 1676.93 1992.16 1541.24C2184.09 1400.9 2361.19 1532.85 2433.82 1387.93C2551.65 1152.81 2502.04 675.582 2346.55 563.78C2131.03 408.809 1936.08 1045.8 1528.29 1000.51C1274.94 972.379 1180.9 666.048 993.731 667.727C843.707 669.08 729.814 833.539 696.516 941.34C653.45 1080.74 691.344 1207.94 805.386 1398.94C858.067 1487.16 924.063 1580.52 1000.1 1660.05C1069.32 1732.67 1141.26 1787.48 1204.44 1812.62C1319.51 1858.41 1344.59 1823.15 1481.1 1771.21Z"
        stroke="#ACCA65"
        strokeWidth="0.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0 0"
      />
      <path
        d="M1509.61 1762.36C1594.3 1901.2 1610.62 1955.49 1661.43 1952.32C1773.11 1945.35 1833.23 1665.03 2012.24 1534.36C2198.84 1399.15 2370.86 1523.94 2443.34 1381.24C2564.09 1143.57 2518.11 663.616 2365.13 552.564C2154.01 399.305 1959.46 1036.59 1551.24 994.552C1301.96 968.876 1206.9 672.428 1023.59 671.566C876.112 670.867 763.933 827.405 728.582 933.127C681.629 1073.52 715.758 1209.9 827.431 1405.37C879.657 1496.79 945.786 1592.17 1022.23 1672.22C1092.13 1745.65 1165.14 1800.04 1229.24 1823.19C1345.88 1865.32 1369.42 1822.03 1509.61 1762.36Z"
        stroke="#A7CC65"
        strokeWidth="0.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0 0"
      />
      <path
        d="M1536.81 1753.47C1619.33 1884.26 1636.48 1934.83 1686.43 1930.7C1796.82 1921.56 1857.29 1653.1 2031.01 1527.45C2212.28 1397.36 2379.22 1515.02 2451.56 1374.53C2575.24 1134.33 2532.89 651.618 2382.41 541.298C2175.68 389.753 1981.54 1027.29 1572.88 988.549C1327.67 965.307 1231.59 678.758 1052.15 675.355C907.21 672.611 796.709 821.154 759.338 924.876C708.434 1066.16 738.829 1211.85 848.161 1411.76C899.927 1506.4 966.199 1603.79 1043.05 1684.36C1113.64 1758.6 1187.72 1812.55 1252.74 1833.72C1370.95 1872.19 1392.94 1820.88 1536.81 1753.47Z"
        stroke="#A3CF64"
        strokeWidth="0.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0 0"
      />
      <path
        d="M1564.31 1744.56C1644.65 1867.28 1662.64 1914.13 1711.72 1909.04C1820.83 1897.74 1881.65 1641.15 2050.08 1520.51C2226 1395.55 2387.9 1506.09 2460.07 1367.79C2586.71 1125.1 2547.95 639.594 2399.97 530.019C2197.65 380.186 2003.91 1017.9 1594.81 982.526C1353.67 961.668 1256.57 685.052 1080.99 679.125C938.602 674.324 829.748 814.804 790.392 916.593C735.461 1058.65 762.157 1213.8 869.192 1418.12C920.466 1516 986.909 1615.36 1064.17 1696.47C1135.44 1771.52 1210.58 1825.04 1276.53 1844.22C1396.31 1879.05 1416.75 1819.7 1564.31 1744.56Z"
        stroke="#9ED165"
        strokeWidth="0.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0 0"
      />
      <path
        d="M1590.49 1735.59C1668.66 1850.26 1687.47 1893.38 1735.69 1887.34C1843.53 1873.85 1904.68 1629.14 2067.82 1513.52C2238.42 1393.69 2395.27 1497.12 2467.26 1361C2596.89 1115.87 2561.69 627.53 2416.22 518.681C2218.3 370.572 2024.97 1008.42 1615.43 976.451C1378.36 957.942 1280.24 691.282 1108.52 682.848C968.676 675.98 861.445 808.335 820.124 908.259C761.121 1050.97 784.128 1215.72 888.899 1424.44C939.666 1525.58 1006.31 1626.9 1083.97 1708.54C1155.92 1784.39 1232.13 1837.5 1299 1854.67C1420.35 1885.86 1439.25 1818.47 1590.49 1735.59Z"
        stroke="#98D565"
        strokeWidth="0.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0 0"
      />
      <path
        d="M1618.54 1724.38C1694.55 1830.99 1714.19 1870.37 1761.55 1863.39C1868.1 1847.69 1929.59 1614.88 2087.45 1504.28C2252.71 1389.57 2404.53 1485.93 2476.33 1351.95C2608.98 1104.43 2577.31 613.219 2434.35 505.097C2240.82 358.729 2047.91 996.611 1637.93 968.124C1404.93 951.936 1305.78 695.255 1137.93 684.318C1000.63 675.371 895.005 799.534 851.74 897.684C788.602 1040.92 807.945 1215.41 910.489 1428.5C960.73 1532.91 1027.57 1636.19 1105.64 1718.35C1178.28 1795.01 1255.55 1847.69 1323.35 1862.88C1446.28 1890.42 1463.63 1815 1618.54 1724.38Z"
        stroke="#93D766"
        strokeWidth="0.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0 0"
      />
      <path
        d="M1646.87 1700.28C1720.71 1798.84 1741.18 1834.47 1787.68 1826.56C1892.94 1808.64 1954.77 1587.74 2107.35 1482.15C2267.27 1372.57 2414.07 1461.87 2485.67 1330.03C2621.37 1080.15 2593.21 586.02 2452.75 478.624C2263.61 333.992 2071.12 971.851 1660.69 946.908C1431.77 933.002 1331.6 686.327 1167.61 672.901C1032.85 661.863 928.826 777.772 883.624 874.22C816.308 1017.85 831.994 1202.23 932.35 1419.68C982.04 1527.36 1049.12 1632.58 1127.59 1715.28C1200.91 1792.75 1279.26 1845.01 1347.98 1858.2C1472.47 1882.11 1488.28 1798.63 1646.87 1700.28Z"
        stroke="#8ED966"
        strokeWidth="0.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0 0"
      />
      <path
        d="M1673.85 1676.01C1745.52 1766.51 1766.82 1798.38 1812.46 1789.54C1916.43 1769.4 1978.61 1560.43 2125.9 1459.85C2280.49 1355.4 2422.28 1437.65 2493.66 1307.92C2632.44 1055.74 2607.75 558.664 2469.81 451.973C2285.06 309.093 2092.99 946.845 1682.11 925.525C1457.27 913.856 1356.07 677.216 1195.95 661.31C1063.73 648.182 961.297 755.752 914.166 850.583C842.635 994.494 854.667 1188.88 952.866 1410.69C1001.99 1521.65 1069.31 1628.81 1148.19 1712.03C1222.2 1790.31 1301.61 1842.15 1371.25 1853.35C1497.32 1873.61 1511.57 1782.09 1673.85 1676.01Z"
        stroke="#87DC66"
        strokeWidth="0.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0 0"
      />
      <path
        d="M1701.09 1651.61C1770.59 1734.05 1792.72 1762.17 1837.49 1752.41C1940.18 1730.01 2002.71 1532.98 2144.71 1437.43C2293.96 1338.1 2430.75 1413.32 2501.91 1285.7C2643.8 1031.25 2622.55 531.177 2487.11 425.207C2306.75 284.079 2115.11 921.645 1703.79 904.015C1483.03 894.55 1380.79 667.955 1224.54 649.593C1094.86 634.352 994.019 733.544 944.958 826.814C869.176 970.872 877.558 1175.42 973.633 1401.57C1022.17 1515.82 1089.77 1624.91 1169.05 1708.66C1243.74 1787.74 1324.22 1839.17 1394.78 1848.37C1522.43 1865 1535.13 1765.43 1701.09 1651.61Z"
        stroke="#82DE66"
        strokeWidth="0.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0 0"
      />
      <path
        d="M1730.16 1627.12C1797.49 1701.51 1820.45 1725.86 1864.36 1715.18C1965.76 1690.53 2028.52 1505 2165.35 1414.91C2309.15 1320.25 2441.06 1388.93 2512 1263.39C2657.02 1006.74 2639.18 503.621 2506.26 398.35C2330.29 258.986 2139.07 896.276 1727.3 882.415C1510.62 875.125 1407.35 658.604 1254.96 637.791C1127.83 620.432 1028.58 711.183 977.589 802.966C897.536 947.046 902.254 1161.87 996.241 1392.36C1044.17 1509.92 1112.05 1620.93 1191.75 1705.2C1267.12 1785.1 1348.67 1836.11 1420.15 1843.3C1549.36 1856.3 1560.52 1748.68 1730.16 1627.12Z"
        stroke="#7CE267"
        strokeWidth="0.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0 0"
      />
      <path
        d="M1757.87 1602.57C1823.03 1668.9 1846.81 1689.48 1889.86 1677.9C1989.97 1650.96 2053.08 1477.41 2184.62 1392.34C2323.09 1302.8 2450.01 1364.49 2520.72 1241.01C2668.91 982.215 2654.45 476.002 2524.04 371.424C2352.45 233.828 2161.66 870.757 1749.45 860.757C1536.83 855.596 1432.54 649.166 1284.02 625.925C1159.43 606.43 1061.78 688.68 1008.85 779.048C924.505 923.038 925.548 1148.24 1017.48 1383.1C1064.79 1503.95 1132.97 1616.88 1213.07 1701.68C1289.13 1782.38 1371.74 1832.98 1444.15 1838.17C1574.94 1847.55 1584.55 1731.86 1757.87 1602.57Z"
        stroke="#75E367"
        strokeWidth="0.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0 0"
      />
      <path
        d="M1785.79 1577.83C1848.78 1636.1 1873.39 1652.9 1915.58 1640.42C2014.4 1611.18 2077.86 1449.63 2204.12 1369.57C2337.25 1285.16 2459.19 1339.87 2529.65 1218.44C2681.04 957.556 2669.92 448.193 2542.03 344.313C2374.82 208.493 2184.46 844.966 1771.8 838.897C1563.27 835.832 1457.96 639.517 1313.3 613.869C1191.25 592.222 1095.22 665.925 1040.33 754.941C951.678 898.711 949.033 1134.42 1038.93 1373.63C1085.6 1497.79 1154.11 1612.63 1234.61 1697.96C1311.36 1779.48 1395.04 1829.66 1468.37 1832.85C1600.73 1838.6 1608.79 1714.85 1785.79 1577.83Z"
        stroke="#6FE668"
        strokeWidth="0.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0 0"
      />
      <path
        d="M1813.92 1552.92C1874.75 1603.13 1900.18 1616.14 1941.52 1602.77C2039.03 1571.21 2102.85 1421.68 2223.82 1346.63C2351.62 1267.34 2468.58 1315.1 2538.8 1195.7C2693.41 932.78 2685.61 420.228 2560.23 317.024C2397.4 183.001 2207.47 818.911 1794.37 816.877C1589.91 815.874 1483.58 629.688 1342.78 601.635C1223.28 577.83 1128.87 642.935 1072.02 730.666C979.054 874.115 972.696 1120.42 1060.6 1364C1106.6 1491.47 1175.46 1608.22 1256.37 1694.08C1333.79 1776.4 1418.54 1826.18 1492.8 1827.35C1626.73 1829.48 1633.24 1697.67 1813.92 1552.92Z"
        stroke="#68E967"
        strokeWidth="0.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0 0"
      />
      <path
        d="M1840.65 1528.17C1899.31 1570.33 1925.56 1579.55 1966.04 1565.28C2062.26 1531.39 2126.45 1393.9 2242.12 1323.86C2364.6 1249.71 2476.58 1290.51 2546.54 1173.12C2704.42 908.237 2699.89 392.428 2577.04 289.912C2418.58 157.681 2229.08 792.914 1815.55 795.016C1615.15 796.035 1507.81 619.997 1370.86 589.572C1253.91 563.587 1161.14 620.054 1102.31 706.551C1005.02 849.578 994.932 1106.59 1080.86 1354.53C1126.19 1485.31 1195.4 1603.97 1276.72 1690.36C1354.83 1773.48 1440.65 1822.85 1515.82 1822.03C1651.34 1820.54 1656.29 1680.66 1840.65 1528.17Z"
        stroke="#5FEB68"
        strokeWidth="0.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0 0"
      />
      <path
        d="M1869.18 1503.15C1925.67 1537.25 1952.74 1542.66 1992.37 1527.52C2087.27 1491.27 2151.83 1365.84 2262.22 1300.81C2379.36 1231.8 2486.37 1265.66 2556.08 1150.27C2717.25 883.473 2715.96 364.362 2595.64 262.511C2441.55 132.094 2252.47 766.544 1838.51 772.883C1642.18 775.886 1533.82 610.018 1400.74 577.237C1286.34 549.055 1195.22 596.845 1134.39 682.165C1032.79 824.668 1018.94 1092.45 1102.92 1344.79C1147.55 1478.87 1217.14 1599.45 1298.86 1686.37C1377.66 1770.29 1464.55 1819.26 1540.64 1816.42C1677.74 1811.31 1681.14 1663.38 1869.18 1503.15Z"
        stroke="#58ED68"
        strokeWidth="0.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0 0"
      />
      <path
        d="M1897.89 1478.14C1952.21 1504.19 1980.1 1505.79 2018.88 1489.78C2112.47 1451.15 2177.41 1337.81 2282.5 1277.78C2394.31 1213.91 2496.36 1240.84 2565.8 1127.44C2730.31 858.799 2732.22 336.326 2614.42 235.139C2464.7 106.531 2276.05 740.074 1861.66 750.767C1669.39 755.726 1560.04 600.033 1430.8 564.915C1318.96 534.512 1229.51 573.603 1166.66 657.79C1060.74 799.679 1043.1 1078.32 1125.16 1335.06C1169.07 1472.45 1239.07 1594.94 1321.2 1682.39C1400.67 1767.13 1488.64 1815.92 1565.64 1810.84C1704.33 1802.54 1706.17 1646.11 1897.89 1478.14Z"
        stroke="#4FF068"
        strokeWidth="0.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0 0"
      />
      <path
        d="M1925.17 1453.07C1977.32 1471.05 2006.02 1468.83 2043.96 1451.97C2136.23 1410.94 2201.55 1309.71 2301.35 1254.68C2407.84 1195.96 2504.92 1215.97 2574.1 1104.54C2741.96 834.124 2747.04 308.23 2631.77 207.686C2486.42 80.9144 2298.19 713.421 1883.38 728.577C1695.17 735.451 1584.82 589.956 1459.44 552.522C1350.15 519.888 1262.38 550.24 1197.5 633.346C1087.28 774.536 1065.81 1064.1 1145.98 1325.27C1189.17 1465.95 1259.57 1590.36 1342.1 1678.34C1422.26 1763.88 1511.3 1812.25 1589.22 1805.18C1729.5 1793.23 1729.77 1628.77 1925.17 1453.07Z"
        stroke="#43F268"
        strokeWidth="0.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0 0"
      />
      <path
        d="M1954.23 1428.06C2004.2 1437.99 2033.72 1431.95 2070.81 1414.22C2161.75 1370.77 2227.47 1281.69 2321.97 1231.65C2423.13 1178.08 2515.26 1191.19 2584.17 1081.7C2755.42 809.587 2763.63 280.205 2650.9 180.315C2509.9 55.3742 2322.08 686.714 1906.88 706.462C1722.72 715.212 1611.38 579.933 1489.85 540.194C1383.12 505.312 1297.05 526.909 1230.11 608.978C1115.6 749.384 1090.27 1049.94 1168.56 1315.54C1211 1459.52 1281.83 1585.86 1364.77 1674.36C1445.62 1760.72 1535.74 1808.64 1614.57 1799.59C1756.43 1783.98 1755.14 1611.49 1954.23 1428.06Z"
        stroke="#37F668"
        strokeWidth="0.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0 0"
      />
      <path
        d="M1981.84 1402.98C2029.65 1404.85 2059.97 1394.97 2096.21 1376.4C2185.82 1330.51 2251.94 1253.59 2341.15 1208.54C2436.99 1160.13 2524.15 1166.35 2592.78 1058.79C2767.47 785.056 2778.78 252.125 2668.58 152.86C2531.94 29.7727 2344.53 659.794 1928.92 684.264C1748.82 694.868 1636.51 569.803 1518.81 527.794C1414.65 490.63 1330.29 503.454 1261.28 584.531C1142.48 724.087 1113.27 1035.67 1189.71 1305.73C1231.39 1453.01 1302.66 1581.28 1386.01 1670.31C1467.53 1757.47 1558.72 1804.96 1638.47 1793.93C1781.91 1774.65 1779.07 1594.15 1981.84 1402.98Z"
        stroke="#2AF868"
        strokeWidth="0.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0 0"
      />
      <path
        d="M2009.61 1377.82C2055.25 1371.63 2086.37 1357.91 2121.79 1338.5C2210.04 1290.14 2276.58 1225.43 2360.49 1185.34C2451.01 1142.11 2533.21 1141.44 2601.57 1035.8C2779.72 760.513 2794.07 223.965 2686.42 125.326C2554.13 4.09801 2367.12 632.654 1951.13 661.992C1775.07 674.404 1661.81 559.566 1547.93 515.314C1446.35 475.846 1363.71 479.88 1292.61 560C1169.54 698.649 1136.4 1021.31 1211.01 1295.85C1251.93 1446.41 1323.64 1576.61 1407.4 1666.17C1489.6 1754.14 1581.87 1801.2 1662.54 1788.19C1807.57 1765.24 1803.16 1576.72 2009.61 1377.82Z"
        stroke="#09FA69"
        strokeWidth="0.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0 0"
      />
      <path
        d="M2037.52 1352.59C2078.82 1326.45 2112.91 1320.77 2147.49 1300.53C2234.39 1249.68 2301.4 1197.45 2379.98 1162.09C2465.21 1124.29 2542.41 1116.48 2610.49 1012.75C2792.14 735.989 2809.51 195.761 2704.4 97.7241C2576.45 -21.6272 2389.84 605.295 1973.48 639.642C1801.45 653.841 1687.25 549.24 1577.19 502.768C1478.2 460.967 1397.3 456.218 1324.07 535.402C1196.76 673.076 1159.66 1006.86 1232.45 1285.9C1272.59 1439.74 1344.77 1571.88 1428.93 1661.97C1511.82 1750.75 1605.16 1797.38 1686.75 1782.37C1833.35 1755.77 1808.46 1497.58 2037.52 1352.59Z"
        stroke="#00FD68"
        strokeWidth="0.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0 0"
      />
      <path
        d="M2067.17 1327.29C2106.59 1297.39 2141.19 1283.57 2174.95 1262.5C2260.47 1209.14 2327.91 1169.11 2401.2 1138.77C2481.1 1106.1 2553.35 1091.47 2621.16 989.618C2806.34 711.476 2826.57 167.8 2724.13 70.0504C2600.38 -47.0415 2414.28 577.706 1997.57 617.242C1829.57 633.176 1714.45 538.824 1608.2 490.16C1511.8 445.999 1432.65 432.448 1357.28 510.743C1225.75 647.391 1184.64 992.317 1255.64 1275.89C1294.98 1432.99 1367.64 1567.08 1452.2 1657.7C1535.78 1747.28 1630.19 1793.48 1712.69 1776.5C1860.88 1746.23 1839.38 1500.09 2067.17 1327.29Z"
        stroke="#00FF69"
        strokeWidth="0.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0 0"
      />
      <path
        d="M2095.36 1302.1C2133.34 1268.13 2168 1246.45 2200.93 1224.57C2285.06 1168.67 2352.96 1140.87 2420.96 1115.55C2495.53 1088 2562.83 1066.58 2630.36 966.6C2819.11 687.156 2842.32 139.508 2742.39 42.4854C2623.03 -72.8768 2437.23 550.051 2020.2 594.928C1856.21 612.58 1740.2 528.483 1637.74 477.65C1543.94 431.105 1466.56 408.748 1389.03 486.186C1253.28 621.759 1208.14 977.852 1277.36 1265.97C1315.89 1426.34 1389.04 1562.39 1474.01 1653.53C1558.27 1743.92 1653.76 1789.69 1737.18 1770.72C1886.95 1736.79 1870.25 1503.46 2095.36 1302.1Z"
        stroke="#00FF69"
        strokeWidth="0.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0 0"
      />
      <path
        d="M1299.21 1256C1374.44 1582.42 1592.68 1806.89 1761.79 1764.89C1913.14 1727.29 1902.5 1506.69 2123.67 1276.84C2360.96 1030.26 2487.86 1165.02 2639.69 943.518C2832.05 662.868 2858.19 111.163 2760.77 14.8613C2645.81 -98.7769 2460.29 522.151 2042.95 572.567C1735.15 609.753 1586.88 302.075 1420.9 461.565C1280.96 596.028 1231.75 963.305 1299.21 1256Z"
        stroke="#00FF6A"
        strokeWidth="0.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0 0"
      />
    </g>
  </svg>
);
export const icons = {
  search,
  info,
  line,
  expand,
  close,
  arrowDown,
  minimize,
  plus,
  downArrow,
  bgArtwork,
};
