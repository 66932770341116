import React, { useContext, useState } from 'react';

import { GlobalContext, UserContext } from '../../../../context';
import {
  formatCurrency,
  formatNumber,
  formatRate,
} from '../../../../context/utils';
import { BorrowData } from '../../../../interfaces';
import { BorrowModal } from '../../../../interfaces/index';
import { Button } from '../../../atoms/Button';
import { Card } from '../../../atoms/Card';
import { NoRecord } from '../../../atoms/NoRecord';
import { Symbol } from '../../../atoms/Symbol';
import { Tooltip, TooltipContent, TooltipIcon } from '../../../atoms/Tooltip';
import Borrowing from '../../../organisms/Modals/Borrowing';
import Claim from '../../../organisms/Modals/Claim';
import Repay from '../../../organisms/Modals/Repay';

export const InvestedBorrow = ({
  borrowingData,
  loading,
}: {
  borrowingData: any;
  loading: boolean;
}) => {
  const {
    state: { priceData, borrowData },
  } = useContext(GlobalContext);

  const {
    state: { userLendingData },
  } = useContext(UserContext);

  const lendingData = userLendingData.data.investments;

  const [borrowModal, setBorrowModal] = useState<BorrowModal>({
    open: false,
    dataBorrow: undefined,
    protocol: '',
    symbol: '',
  });
  const [repayModal, setRepayModal] = useState<BorrowModal>({
    open: false,
    dataBorrow: undefined,
    protocol: '',
    symbol: '',
  });
  const [claimModal, setClaimModal] = useState<{ open: boolean; data?: any }>({
    open: false,
    data: {},
  });

  const handleLTV = (symbol) => {
    const asset = borrowData.find(
      (element: BorrowData) =>
        element.symbol === (symbol === 'WMATIC' ? 'MATIC' : symbol),
    );

    if (formatRate(asset.aaveData.ltv) > formatRate(asset.creamData.ltv)) {
      return formatRate(asset.aaveData.ltv);
    }

    return formatRate(asset.creamData.ltv);
  };

  const handleBorrowRate = (_symbol) => {
    let borrowAvgRate = 0;

    const aaveLoan = borrowingData.loans.filter(
      (element) =>
        element.symbol === (_symbol === 'MATIC' ? 'WMATIC' : _symbol) &&
        element.protocol === 'aave',
    )[0];
    const creamLoan = borrowingData.loans.filter(
      (element) =>
        element.symbol === (_symbol === 'MATIC' ? 'WMATIC' : _symbol) &&
        element.protocol === 'cream',
    )[0];
    const globalIndicator = borrowData.filter(
      (element: any) => element.symbol === _symbol,
    )[0];

    if (aaveLoan !== undefined && creamLoan !== undefined) {
      borrowAvgRate = formatRate(
        (aaveLoan.borrowRate * aaveLoan.amountUSD +
          creamLoan.borrowRate * creamLoan.amountUSD) /
          (aaveLoan.amountUSD + creamLoan.amountUSD),
      );
    } else if (aaveLoan !== undefined && creamLoan === undefined) {
      borrowAvgRate = formatRate(aaveLoan?.borrowRate);
    } else if (creamLoan !== undefined && aaveLoan === undefined) {
      borrowAvgRate = formatRate(creamLoan?.borrowRate);
    } else {
      borrowAvgRate = formatRate(
        (globalIndicator.aaveData.borrowRate *
          globalIndicator.aaveData.totalBorrowedUSD +
          globalIndicator.creamData.borrowRate *
            globalIndicator.creamData.totalBorrowedUSD) /
          (globalIndicator.aaveData.totalBorrowedUSD +
            globalIndicator.creamData.totalBorrowedUSD),
      );
    }

    return borrowAvgRate;
  };

  const handleCurrentBorrowed = (_symbol) => {
    const aaveLoan = borrowingData.loans.filter(
      (element) =>
        element.symbol === (_symbol === 'MATIC' ? 'WMATIC' : _symbol) &&
        element.protocol === 'aave',
    )[0];
    const creamLoan = borrowingData.loans.filter(
      (element) =>
        element.symbol === (_symbol === 'MATIC' ? 'WMATIC' : _symbol) &&
        element.protocol === 'cream',
    )[0];

    let totalAave = 0;
    let totalCream = 0;

    if (aaveLoan !== undefined && creamLoan !== undefined) {
      totalAave = aaveLoan.amountUSD;
      totalCream = creamLoan.amountUSD;
    } else if (aaveLoan === undefined && creamLoan !== undefined) {
      totalCream = creamLoan.amountUSD;
    } else if (aaveLoan !== undefined && creamLoan === undefined) {
      totalAave = aaveLoan.amountUSD;
    } else {
      return 0;
    }

    return totalAave + totalCream;
  };

  return (
    <>
      {!loading ? (
        borrowData.length > 0 && lendingData.length > 0 ? (
          borrowData.map((item, i) => {
            let canRepay = false;
            let canClaim = false;
            let borrowBalanceAsset = 0;

            const lendingAsset = lendingData.filter(
              (t) =>
                t.symbol === (item.symbol === 'MATIC' ? 'WMATIC' : item.symbol),
            )[0];

            if (borrowingData.loans) {
              /*
               * The repay check is only when we have borrowed something,
               * that's mean that we have loans in the borrowingData.
               */
              canRepay =
                borrowingData.loans
                  .filter(
                    (t) =>
                      (t.symbol === 'WMATIC' ? 'MATIC' : t.symbol) ===
                      item.symbol,
                  )
                  .filter((t) => t.amount > 0.0001).length > 0;
              canClaim =
                borrowingData.loans
                  .filter(
                    (t) =>
                      (t.symbol === 'WMATIC' ? 'MATIC' : t.symbol) ===
                      item.symbol,
                  )
                  .filter((t) => t.amount > 0.0001 && t.protocol === 'aave')
                  .length > 0;
              borrowingData.loans
                .filter(
                  (t) =>
                    (t.symbol === 'WMATIC' ? 'MATIC' : t.symbol) ===
                    item.symbol,
                )
                .map((t) => (borrowBalanceAsset += t.amount));
            }

            return (
              <Card className="card-box invest-card" key={i}>
                <div className="invest-card-body">
                  <div className="invest-top">
                    <Symbol src={item.icon} alt={item.symbol} />
                    <div className="borrow-card-top">
                      <div className="asset-title">
                        {lendingAsset
                          ? formatNumber(lendingAsset.invested)
                          : formatNumber(0)}{' '}
                        {item.symbol}
                      </div>

                      <span className="asset-title">
                        <span className="text-light">
                          (
                          {formatCurrency(
                            (lendingAsset
                              ? Number(formatNumber(lendingAsset.invested))
                              : Number(formatNumber(0))) *
                              priceData[
                                item.symbol === 'MATIC' ? 'WMATIC' : item.symbol
                              ],
                          )}
                          )
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="info-box">
                    <div className="flex text-center">
                      <div className="invest-item-col flex-1">
                        <label className="label-text">
                          Borrow APY
                          <Tooltip>
                            <TooltipIcon />
                            <TooltipContent>
                              The Borrow APY represents the current interest
                              rate you pay for borrowing assets. The interest
                              rate derives from the lending and borrowing ratio
                              of an Asset.
                            </TooltipContent>
                          </Tooltip>
                        </label>
                        <span className="color-yellow-secondary">
                          {handleBorrowRate(item.symbol)}%
                        </span>
                        <div className="borrowing-item">
                          <label className="label-text">Borrowed amount</label>
                          <span>
                            {formatCurrency(
                              handleCurrentBorrowed(item.symbol),
                              2,
                            )}{' '}
                            USD
                          </span>
                        </div>
                      </div>
                      <div className="invest-item-col flex-1">
                        <label className="label-text">
                          Max LTV
                          <Tooltip>
                            <TooltipIcon />
                            <TooltipContent>
                              The maximum Loan to Value (LTV) ratio defines the
                              maximum amount of an asset that can be borrowed
                              with specific collateral. At LTV=75%, for every
                              1000 USD worth of collateral, borrowers will be
                              able to borrow 750 USD worth of a corresponding
                              asset.
                            </TooltipContent>
                          </Tooltip>
                        </label>
                        <span>{handleLTV(item.symbol)}%</span>
                        <div className="borrowing-item flex">
                          {canClaim && (
                            <Button
                              className={'default flex-1'}
                              size={'md'}
                              handleClick={() =>
                                setClaimModal({ open: true, data: item })
                              }
                            >
                              CLAIM
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="invest-action-inner">
                    <div className="btn-group flex">
                      <Button className="disabled">BORROW</Button>
                      {canRepay && (
                        <Button
                          handleClick={() =>
                            setRepayModal({
                              open: true,
                              symbol:
                                item.symbol === 'MATIC'
                                  ? 'WMATIC'
                                  : item.symbol,
                              dataBorrow: borrowingData,
                              protocol: '',
                            })
                          }
                        >
                          REPAY
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </Card>
            );
          })
        ) : (
          <NoRecord>No borrows yet!</NoRecord>
        )
      ) : (
        <NoRecord>
          <div className="no-record flex justify-center">
            <div className="mr-3">Loading borrow information...</div>
            <div
              style={{ borderTopColor: 'transparent' }}
              className="w-6 h-6 border-4 border-blue-400 border-solid rounded-full animate-spin"
            ></div>
          </div>
        </NoRecord>
      )}
      {borrowModal.open && (
        <Borrowing
          close={() => setBorrowModal({ open: false })}
          symbol={borrowModal.symbol}
          dataBorrow={borrowModal.dataBorrow}
        />
      )}
      {repayModal.open && (
        <Repay
          close={() => setRepayModal({ open: false })}
          symbol={repayModal.symbol}
          borrowData={repayModal.dataBorrow}
        />
      )}
      {claimModal.open && (
        <Claim
          close={() => setClaimModal({ open: false })}
          data={claimModal.data}
        />
      )}
    </>
  );
};
