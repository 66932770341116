export const Item = ({
  href,
  children,
  handleClick,
  className,
}: {
  href?: any;
  children: any;
  handleClick?: any;
  className?: any;
}) => {
  return (
    <li className={className}>
      <a href={href} onClick={handleClick}>
        {children}
      </a>
    </li>
  );
};
export const Dropdown = ({
  children,
  visible,
}: {
  children: any;
  visible?;
}) => {
  return (
    <>
      <ul className={`dropdown ${visible ? 'visible' : ''}`}>{children}</ul>
    </>
  );
};
