import React from 'react';

const ProjectedLoading = () => {
  return (
    <>
      <div className="card-top flex">
        <div className="title3 flex-auto">
          <span className="skeleton inline-block" style={{ width: '70%' }}>
            &nbsp;
          </span>
        </div>
        <div className="radio-button-outer theme-button sm skeleton">
          <label className="radio-button">
            <span className="hidden-text">Daily</span>{' '}
          </label>
          <label className="radio-button">
            <span className="hidden-text">Yearly</span>{' '}
          </label>
        </div>
      </div>
      <div className="group-outer">
        <div className="group">
          <div className="asset-info-box">
            <span className="symbol skeleton"></span>
            <div className="asset-info-title">
              <span
                className="skeleton inline-block"
                style={{ width: '60%', height: '20px' }}
              >
                &nbsp;
              </span>
              <span
                className="skeleton inline-block"
                style={{ width: '42%', height: '20px' }}
              >
                &nbsp;
              </span>
            </div>
          </div>
          <div className="asset-info-bot flex">
            <span className="flex-auto color-light">
              <span
                className="skeleton inline-block"
                style={{ width: '42%', height: '16px' }}
              >
                &nbsp;
              </span>
            </span>
          </div>
        </div>
        <div className="group">
          <div className="fee-info flex">
            <div className="flex-1 fee-info-left">
              <div className="title5">
                <span
                  className="skeleton inline-block"
                  style={{ width: '42%', height: '14px' }}
                >
                  &nbsp;
                </span>
              </div>
              <div className="fee-row flex">
                <div className="fee-col">
                  <div className="title4">
                    <span
                      className="skeleton inline-block"
                      style={{ width: '20%', height: '14px' }}
                    >
                      &nbsp;
                    </span>
                  </div>
                  <div className="color-light">
                    <span
                      className="skeleton inline-block"
                      style={{ width: '42%', height: '14px' }}
                    >
                      &nbsp;
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-1 fee-info-right">
              <div className="title5">
                <span
                  className="skeleton inline-block"
                  style={{ width: '42%', height: '14px' }}
                >
                  &nbsp;
                </span>{' '}
              </div>
              <div className="fee-row flex">
                <div className="fee-col">
                  <div className="title4">
                    <span
                      className="skeleton inline-block"
                      style={{ width: '20%', height: '14px' }}
                    >
                      &nbsp;
                    </span>
                  </div>
                  <span className="color-light">
                    <span
                      className="skeleton inline-block"
                      style={{ width: '42%', height: '14px' }}
                    >
                      &nbsp;
                    </span>{' '}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        type="button"
        className="button btn-invest-main skeleton"
        data-size="lg"
      >
        <span className="hidden-text">
          Invest <br /> DAI
        </span>
      </button>
    </>
  );
};
export default ProjectedLoading;
