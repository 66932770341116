import { useEffect, useState } from 'react';
import { useNetwork } from 'wagmi';

import { Button } from '../../../components/atoms/Button';
import { renderDepositSymbols } from '../../../context/utils';
import { ChainIdType } from '../../../interfaces';
import { Card } from '../../atoms/Card';
import { Symbol } from '../../atoms/Symbol/index';
import { SymbolGroup } from '../../atoms/SymbolGroup/index';

export const Strategy = ({
  investment,
  chainId,
}: {
  investment: any;
  chainId: ChainIdType;
}) => {
  const { chain } = useNetwork();

  const [depositAssets, setDepositAssets] = useState('');
  const [rewardAssets, setRewardAssets] = useState('');
  const [showDetails, setShowDetails] = useState(false);

  const renderDepositName = () => {
    const stablecoin = investment.deposit.every((t) => t.isStableCoin);

    return stablecoin ? 'Stablecoins' : 'Volatile';
  };

  useEffect(() => {
    if (investment.deposit.length > 0) {
      let _depositAssets = '';
      let _rewardAssets = '';
      investment.deposit.forEach(
        (t, k) =>
          (_depositAssets +=
            t.symbol + (k < investment.deposit.length - 1 ? ',' : '')),
      );

      investment.rewards.forEach(
        (t, k) =>
          (_rewardAssets += t + (k < investment.rewards.length - 1 ? ',' : '')),
      );

      setDepositAssets(_depositAssets);
      setRewardAssets(_rewardAssets);
    }
  }, [investment]);

  return (
    <Card theme="style-3">
      <div className="strategy-card-box">
        <div className="strategy-card-tittle">
          <div className="title1">Strategy</div>
          <div className="">
            <div
              className="btn-quaternary"
              onClick={() => {
                setShowDetails(!showDetails);
              }}
            >
              {showDetails ? 'Show less' : 'Show More'}
            </div>
          </div>
        </div>

        {showDetails && (
          <div className="strategy-card-subtittle">
            <div className="strategy-card-active">
              <span>Active vault</span>{' '}
              <div
                className={
                  investment.active ? 'indicator_green' : 'indicator_red'
                }
              ></div>
            </div>

            <div>
              <Button
                type={'link'}
                to={`${chain.blockExplorers['default'].url}/address/${investment.strategyAddress}`}
                target={'_blank'}
                size={'sm'}
                className={'more-info'}
              >
                Strategy address
              </Button>

              <Button
                type={'link'}
                to={`${chain.blockExplorers['default'].url}/address/${investment.vaultAddress}`}
                target={'_blank'}
                size={'sm'}
                className={'more-info'}
              >
                Vault address
              </Button>
            </div>
          </div>
        )}
        {investment.version.isCompounding ? (
          <p className="text-md">
            Investing in this vault will stake your assets and swap the yield
            generated ({rewardAssets}) into more{' '}
            {renderDepositSymbols(investment.deposit)}{' '}
            {investment.version.isLp ? 'LP' : ''} token.
          </p>
        ) : (
          <p className="text-md">
            Investing in this vault will stake your assets and swap the yield
            generated ({rewardAssets}) into {investment.earn}.
          </p>
        )}

        <div className="asset-supply-box flex">
          <div className="strategy-asset-box">
            <SymbolGroup data={investment.deposit} size={'lg'} />
            <Symbol
              src={investment.earnIcon}
              alt={investment.earn}
              size={'lg'}
            />
          </div>
          <div className="flex-auto">
            <div className="content-box flex">
              <div className="content-left">
                <div className="title4">Supply:</div>
              </div>
              <div className="content-right flex-auto">
                <p>
                  {renderDepositName()} ({depositAssets})
                </p>
              </div>
            </div>
            <div className="content-box flex">
              <div className="content-left">
                <div className="title4">Earn:</div>
              </div>
              {chainId === ChainIdType.POLYGON ? (
                <div className="content-right flex-auto">
                  {investment.version.isCompounding ? (
                    <p>{renderDepositSymbols(investment.deposit)}</p>
                  ) : (
                    <p>{investment.earn}</p>
                  )}
                </div>
              ) : (
                <div className="content-right flex-auto">
                  {investment.version.isCompounding ? (
                    <p>{renderDepositSymbols(investment.deposit)}</p>
                  ) : (
                    <p>{investment.earn}</p>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};
