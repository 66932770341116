import { ReactNode, useEffect, useState } from 'react';

export const RadioItem = ({
  value,
  name,
  wallet,
  handleRadio,
  defaultChecked,
  children,
  disabled,
}: {
  value?: any;
  name: string;
  wallet?: any;
  handleRadio: any;
  children?: ReactNode;
  defaultChecked?: any;
  disabled?: any;
}) => {
  const [radioOffset, setRadioOffset] = useState(0);

  const handleChange = (e) => {
    handleRadioOffset(e.target);
    handleRadio(e);
  };
  const handleRadioOffset = (elm) => {
    const radioParent = document.getElementById('radioButton');
    if (radioParent) {
      setRadioOffset(radioParent.getBoundingClientRect().x);
    }
    const activeStrip = document.getElementById('radio-selected-strip');
    if (elm.parentNode.parentNode === radioParent) {
      const target = elm.getBoundingClientRect();
      activeStrip.style.left = target.x - radioOffset + 'px';
      activeStrip.style.width = target.width + 'px';
    }
  };
  useEffect(() => {
    const initialChecked = document.querySelector(
      'input[name=' + name + ']:checked',
    );
    handleRadioOffset(initialChecked);
  }, [radioOffset]);
  return (
    <>
      <label
        className={`radio-button${defaultChecked ? ' checked' : ''}${
          wallet === '' ? ' disabled' : ''
        }`}
      >
        <input
          type="radio"
          name={name}
          value={value}
          defaultChecked={defaultChecked}
          onChange={handleChange}
          disabled={disabled}
        />
        <span>{children}</span>{' '}
      </label>
    </>
  );
};
export const Radio = ({
  theme,
  size,
  children,
  classNames,
}: {
  theme?: 'radio' | 'button';
  size?: 'sm' | 'md' | 'lg';
  classNames?: string;
  children: ReactNode;
}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 200);

    return () => {
      setLoading(true);
    };
  }, []);

  return (
    <div
      className={`radio-button-outer theme-${theme} ${size} ${classNames}`}
      id={theme == 'button' ? 'radioButton' : ''}
    >
      {children}
      {theme == 'button' && (
        <>
          <span className="radio-selected-strip" id="radio-selected-strip">
            {' '}
          </span>
          <div
            className={`radio-button-skeleton ${loading ? 'show' : 'hide'}`}
          ></div>
        </>
      )}
    </div>
  );
};
