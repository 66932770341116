import React from 'react';

const GovernanceLoading = () => {
  return (
    <div className="portfolio-body activity-body governance-body">
      <div className="portfolio-body-top">
        <div className="invested-area flex">
          <div className="invested-col">
            <div className="radio-button-outer theme-button skeleton">
              <label className="radio-button hidden-text">
                <span>Invest</span>{' '}
              </label>
              <label className="radio-button hidden-text">
                <span>Claim</span>{' '}
              </label>
            </div>
          </div>
          <div className="invested-col flex-auto">
            <div className="invest-top-right flex">
              <div className="flex-auto"></div>
              <div className="invest-btn-outer"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="my-4">
        <span className="my-4">
          <span className="skeleton inline-block" style={{ width: '15%' }}>
            &nbsp;
          </span>{' '}
        </span>
        <div className="input my-4 skeleton">
          <input type="number" className="input-style sm" />
        </div>
        <span className="my-4">
          <span className="skeleton inline-block" style={{ width: '15%' }}>
            &nbsp;
          </span>
        </span>
      </div>
      <div className="governance-slider-box">
        <div
          className="skeleton"
          style={{ height: '9px', margin: '40px 0 50px', borderRadius: '5px' }}
        ></div>
      </div>
      <div>
        <div className="my-4 governance-info-body">
          <div className="flex justify-between my-4">
            <span>
              <span className="skeleton inline-block" style={{ width: '15%' }}>
                &nbsp;
              </span>
            </span>
            <span>
              <span className="skeleton inline-block" style={{ width: '40px' }}>
                &nbsp;
              </span>
            </span>
          </div>

          <div className="flex justify-between my-4">
            <span>
              <span className="skeleton inline-block" style={{ width: '20%' }}>
                &nbsp;
              </span>
            </span>
            <span>
              <span className="skeleton inline-block" style={{ width: '60px' }}>
                &nbsp;
              </span>
            </span>
          </div>

          <div className="flex justify-between my-4">
            <span>
              <span className="skeleton inline-block" style={{ width: '12%' }}>
                &nbsp;
              </span>
            </span>
            <span>
              <span
                className="skeleton inline-block"
                style={{ width: '120px' }}
              >
                &nbsp;
              </span>
            </span>
          </div>

          <div className="flex justify-between my-4">
            <span>
              <span className="skeleton inline-block" style={{ width: '25%' }}>
                &nbsp;
              </span>
            </span>
            <span>
              <span className="skeleton inline-block" style={{ width: '40px' }}>
                &nbsp;
              </span>
            </span>
          </div>

          <div className="btn-group flex">
            <button type="button" className="button skeleton">
              <span>
                <span className="hidden-text">Create lock</span>{' '}
              </span>
            </button>
            <button type="button" className="button skeleton">
              <span>
                <span className="hidden-text"> Withdraw All</span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default GovernanceLoading;
