import React from 'react';

import { Modal } from '../../../atoms/Modal';
import { Tooltip, TooltipContent, TooltipIcon } from '../../../atoms/Tooltip';
// COMPONENTS

type Props = {
  close: () => void;
  infoText: string;
};

const InfoModal: React.FC<Props> = ({ close, infoText }) => {
  return (
    <Modal close={close} closeButton size={'sm'}>
      <div className="flex justify-center">
        <Tooltip>
          <TooltipIcon />
          <TooltipContent></TooltipContent>
        </Tooltip>
      </div>
      <p></p>
      <div className="pb-5 text-center">
        <span>{infoText}</span>
      </div>
    </Modal>
  );
};

export default InfoModal;
