import { Link } from 'react-router-dom';

export const Button = ({
  type,
  target,
  to,
  size,
  children,
  handleClick,
  className,
  disabled,
  rel,
}: {
  type?: 'button' | 'link';
  target?: 'default' | '_blank' | '_parent' | '_self' | '_top';
  to?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  children: any;
  handleClick?: any;
  className?: string;
  disabled?: any;
  rel?: string;
}) => {
  return (
    <>
      {type == 'link' ? (
        <>
          {target ? (
            <a
              href={to}
              className={`button ${className}`}
              data-size={size}
              onClick={handleClick}
              target={target}
              rel={rel}
            >
              <span>{children}</span>
            </a>
          ) : (
            <Link
              to={to ? to : ''}
              className={`button ${className}`}
              data-size={size}
              onClick={handleClick}
            >
              <span>{children}</span>
            </Link>
          )}
        </>
      ) : (
        <button
          type={'button'}
          className={`button ${className}`}
          data-size={size}
          onClick={handleClick}
          disabled={disabled}
        >
          <span>{children}</span>
        </button>
      )}
    </>
  );
};
