import React, { useContext, useState } from 'react';

import { GlobalContext } from '../../../../context';
import {
  formatCurrency,
  formatNumber,
  formatRate,
} from '../../../../context/utils';
import { Button } from '../../../atoms/Button';
import { Card } from '../../../atoms/Card';
import { NoRecord } from '../../../atoms/NoRecord';
import { Symbol } from '../../../atoms/Symbol/';
import { Tooltip, TooltipContent, TooltipLabel } from '../../../atoms/Tooltip';
import Claim from '../../../organisms/Modals/Claim/';
import WithdrawInvest from '../../../organisms/Modals/WithdrawInvest/';

const MIN_AMOUNT = 1e-8;

export const InvestedLending = ({
  investedType,
  loading,
}: {
  investedType: any;
  loading: boolean;
}) => {
  const {
    state: { priceData },
  } = useContext(GlobalContext);
  const [withdrawModal, setWithdrawModal] = useState<{
    open: boolean;
    data?: any;
  }>({ open: false });
  const [claimModal, setClaimModal] = useState<{ open: boolean; data?: any }>({
    open: false,
  });

  const renderRewards = (item) => {
    let rewards;
    let ethaRewards;

    // Matic Rewards
    if (item.type === 'Lending') {
      if (item.rewards >= MIN_AMOUNT) {
        rewards = Number(formatNumber(item.rewards));
      } else if (item.rewards > 0 && item.rewards < MIN_AMOUNT) {
        rewards = '<0.001';
      } else rewards = '0.0';

      if (item.ethaRewards >= MIN_AMOUNT) {
        ethaRewards = Number(formatNumber(item.ethaRewards));
      } else {
        ethaRewards = '<0.001';
      }
    }

    return (
      <>
        {ethaRewards && (
          <div className="invest-title color-green text-sm">
            {typeof ethaRewards === 'number' ? '+' : ''} {ethaRewards} ETHA
          </div>
        )}
        <div className="invest-title">{rewards + ' MATIC'}</div>
      </>
    );
  };

  const renderAPYs = (item) => {
    let currentApy;
    let tooltipData;

    if (item.type === 'Lending') {
      currentApy = Number(item.userApy) + item.dApy;
      tooltipData = (
        <TooltipContent>
          <div className="tooltip-title">Yields</div>
          <div>
            {item.earn}: {formatRate(item.userApy)}% APY
          </div>
          {item.balances.aave > 0 && (
            <div>
              MATIC:{' '}
              {formatRate((item.rapy * item.balances.aave) / item.savings)}% APR
            </div>
          )}
          <div>ETHA: {formatRate(item.dApy)}% APR</div>
        </TooltipContent>
      );
    }

    return (
      <Tooltip>
        <TooltipLabel>
          <span className="color-green" style={{ cursor: 'pointer' }}>
            {formatRate(currentApy)}%
          </span>
        </TooltipLabel>
        {tooltipData}
      </Tooltip>
    );
  };

  return (
    <>
      {!loading ? (
        investedType.length > 0 ? (
          investedType
            .sort((a, b) => Number(b.apy) - Number(a.apy))
            .filter(
              (t) => +t.invested > MIN_AMOUNT || t.ethaRewards > MIN_AMOUNT,
            ) // remove dust
            .map((item, i) => {
              return (
                <Card className="invest-card" key={i}>
                  <div className="invest-card-body">
                    <div className="invest-top items-center">
                      <Symbol src={item.icon} alt={item.symbol} />
                      <Tooltip>
                        <TooltipLabel>
                          <span className="asset-title">
                            {(item.savings > 0.001
                              ? formatNumber(item.savings)
                              : '<0.001') +
                              ' ' +
                              item.symbol}
                            <span className="text-light">
                              (
                              {formatCurrency(
                                item.savings * priceData[item.symbol],
                              )}
                              )
                            </span>
                          </span>
                        </TooltipLabel>
                        <TooltipContent>
                          <div className="title-tooltip">Details</div>
                          <div>
                            Invested: {formatNumber(item.invested)}{' '}
                            {item.symbol}
                          </div>
                          <div>
                            Interest: {formatNumber(item.earned)} {item.symbol}
                          </div>
                        </TooltipContent>
                      </Tooltip>
                    </div>
                    <div className="invest-info-body">
                      <div className="invest-info">
                        <div className="invest-item-row flex">
                          <div className="invest-item-col flex-1">
                            <div className="invest-item-body">
                              <div className="invest-title">{item.symbol}</div>
                              <div className="invest-label">
                                <span className="invested-text invested color-green">
                                  <span className="invest-icon pulse-effect"></span>
                                  Invested
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="invest-item-col flex-1">
                            <div className="invest-item-body">
                              <div className="invest-title">
                                {renderAPYs(item)}
                              </div>
                              <div className="invest-label">Current APY</div>
                            </div>
                          </div>
                          <div className="invest-item-col flex-1">
                            <div className="invest-item-body">
                              {renderRewards(item)}
                              <div className="invest-label">Rewards</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="invest-actions">
                        <div className="invest-action-inner">
                          <div className="btn-group flex">
                            <Button
                              className="default"
                              size={'md'}
                              handleClick={() =>
                                setWithdrawModal({ open: true, data: item })
                              }
                            >
                              WITHDRAW
                            </Button>
                            <Button
                              className="default"
                              size={'md'}
                              handleClick={() =>
                                setClaimModal({ open: true, data: item })
                              }
                            >
                              CLAIM
                            </Button>
                          </div>
                          <div className="btn-group flex">
                            <Button className="disabled">
                              <span>INVEST</span>
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              );
            })
        ) : (
          <NoRecord>No investments yet!</NoRecord>
        )
      ) : (
        <NoRecord>
          <div className="no-record flex justify-center">
            <div className="mr-3">Loading lending information...</div>
            <div
              style={{ borderTopColor: 'transparent' }}
              className="w-6 h-6 border-4 border-blue-400 border-solid rounded-full animate-spin"
            ></div>
          </div>
        </NoRecord>
      )}
      {withdrawModal.open && (
        <WithdrawInvest
          close={() => setWithdrawModal({ open: false })}
          data={withdrawModal.data}
        />
      )}
      {claimModal.open && (
        <Claim
          close={() => setClaimModal({ open: false })}
          data={claimModal.data}
        />
      )}
    </>
  );
};
