import React, { useContext, useEffect, useState } from 'react';
import { useAccount, useNetwork } from 'wagmi';

// CONTEXT
import { GlobalContext, UserContext } from '../../../../context';
import { MATIC_ADDRESS, WMATIC_ADDRESS } from '../../../../context/constants';
import {
  formatCurrency,
  formatDecimals,
  formatNumber,
  formatRate,
  formatValue,
  formatWeiSymbol,
  getTokenDecimals,
  getTokenIcon,
  getTokenInfo,
} from '../../../../context/utils';
import { BorrowData } from '../../../../interfaces';
import { Modal } from '../../../atoms/Modal';
import { DecimalAssetSelect } from '../../../molecules/AssetsSelect/Decimal';

type Props = {
  close: () => void;
  symbol: string;
  borrowData: BorrowData;
};

const Repay: React.FC<Props> = ({ close, symbol, borrowData }) => {
  const {
    state: { priceData, tokensData, tokenList, actions },
  } = useContext(GlobalContext);
  const {
    state: { balances, wallet },
  } = useContext(UserContext);

  const { address: account } = useAccount();
  const { chain } = useNetwork();

  const protocolSelection = borrowData.loans.filter(
    (t) => t.symbol === symbol && t.amount > 0.0001,
  )[0].protocol;
  const tokenData = tokensData.filter(
    (t) => t.symbol === (symbol === 'WMATIC' ? 'MATIC' : symbol),
  )[0];

  let balanceAssetCream = 0;
  const creamAsset = borrowData.loans
    .filter((t) => t.symbol === symbol)
    .filter((t) => t.protocol === 'cream');

  let balanceAssetAave = 0;
  const aaveAsset = borrowData.loans
    .filter((t) => t.symbol === symbol)
    .filter((t) => t.protocol === 'aave');

  if (aaveAsset.length > 0) {
    balanceAssetAave = aaveAsset[0].amount;
  }

  if (creamAsset.length > 0) {
    balanceAssetCream = creamAsset[0].amount;
  }

  const [selected, setSelected] = useState<any>({});
  const [selectedProtocol, setSelectedProtocol] = useState(protocolSelection);
  const [amount, setAmount] = useState<any>(0);
  const [amountWei, setAmountWei] = useState<string>();
  const [pending, setPending] = useState<boolean>(false);
  const [isWrapping, setIsWrapping] = useState<boolean>(false);
  const [loanToValue, setLoanToValue] = useState<number>(0);
  const [noBalance, setNoBalance] = useState<boolean>(false);
  const [options, setOptions] = useState<any>();
  const [isSelectable, setIsSelectable] = useState<boolean>(false);

  const balanceCheck = (amt) => {
    // Balance check
    if (selected.balance === 'NaN') {
      setNoBalance(true);
    } else {
      if (amt > formatWeiSymbol(symbol, selected.balance, tokenList)) {
        setNoBalance(true);
      } else setNoBalance(false);
    }
  };

  const handleAmount = (amt) => {
    if (amt === -1) {
      const _amount = selected.amount;
      setAmount(_amount);
      setAmountWei(selected.amountWei);

      setLoanToValue(
        (borrowData[selectedProtocol].totalBorrowedUSD -
          _amount * priceData[symbol]) /
          borrowData[selectedProtocol].totalCollateralUSD,
      );
    } else {
      setAmount(amt);
      setAmountWei(formatDecimals(symbol, amt, tokenList));

      setLoanToValue(
        (borrowData[selectedProtocol].totalBorrowedUSD -
          amt * priceData[symbol]) /
          borrowData[selectedProtocol].totalCollateralUSD,
      );
    }
    balanceCheck(amt);
  };

  const handleSelect = (item) => {
    setSelected(item);
  };

  const executeRepay = async () => {
    if (selected.symbol === 'MATIC') {
      if (Number(selected.balance) > Number(amountWei)) {
        //Wrapp Matic before Repay
        try {
          setIsWrapping(true);
          const fromAssets = [
            { address: MATIC_ADDRESS, amountWei: selected.amountWei },
          ];
          const dest = WMATIC_ADDRESS;
          await actions.handleSwap(fromAssets, dest, wallet, account, chain.id);
        } catch (error) {
          console.error(error);
        }
      }
    }

    try {
      setIsWrapping(false);
      setPending(true);
      await actions.handleRepay({
        token: getTokenInfo(
          selected.symbol === 'MATIC' ? 'WMATIC' : selected.symbol,
          tokenList,
        ).address,
        amount: amountWei,
        protocol: selectedProtocol,
      });
      setPending(false);
      //await fetchUserBorrowData();
      close();
    } catch (error) {
      setPending(false);
      console.error(error.message);
    }
  };

  const updateSelected = (symbol) => {
    const _symbol = symbol === 'WMATIC' ? 'MATIC' : symbol;
    const _selected = borrowData.loans.filter(
      (t) =>
        t.protocol === selectedProtocol &&
        (t.symbol === 'WMATIC' ? 'MATIC' : t.symbol) === _symbol,
    );
    const asset = balances?.wallet.filter((t) => t.symbol === symbol)[0];
    const amount = formatValue(asset?.balance || 0, asset?.decimals);

    setAmount('');

    setLoanToValue(borrowData[selectedProtocol].userLTV);

    if (_selected.length > 0) {
      setSelected({
        ..._selected[0],
        decimals: getTokenDecimals(_symbol, tokenList),
        balance: formatDecimals(_symbol, amount, tokenList),
      });
    } else {
      setSelected({
        symbol,
        icon: getTokenIcon(_symbol, tokenList),
        decimals: getTokenDecimals(_symbol, tokenList),
        protocol: selectedProtocol,
        amount: 0,
        balance: formatDecimals(_symbol, amount, tokenList),
      });
    }
  };

  const handleRadio = (e) => {
    setSelectedProtocol(e.target.value);
  };

  useEffect(() => {
    updateSelected(symbol);
  }, [selectedProtocol]);

  useEffect(() => {
    if (selected.symbol === 'WMATIC') {
      //const _symbol = symbol === 'WMATIC' ? 'MATIC' : symbol;
      const _selected = borrowData.loans.filter(
        (t) => t.protocol === selectedProtocol && t.symbol === symbol,
      );

      const { amount, amountWei } = _selected[0];

      const _balanceW = balances?.wallet.filter(
        (t) => t.symbol === 'WMATIC',
      )[0];

      const _balanceM = balances?.wallet.filter((t) => t.symbol === 'MATIC')[0];

      setOptions([
        {
          ..._selected[0],
          balance: String(_balanceM?.balance ? _balanceM.balance : '0'),
          address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
          decimals: 18,
          icon: 'https://assets.coingecko.com/coins/images/4713/small/matic-token-icon.png?1624446912',
          symbol: 'MATIC',
        },
        {
          amount,
          amountWei,
          balance: String(_balanceW?.balance ? _balanceW.balance : '0'),
          address: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
          decimals: 18,
          icon: 'https://tokens.1inch.exchange/0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0.png',
          symbol: 'WMATIC',
        },
      ]);

      setIsSelectable(true);
    }
  }, [selected]);

  return (
    <Modal close={close} closeButton size={'sm'} pending={pending}>
      <div className="heading">
        Repay {symbol === 'WMATIC' ? 'MATIC' : symbol}
      </div>
      <div className="swap-modal-box">
        <div className="group">
          <div className="borrow-section">
            <div className="info-box">
              <label className="label-text">Protocol</label>
              <div className="select-layout">
                <div className="select-orientation">
                  {balanceAssetAave > 0.0001 && !isNaN(balanceAssetAave) && (
                    <div className="radio-check-outer">
                      <label className="radio-check">
                        <input
                          type="radio"
                          name="protocol"
                          value={'aave'}
                          defaultChecked={selectedProtocol === 'aave'}
                          onChange={handleRadio}
                        />{' '}
                        <span>Aave V2</span>{' '}
                      </label>
                    </div>
                  )}
                  {balanceAssetCream > 0.0001 && !isNaN(balanceAssetCream) && (
                    <div className="radio-check-outer">
                      <label className="radio-check">
                        <input
                          type="radio"
                          name="protocol"
                          value={'cream'}
                          defaultChecked={selectedProtocol === 'cream'}
                          onChange={handleRadio}
                        />{' '}
                        <span>Cream</span>{' '}
                      </label>
                    </div>
                  )}
                </div>
              </div>
              <div className="info-box" />
              <div className="info-box">
                <label className="label-text">Borrowed amount</label>
                <span>
                  {formatNumber(
                    borrowData.loans.filter(
                      (t) =>
                        t.symbol === symbol && t.protocol === selectedProtocol,
                    )[0]?.amount,
                  )}{' '}
                  {symbol === 'WMATIC' ? 'MATIC' : symbol}
                </span>
              </div>
            </div>
            <div>
              <div className="info-box">
                <label className="label-text">Borrow APY</label>
                <span>
                  {selectedProtocol === 'aave'
                    ? formatRate(tokenData.aaveData.borrowRate)
                    : formatRate(tokenData.creamData.borrowRate)}
                  %
                </span>
              </div>
              <div className="info-box">
                <label className="label-text">Total Collateral </label>
                <span>
                  {formatCurrency(
                    borrowData[selectedProtocol].totalCollateralUSD,
                    2,
                  )}{' '}
                  USD
                </span>
              </div>
              <div className="info-box">
                <label className="label-text">Total Borrowed </label>
                <span>
                  {formatCurrency(
                    borrowData[selectedProtocol].totalBorrowedUSD,
                    2,
                  )}{' '}
                  USD
                </span>
              </div>
            </div>
          </div>
          <div className="borrow-box">
            <div className="from-wallet-box">
              <div className="from-option-box">
                <DecimalAssetSelect
                  options={options}
                  isSelectable={isSelectable}
                  amount={amount}
                  selected={selected}
                  isInput
                  showBalance
                  handleAmount={handleAmount}
                  handleSelect={handleSelect}
                />
              </div>
            </div>
            <div className="divider-line"></div>
            <div className="borrow-projected-info">
              <div className="title-new2 text-center">
                Projected Debt Position
              </div>
              <div className="info-box-group">
                <div className="info-box">
                  <label className="label-text">
                    Status (Max.{' '}
                    {formatRate(borrowData[selectedProtocol].maxLTV)}
                    %){' '}
                  </label>
                  <div className="status-info-box flex">
                    <span className="color-white">
                      {!isNaN(loanToValue)
                        ? formatRate(loanToValue)
                        : formatRate(0)}
                      %
                    </span>
                    <span className="status-info-bar">
                      <span
                        style={{
                          width: `${
                            loanToValue > 1
                              ? borrowData[selectedProtocol].maxLTV * 100
                              : (loanToValue /
                                  borrowData[selectedProtocol].maxLTV) *
                                100
                          }%`,
                          backgroundColor:
                            loanToValue >
                            borrowData[selectedProtocol].maxLTV * 0.75
                              ? 'red'
                              : 'green',
                        }}
                      ></span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="swap-button-box">
            <div className="btn-outer">
              <button
                type="button"
                className={`btn-main lg  ${
                  isWrapping || pending || noBalance ? 'disabled' : 'full'
                }`}
                onClick={executeRepay}
                disabled={isWrapping || pending || noBalance}
              >
                <span>
                  {pending
                    ? 'PENDING'
                    : isWrapping
                    ? 'WRAPPING MATIC'
                    : noBalance
                    ? 'Not enough funds!'
                    : 'REPAY'}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Repay;
