// import { UAuthConnector } from '@uauth/web3-react';
import { SafeConnector } from '@gnosis.pm/safe-apps-wagmi';
import { Chain, chain, configureChains, createClient } from 'wagmi';
import { InjectedConnector } from 'wagmi/connectors/injected';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';

import { ChainIdType } from './interfaces';

export enum ConnectorNames {
  Metamask = 'Metamask',
  ImToken = 'ImToken',
  WalletConnect = 'WalletConnect',
  GnosisSafe = 'GnosisSafe',
}

const avalancheChain: Chain = {
  id: 43114,
  name: 'Avalanche',
  network: 'avalanche',
  nativeCurrency: {
    decimals: 18,
    name: 'Avalanche',
    symbol: 'AVAX',
  },
  rpcUrls: {
    default: 'https://api.avax.network/ext/bc/C/rpc',
  },
  blockExplorers: {
    default: { name: 'SnowTrace', url: 'https://snowtrace.io' },
  },
  testnet: false,
};

// the networks, we want to support
export const supportedChains = [ChainIdType.AVALANCHE, ChainIdType.POLYGON];

const { chains, provider } = configureChains(
  [chain.polygon, avalancheChain],
  [
    jsonRpcProvider({
      rpc: (chain) => {
        if (!supportedChains.includes(chain.id)) return null;
        return { http: chain.rpcUrls.default };
      },
    }),
  ],
);

export const injectedConnector = new InjectedConnector({
  chains,
});

export const metamaskConnector = new MetaMaskConnector({
  chains,
});

export const walletConnectConnector = new WalletConnectConnector({
  chains,
  options: {
    qrcode: true,
  },
});

export const safeConnector = new SafeConnector({ chains });

export const client = createClient({
  autoConnect: false,
  connectors: [injectedConnector, walletConnectConnector, safeConnector],
  provider,
});

// export const uauthConnector = new UAuthConnector({
//   clientID: 'DN+EBueHRwG33SmDFFiRjqlkLjfKIfQ0CuHKf4g6EFg=',
//   clientSecret: 'P+MqarWWiKGxqSXbNd+ObejVdXuSbLzN1+vycg2tm0M=',
//   redirectUri: 'https://app.ethalend.org/callback',
//   scope: 'openid wallet',
//   connectors: {
//     injected: injectedConnector,
//     walletconnect: walletConnectConnector,
//   },
// });

export const connectorsByName: { [connectorName in ConnectorNames]: any } = {
  [ConnectorNames.Metamask]: metamaskConnector,
  [ConnectorNames.ImToken]: injectedConnector,
  [ConnectorNames.WalletConnect]: walletConnectConnector,
  [ConnectorNames.GnosisSafe]: safeConnector,
};
