import React, { useState } from 'react';

const VaultsSkeleton = () => {
  const [data] = useState([1, 2, 3, 4]);
  return (
    <>
      {data.map((item, i) => {
        return (
          <div className="table-row list-card" key={i}>
            <div className="table-col col-1 list-col-1">
              <div className="asset-group-outer">
                <div className="asset-group asset-group-3">
                  <span className="asset-item skeleton"></span>
                  <span className="asset-item skeleton"></span>
                  <span className="asset-item skeleton"></span>
                </div>
                <div className="asset-info flex-auto">
                  <span className="asset-symbol skeleton"></span>
                  <span className="asset-title xs flex-auto">
                    <span className="skeleton-text-area skeleton">&nbsp;</span>
                    <span className="skeleton-text-area skeleton">&nbsp;</span>
                  </span>
                </div>
              </div>
            </div>
            <div className="table-col col-2 list-col-2">
              <span className="color-green">
                <span className="skeleton-text-area skeleton">&nbsp;</span>
              </span>
            </div>
            <div className="table-col col-3 list-col-3">
              <span>
                <span className="skeleton-text-area skeleton">&nbsp;</span>
              </span>
            </div>
            <div className="table-col col-4 list-col-4">
              <span>
                <span className="skeleton-text-area skeleton">&nbsp;</span>
              </span>
            </div>
            <div className="table-col col-5 list-col-5 btn-col">
              <span className="button">
                <span className="skeleton">
                  <span className="hidden-text">Invest</span>
                </span>
              </span>
            </div>
          </div>
        );
      })}
    </>
  );
};
export default VaultsSkeleton;
