import React from 'react';

const HeadingSection = () => {
  return (
    <div className="heading-box">
      <div className="title">
        <span className="skeleton d-inline-block">
          {' '}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </div>
      <div className="content flex text-sm">
        <p className="flex-1">
          <span className="skeleton-text-area skeleton">&nbsp;</span>
          <span className="skeleton-text-area skeleton">&nbsp;</span>
          <span className="skeleton-text-area skeleton">&nbsp;</span>
        </p>
        <p className="flex-1">
          <span className="skeleton-text-area skeleton">&nbsp;</span>
          <span className="skeleton-text-area skeleton">&nbsp;</span>
          <span className="skeleton-text-area skeleton">&nbsp;</span>
        </p>
        <p className="flex-1">
          <span className="skeleton-text-area skeleton">&nbsp;</span>
          <span className="skeleton-text-area skeleton">&nbsp;</span>
        </p>
      </div>
    </div>
  );
};
export default HeadingSection;
