import './index.scss';

import cls from 'classnames';
import { MutableRefObject, useEffect, useRef, useState } from 'react';

import ArrowUp from '../../../styles/img/chevron-up.svg';
import { Symbol } from '../../atoms/Symbol';

export interface Option {
  [key: string]: any;
}

export const NetworkSelect = ({
  optionsList,
  onSelectOptionChange,
  optionsListName,
  displayFields,
  selected,
  size,
  className,
  disabled,
}: {
  optionsList: Option[];
  onSelectOptionChange: (option: any) => void;
  selected?: number;
  optionsListName?: string;
  className?: string | string[];
  size?: 'sm' | 'md';
  displayFields: {
    primary: string;
    secondary: string;
    icon?: string;
    network?: string;
  };
  disabled?: boolean;
}) => {
  const [expanded, setExpanded] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    optionsList[selected ? selected : 0],
  );

  const handleChange = (option: Option) => {
    setExpanded(!expanded);
    setSelectedOption(option);
    onSelectOptionChange(option);
  };

  const selectRef: MutableRefObject<null | any> = useRef(null);

  function handleClickOutside(event: Event) {
    if (!selectRef.current.contains(event.target)) {
      setExpanded(false);
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  useEffect(() => {
    setSelectedOption(optionsList[selected ? selected : 0]);
  }, [selected]);

  const getHeaderRow = () => {
    const headerContent = optionsList.length ? (
      <div className="flex items-center">
        {selectedOption[displayFields.icon] && (
          <Symbol
            src={selectedOption[displayFields.icon]}
            size={'sm2'}
            alt={''}
          />
        )}
        <span className="mr-2">{selectedOption[displayFields.primary]}</span>
        {selectedOption[displayFields.secondary] && (
          <span className="mr-2">
            {selectedOption[displayFields.secondary]}
          </span>
        )}
      </div>
    ) : (
      <span>No options available</span>
    );

    return (
      <div className={expanded ? 'select-dropdown active' : 'select-dropdown'}>
        {headerContent}
        {!disabled && (
          <img
            className={
              expanded
                ? 'arrow flex items-center'
                : 'rotated arrow flex items-center'
            }
            src={ArrowUp}
          />
        )}
      </div>
    );
  };

  const getOptionList = () => {
    return (
      <ul
        className={`select-options dropdown-main ${
          expanded ? 'show' : 'hide'
        } `}
      >
        {optionsList.map((option, index) => {
          const { primary, secondary, icon } = displayFields;

          if (option['disabled']) {
            return (
              <li key={index} className="pointer-events-none">
                <div className="select-option">
                  <Symbol src={option[icon]} alt={''} size={'sm2'} />
                  <span className="mr-2">{option[primary]}</span>
                  {option[secondary] && <span>{option[secondary]}</span>}
                </div>
              </li>
            );
          }

          return (
            <li
              className={
                selectedOption[displayFields.primary] ===
                option[displayFields.primary]
                  ? 'selected'
                  : ''
              }
              key={index}
              onClick={() => handleChange(option)}
            >
              <div className="select-option">
                <Symbol src={option[icon]} alt={''} size={'sm2'} />
                <span className="mr-2">{option[primary]}</span>
                {option[secondary] && <span>{option[secondary]}</span>}
              </div>
            </li>
          );
        })}
      </ul>
    );
  };

  const tryToExpand = () => {
    if (!disabled) {
      setExpanded(!expanded);
    }
  };

  return (
    <div
      className={cls(`component-select`, className, disabled ? 'disabled' : '')}
      ref={selectRef}
    >
      {optionsListName && (
        <p className="text-secondary text-bold mb-3">{optionsListName}</p>
      )}
      <div
        className={`container-select-wrapper container-select-wrapper-${
          size ? size : 'md'
        }`}
        onClick={() => tryToExpand()}
      >
        {getHeaderRow()}
      </div>
      {getOptionList()}
    </div>
  );
};
