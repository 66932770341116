import React, { useState } from 'react';

import {
  formatCurrency,
  formatNumber,
  formatRate,
  fromWei,
} from '../../../../context/utils';
import { Button } from '../../../atoms/Button/index';
import { Card } from '../../../atoms/Card';
import { NoRecord } from '../../../atoms/NoRecord';
import { Symbol } from '../../../atoms/Symbol';
import { SymbolGroup } from '../../../atoms/SymbolGroup';
import { Tooltip, TooltipContent, TooltipIcon } from '../../../atoms/Tooltip';
import Claim from '../../../organisms/Modals/Claim';
import Staking from '../../../organisms/Modals/Staking';
import WithdrawInvest from '../../../organisms/Modals/WithdrawInvest';
import WithdrawLP from '../../../organisms/Modals/WithdrawLP';

export const InvestedStaking = ({
  stakingData,
  loading,
  reFetch,
}: {
  stakingData: any;
  loading: boolean;
  reFetch: () => void;
}) => {
  const [stakingModal, setStakingModal] = useState(false);
  const [withdrawModal, setWithdrawModal] = useState<any>({
    open: false,
    data: {},
  });
  const [claimModal, setClaimModal] = useState<any>({ open: false, data: {} });
  const [selectedStaking, setSelectedStaking] = useState();

  const handleStakingSelect = (item) => {
    setSelectedStaking(item);
    setStakingModal(true);
  };

  const getStakedAssets = (item) => {
    let title = '';
    item.deposit.forEach((t, k) => (title += (k > 0 ? '-' : '') + t.symbol));
    return title;
  };

  const formatEarnings = (earned) => {
    if (Number(fromWei(earned)) > 0.001)
      return formatNumber(Number(fromWei(earned)));
    else if (+earned === 0) return '0.00';
    else return '<0.001';
  };

  const renderAPYs = (item) => {
    const currentApy = item.apy;

    return (
      <div className="tooltip-box">
        <span className="color-green" style={{ cursor: 'pointer' }}>
          {formatRate(currentApy)}%
        </span>
      </div>
    );
  };

  return (
    <>
      {!loading ? (
        stakingData.length > 0 ? (
          stakingData.map((item, i) => {
            return (
              <Card className="card-box invest-card" key={i}>
                <div className="invest-card-body">
                  <div className="invest-top">
                    <div className="asset-info asset-exchange">
                      <span className="asset-exchange-symbol flex items-center">
                        <SymbolGroup data={item.deposit} size={'md'} />
                        <Symbol
                          src={item.earnIcon}
                          alt={item.earn}
                          size={'md'}
                        />
                      </span>
                      <span className="asset-title">
                        {(item.balance > 0.001
                          ? formatCurrency(item.balance)
                          : +item.balance === 0
                          ? '0.0'
                          : '<0.001') + ' USD'}
                      </span>
                    </div>
                  </div>
                  <div className="invest-info-body mt-5">
                    <div className="invest-info">
                      <div className="invest-item-row flex">
                        <div className="invest-item-col flex-1">
                          <div className="invest-item-body">
                            <div className="invest-title">
                              {getStakedAssets(item)}
                            </div>
                            <div className="invest-label">
                              {item.deposit.length > 1 && 'LP '}Staked
                            </div>
                          </div>
                        </div>
                        <div className="invest-item-col flex-1">
                          <div className="invest-item-body">
                            <div className="invest-title">
                              {formatEarnings(item.earned)}
                            </div>
                            <div className="invest-label">Earned</div>
                          </div>
                        </div>
                        <div className="invest-item-col flex-1">
                          <div className="invest-item-body">
                            <div className="invest-title">
                              {renderAPYs(item)}
                            </div>
                            <div className="invest-label">Current APY</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="invest-actions">
                      <div className="invest-action-inner">
                        <div className="btn-group flex items-center">
                          <Button
                            className={'default'}
                            size={'md'}
                            handleClick={() =>
                              setWithdrawModal({ open: true, data: item })
                            }
                          >
                            WITHDRAW
                          </Button>
                          <Button
                            className={`default ${
                              item.claim === false && 'disabled'
                            }`}
                            size={'md'}
                            handleClick={() =>
                              setClaimModal({ open: true, data: item })
                            }
                          >
                            CLAIM
                          </Button>
                          {item.claim === false && (
                            <div>
                              <Tooltip>
                                <TooltipIcon />
                                <TooltipContent>
                                  Remaining days to claim: {item.daysToClaim}
                                </TooltipContent>
                              </Tooltip>
                            </div>
                          )}
                        </div>
                        <div className="btn-group flex">
                          <Button
                            className={'disabled'}
                            handleClick={() => handleStakingSelect(item)}
                          >
                            STAKE
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            );
          })
        ) : (
          <NoRecord>No investments yet!</NoRecord>
        )
      ) : (
        <NoRecord>
          <div className="no-record flex justify-center">
            <div className="mr-3">Loading staking information...</div>
            <div
              style={{ borderTopColor: 'transparent' }}
              className="w-6 h-6 border-4 border-blue-400 border-solid rounded-full animate-spin"
            ></div>
          </div>
        </NoRecord>
      )}
      {stakingModal && (
        <Staking
          close={() => setStakingModal(false)}
          reFetch={reFetch}
          stakingData={selectedStaking}
        />
      )}
      {withdrawModal.open &&
        withdrawModal.data.earn === 'ETHA' &&
        withdrawModal.data.deposit.length === 1 && (
          <WithdrawInvest
            close={() => setWithdrawModal({ open: false })}
            reFetch={reFetch}
            data={withdrawModal.data}
          />
        )}
      {withdrawModal.open && withdrawModal.data.deposit.length > 1 && (
        <WithdrawLP
          close={() => setWithdrawModal({ open: false })}
          reFetch={reFetch}
          data={withdrawModal.data}
        />
      )}
      {claimModal.open && (
        <Claim
          close={() => setClaimModal({ open: false })}
          reFetch={reFetch}
          data={claimModal.data}
        />
      )}
    </>
  );
};
