import React from 'react';

function ProfileTop() {
  return (
    <>
      <div className="page-title">
        <div className="heading-main">
          <span
            className="skeleton-text-area skeleton"
            style={{ width: '20%' }}
          >
            &nbsp;
          </span>
        </div>
      </div>
      <div className="portfolio-top flex">
        <div className="portfolio-top-left flex-auto">
          <div className="port-facts flex">
            <div className="port-facts-item flex-1">
              <div className="title">
                <span
                  className="skeleton-text-area skeleton"
                  style={{ width: '80%' }}
                >
                  &nbsp;
                </span>
              </div>
              <span className="text-sm font-bold">
                <span className="skeleton-text-area skeleton">&nbsp;</span>
              </span>
            </div>
            <div className="port-facts-item flex-1">
              <div className="title color-green">
                <span
                  className="skeleton-text-area skeleton"
                  style={{ width: '80%' }}
                >
                  &nbsp;
                </span>
              </div>
              <span className="text-sm font-bold">
                <span className="skeleton-text-area skeleton">&nbsp;</span>
              </span>
              <span className="text-sm font-normal color-green">
                <span className="skeleton-text-area skeleton">&nbsp;</span>
              </span>
            </div>
            <div className="port-facts-item flex-1">
              <div className="title">
                <span
                  className="skeleton-text-area skeleton"
                  style={{ width: '80%' }}
                >
                  &nbsp;
                </span>
              </div>
              <span className="text-sm font-bold">
                <span className="skeleton-text-area skeleton">&nbsp;</span>
              </span>
            </div>
          </div>
        </div>
        <div className="portfolio-top-right">
          <div className="portfolio-graph-box">
            <div className="port-chart-box"></div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ProfileTop;
