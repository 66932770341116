import { ethers } from 'ethers';

import { ChainIdType, Token } from '../interfaces';
import {
  contractByNetwork,
  DAI_ADDRESS,
  logicByNetwork,
  nativeTokenByNetwork,
  USDC_ADDRESS,
  USDT_ADDRESS,
  WMATIC_ADDRESS,
} from './constants';
import {
  formatDecimals,
  getExpectedRate,
  getParaswapParams,
  getTokenSymbol,
} from './utils';

export class Encode {
  tokenList: Token[];

  constructor(tokenList: Token[]) {
    this.tokenList = tokenList;
  }

  encodeWrap(amount: any, isWei: boolean): string {
    const formattedAmount = isWei
      ? amount
      : formatDecimals('MATIC', amount, this.tokenList);

    return new ethers.utils.Interface([
      'function wrap(uint256 tokenAmt)',
    ]).encodeFunctionData('wrap', [formattedAmount]);
  }

  encodeUnWrap(amount: any, isWei: boolean): string {
    const formattedAmount = isWei
      ? amount
      : formatDecimals('MATIC', amount, this.tokenList);

    return new ethers.utils.Interface([
      'function unwrap(uint256 tokenAmt)',
    ]).encodeFunctionData('unwrap', [formattedAmount]);
  }

  encodeAave(
    command: string,
    amount: any,
    token: string,
    getVal = 0,
    setVal = 0,
    divider = 1,
  ): string {
    return new ethers.utils.Interface([
      `function ${command}(address erc20, uint256 tokenAmt, uint256 getId, uint256 setId, uint256 divider)`,
    ]).encodeFunctionData(command, [token, amount, getVal, setVal, divider]);
  }

  encodeCream(
    command: string,
    amount: any,
    token: string,
    getVal = 0,
    setVal = 0,
    divider = 1,
  ): string {
    return new ethers.utils.Interface([
      `function ${command}(address erc20, uint256 tokenAmt, uint256 getId, uint256 setId, uint256 divider)`,
    ]).encodeFunctionData(command, [token, amount, getVal, setVal, divider]);
  }

  encodeVGhstWrap(amount: any, getVal = 0, setVal = 0, isWei: boolean): string {
    const formattedAmount = isWei
      ? amount
      : formatDecimals('MATIC', amount, this.tokenList);

    return new ethers.utils.Interface([
      'function wrap(uint256 tokenAmt, uint getId, uint setId)',
    ]).encodeFunctionData('wrap', [formattedAmount, getVal, setVal]);
  }

  encodeVGhstUnWrap(
    amount: any,
    getVal = 0,
    setVal = 0,
    isWei: boolean,
  ): string {
    const formattedAmount = isWei
      ? amount
      : formatDecimals('MATIC', amount, this.tokenList);

    return new ethers.utils.Interface([
      'function unwrap(uint256 shares, uint getId, uint setId)',
    ]).encodeFunctionData('unwrap', [formattedAmount, getVal, setVal]);
  }

  encodeQuickswap(
    src: string,
    dest: string,
    amount: any,
    getVal = 0,
    setVal = 0,
    divider = 1,
    isWei = false,
    customPath = [],
  ): string {
    const formattedAmount = isWei
      ? String(amount)
      : formatDecimals(
          getTokenSymbol(src, this.tokenList),
          amount,
          this.tokenList,
        );

    const path = customPath.length > 0 ? customPath : [src, dest];

    return new ethers.utils.Interface([
      'function swap(address[] path, uint256 amount, uint256 getId, uint256 setId, uint256 divider)',
    ]).encodeFunctionData('swap', [
      path,
      formattedAmount,
      getVal,
      setVal,
      divider,
    ]);
  }

  encodeUniAddLiquidity(
    tokenA: string,
    tokenB: string,
    amountA: any,
    amountB: any,
    getVal = 0,
    getVal2 = 0,
    setVal = 0,
    divider = 1,
    isWei = false,
  ): string {
    let formattedAmountA = amountA;
    let formattedAmountB = amountB;
    if (!isWei) {
      formattedAmountA =
        amountA > 0
          ? formatDecimals(
              getTokenSymbol(tokenA, this.tokenList),
              amountA,
              this.tokenList,
            )
          : 0;
      formattedAmountB =
        amountB > 0
          ? formatDecimals(
              getTokenSymbol(tokenB, this.tokenList),
              amountB,
              this.tokenList,
            )
          : 0;
    }

    return new ethers.utils.Interface([
      'function addLiquidity(address tokenA, address tokenB, uint256 amountA, uint256 amountB, uint256 getId, uint256 getId2, uint256 setId, uint256 divider)',
    ]).encodeFunctionData('addLiquidity', [
      tokenA,
      tokenB,
      formattedAmountA,
      formattedAmountB,
      getVal,
      getVal2,
      setVal,
      divider,
    ]);
  }

  encodeQuickswapAddLiquidity2(
    tokenA: string,
    tokenB: string,
    amountA: any,
    amountB: any,
    getVal = 0,
    getVal2 = 0,
    setVal = 0,
    divider = 1,
    isWei = false,
  ): string {
    let formattedAmountA = amountA;
    let formattedAmountB = amountB;
    if (!isWei) {
      formattedAmountA =
        amountA > 0
          ? formatDecimals(
              getTokenSymbol(tokenA, this.tokenList),
              amountA,
              this.tokenList,
            )
          : 0;
      formattedAmountB =
        amountB > 0
          ? formatDecimals(
              getTokenSymbol(tokenB, this.tokenList),
              amountB,
              this.tokenList,
            )
          : 0;
    }

    return new ethers.utils.Interface([
      'function addLiquidity(address tokenA, address tokenB, uint256 amtA, uint256 amtB, uint256 getId, uint256 getId2, uint256 setId, uint256 divider)',
    ]).encodeFunctionData('addLiquidity', [
      tokenA,
      tokenB,
      formattedAmountA,
      formattedAmountB,
      getVal,
      getVal2,
      setVal,
      divider,
    ]);
  }

  encodeUniRemoveLiquidity(
    tokenA: string,
    tokenB: string,
    pool: string,
    amount: any,
    getVal = 0,
    setVal = 0,
    setVal2 = 0,
    divider = 1,
  ): string {
    return new ethers.utils.Interface([
      'function removeLiquidity(address tokenA, address tokenB, address poolToken, uint256 amtPoolTokens, uint256 getId, uint256 setId, uint256 setId2, uint256 divider)',
    ]).encodeFunctionData('removeLiquidity', [
      tokenA,
      tokenB,
      pool,
      amount,
      getVal,
      setVal,
      setVal2,
      divider,
    ]);
  }

  encodeCurve(
    pool: string,
    src: string,
    dest: string,
    amount: any,
    getVal = 0,
    setVal = 0,
    divider = 1,
    isWei = false,
  ): string {
    const formattedAmount = isWei
      ? String(amount)
      : formatDecimals(
          getTokenSymbol(src, this.tokenList),
          amount,
          this.tokenList,
        );

    return new ethers.utils.Interface([
      'function swap(address pool, address src, address dest, uint256 srcAmount, uint256 getId, uint256 setId, uint256 divider)',
    ]).encodeFunctionData('swap', [
      pool,
      src,
      dest,
      formattedAmount,
      getVal,
      setVal,
      divider,
    ]);
  }

  encodeCurveAddLiquidity(
    token: string,
    amount: any,
    getVal = 0,
    setVal = 0,
    divider = 1,
    chainId: ChainIdType,
  ): string {
    let tokenId: number;

    switch (token) {
      case DAI_ADDRESS:
        tokenId = 0;
        break;
      case USDC_ADDRESS:
        tokenId = 1;
        break;
      case USDT_ADDRESS:
        tokenId = 2;
        break;
      case '0x3A58a54C066FdC0f2D55FC9C89F0415C92eBf3C4':
        tokenId = 0;
        break;
      case WMATIC_ADDRESS:
        tokenId = 1;
        break;
      default:
        tokenId = -1;
        break;
    }

    if (tokenId < 0) return null;

    if (
      token.toLowerCase() === '0x3a58a54c066fdc0f2d55fc9c89f0415c92ebf3c4' ||
      token.toLowerCase() === '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270'
    ) {
      return new ethers.utils.Interface([
        'function addLiquidity2(address lpToken, uint256 tokenAmt, uint256 tokenId, uint256 getId, uint256 setId, uint256 divider)',
      ]).encodeFunctionData('addLiquidity2', [
        '0xe7CEA2F6d7b120174BF3A9Bc98efaF1fF72C997d',
        amount,
        tokenId,
        getVal,
        setVal,
        divider,
      ]);
    }

    return new ethers.utils.Interface([
      'function addLiquidity(address pool, uint256 amount, uint256 tokenId, uint256 getId, uint256 setId, uint256 divider)',
    ]).encodeFunctionData('addLiquidity', [
      contractByNetwork[chainId]['CurvePool'].address,
      amount,
      tokenId,
      getVal,
      setVal,
      divider,
    ]);
  }

  encodeCurveRemoveLiquidity(
    token: string,
    amount: any,
    getVal = 0,
    setVal = 0,
    divider = 1,
    chainId: ChainIdType,
  ): string {
    let tokenId: number;

    switch (token) {
      case DAI_ADDRESS:
        tokenId = 0;
        break;
      case USDC_ADDRESS:
        tokenId = 1;
        break;
      case USDT_ADDRESS:
        tokenId = 2;
        break;
      case '0x3A58a54C066FdC0f2D55FC9C89F0415C92eBf3C4':
        tokenId = 0;
        break;
      case WMATIC_ADDRESS:
        tokenId = 1;
        break;
      default:
        tokenId = -1;
        break;
    }

    if (tokenId < 0) return null;

    // @REMOVE: Make this more agnostic and dynamic
    if (
      token.toLowerCase() === '0x3a58a54c066fdc0f2d55fc9c89f0415c92ebf3c4' ||
      token.toLowerCase() === '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270'
    ) {
      return new ethers.utils.Interface([
        'function removeLiquidity2(address lpToken, uint256 tokenAmt, uint256 tokenId, uint256 getId, uint256 setId, uint256 divider)',
      ]).encodeFunctionData('removeLiquidity2', [
        '0xe7CEA2F6d7b120174BF3A9Bc98efaF1fF72C997d',
        amount,
        tokenId,
        getVal,
        setVal,
        divider,
      ]);
    }

    return new ethers.utils.Interface([
      'function removeLiquidity(address pool, uint256 amount, uint256 tokenId, uint256 getId, uint256 setId, uint256 divider)',
    ]).encodeFunctionData('removeLiquidity', [
      contractByNetwork[chainId]['CurvePool'].address,
      amount,
      tokenId,
      getVal,
      setVal,
      divider,
    ]);
  }

  encodeDeposit(symbol: string, amount: any, isWei: boolean): string {
    const formattedAmount = isWei
      ? amount
      : formatDecimals(symbol, amount, this.tokenList);
    const tokenAddress = this.tokenList.filter(
      (item: Token) => item.symbol === symbol,
    )[0].address;

    return new ethers.utils.Interface([
      'function deposit(address erc20, uint256 amount)',
    ]).encodeFunctionData('deposit', [tokenAddress, formattedAmount]);
  }

  encodeWithdraw(symbol: string, amount: any): string {
    const tokenAddress = this.tokenList.filter(
      (item: Token) => item.symbol === symbol,
    )[0].address;

    return new ethers.utils.Interface([
      'function withdraw(address erc20, uint256 amount)',
    ]).encodeFunctionData('withdraw', [tokenAddress, amount]);
  }

  encodeVaultDeposit(
    vaultAddress: string,
    tokenAddress: string,
    amount: any,
    getVal = 0,
    setVal = 0,
  ): string {
    return new ethers.utils.Interface([
      'function deposit(address vault, uint256 amount, uint256 getId, uint256 setId)',
    ]).encodeFunctionData('deposit', [vaultAddress, amount, getVal, setVal]);
  }

  encodeVaultWithdraw(
    vaultAddress: string,
    amount: any,
    getVal = 0,
    setVal = 0,
  ): string {
    return new ethers.utils.Interface([
      'function withdraw(address vault, uint256 tokenAmt, uint256 getId, uint256 setId)',
    ]).encodeFunctionData('withdraw', [vaultAddress, amount, getVal, setVal]);
  }

  encodeVaultRedeem(
    vaultAddress: string,
    shares: any,
    getVal = 0,
    setVal = 0,
  ): string {
    return new ethers.utils.Interface([
      'function redeem(address vault, uint256 shares, uint256 getId, uint256 setId)',
    ]).encodeFunctionData('redeem', [vaultAddress, shares, getVal, setVal]);
  }

  encodeVaultClaim(vaultAddress: string, setVal = 0): string {
    return new ethers.utils.Interface([
      'function claim(address vault, uint256 setId)',
    ]).encodeFunctionData('claim', [vaultAddress, setVal]);
  }

  encodeLendingClaimMatic(tokens: string[], amount: any): string {
    return new ethers.utils.Interface([
      'function claimAaveRewards(address[] tokens, uint256 amount)',
    ]).encodeFunctionData('claimAaveRewards', [tokens, amount]);
  }

  encodeLendingClaimEtha(stakingContract: string): string {
    return new ethers.utils.Interface([
      'function claimRewardsLending(address erc20)',
    ]).encodeFunctionData('claimRewardsLending', [stakingContract]);
  }

  encodeLendingClaimEthaOld(stakingContract: string): string {
    return new ethers.utils.Interface([
      'function claimRewardsLendingOld(address _stakingContract)',
    ]).encodeFunctionData('claimRewardsLendingOld', [stakingContract]);
  }

  encodeLock(
    veEthaContract: string,
    tokenAmt: any,
    days: any,
    getId: number,
  ): string {
    return new ethers.utils.Interface([
      'function deposit(address veEthaContract, uint256 tokenAmt, uint256 noOfDays, uint256 getId)',
    ]).encodeFunctionData('deposit', [veEthaContract, tokenAmt, days, getId]);
  }

  encodeIncreaseAmountLock(
    veEthaContract: string,
    tokenAmt: any,
    getId: number,
  ): string {
    return new ethers.utils.Interface([
      'function increase_amount(address veEthaContract, uint256 tokenAmt, uint256 getId)',
    ]).encodeFunctionData('increase_amount', [veEthaContract, tokenAmt, getId]);
  }

  encodeIncreaseTimeLock(veEthaContract: string, noOfDays: string): string {
    return new ethers.utils.Interface([
      'function increase_time(address veEthaContract, uint256 noOfDays)',
    ]).encodeFunctionData('increase_time', [veEthaContract, noOfDays]);
  }

  encodeLockWithdraw(veEthaContract: string): string {
    return new ethers.utils.Interface([
      'function withdraw_unlocked(address veEthaContract)',
    ]).encodeFunctionData('withdraw_unlocked', [veEthaContract]);
  }

  encodeLockEmergencyWithdraw(veEthaContract: string): string {
    return new ethers.utils.Interface([
      'function emergency_withdraw(address veEthaContract)',
    ]).encodeFunctionData('emergency_withdraw', [veEthaContract]);
  }

  encodeFeeClaim(
    multiFeeContract: string,
    user: string,
    rewardTokens: any,
  ): string {
    return new ethers.utils.Interface([
      'function claim(address multiFeeContract, address user,address[] calldata rewardTokens)',
    ]).encodeFunctionData('claim', [multiFeeContract, user, rewardTokens]);
  }

  encodeStakingStake(
    stakingContract: string,
    stakingToken: string,
    amount: any,
    getVal = 0,
  ): string {
    return new ethers.utils.Interface([
      'function stake(address stakingContract, address erc20, uint256 amount, uint256 getId)',
    ]).encodeFunctionData('stake', [
      stakingContract,
      stakingToken,
      amount,
      getVal,
    ]);
  }

  encodeStakingUnstake(
    stakingContract: string,
    stakingToken: string,
    amount: any,
    getVal = 0,
  ): string {
    return new ethers.utils.Interface([
      'function unstake(address stakingContract, address erc20, uint256 amount, uint256 getId)',
    ]).encodeFunctionData('unstake', [
      stakingContract,
      stakingToken,
      amount,
      getVal,
    ]);
  }

  encodeStakingClaim(stakingContract: string, setVal = 0): string {
    return new ethers.utils.Interface([
      'function claim(address stakingContract, uint256 setId)',
    ]).encodeFunctionData('claim', [stakingContract, setVal]);
  }

  encodeParaswap(
    src: any,
    dest: any,
    transferProxy: any,
    amount: any,
    swapTarget: any,
    swapData: any,
    setVal: any = 0,
  ): string {
    return new ethers.utils.Interface([
      'function swap(address fromToken, address destToken, address transferProxy, uint256 tokenAmt, address swapTarget, bytes memory swapData, uint256 setId)',
    ]).encodeFunctionData('swap', [
      src,
      dest,
      transferProxy,
      amount,
      swapTarget,
      swapData,
      setVal,
    ]);
  }

  async encodeSwaps(
    src,
    dest,
    amountWei: string,
    wallet: string,
    account: string,
    chainId: ChainIdType,
    dataFromSwap?: any,
  ) {
    const datas = [];
    const targets = [];

    if (
      src === nativeTokenByNetwork[chainId].address &&
      dest === nativeTokenByNetwork[chainId].wrappedAddress
    ) {
      datas.push(this.encodeWrap(amountWei, true));
      targets.push(logicByNetwork[chainId]['EthwrapLogic']);
    } else if (
      src === nativeTokenByNetwork[chainId].wrappedAddress &&
      dest === nativeTokenByNetwork[chainId].address
    ) {
      datas.push(this.encodeUnWrap(amountWei, true));
      targets.push(logicByNetwork[chainId]['EthwrapLogic']);
    } else {
      if (src === nativeTokenByNetwork[chainId].address) {
        datas.push(this.encodeWrap(amountWei, true));
        targets.push(logicByNetwork[chainId]['EthwrapLogic']);
      }

      if (dataFromSwap === undefined) {
        const { realSrc, realDest, proxy, path } = await getExpectedRate(
          src,
          dest,
          amountWei,
          wallet,
          true,
          chainId,
          this.tokenList,
        );

        if (!realSrc && !realDest)
          throw Error('Fail to get the rate for this swap.');

        const { swapTarget, swapData } = await getParaswapParams(
          path,
          wallet,
          account,
          chainId,
        );

        const data = await this.encodeParaswap(
          realSrc,
          realDest,
          proxy,
          amountWei,
          swapTarget,
          swapData,
          1,
        );

        datas.push(data);
        targets.push(logicByNetwork[chainId]['ParaswapLogic']);
      } else {
        const { realSrc, realDest, proxy, path } = dataFromSwap;

        const { swapTarget, swapData } = await getParaswapParams(
          path,
          wallet,
          account,
          chainId,
        );

        const data = await this.encodeParaswap(
          realSrc,
          realDest,
          proxy,
          amountWei,
          swapTarget,
          swapData,
          1,
        );

        datas.push(data);
        targets.push(logicByNetwork[chainId]['ParaswapLogic']);
      }
    }

    return { datas, targets };
  }

  encodeWithdrawAll(address: string): string {
    return new ethers.utils.Interface([
      'function withdrawAll(address erc20)',
    ]).encodeFunctionData('withdrawAll', [address]);
  }
}
