import './index.scss';

import React, { useContext, useState } from 'react';
import { useNetwork } from 'wagmi';

import { GlobalContext, UserContext } from '../../../../context';
import { formatDecimals, formatWeiSymbol } from '../../../../context/utils';
import { Button } from '../../../atoms/Button';
import { Modal } from '../../../atoms/Modal';
import { AddressQR } from '../../../molecules/AddressQR/index';
import { DecimalAssetSelect } from '../../../molecules/AssetsSelect/Decimal';
import { TypeEnum } from '../../Toast';

type Props = {
  close: () => void;
  reFetch: () => void;
  startingAsset: any;
};

const Deposit: React.FC<Props> = ({ close, reFetch, startingAsset }) => {
  const {
    state: { tokenList, actions },
    setToast,
  } = useContext(GlobalContext);
  const { chain } = useNetwork();
  const {
    state: { balances, wallet },
    transactions,
    setTransactions,
  } = useContext(UserContext);

  const [selected, setSelected] = useState<any>(
    balances.account.filter((item) => item.symbol === startingAsset)[0],
  );
  const [amount, setAmount] = useState<number | string>('');
  const [amountWei, setAmountWei] = useState<string>('');
  const [pending, setPending] = useState(false);
  const [noBalance, setNoBalance] = useState<boolean>(false);

  const handleSelect = (item) => {
    setSelected(item);
  };

  const handleAmount = (amt: string | number) => {
    if (amt === -1) {
      setAmount(selected.balance / 10 ** selected.decimals);
      setAmountWei(selected.balance);
    } else {
      setAmount(amt);
      setAmountWei(formatDecimals(selected.symbol, Number(amt), tokenList));

      if (amt > formatWeiSymbol(selected.symbol, selected.balance, tokenList)) {
        setNoBalance(true);
      } else {
        setNoBalance(false);
      }
    }
  };

  const executeDeposit = async () => {
    try {
      setPending(true);
      const initialTx = await actions.handleDeposit(selected.symbol, amountWei);

      setToast({
        open: true,
        type: TypeEnum.Pending,
        title: 'Pending transaction',
        timer: 14000,
        message: (
          <a
            href={
              chain
                ? `${chain.blockExplorers['default'].url}/tx/${initialTx.hash}`
                : `https://polygonscan.com/tx/${initialTx.hash}`
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            Check on block explorer
          </a>
        ),
      });

      transactions[initialTx.hash] = {
        from: initialTx.from,
        hash: initialTx.hash,
        titleTx: `Deposit Asset ${selected.symbol}`,
      };

      setTransactions(transactions);

      const receiptTx = await initialTx.wait();

      transactions[initialTx.hash].receipt = {
        blockHash: receiptTx.blockHash,
        blockNumber: receiptTx.blockNumber,
        contractAddress: receiptTx.contractAddress,
        from: receiptTx.from,
        status: receiptTx.status,
        to: receiptTx.to,
        transactionHash: receiptTx.transactionHash,
        transactionIndex: receiptTx.transactionIndex,
      };

      setTransactions(transactions);

      reFetch();

      setToast({
        open: true,
        type: TypeEnum.Success,
        title: 'Success in the deposit!',
        timer: 14000,
      });
      setPending(false);
      close();
    } catch (error) {
      setToast({
        open: true,
        type: TypeEnum.Error,
        title: 'Error in the deposit...',
        timer: 14000,
        message: error.message,
      });
      console.error(error);
      setPending(false);
    }
  };

  return (
    <Modal close={close} closeButton size={'sm'} pending={pending}>
      <div className="heading text-center">Deposit</div>
      <div className="swap-modal-box">
        <div className="group">
          <div className="from-wallet-box">
            <div className="from-option-box">
              <DecimalAssetSelect
                label={'Amount'}
                options={balances.account}
                amount={amount}
                selected={selected}
                isInput
                isSelectable
                showBalance
                handleSelect={handleSelect}
                handleAmount={handleAmount}
              />
            </div>
          </div>
          <div className="modal-qr-view">
            <AddressQR wallet={wallet} isTrim={false} />
          </div>
          <div className="swap-button-box">
            <Button
              className={
                pending || noBalance || amountWei === '' || amountWei === '0'
                  ? 'disabled'
                  : ''
              }
              handleClick={executeDeposit}
              disabled={pending}
              size={'lg'}
            >
              {pending
                ? 'Depositing'
                : noBalance
                ? 'Not enough funds'
                : 'Deposit'}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Deposit;
