import { useEffect } from 'react';
import { useConnect } from 'wagmi';

function useAutoConnect(): boolean {
  const { connect, connectors, isIdle } = useConnect();

  useEffect(() => {
    const autoConnect = () => {
      let connected = false;
      const retrys = 3;

      const connectorInstance = connectors.find(
        (c) => c.id === 'safe' && c.ready,
      );

      if (connectorInstance) {
        connected = true;
        return connect({ connector: connectorInstance });
      }

      for (let i = 0; i < retrys; i++) {
        if (connected) return;

        for (const c of connectors) {
          if (c.ready && !connected) {
            connected = true;
            return connect({ connector: c });
          }
        }
      }
    };

    autoConnect();
  }, [connect, connectors]);

  return isIdle;
}

export { useAutoConnect };
