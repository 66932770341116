import './index.scss';

import { ReactNode } from 'react';

import { icons } from '../../atoms/Icons/index';

export const Tooltip = ({ children }: { children: any }) => {
  return <div className="tooltip-box">{children}</div>;
};

export const TooltipLabel = ({ children }: { children: any }) => {
  return <div>{children}</div>;
};
export const TooltipContent = ({ children }: { children?: ReactNode }) => {
  return (
    <>
      {children && (
        <div className="tooltip-content">
          <div className="tooltip-content-body">{children}</div>
        </div>
      )}
    </>
  );
};
export const TooltipIcon = ({
  size,
}: {
  size?: 'default' | 'lg' | 'md' | 'sm';
}) => {
  return <span className={`info-icon-${size}`}>{icons.info}</span>;
};
