export const Address = ({
  wallet,
  isTrim,
}: {
  wallet: any;
  isTrim?: boolean;
}) => {
  return (
    <span className="address-text">
      {isTrim
        ? wallet.substring(0, 5) + '...' + wallet.substring(37, 42)
        : wallet}
    </span>
  );
};
