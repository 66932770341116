// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_tabContainer__d294U {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.styles_tab__3TEEj {\n  cursor: pointer;\n  padding: 20px;\n  background-color: rgba(39, 36, 62, 0.3);\n  border-radius: 10px 10px 0px 0px;\n  margin: 10px 10px 0px 10px;\n}\n.styles_tab__3TEEj input {\n  display: none;\n}\n.styles_tab__3TEEj label {\n  cursor: pointer;\n  font-size: 16px;\n}\n@media (max-width: 479px) {\n  .styles_tab__3TEEj label {\n    font-size: 14px;\n  }\n}\n\n.styles_checked__385n8 {\n  border-bottom: 2px solid #a894f8;\n}", "",{"version":3,"sources":["webpack://src/components/molecules/Tab/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;;AACA;EACE,eAAA;EACA,aAAA;EAEA,uCAAA;EACA,gCAAA;EACA,0BAAA;AACF;AAAE;EACE,aAAA;AAEJ;AAAE;EACE,eAAA;EACA,eAAA;AAEJ;AADI;EAHF;IAII,eAAA;EAIJ;AACF;;AADA;EACE,gCAAA;AAIF","sourcesContent":[".tabContainer {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.tab {\n  cursor: pointer;\n  padding: 20px;\n  // border: 1px solid #aaa;\n  background-color: rgba(39, 36, 62, 0.3);\n  border-radius: 10px 10px 0px 0px;\n  margin: 10px 10px 0px 10px;\n  input {\n    display: none;\n  }\n  label {\n    cursor: pointer;\n    font-size: 16px;\n    @media (max-width: 479px) {\n      font-size: 14px;\n    }\n  }\n}\n.checked {\n  border-bottom: 2px solid #a894f8;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabContainer": "styles_tabContainer__d294U",
	"tab": "styles_tab__3TEEj",
	"checked": "styles_checked__385n8"
};
export default ___CSS_LOADER_EXPORT___;
