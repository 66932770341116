import React, { useEffect, useState } from 'react';

import { formatNumber } from '../../../../context/utils';
import { Button } from '../../../atoms/Button';
import { Symbol } from '../../../atoms/Symbol';

const SimpleAssetSelect = ({
  label,
  options,
  isInput,
  isSelectable,
  amount,
  selected,
  handleAmount,
  handleSelect,
  showBalance,
  placeholder,
}: {
  label?;
  options?;
  isInput?;
  isSelectable?;
  amount;
  selected;
  handleAmount?;
  handleSelect?;
  showBalance?;
  placeholder?;
}) => {
  const [optionsActive, setOptionsActive] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleOptionsActive = () => {
    setOptionsActive((prev) => !prev);
  };

  const handleSelectOption = (item) => {
    handleSelect(item);
    setOptionsActive(false);
  };

  const handleMaxAmount = () => {
    handleAmount(-1);
  };

  const formatBalance = () => {
    return (
      formatNumber(selected.balance / 10 ** selected.decimals) +
      ' ' +
      selected.symbol
    );
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <div className="input-outer flex-1">
      <label className="label">{label}</label>
      {loading ? (
        <div className="input-box flex">
          <div className="input">
            <span className="asset-symbol skeleton"></span>
            <div className="input-style skeleton"></div>
          </div>
        </div>
      ) : (
        <div className="input-box flex">
          {isSelectable && (
            <div className="asset-select">
              {selected.symbol && (
                <div className="asset-label" onClick={handleOptionsActive}>
                  <Symbol
                    src={selected?.icon}
                    alt={selected?.symbol}
                    size={'md2'}
                  />
                </div>
              )}
              {optionsActive && (
                <>
                  <ul className="select-options">
                    <div className="select-asset-outer">
                      {options.map((item, i) => {
                        return (
                          <li
                            className={
                              selected.symbol === item.symbol ? 'selected' : ''
                            }
                            key={i}
                          >
                            <div
                              className="select-option flex items-center"
                              onClick={() => handleSelectOption(item)}
                            >
                              <div className="asset-info">
                                <span className="asset-symbol">
                                  <img src={item.icon} alt={item.symbol} />
                                </span>
                                <span className="asset-title">
                                  {item.symbol}
                                </span>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </div>
                  </ul>
                  <div
                    className="page-overlay"
                    onClick={handleOptionsActive}
                  ></div>
                </>
              )}
            </div>
          )}
          <div className="input">
            {!isSelectable && (
              <Symbol
                src={selected?.icon}
                alt={selected?.symbol}
                size={'md2'}
              />
            )}
            {isInput && (
              <Button
                className={'btn-max default'}
                size={'xs'}
                handleClick={handleMaxAmount}
              >
                MAX
              </Button>
            )}
            <input
              type="number"
              className="input-style"
              value={amount ? amount : ''}
              onChange={(e) => {
                if (isInput) handleAmount(e.target.value);
              }}
              placeholder={placeholder ? placeholder : '0.00'}
              pattern="[0-9]"
              inputMode="numeric"
              readOnly={!isInput}
            />
          </div>
        </div>
      )}

      {showBalance && selected.balance && selected.symbol && (
        <div className="balance-text text-sm">Available: {formatBalance()}</div>
      )}
    </div>
  );
};

export default SimpleAssetSelect;
