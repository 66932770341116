import { useCallback, useContext, useEffect, useState } from 'react';
import { useNetwork, useProvider } from 'wagmi';

import { GlobalContext, UserContext } from '../../context';
import { ETHA_ADDRESS } from '../../context/constants';
import {
  fromWei,
  getPoolData,
  loadContract,
  toBigNumber,
} from '../../context/utils';
import { ChainIdType, RefetchState } from '../../interfaces';

async function getUserStakingData(
  staking,
  library,
  wallet: string,
  dragon,
  priceData,
  tokenList,
  chainId: ChainIdType,
) {
  const {
    stakingContract: stakingContractAddress,
    stakingToken,
    earn,
  } = staking;

  const stakingContract = loadContract(
    'StakingRewards',
    chainId,
    library,
    stakingContractAddress,
  );

  const balance = await stakingContract.balanceOf(wallet);
  let earned = await stakingContract.earned(wallet);

  if (earn === 'QUICK') {
    earned = await dragon.dQUICKForQUICK(earned);
  }

  const balanceBN = toBigNumber(balance);
  const earnedBN = toBigNumber(earned);

  let lpValueUsd;

  if (stakingToken === ETHA_ADDRESS) {
    lpValueUsd = Number(fromWei(String(balanceBN))) * priceData['ETHA'];
  } else {
    ({ usdValue: lpValueUsd } = await getPoolData(
      stakingToken,
      balanceBN,
      library,
      tokenList,
      chainId,
    ));
  }

  return {
    ...staking,
    balanceBN,
    earnedBN,
    balance: lpValueUsd,
    earned: String(earnedBN),
    lpBalanceWei: String(balanceBN),
  };
}

export function useUserStakeData(): [RefetchState, any] {
  const { chain } = useNetwork();
  const provider = useProvider();
  const {
    state: { wallet },
    dispatch,
  } = useContext(UserContext);
  const {
    state: { stakingData, tokenList, priceData },
  } = useContext(GlobalContext);

  const [refetch, setRefetch] = useState<boolean>(false);
  const [userStakingData, setUserStakingData] = useState<any>(null);
  const [loadingUserStakeData, setLoadingUserStakeData] =
    useState<RefetchState>({
      isLoading: true,
      error: null,
      refetch: null,
    });

  const fetchUserStakeData = useCallback(async () => {
    const _userStakingData = [];
    const stakingPromises = [];

    if (!chain && chain.id !== ChainIdType.POLYGON) return _userStakingData;

    const dragon = loadContract('DragonLair', chain.id, provider);

    for (let i = 0; i < stakingData.length; i++) {
      stakingPromises.push(
        getUserStakingData(
          stakingData[i],
          provider,
          wallet,
          dragon,
          priceData,
          tokenList,
          chain.id,
        ),
      );
    }

    const results = await Promise.all(stakingPromises);

    for (const staking of results) {
      _userStakingData.push(staking);
    }

    return _userStakingData;
  }, [chain, stakingData, priceData]);

  useEffect(() => {
    setLoadingUserStakeData((pastLoadingUserStakeData) => ({
      ...pastLoadingUserStakeData,
      isLoading: true,
    }));

    fetchUserStakeData()
      .then((stakingData) => {
        setUserStakingData(stakingData);
        dispatch({
          type: 'SET_STAKING_DATA',
          payload: stakingData,
        });
        setLoadingUserStakeData((pastLoadingUserStakeData) => ({
          ...pastLoadingUserStakeData,
          refetch: () => setRefetch(true),
          isLoading: false,
        }));
        if (refetch) setRefetch(false);
      })
      .catch(() => {
        const errorToShow = new Error('Error fetching user staking data');
        setLoadingUserStakeData((pastLoadingUserStakeData) => ({
          ...pastLoadingUserStakeData,
          refetch: () => setRefetch(true),
          error: errorToShow,
        }));
        if (refetch) setRefetch(false);
      });
  }, [chain, stakingData, priceData, fetchUserStakeData]);

  return [loadingUserStakeData, userStakingData];
}
