import { useContext, useEffect, useState } from 'react';
import { useNetwork, useProvider } from 'wagmi';

import { Button } from '../../components/atoms/Button/index';
import PortfolioChart from '../../components/atoms/PortfolioChart';
import { Radio, RadioItem } from '../../components/atoms/RadioButton';
import { SectionArrow } from '../../components/atoms/SectionArrow';
import { Dropdown, Item } from '../../components/molecules/Dropdown/index';
import { PortfolioCard } from '../../components/molecules/PortfolioCard';
import { InvestedBorrow } from '../../components/organisms/Invest/Borrow';
import { InvestedLending } from '../../components/organisms/Invest/Lending';
import { InvestedStaking } from '../../components/organisms/Invest/Staking';
import { InvestedVaults } from '../../components/organisms/Invest/Vault';
import Deposit from '../../components/organisms/Modals/Deposit';
import { Swap } from '../../components/organisms/Modals/Swap';
import Withdraw from '../../components/organisms/Modals/Withdraw';
import ZapperWithdraw from '../../components/organisms/Modals/ZapperWithdraw';
import { TypeEnum } from '../../components/organisms/Toast/index';
import { UnusedTable } from '../../components/organisms/UnusedTable';
import InvestedAssets from '../../components/Skeleton/InvestedAssets';
import ProfileTop from '../../components/Skeleton/ProfileTop';
import { GlobalContext, UserContext } from '../../context';
import {
  ETHA_ADDRESS,
  LENDING_DISTRIBUTIONS,
  nativeTokenByNetwork,
  NEW_LENDING_DISTRIBUTIONS,
  TOKENS,
} from '../../context/constants';
import {
  formatCurrency,
  formatValue,
  formatWei,
  formatWeiSymbol,
  loadContract,
  toBigNumber,
} from '../../context/utils';
import {
  ChainIdType,
  GlobalLoading,
  RefetchState,
  UserData,
} from '../../interfaces';

export const Portfolio = ({
  loadingUser,
  loadingGlobal,
  loadingBalances,
  chainId,
}: {
  loadingUser: UserData;
  loadingGlobal: GlobalLoading;
  loadingBalances: RefetchState;
  chainId: ChainIdType;
}) => {
  const {
    state: { priceData, graphData, tokenList, actions },
    setToast,
  } = useContext(GlobalContext);

  const {
    state: {
      balances,
      wallet,
      userStakingData,
      userBorrowData,
      userLendingData,
      userVaultData,
    },
    transactions,
    setTransactions,
  } = useContext(UserContext);

  const provider = useProvider();
  const { chain } = useNetwork();

  const [selected, setSelected] = useState('Invested Assets');
  const [swapModal, setSwapModal] = useState<boolean>(false);
  const [withdrawModal, setWithdrawModal] = useState<boolean>(false);
  const [depositModal, setDepositModal] = useState<boolean>(false);
  const [asset, setAsset] = useState<string>('');
  const [chartData, setChartData] = useState<any>({});
  const [totalEarningsPast, setTotalEarningsPast] = useState<number>(0);
  const [showAll, setShowAll] = useState<boolean>(false);
  const [investedType, setInvestedType] = useState('Vaults');
  const [zapperWithdrawModal, setZapperWithdrawModal] =
    useState<boolean>(false);

  // Vault Portfolio
  const [vaultInvestments, setVaultInvestments] = useState([]);
  const [loadingVaultInvestments, setLoadingVaultInvestments] =
    useState<boolean>(false);
  const [totalInvestedVaults, setTotalInvestedVaults] = useState<number>(0);
  const [totalNetworthVaults, setTotalNetworthVaults] = useState<number>(0);

  // Lending Portfolio
  const [lendingInvestments, setLendingInvestments] = useState([]);
  const [totalInvestedLending, setTotalInvestedLending] = useState<number>(0);
  const [totalEarningsLending, setTotalEarningsLending] = useState<number>(0);
  const [totalNetworthLending, setTotalNetworthLending] = useState<number>(0);

  // Staking Portfolio
  const [stakingInvestments, setStakingInvestments] = useState([]);
  const [totalInvestedStaking, setTotalInvestedStaking] = useState<number>(0);
  const [totalNetworthStaking, setTotalNetworthStaking] = useState<number>(0);
  const [totalEarningsStaking, setTotalEarningsStaking] = useState<number>(0);

  // Borrowing Portfolio
  const [totalBorrowed, setTotalBorrowed] = useState<number>(0);
  const [, setBorrowLoans] = useState<number>(0);

  // Wallet Portfolio
  const [unusedAssets, setUnusedAssets] = useState([]);
  const [totalNetworthWallet, setTotalNetworthWallet] = useState<number>(0);

  const [loading, setLoading] = useState(true);
  const [claiming, setClaiming] = useState<boolean>(false);
  const [, setSync] = useState<boolean>(false);

  const {
    loadingUserLendingData,
    loadingUserVaultData,
    loadingUserBorrowingData,
    loadingUserStakeData,
  } = loadingUser;

  const handleRadio = (e) => {
    setSelected(e.target.value);
  };

  const handleSwapModal = (status: boolean, _asset?) => {
    if (status) setAsset(_asset);
    setSwapModal(status);
    const mainBody = document.body;
    swapModal
      ? mainBody.setAttribute('data-modal', '')
      : mainBody.setAttribute('data-modal', 'active');
  };

  const handleWithdrawModal = (status, _asset?) => {
    if (status) setAsset(_asset);
    setWithdrawModal(status);
  };

  const handleDepositModal = (status, _asset?) => {
    if (status) setAsset(_asset);
    setDepositModal(status);
  };

  const handleClaimAll = async () => {
    setClaiming(true);

    try {
      const initialTx = await actions.handleClaimAll(
        vaultInvestments,
        lendingInvestments,
        stakingInvestments,
      );

      setToast({
        open: true,
        type: TypeEnum.Pending,
        title: 'Pending transaction',
        timer: 14000,
        message: (
          <a
            href={`${chain.blockExplorers['default'].url}/tx/${initialTx.hash}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Check on block explorer
          </a>
        ),
      });

      transactions[initialTx.hash] = {
        from: initialTx.from,
        hash: initialTx.hash,
        titleTx: 'Claiming All',
      };

      setTransactions(transactions);

      const receiptTx = await initialTx.wait();
      loadingUserVaultData.refetch();
      loadingUserBorrowingData.refetch();
      loadingUserLendingData.refetch();
      loadingUserStakeData.refetch();

      transactions[initialTx.hash].receipt = {
        blockHash: receiptTx.blockHash,
        blockNumber: receiptTx.blockNumber,
        contractAddress: receiptTx.contractAddress,
        from: receiptTx.from,
        status: receiptTx.status,
        to: receiptTx.to,
        transactionHash: receiptTx.transactionHash,
        transactionIndex: receiptTx.transactionIndex,
      };

      setTransactions(transactions);

      setClaiming(false);
      setToast({
        open: true,
        type: TypeEnum.Success,
        title: 'Success in Claiming All!',
        timer: 14000,
      });
    } catch (error) {
      setToast({
        open: true,
        type: TypeEnum.Error,
        title: 'Error in Claiming All...',
        message: error.message,
        timer: 14000,
      });
      console.error(error.message);
      setClaiming(false);
    }
  };

  const handleShow = () => {
    setShowAll((prev) => !prev);
  };

  const shouldSync = async () => {
    try {
      let _sync = false;

      for (let i = 0; i < TOKENS.length; i++) {
        const symbol = TOKENS[i];

        const oldStaking = loadContract(
          'DistributionSynthethix',
          chainId,
          provider,
          LENDING_DISTRIBUTIONS[symbol],
        );

        const earned = await oldStaking.earned(wallet);

        if (earned > 0) _sync = true;
      }

      setSync(_sync);
    } catch (error) {}
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (
      balances.wallet.length > 0 &&
      !loadingGlobal.loadingPriceData.isLoading
    ) {
      let totalBalanceUSD = 0;

      const _unusedAssets = balances.wallet.map((item) => {
        let usdValue = 0;

        if (item.balance > 0) {
          if (
            priceData[item.symbol] !== undefined ||
            item.symbol === nativeTokenByNetwork[chainId].symbol
          ) {
            usdValue =
              formatValue(item.balance, item.decimals) *
              priceData[
                item.symbol === nativeTokenByNetwork[chainId].symbol
                  ? nativeTokenByNetwork[chainId].wrappedSymbol
                  : item.symbol
              ];
            totalBalanceUSD += usdValue;
          }
        }

        return {
          ...item,
          usdValue,
        };
      });

      setTotalNetworthWallet(totalBalanceUSD);
      setUnusedAssets(_unusedAssets);
    }
  }, [balances, loadingGlobal.loadingPriceData.isLoading, chain]);

  useEffect(() => {
    const _fetch = async () => {
      try {
        const _investments = userLendingData.data.investments.filter(
          (t) => !!t && +t.savings > 0,
        );

        setTotalInvestedLending(userLendingData.data.totalInvestedUSD);
        setTotalEarningsLending(
          userLendingData.data.totalEarnedUSD >= 0
            ? userLendingData.data.totalEarnedUSD
            : 0,
        );

        for (let i = 0; i < _investments.length; i++) {
          const { symbol } = _investments[i];
          const dist = loadContract(
            'DistributionSynthethix',
            chainId,
            provider,
            NEW_LENDING_DISTRIBUTIONS[symbol],
          );

          const ethaRewards = await dist.earned(wallet);

          _investments[i].ethaRewards = formatWei(
            ETHA_ADDRESS,
            ethaRewards,
            tokenList,
          );
        }

        setLendingInvestments(_investments);
        setTotalNetworthLending(userLendingData.data.totalInvestedUSD);
      } catch (error) {
        console.error(error);
      }
    };

    if (
      !loadingUserLendingData.isLoading &&
      !loadingGlobal.loadingPriceData.isLoading &&
      chainId === ChainIdType.POLYGON
    ) {
      _fetch();
    }

    return () => {
      setLendingInvestments([]);
      setTotalNetworthLending(0);
      setTotalInvestedLending(0);
      setTotalEarningsLending(0);
    };
  }, [
    loadingUserLendingData.isLoading,
    loadingGlobal.loadingPriceData.isLoading,
    chain,
  ]);

  useEffect(() => {
    const _fetch = async () => {
      setLoadingVaultInvestments(true);
      try {
        const _investments = userVaultData.filter((t) => !!t && +t.balance > 0);

        let _totalInvested = 0;

        if (_investments.length > 0) {
          _totalInvested = _investments
            .map((i) => +i.balance)
            .reduce((a, b) => a + b, 0);
        }

        setTotalInvestedVaults(_totalInvested);

        for (let i = 0; i < _investments.length; i++) {
          const { distributionAddress, version, vaultAddress } =
            _investments[i];

          if (version.distributionType === 'SYNTH') {
            _investments[i].ethaRewards = formatWei(
              ETHA_ADDRESS,
              toBigNumber(0),
              tokenList,
            );
          } else {
            const dist = loadContract(
              'MasterChefEtha',
              chainId,
              provider,
              distributionAddress,
            );

            try {
              const pid = await dist.vaultToPoolId(vaultAddress);
              const ethaRewards = (await dist.getPendingRewards(pid, wallet))
                .pendingAmountWithBoost;

              _investments[i].ethaRewards = formatWei(
                ETHA_ADDRESS,
                ethaRewards,
                tokenList,
              );
            } catch (e) {
              _investments[i].ethaRewards = 0;
            }
          }
        }

        setVaultInvestments(_investments);
        setLoadingVaultInvestments(false);
        setTotalNetworthVaults(_totalInvested);
      } catch (error) {
        setLoadingVaultInvestments(false);
        console.error(error);
      }
    };

    // if (!loadingUserVaultData.isLoading) {
    _fetch();
    // }

    return () => {
      setVaultInvestments([]);
      setLoadingVaultInvestments(false);
      setTotalNetworthVaults(0);
      setTotalInvestedVaults(0);
    };
  }, [userVaultData, priceData, chain, loadingUserVaultData.isLoading]);

  useEffect(() => {
    const _fetch = async () => {
      try {
        const _staked = userStakingData.filter((t) => !!t && +t.balance > 0);

        setStakingInvestments(_staked);

        let _totalInvested = 0;
        let _totalEarned = 0;

        if (_staked.length > 0) {
          _totalInvested = _staked
            .map((i) => i.balance)
            .reduce((a, b) => a + b, 0);
          _totalEarned = _staked
            .map(
              (i) =>
                formatWeiSymbol(i.earn, i.earned, tokenList) *
                priceData[i.earn === 'DQUICK' ? 'QUICK(OLD)' : i.earn],
            )
            .reduce((a, b) => a + b, 0);
        }

        setTotalInvestedStaking(_totalInvested);
        setTotalNetworthStaking(_totalInvested);
        setTotalEarningsStaking(_totalEarned);
      } catch (error) {
        console.error(error);
      }
    };

    if (
      !loadingUserStakeData.isLoading &&
      userStakingData.length > 0 &&
      chainId === ChainIdType.POLYGON
    ) {
      _fetch();
    }

    return () => {
      setStakingInvestments([]);
      setTotalInvestedStaking(0);
      setTotalNetworthStaking(0);
      setTotalEarningsStaking(0);
    };
  }, [userStakingData, priceData, chain]);

  useEffect(() => {
    const _fetch = async () => {
      try {
        const earned = Object.keys(graphData.totalData).map(
          (key) => graphData.totalData[key],
        );

        if (earned.length > 0) {
          const earnedUsd = earned
            .map((t) =>
              priceData[t.symbol] ? t.totalEarned * priceData[t.symbol] : 0,
            )
            .reduce((a, b) => a + b);

          setTotalEarningsPast(earnedUsd);
        } else setTotalEarningsPast(0);
      } catch (error) {
        console.error(error);
      }
    };

    if (
      !loadingGlobal.loadingPriceData.isLoading &&
      chainId === ChainIdType.POLYGON
    ) {
      _fetch();
    }

    return () => {
      setTotalEarningsPast(0);
    };
  }, [graphData, priceData, chain]);

  useEffect(() => {
    const totalInvestedAll =
      totalInvestedVaults +
      totalInvestedLending +
      totalNetworthWallet +
      totalInvestedStaking;

    const _chartLabels = [];
    const _chartValues = [];
    const _chartColors = [];

    if (totalInvestedVaults > 0) {
      _chartLabels.push('eVAULTS');
      _chartValues.push(
        Number((100 * totalInvestedVaults) / totalInvestedAll).toFixed(2),
      );
      _chartColors.push('#5a6291');
    }

    if (totalInvestedLending > 0) {
      _chartLabels.push('LENDING');
      _chartValues.push(
        Number((100 * totalInvestedLending) / totalInvestedAll).toFixed(2),
      );
      _chartColors.push('#4d547c');
    }

    if (totalNetworthWallet > 0) {
      _chartLabels.push('ETHA WALLET');
      _chartValues.push(
        Number((100 * totalNetworthWallet) / totalInvestedAll).toFixed(2),
      );
      _chartColors.push('#404666');
    }

    if (totalInvestedStaking > 0) {
      _chartLabels.push('STAKING');
      _chartValues.push(
        Number((100 * totalInvestedStaking) / totalInvestedAll).toFixed(2),
      );
      _chartColors.push('#404666');
    }

    if (totalBorrowed > 0) {
      _chartLabels.push('BORROWED');
      _chartValues.push(
        Math.floor(Number((100 * totalBorrowed) / totalInvestedAll)),
      );
      _chartColors.push('#6872A8');
    }

    const _chartData = {
      dataLabels: [..._chartLabels],
      dataValues: [..._chartValues],
      dataColors: [..._chartColors],
    };

    if (wallet !== '') setChartData(_chartData);
  }, [
    totalInvestedVaults,
    totalInvestedLending,
    totalNetworthWallet,
    totalInvestedStaking,
    totalBorrowed,
  ]);

  useEffect(() => {
    if (userBorrowData) {
      setTotalBorrowed(
        userBorrowData.aave.totalBorrowedUSD +
          userBorrowData.cream.totalBorrowedUSD,
      );

      setBorrowLoans(userBorrowData?.loans);
    }
  }, [userBorrowData]);

  useEffect(() => {
    if (wallet !== '') shouldSync();
  }, [wallet]);

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    if (!loadingUserVaultData.isLoading) setZapperWithdrawModal(true);
  }, [loadingUserVaultData.isLoading]);

  const netWorth =
    totalNetworthVaults +
    totalNetworthLending +
    totalNetworthStaking +
    totalNetworthWallet +
    totalEarningsLending;

  return (
    <>
      <main className="page-main">
        <div className="container">
          <div className="page-holder page-holder-port">
            {loading ? (
              <ProfileTop />
            ) : (
              <>
                <div className="page-title">
                  <div className="heading-main">My Portfolio</div>
                </div>
                <div className="port-top-panel flex">
                  <div className="port-top-col port-top-left flex">
                    <PortfolioCard
                      title={formatCurrency(
                        totalInvestedVaults +
                          totalInvestedLending +
                          totalInvestedStaking,
                      )}
                      label={'Total invested'}
                    />
                    <PortfolioCard
                      title={formatCurrency(netWorth, 2)}
                      label={'Net worth'}
                      tooltip={
                        'The net worth represents the total amount of invested assets across all investment opportunities, including pending earnings and unused assets in the ETHA Wallet'
                      }
                    />
                    <PortfolioCard
                      title={formatCurrency(
                        totalEarningsLending +
                          totalEarningsPast +
                          totalEarningsStaking,
                      )}
                      color={'green'}
                      label={'Total Earnings'}
                      tooltip={
                        'The total earnings display yields earned from all investment opportunities, including previously claimed rewards, using the current token prices.'
                      }
                    />
                  </div>
                  <div className="port-top-col port-top-right">
                    <div className="port-graph-outer">
                      <div className="port-item">
                        <div className="port-item-inner flex justify-center items-center port-chart-box">
                          {Object.keys(chartData).length > 0 ? (
                            <PortfolioChart chartData={chartData} />
                          ) : (
                            <div>No wallet connected...</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="arrow-portfolio-full">
              <SectionArrow target={'#portfolioInvested'} />
            </div>
            <div className="portfolio-body" id="portfolioInvested">
              {loading ? (
                <InvestedAssets />
              ) : (
                <>
                  <div className="portfolio-body-top">
                    <div className="invested-area flex">
                      <div className="invested-col">
                        <Radio theme={'button'}>
                          <RadioItem
                            name="assets"
                            value={'Invested Assets'}
                            defaultChecked={selected === 'Invested Assets'}
                            handleRadio={handleRadio}
                          >
                            Invested Assets
                          </RadioItem>
                          <RadioItem
                            name="assets"
                            value={'Unused Assets'}
                            defaultChecked={selected === 'Unused Assets'}
                            handleRadio={handleRadio}
                          >
                            Unused Assets
                          </RadioItem>
                        </Radio>
                      </div>
                      <div className="invested-col flex-auto">
                        <div className="invest-top-right flex justify-content-end">
                          <div className="invest-btn-outer">
                            {selected === 'Invested Assets' &&
                              (!claiming ? (
                                <Button handleClick={handleClaimAll}>
                                  Claim All
                                </Button>
                              ) : (
                                <Button className="disabled">
                                  Claiming...
                                </Button>
                              ))}
                            {selected === 'Unused Assets' && (
                              <Button handleClick={handleShow}>
                                {showAll ? 'Show Less' : 'Show All'}
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {selected === 'Invested Assets' ? (
                    <div className="port-assets-body">
                      <div className="invested-col-title">
                        <div className="invested-filter dropdown-parent">
                          <div className="th-item flex">
                            <span className="th-text">{investedType}</span>
                            <span className="th-arrow"></span>
                          </div>
                          <Dropdown>
                            <Item
                              handleClick={() => setInvestedType('Vaults')}
                              className={
                                investedType === 'Vaults' ? 'active' : ''
                              }
                            >
                              eVaults
                            </Item>
                            {chainId === ChainIdType.POLYGON && (
                              <>
                                <Item
                                  handleClick={() => setInvestedType('Lending')}
                                  className={
                                    investedType === 'Lending' ? 'active' : ''
                                  }
                                >
                                  Lending
                                </Item>
                                <Item
                                  handleClick={() =>
                                    setInvestedType('Borrowing')
                                  }
                                  className={
                                    investedType === 'Borrowing' ? 'active' : ''
                                  }
                                >
                                  Borrowing
                                </Item>
                                <Item
                                  handleClick={() => setInvestedType('Staking')}
                                  className={
                                    investedType === 'Staking' ? 'active' : ''
                                  }
                                >
                                  Staking
                                </Item>
                              </>
                            )}
                          </Dropdown>
                        </div>
                      </div>
                      <div className="invested-area invested-area-body">
                        {investedType === 'Vaults' && (
                          <div className="invested-listing-box">
                            <InvestedVaults
                              investedType={vaultInvestments}
                              loadingInvestedType={loadingVaultInvestments}
                              loading={loadingUserVaultData.isLoading}
                              chainId={chainId}
                              reFetch={() => loadingUserVaultData.refetch()}
                            />
                          </div>
                        )}
                        {investedType === 'Lending' && (
                          <div className="invested-listing-box">
                            <InvestedLending
                              investedType={lendingInvestments}
                              loading={loadingUserLendingData.isLoading}
                            />
                          </div>
                        )}
                        {investedType === 'Staking' && (
                          <div className="invested-listing-box">
                            <InvestedStaking
                              stakingData={userStakingData}
                              loading={loadingUserStakeData.isLoading}
                              reFetch={() => loadingUserStakeData.refetch()}
                            />
                          </div>
                        )}
                        {investedType === 'Borrowing' && (
                          <div className="invested-listing-box">
                            <InvestedBorrow
                              borrowingData={userBorrowData}
                              loading={loadingUserBorrowingData.isLoading}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="portfolio-table-box unused-table-view">
                      <div className="title-new">
                        Unused assets in ETHA Wallet (
                        {formatCurrency(totalNetworthWallet)})
                      </div>

                      <UnusedTable
                        data={unusedAssets}
                        showAll={showAll}
                        handleSwapModal={(_asset) =>
                          handleSwapModal(true, _asset)
                        }
                        handleWithdrawModal={(_asset) =>
                          handleWithdrawModal(true, _asset)
                        }
                        handleDepositModal={(_asset) =>
                          handleDepositModal(true, _asset)
                        }
                        loadingBalances={loadingBalances}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </main>

      {swapModal && (
        <Swap
          close={() => handleSwapModal(false)}
          startingAsset={asset}
          reFetch={() => loadingBalances.refetch()}
          chainId={chainId}
        />
      )}

      {withdrawModal && (
        <Withdraw
          close={() => handleWithdrawModal(false)}
          reFetch={() => loadingBalances.refetch()}
          startingAsset={asset}
        />
      )}

      {depositModal && (
        <Deposit
          close={() => handleDepositModal(false)}
          reFetch={() => loadingBalances.refetch()}
          startingAsset={asset}
        />
      )}
      {zapperWithdrawModal && !loadingVaultInvestments && (
        <ZapperWithdraw
          close={() => setZapperWithdrawModal(false)}
          vaultInvestments={vaultInvestments}
          unusedAssets={unusedAssets}
        />
      )}
    </>
  );
};
