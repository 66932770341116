import { useEffect, useState } from 'react';

import { Button } from '../../../components/atoms/Button';
import PrivacyPage from '../../../documents/PrivacyPage.pdf';
import IconCookie from '../../../styles/img/svgr/Icon-cookie';
import { Overlay } from '../../atoms/Overlay/index';
import styles from './styles.module.scss';

type Props = {
  close: () => void;
};

const PrivacyPolicy = ({ close }: Props) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    let shouldClose = true;

    if (localStorage.getItem('PrivacyPolicy') !== 'Version-26/03/22') {
      setTimeout(() => {
        setShow(true);
      }, 50);
    }

    setTimeout(() => {
      localStorage.setItem('PrivacyPolicy', 'Version-26/03/22');

      if (shouldClose) close();
    }, 15000);

    return () => {
      shouldClose = false;
    };
  }, []);

  return (
    <div
      className={`${styles.modalMain} ${show ? styles.show : ''} ${
        styles.modalSm
      }`}
    >
      <div className={styles.modalMainInner}>
        <div className={styles.modalOuter}>
          <div className={styles.modalInner}>
            <div className={styles.modalBody}>
              {' '}
              <div className={styles.modalContainer}>
                <div>
                  <IconCookie />
                </div>
                <div>
                  <span>This site uses cookies. Read our</span>
                  <a
                    href={PrivacyPage}
                    target="_blank"
                    rel="noreferrer"
                    className={styles.modalLink}
                  >
                    {' '}
                    cookies policy
                  </a>
                </div>
                <div>
                  <Button
                    type="button"
                    size="xs"
                    className="btn-default"
                    handleClick={() => {
                      localStorage.setItem('PrivacyPolicy', 'Version-26/03/22');
                      close();
                    }}
                  >
                    <span>OK</span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Overlay onClick={close} />
      </div>
    </div>
  );
};

export default PrivacyPolicy;
