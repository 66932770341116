import './index.scss';

import React, { useContext, useState } from 'react';
import { useNetwork } from 'wagmi';

import { Button } from '../../components/atoms/Button';
import {
  Col,
  Row,
  Table,
  TableBody,
  TableHead,
} from '../../components/atoms/ListTable';
import { NoRecord } from '../../components/atoms/NoRecord';
import Pager from '../../components/atoms/Pager';
import { Symbol } from '../../components/atoms/Symbol';
import { SymbolGroup } from '../../components/atoms/SymbolGroup';
import ActivitiesListing from '../../components/Skeleton/Activities';
import { GlobalContext } from '../../context';
import { formatNumber, getIcon } from '../../context/utils';

function Activities({ loadingGraphData }: { loadingGraphData: boolean }) {
  const {
    state: { tokenList, graphData, vaultData },
  } = useContext(GlobalContext);
  const { chain } = useNetwork();

  const [slice] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState((currentPage - 1) * slice);

  const renderIcon = (item) => {
    let _icon;

    if (item.type === 'VaultDeposit' || item.type === 'VaultWithdraw') {
      const _vault = vaultData.filter(
        (vault: any) =>
          vault.stakingToken.toLowerCase() === item.token.toLowerCase(),
      )[0];

      if (_vault) {
        _icon = <SymbolGroup data={_vault.deposit} />;
      } else {
        if (item.symbol === 'UNI-V2') {
          item.token.toLowerCase() ===
            '0x1f1e4c845183ef6d50e9609f16f6f9cae43bc9cb' &&
            (_icon = (
              <div className={`asset-group asset-group-2`}>
                <Symbol src={getIcon('USDC', tokenList)} alt={'USDC'} key={1} />
                <Symbol
                  src={getIcon('QUICK(OLD)', tokenList)}
                  alt={'QUICK(OLD)'}
                  key={2}
                />
              </div>
            ));
        }
      }
    } else if (item.type === 'Stake' || item.type === 'Unstake') {
      if (item.symbol === 'UNI-V2') {
        if (
          item.token.toLowerCase() ===
          '0xb417da294ae7c5cbd9176d1a7a0c7d7364ae1c4e'
        ) {
          _icon = (
            <div className={`asset-group asset-group-2`}>
              <Symbol src={getIcon('USDC', tokenList)} alt={'USDC'} key={1} />
              <Symbol src={getIcon('ETHA', tokenList)} alt={'ETHA'} key={2} />
            </div>
          );
        }
        if (
          item.token.toLowerCase() ===
          '0x8167d3156fccdbaf3e43ae019a0e842e5d1f1ac1'
        ) {
          _icon = (
            <div className={`asset-group asset-group-2`}>
              <Symbol src={getIcon('ETHA', tokenList)} alt={'ETHA'} key={1} />
              <Symbol src={getIcon('QUICK', tokenList)} alt={'QUICK'} key={2} />
            </div>
          );
        }
      }

      if (item.symbol === 'ETHA') {
        _icon = <Symbol src={getIcon('ETHA', tokenList)} alt={'ETHA'} />;
      }
    } else {
      _icon = (
        <Symbol src={getIcon(item.symbol, tokenList)} alt={item.symbol} />
      );
    }

    return (
      <>
        {_icon}
        <span className="asset-title">
          {formatNumber(item.amount) +
            ' ' +
            item.symbol +
            (item.type === 'Swap' ? ` > ${item.symbol1}` : '')}
        </span>
      </>
    );
  };

  return (
    <main className="page-main">
      <div className="container">
        <div className="page-holder">
          <div className="page-title">
            <div className="heading-main">Activities</div>
          </div>
          <div className="portfolio-body activity-body">
            <Table className="table-listing-view table-activity">
              <TableHead>
                <Row className="list-card activity-list-row">
                  <Col className="col-1 list-col-1">
                    <div className="th-item flex">
                      <span className="th-text">Asset</span>
                    </div>
                  </Col>
                  <Col className="col-2 list-col-2">
                    <div className="th-item flex">
                      <span className="th-text">Action</span>
                    </div>
                  </Col>
                  <Col className="col-3 list-col-2">
                    <div className="th-item flex">
                      <span className="th-text">Date</span>
                    </div>
                  </Col>
                  <Col className="col-4 list-col-4"></Col>
                </Row>
              </TableHead>
              <TableBody>
                {loadingGraphData ? (
                  <ActivitiesListing />
                ) : (
                  <>
                    {graphData.activities.length > 0 ? (
                      graphData.activities
                        .sort((a, b) => b.timestamp - a.timestamp)
                        .slice(offset, offset + slice)
                        .map((item, i) => {
                          return (
                            <Row
                              className="list-card activity-list-row"
                              key={i}
                            >
                              <Col className="col-1 list-col-1">
                                <div className="asset-info">
                                  {/*  */}
                                  {renderIcon(item)}
                                </div>
                              </Col>

                              <Col className="col-2 list-col-2">
                                <div className="">
                                  <span>{item.type}</span>
                                </div>
                              </Col>

                              <Col className="col-3 list-col-3">
                                <div className="flex items-center">
                                  <span className="">
                                    {new Date(
                                      +item.timestamp * 1000,
                                    ).toLocaleDateString()}
                                  </span>
                                </div>
                              </Col>

                              <Col className="col-4 list-col-4">
                                <div className="flex items-center">
                                  <Button
                                    type={'link'}
                                    to={`${chain.blockExplorers['default'].url}/tx/${item.transactionHash}`}
                                    target={'_blank'}
                                    size={'sm'}
                                    className={'etherscan'}
                                  >
                                    Check on{' '}
                                    {chain.blockExplorers['default'].name}
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          );
                        })
                    ) : (
                      <NoRecord>No activities yet!</NoRecord>
                    )}
                    {!loadingGraphData && (
                      <Pager
                        slice={slice}
                        totalItems={graphData.activities.length}
                        currentPage={currentPage}
                        setOffset={setOffset}
                        setCurrentPage={setCurrentPage}
                        resultQuery={graphData.activities}
                      />
                    )}
                  </>
                )}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Activities;
