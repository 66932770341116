import './index.scss';

import { useEffect, useState } from 'react';

import { Modal } from '../../components/atoms/Modal/index';
import { FloatingMenu } from '../../components/molecules/FloatingMenu/index';
import PrivacyPolicy from '../../components/molecules/PrivacyPolicy';
import { ContentSection } from '../../components/organisms/ContentSection/index';
import HeadingSection from '../../components/Skeleton/HeadingSection';
import { GlobalLoading } from '../../interfaces/index';
import EvaultsPage from './EvaultsPage';

const Home = ({ loadingGlobal }: { loadingGlobal: GlobalLoading }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [sectionActive] = useState<number>(0);
  const [activeModalView, setActiveModalView] = useState<string>('');

  useEffect(() => {
    setLoading(false);
    handleExpand('privacyPolicy');
    handleExpand('zapperWithdraw');
  }, []);

  const handleExpand = (type) => {
    setActiveModalView(type);
  };

  const handleMinimize = (type) => {
    setActiveModalView(type);
  };

  return (
    <main className="page-main">
      {/* VAULT SECTION */}
      <section className="section-main" id="vaults">
        <div className="container">
          {loading ? (
            <HeadingSection />
          ) : (
            <ContentSection
              heading={'eVaults'}
              content={[
                'Get exposure to volatile asset classes while simultaneously earning yield on your principal in stable or volatile assets',
                'Deposit your assets and automatically begin earning yield in BTC, ETH and more',
                'No lockup period for deposits',
              ]}
            />
          )}
          <EvaultsPage
            handleExpand={() => handleExpand('eVaults')}
            loading={loadingGlobal.loadingVaultData.isLoading}
            error={loadingGlobal.loadingVaultData.error}
            limit={4}
          />
          {activeModalView === 'eVaults' && (
            <Modal
              close={() => handleMinimize('')}
              closeButton
              size={'xl'}
              type={'asset'}
              overlayClickable={false}
            >
              <EvaultsPage
                scrollable={true}
                loading={loadingGlobal.loadingVaultData.isLoading}
                error={loadingGlobal.loadingVaultData.error}
              />
            </Modal>
          )}
        </div>
      </section>
      <FloatingMenu
        items={[
          {
            label: 'eVaults',
            href: '#vaults',
            id: 'vaults_link',
            active: 0,
          },
        ]}
        sectionActive={sectionActive}
      />
      {activeModalView === 'privacyPolicy' && (
        <PrivacyPolicy close={() => handleMinimize('')} />
      )}
    </main>
  );
};

export default Home;
