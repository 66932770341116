export const Symbol = ({
  src,
  alt,
  size,
  rounded,
}: {
  src: any;
  alt?: string;
  size?: 'xs' | 'sm' | 'sm2' | 'md' | 'md2' | 'lg';
  rounded?: boolean;
}) => {
  return (
    <span className={`symbol ${rounded ? 'rounded' : ''}`} data-size={size}>
      <img src={src} alt={alt} />
    </span>
  );
};
