import './index.scss';

import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import React, { useContext } from 'react';

import { UserContext } from '../../../../context';
import { Modal } from '../../../atoms/Modal';

type Props = {
  close: () => void;
};

const QRCodeView: React.FC<Props> = ({ close }) => {
  const {
    state: { wallet },
  } = useContext(UserContext);

  return (
    <Modal close={close} closeButton size={'sm'}>
      <div className="group">
        <div className="qr-image-outer">
          <div className="qr-image-view">
            <QRCode value={wallet || ''} />
          </div>
          <div className="qr-address-text">{wallet}</div>
        </div>
      </div>
    </Modal>
  );
};

QRCodeView.defaultProps = {
  close: () => null,
};

QRCodeView.propTypes = {
  close: PropTypes.func,
};

export default QRCodeView;
