import { useContext } from 'react';

import { UserContext } from '../../../context';
import { Radio, RadioItem } from '../../atoms/RadioButton/index';
import { AssetSelect } from '../../molecules/AssetsSelect/AssetSelect';
import InvestFromLoading from '../../Skeleton/InvestFrom';

export const InvestFrom = ({
  options,
  optionsAccount,
  handleSelect,
  handleAmount,
  setInvestFrom,
  investFrom,
  amount,
  selected,
  selectedAccount,
  loadingVaults,
  loadingTokensData,
  loadingPriceData,
  loadingBalances,
}: {
  options: any;
  optionsAccount: any;
  handleSelect: any;
  handleAmount: any;
  setInvestFrom: any;
  investFrom: any;
  amount: any;
  selected: any;
  selectedAccount: any;
  globalBorrowData: any;
  loadingVaults: any;
  loadingTokensData: any;
  loadingPriceData: any;
  loadingBalances: any;
}) => {
  const {
    state: { wallet },
  } = useContext(UserContext);

  const handleRadio = (e) => {
    setInvestFrom(e.target.value);
    handleAmount('');
  };

  const shouldBeLoading =
    loadingVaults ||
    loadingTokensData ||
    loadingBalances ||
    loadingPriceData ||
    loadingBalances ||
    selected === undefined ||
    selectedAccount === undefined;

  return (
    <>
      {!shouldBeLoading ? (
        <>
          {/* INVEST FROM SMART WALLET BALANCE */}
          <div
            className={
              investFrom === 'wallet'
                ? 'group group-invest-from flex items-start arrow-active'
                : 'group group-invest-from flex items-start'
            }
          >
            <div className="from-group">
              <Radio theme={'radio'}>
                <RadioItem
                  value={'wallet'}
                  name={'from'}
                  defaultChecked={investFrom === 'wallet'}
                  wallet={wallet}
                  handleRadio={handleRadio}
                  disabled={wallet === ''}
                >
                  From your ETHA Wallet Balance
                </RadioItem>
              </Radio>
              {investFrom === 'wallet' && (
                <>
                  <div className="from-wallet-box">
                    <div className="from-option-box">
                      <div className="asset-select-box flex items-center">
                        <AssetSelect
                          options={options}
                          label={'Amount'}
                          amount={amount}
                          selected={selected}
                          handleSelect={handleSelect}
                          handleAmount={handleAmount}
                          isInput
                          isSelectable
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="input-arrow">
              <i className="arrow-right"></i>
            </div>
          </div>
          {/* INVEST FROM METAMASK WALLET BALANCE */}
          <div
            className={
              investFrom === 'account'
                ? 'group group-invest-from flex items-start arrow-active'
                : 'group group-invest-from flex items-start'
            }
          >
            <div className="from-group">
              <Radio theme={'radio'}>
                <RadioItem
                  value={'account'}
                  name={'from'}
                  handleRadio={handleRadio}
                  defaultChecked={investFrom === 'account'}
                  wallet={wallet}
                >
                  From your Web3 Wallet Balance
                </RadioItem>
              </Radio>
              {investFrom === 'account' && (
                <div className="from-option-box">
                  <div className="asset-select-box flex items-center">
                    <AssetSelect
                      label={'Amount'}
                      options={optionsAccount}
                      amount={amount}
                      selected={selectedAccount}
                      handleSelect={handleSelect}
                      handleAmount={handleAmount}
                      isInput
                      isSelectable={optionsAccount.length > 1}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="input-arrow">
              <i className="arrow-right"></i>
            </div>
          </div>
        </>
      ) : (
        <InvestFromLoading />
      )}
    </>
  );
};
