import React, { useEffect, useRef, useState } from 'react';

import { formatNumber } from '../../../../context/utils';
import { Button } from '../../../atoms/Button';
import { Symbol } from '../../../atoms/Symbol';

export const AssetSelect = ({
  label,
  options,
  isInput,
  isSelectable,
  isSwap,
  amount,
  selected,
  handleAmount,
  handleSelect,
  getRate,
  pending,
}: {
  label?: any;
  options?: any;
  isInput?: any;
  isSelectable?: any;
  isSwap?: any;
  amount?: any;
  selected?: any;
  handleAmount?: any;
  handleSelect?: any;
  getRate?: any;
  pending?: any;
}) => {
  const [optionsActive, setOptionsActive] = useState(false);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState<string>('');
  const [selectOptions, setSelectOptions] = useState<any>(options);
  const inputRef = useRef(null);
  const handleOptionsActive = () => {
    setOptionsActive((prev) => !prev);
    setSearch('');
    setTimeout(() => {
      if (optionsActive == false && inputRef) {
        inputRef.current.focus();
      }
    }, 100);
  };

  const handleSelectOption = (current, item) => {
    handleSelect(current, item);
    if (isInput && !isSwap) handleAmount('');
    setOptionsActive(false);
    setSelectOptions(options);
  };

  const handleMaxAmount = () => {
    handleAmount(-1);
  };

  const handleSearch = (e) => {
    const searchText = e.target.value.toLowerCase();

    const filtered = options.filter((item) => {
      if (item.symbol) {
        return item.symbol.toLowerCase().search(searchText) !== -1;
      }
    });

    setSearch(searchText);
    setSelectOptions(filtered);
  };

  useEffect(() => {
    getRate && getRate();
  }, [amount]);

  useEffect(() => {
    setLoading(false);
  }, []);
  useEffect(() => {
    setSelectOptions(options);
  }, [options]);

  return (
    <div className="input-outer flex-1">
      <label className="label">{label}</label>
      {loading ? (
        <div className="input-box flex">
          <div className="input">
            <span className="asset-symbol skeleton"></span>
            <div className="input-style skeleton"></div>
          </div>
        </div>
      ) : (
        <div className="input-box flex">
          {isSelectable && (
            <div className="asset-select">
              {selected.symbol && (
                <div
                  className="asset-label"
                  onClick={handleOptionsActive}
                  style={{
                    pointerEvents: pending ? 'none' : 'auto',
                  }}
                >
                  <Symbol
                    src={selected?.icon}
                    alt={selected?.symbol}
                    size={'md2'}
                  />
                </div>
              )}
              {optionsActive && (
                <>
                  <ul className="select-options">
                    <div className="select-search-box">
                      <div className="assets-search">
                        <div className="assets-search-box">
                          <input
                            type="text"
                            className="search-input"
                            placeholder="Search"
                            value={search}
                            onChange={handleSearch}
                            ref={inputRef}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="select-asset-outer">
                      {selectOptions.length ? (
                        selectOptions.map((item, i) => {
                          return (
                            <li
                              className={
                                selected.symbol === item.symbol
                                  ? 'selected'
                                  : ''
                              }
                              key={i}
                            >
                              <div
                                className="select-option flex items-center"
                                onClick={() =>
                                  handleSelectOption(selected, item)
                                }
                              >
                                <Symbol
                                  src={item.icon}
                                  alt={item.symbol}
                                  size={'md2'}
                                />
                                <span className="asset-title">
                                  {item.symbol}
                                </span>
                              </div>
                            </li>
                          );
                        })
                      ) : (
                        <li className="select-no-record">No records</li>
                      )}
                    </div>
                  </ul>
                  <div
                    className="page-overlay"
                    onClick={handleOptionsActive}
                  ></div>
                </>
              )}
            </div>
          )}
          <div className="input">
            {!isSelectable && selected?.symbol && (
              <Symbol
                src={selected?.icon}
                alt={selected?.symbol}
                size={'md2'}
              />
            )}
            {isInput && (
              <Button
                className={'btn-max default'}
                disabled={pending ? true : false}
                handleClick={handleMaxAmount}
              >
                MAX
              </Button>
            )}
            <input
              type="number"
              className="input-style"
              value={amount || ''}
              onChange={(e) => {
                if (isInput) handleAmount(e.target.value);
              }}
              placeholder="0.00"
              pattern="[0-9]"
              min="0"
              inputMode="numeric"
              readOnly={!isInput}
              disabled={pending}
            />
          </div>
        </div>
      )}
      {isInput && selected.symbol && (
        <div className="balance-text text-sm">
          Available:{' '}
          {formatNumber(+selected?.balance / 10 ** selected?.decimals) +
            ' ' +
            selected.symbol}
        </div>
      )}
    </div>
  );
};
