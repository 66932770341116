import React, { useContext, useEffect, useState } from 'react';

import { GlobalContext } from '../../../../context';
import { formatNumber, handleBorrowRate } from '../../../../context/utils';
import { Button } from '../../../atoms/Button';
import { Symbol } from '../../../atoms/Symbol';

export const AssetSelect = ({
  label,
  options,
  isInput,
  isSelectable,
  isSwap,
  amount,
  selected,
  protocol,
  handleAmount,
  handleSelect,
  getRate,
  placeholder,
  isNotAsset,
  showBalance,
  isApyInfo,
  globalBorrowData,
}: {
  label?: any;
  options?: any;
  isInput?: any;
  isSelectable?: any;
  isSwap?: any;
  amount?: any;
  selected?: any;
  protocol?: any;
  handleAmount?: any;
  handleSelect?: any;
  getRate?: any;
  placeholder?: any;
  isNotAsset?: any;
  showBalance?: any;
  isApyInfo?: any;
  globalBorrowData?: any;
}) => {
  const {
    state: { priceData },
  } = useContext(GlobalContext);
  const [optionsActive, setOptionsActive] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleOptionsActive = () => {
    setOptionsActive((prev) => !prev);
  };

  const handleSelectOption = (item) => {
    handleSelect(item);
    if (isInput && !isSwap) handleAmount('');
    setOptionsActive(false);
  };

  const handleMaxAmount = () => {
    if (protocol) {
      handleAmount(
        (protocol.totalCollateralUSD - 0.1) / priceData[selected.symbol],
      );
    } else {
      if (selected.balance / 10 ** selected.decimals >= 1e-12)
        handleAmount(selected.balance / 10 ** selected.decimals - 1e-12);
      else handleAmount(0);
    }
  };

  useEffect(() => {
    if (getRate) {
      const timeOutId = setTimeout(() => getRate(), 1000);
      return () => clearTimeout(timeOutId);
    }
  }, [amount]);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <div className="input-outer flex-1">
      <label className="label">{label}</label>
      {loading ? (
        <div className="input-box flex">
          <div className="input">
            <span className="asset-symbol skeleton"></span>
            <div className="input-style skeleton"></div>
          </div>
        </div>
      ) : (
        <div className="input-box flex">
          {isSelectable && (
            <div className="asset-select">
              {selected.symbol && (
                <div className="asset-label" onClick={handleOptionsActive}>
                  <Symbol
                    src={selected?.icon}
                    alt={selected?.symbol}
                    size={'md2'}
                  />
                </div>
              )}
              {optionsActive && (
                <>
                  <ul className="select-options">
                    <div className="select-asset-outer">
                      {options.map((item, i) => {
                        return (
                          <li
                            className={
                              selected.symbol === item.symbol ? 'selected' : ''
                            }
                            key={i}
                          >
                            <div
                              className="select-option flex items-center"
                              onClick={() => handleSelectOption(item)}
                            >
                              <Symbol
                                src={item.icon}
                                alt={item.symbol}
                                size={'sm2'}
                              />

                              <span className="asset-title">{item.symbol}</span>
                            </div>
                          </li>
                        );
                      })}
                    </div>
                  </ul>
                  <div
                    className="page-overlay"
                    onClick={handleOptionsActive}
                  ></div>
                </>
              )}
            </div>
          )}
          <div className={isNotAsset ? 'input input-not-asset' : 'input'}>
            {!isSelectable && selected?.symbol && (
              <Symbol
                src={selected?.icon}
                alt={selected?.symbol}
                size={'md2'}
              />
            )}
            {isInput && (
              <Button
                className={'default btn-max'}
                size={'xs'}
                handleClick={handleMaxAmount}
              >
                MAX
              </Button>
            )}
            <input
              type="number"
              className={
                placeholder ? 'input-style input-placeholder' : 'input-style'
              }
              value={amount}
              onChange={(e) => {
                if (isInput) handleAmount(e.target.value);
              }}
              placeholder={placeholder ? placeholder : '0.00'}
              readOnly={!isInput}
            />
          </div>
        </div>
      )}
      {isInput && selected.symbol && (
        <div
          className={
            isNotAsset
              ? 'balance-text text-sm hide flex'
              : 'balance-text text-sm flex'
          }
        >
          {' '}
          {isApyInfo && (
            <div className="flex-auto text-left">
              <span style={{ color: '#F9A606' }}>
                {handleBorrowRate(
                  `${protocol.protocol}Rate`,
                  selected.symbol,
                  globalBorrowData,
                )}
                %
              </span>{' '}
              APY
            </div>
          )}
          <div>
            Available:{' '}
            {formatNumber(selected.balance / 10 ** selected.decimals) +
              ' ' +
              selected.symbol}
          </div>
        </div>
      )}

      {showBalance && !isInput && (
        <div
          className={
            isNotAsset ? 'balance-text text-sm hide' : 'balance-text text-sm'
          }
        >
          Available: {formatNumber(selected.balance) + ' ' + selected.symbol}
        </div>
      )}
    </div>
  );
};
