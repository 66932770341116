import { Symbol } from '../Symbol/index';

export const SymbolGroup = ({
  data,
  size,
  hasArrow = true,
}: {
  data?: Array<any>;
  size?: 'sm' | 'md' | 'lg';
  hasArrow?: boolean;
}) => {
  return (
    <div className={`asset-group asset-group-${data?.length}`} data-size={size}>
      {data?.map((asset, idx) => {
        return (
          <Symbol src={asset.icon} key={idx} alt={asset.symbol} size={size} />
        );
      })}
      {hasArrow && <i className="asset-group-icon"></i>}
    </div>
  );
};
