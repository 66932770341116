import Identicon from 'identicon.js';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAccount, useConnect, useDisconnect, useNetwork } from 'wagmi';

import { connectorsByName } from '../../../connectors';
import { GlobalContext, UserContext } from '../../../context';
import { formatCurrency } from '../../../context/utils';
import { ChainIdType } from '../../../interfaces';
// import LogoBinance from '../../styles/img/binance.svg';
// import LogoFantom from '../../../styles/img/fantom.svg';
import LogoAvalanche from '../../../styles/img/avalanche.svg';
import logo from '../../../styles/img/logo.svg';
import LogoMoonbeam from '../../../styles/img/moonbeam.svg';
import LogoPolygon from '../../../styles/img/polygon.svg';
import { Address } from '../../atoms/Address/index';
import { Symbol } from '../../atoms/Symbol/index';
import { AddressQR } from '../../molecules/AddressQR/index';
import { MainMenu } from '../../molecules/MainMenu/index';
import { NetworkSelect } from '../../molecules/NetworkSelect/index';
import { PriceShow } from '../../molecules/PriceShow/index';
import HeaderSkeleton from '../../Skeleton/Header';
import { CreateWallet } from '../Modals/CreateWallet/index';
import { TransactionsView } from '../Modals/TransactionsView/index';
import { WalletButton } from '../Modals/WalletButton/index';

export interface Option {
  schemeLabel: string;
  iconLabel: string;
  network: string;
  labelFor: number[];
  switchTo: number;
  disabled: boolean;
}

const options: Option[] = [
  // {
  //   schemeLabel: 'Binance',
  //   iconLabel: LogoBinance,
  //   network: 'binance',
  //   labelFor: [56, 97],
  //   switchTo: 56,
  //   disabled: true
  // },
  {
    schemeLabel: 'Polygon',
    iconLabel: LogoPolygon,
    network: 'polygon',
    labelFor: [80001, 137],
    switchTo: 137,
    disabled: false,
  },
  {
    schemeLabel: 'Avalanche',
    iconLabel: LogoAvalanche,
    network: 'avalanche',
    labelFor: [43114],
    switchTo: 43114,
    disabled: false,
  },
  // {
  //   schemeLabel: 'Fantom (soon)',
  //   iconLabel: LogoFantom,
  //   network: 'fantom',
  //   labelFor: [250],
  //   switchTo: 250,
  //   disabled: true,
  // },
  {
    schemeLabel: 'Moonbeam (soon)',
    iconLabel: LogoMoonbeam,
    network: 'moonbeam',
    labelFor: [1284],
    switchTo: 1284,
    disabled: true,
  },
];

function getSelectedNetwork(chainId: ChainIdType, options: Option[]) {
  let selectedNetwork = 0;

  for (let index = 0; index < options.length; index++) {
    const element = options[index];
    if (element.labelFor.includes(chainId)) {
      selectedNetwork = index;
      break;
    }
  }

  return selectedNetwork;
}

export const Header = ({
  loadingGlobal,
  errorSwitch,
  switchNetwork,
  setChainId,
}: {
  loadingGlobal: boolean;
  errorSwitch: Error;
  switchNetwork: (_chainId: number) => void;
  setChainId: Dispatch<SetStateAction<ChainIdType>>;
}) => {
  const { address: account, isConnected, connector } = useAccount();
  const { disconnect } = useDisconnect();
  const { chain } = useNetwork();
  const location = useLocation();
  const [menuActive, setMenuActive] = useState<boolean>(false);
  const [dropdown, setDropdown] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<string>('');
  const [connectionWallet, setConnectionWallet] = useState<boolean>(false);
  const [transactionsView, setTransactionsView] = useState<boolean>(false);
  const [createWallet, setCreateWallet] = useState<boolean>(false);
  const [scrollTopActive, setScrollTopActive] = useState<boolean>(false);
  const [usedConnector, setUsedConnector] = useState<string | null>(null);

  const {
    connect,
    error,
    reset,
    isLoading: loading,
    pendingConnector,
  } = useConnect({
    onError: () => {
      setUsedConnector(null);
    },
    onSuccess: (data) => {
      if (!data.chain.unsupported) setConnectionWallet(false);
    },
  });
  const [selectedNetwork, setSelectedNetwork] = useState<number>(
    getSelectedNetwork(chain ? chain.id : ChainIdType.POLYGON, options),
  );

  const {
    state: { priceData },
  } = useContext(GlobalContext);
  const {
    state: { wallet },
  } = useContext(UserContext);

  const handleMenuToggle = () => {
    setMenuActive(!menuActive);
    setDropdown(false);
  };

  const handleScroll = () => {
    const viewportHeight = Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0,
    );

    if (window.pageYOffset > viewportHeight / 2) {
      setScrollTopActive(true);
    } else {
      setScrollTopActive(false);
    }
  };

  const handleDropdown = () => {
    setDropdown(!dropdown);
  };

  const handleScrollTop = () => {
    window.scrollTo(0, 0);
  };

  const handleSelect = () => {
    if (menuActive) {
      setMenuActive(false);
    }
  };

  const handleConnectionWallet = () => {
    const mainBody = document.body;
    reset();
    setConnectionWallet(!connectionWallet);
    connectionWallet
      ? mainBody.setAttribute('data-modal', '')
      : mainBody.setAttribute('data-modal', 'active');
  };

  const handleTransactionsView = () => {
    setTransactionsView(!transactionsView);
  };

  const handleCreateWallet = () => {
    setCreateWallet(!createWallet);
  };

  async function changeNetwork(option: Option) {
    if (isConnected) {
      if (connector === connectorsByName['Injected']) {
        setSelectedNetwork(getSelectedNetwork(option.switchTo, options));
        await switchNetwork(option.switchTo);
      }
    } else {
      setSelectedNetwork(getSelectedNetwork(option.switchTo, options));
      setChainId(option.switchTo);
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    setCurrentPage(location.pathname);
  }, [location, account, wallet]);

  useEffect(() => {
    const connectIntoConnector = () => {
      if (!usedConnector && !pendingConnector) return;
      if (pendingConnector && !usedConnector) return;

      connect({
        connector: connectorsByName[usedConnector],
      });
    };

    connectIntoConnector();

    return () => {
      setUsedConnector(null);
    };
  }, [usedConnector]);

  useEffect(() => {
    if (chain) {
      if (chain.unsupported) setConnectionWallet(true);
    }
  }, [chain, isConnected]);

  useEffect(() => {
    if (chain || errorSwitch) {
      setSelectedNetwork(getSelectedNetwork(chain.id, options));
    } else {
      setSelectedNetwork(getSelectedNetwork(ChainIdType.POLYGON, options));
      setChainId(ChainIdType.POLYGON);
    }
  }, [chain, errorSwitch]);

  return (
    <>
      {loadingGlobal ? (
        <HeaderSkeleton />
      ) : (
        <nav className={menuActive ? 'nav-full menu-active' : 'nav-full'}>
          <div className="nav-inner flex">
            <div className="nav-left">
              <Link to={'/'} className="logo">
                <img src={logo} alt="ETHA LEND" />
              </Link>
            </div>
            <div className="nav-spacing"></div>
            <div className="nav-right flex flex-auto justify-center">
              <div className="nav-main-links nav-main-new">
                <MainMenu
                  currentPage={currentPage}
                  handleSelect={handleSelect}
                  wallet={wallet}
                  chainId={chain ? chain.id : ChainIdType.POLYGON}
                />
              </div>
            </div>
            <div className="nav-right nav-right-boxes flex">
              <div className="nav-address-box nav-address-new">
                <NetworkSelect
                  className="second"
                  optionsList={options}
                  displayFields={{
                    primary: 'schemeLabel',
                    secondary: 'schemeName',
                    icon: 'iconLabel',
                    network: 'network',
                  }}
                  size="sm"
                  selected={selectedNetwork}
                  onSelectOptionChange={(option) => {
                    changeNetwork(option);
                  }}
                  disabled={connector === connectorsByName['GnosisSafe']}
                />
                <PriceShow
                  symbol="ETHA"
                  price={formatCurrency(priceData.ETHA)}
                  className="second"
                />
                {!account || chain.unsupported ? (
                  <div
                    className="nav-address first"
                    onClick={handleConnectionWallet}
                  >
                    <div className="nav-address-inner">
                      <span className="address-info">
                        <span className="address-text">Connect Wallet</span>
                      </span>
                    </div>
                  </div>
                ) : wallet ? (
                  <div className="nav-address first">
                    <div className="nav-address-inner" onClick={handleDropdown}>
                      <span className="address-info">
                        <Symbol
                          src={`data:image/png;base64,${new Identicon(
                            account,
                            30,
                          ).toString()}`}
                          alt="Address"
                          size={'sm2'}
                          rounded
                        />
                        <Address wallet={wallet} isTrim={true} />
                      </span>
                    </div>
                    {dropdown && (
                      <>
                        <ul className="select-options dropdown-main">
                          <div className="select-asset-outer">
                            <li className="qr-code-box">
                              <AddressQR
                                wallet={wallet}
                                isTrim={true}
                                isHeader
                              />
                            </li>
                            <li className="etha-price-box">
                              <div className="select-option flex items-center earned-option">
                                <PriceShow
                                  symbol="ETHA"
                                  price={formatCurrency(priceData.ETHA)}
                                  className="second"
                                />
                              </div>
                            </li>
                            <li>
                              <div
                                className="select-option flex items-center"
                                onClick={() => setTransactionsView(true)}
                              >
                                <div className="asset-info">
                                  <span className="asset-title">
                                    View Transactions
                                  </span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="select-option flex items-center">
                                <div className="asset-info">
                                  <span
                                    className="asset-title"
                                    onClick={() => disconnect()}
                                  >
                                    Disconnect
                                  </span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div
                                className="select-option flex items-center"
                                onClick={() => {
                                  handleConnectionWallet();
                                }}
                              >
                                <div className="asset-info">
                                  <span className="asset-title">
                                    Change Provider
                                  </span>
                                </div>
                              </div>
                            </li>
                          </div>
                        </ul>
                        <div
                          className="page-overlay"
                          onClick={handleDropdown}
                        ></div>
                      </>
                    )}
                  </div>
                ) : (
                  <div
                    className="nav-address first"
                    onClick={() => setCreateWallet(true)}
                  >
                    <div className="nav-address-inner">
                      <div className="address-info">
                        <span className="address-text">
                          Create a Smart Wallet
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {wallet && (
                <>
                  <div className="menu-toggle-box" onClick={handleMenuToggle}>
                    <span
                      className={
                        menuActive
                          ? 'menu-toggle-icon menu-close'
                          : 'menu-toggle-icon'
                      }
                    >
                      <i></i>
                      <i></i>
                      <i></i>
                    </span>
                    <div className="nav-main-box">
                      <MainMenu
                        currentPage={currentPage}
                        handleSelect={handleSelect}
                        wallet={wallet}
                        chainId={chain ? chain.id : ChainIdType.POLYGON}
                      />
                    </div>
                  </div>
                  <div
                    className="page-overlay menu-overlay"
                    onClick={handleMenuToggle}
                  ></div>
                </>
              )}
            </div>
          </div>
        </nav>
      )}

      {createWallet && (
        <div className="header-barcode-expand">
          <CreateWallet close={handleCreateWallet} />
        </div>
      )}

      {connectionWallet && (
        <div className="header-walletbutton-expand">
          <WalletButton
            close={handleConnectionWallet}
            connectionWallet={connectionWallet}
            setUsedConnector={setUsedConnector}
            pendingConnector={pendingConnector}
            loading={loading}
            error={error}
          />
        </div>
      )}

      {transactionsView && (
        <div className="header-transation-expand">
          <TransactionsView
            close={handleTransactionsView}
            transactionsView={transactionsView}
          />
        </div>
      )}

      <div className={`backtop-btn-outer ${scrollTopActive && 'active'}`}>
        <span className="backtop-btn" onClick={handleScrollTop}></span>
      </div>
    </>
  );
};
