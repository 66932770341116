import { useEffect } from 'react';

import icons from '../../../icons';

export enum TypeEnum {
  Warning = 'warning',
  Pending = 'pending',
  Error = 'error',
  Success = 'success',
}

type Props = {
  type: TypeEnum;
  title: string;
  message: string | JSX.Element;
  timer: number;
  close: () => void;
};

export const Toast = ({ type, title, message, timer = 5000, close }: Props) => {
  useEffect(() => {
    setTimeout(() => {
      close();
    }, timer);
  }, []);

  return (
    <div className="toast-panel" onClick={() => close()}>
      <div className="toast-box-main">
        <div className="toast-box flex">
          <div className="toast-icon-left">
            <div className={`toast-icon toast-${type} toast-icon-show`}>
              {type === TypeEnum.Success && (
                <>
                  <div
                    className="toast-success-circular-line-left"
                    style={{ backgroundColor: '#454c71' }}
                  ></div>
                  <span className="toast-success-line-tip"></span>{' '}
                  <span className="toast-success-line-long"></span>
                  <div className="toast-success-ring"></div>{' '}
                  <div
                    className="toast-success-fix"
                    style={{ backgroundColor: '#454c71' }}
                  ></div>
                  <div
                    className="toast-success-circular-line-right"
                    style={{ backgroundColor: '#454c71' }}
                  ></div>
                </>
              )}

              {type === TypeEnum.Pending && (
                <>
                  <span className="toast-clock-line clock-line1"></span>
                  <span className="toast-clock-line clock-line2"></span>
                </>
              )}

              {type === TypeEnum.Error && (
                <span className="toast-x-mark">
                  <span className="toast-x-mark-line-left"></span>
                  <span className="toast-x-mark-line-right"></span>
                </span>
              )}

              {type === TypeEnum.Warning && (
                <span style={{ fontSize: 40 }}>{icons.warning}</span>
              )}
            </div>
          </div>
          <div className="toast-data">
            <div className="toast-title">{title}</div>
            <div className="toast-message">
              {type === TypeEnum.Success || type === TypeEnum.Pending
                ? message
                : (message as string).substring(0, 100)}
            </div>
          </div>
          <div className={`toast-progress-bar toast-progress-${type}`}>
            <span style={{ animationDuration: timer + 'ms' }}></span>
          </div>
        </div>
      </div>
    </div>
  );
};
