import { useCallback, useContext, useEffect, useState } from 'react';
import { useNetwork, useProvider } from 'wagmi';

import { GlobalContext, UserContext } from '../../context';
import {
  formatDecimals,
  getTokenDecimals,
  loadContract,
} from '../../context/utils';
import { BorrowData, ChainIdType, RefetchState, Token } from '../../interfaces';

export function useUserBorrowingData(): [RefetchState, any] {
  const { chain } = useNetwork();
  const provider = useProvider();
  const {
    state: { wallet, userLendingData: lendingData },
    dispatch,
  } = useContext(UserContext);
  const {
    state: { tokenList, priceData, borrowData },
  } = useContext(GlobalContext);

  const [refetch, setRefetch] = useState<boolean>(false);
  const [userBorrowingData, setUserBorrowingData] = useState<any>(null);
  const [loadingUserBorrowingData, setLoadingUserBorrowingData] =
    useState<RefetchState>({
      isLoading: true,
      error: null,
      refetch: null,
    });

  const fetchUserBorrowingData = useCallback(async () => {
    const _borrowData = {
      loans: [],
      aave: {
        totalBorrowedUSD: 0,
        totalCollateralUSD: 0,
        userLTV: 0,
        maxLTV: 0,
      },
      cream: {
        totalBorrowedUSD: 0,
        totalCollateralUSD: 0,
        userLTV: 0,
        maxLTV: 0,
      },
    };

    if (!chain && chain.id !== ChainIdType.POLYGON) return _borrowData;

    lendingData.data.investments.forEach((t) => {
      const lendingAsset = borrowData.filter(
        (item: BorrowData) =>
          item.symbol === (t.symbol === 'WMATIC' ? 'MATIC' : t.symbol),
      )[0];

      if (lendingAsset.aaveData.isCollateral) {
        _borrowData.aave.totalCollateralUSD +=
          t.balances.aave * priceData[t.symbol];
        _borrowData.aave.maxLTV +=
          t.balances.aave * priceData[t.symbol] * lendingAsset.aaveData.ltv;
      }
      if (lendingAsset.creamData.isCollateral) {
        _borrowData.cream.totalCollateralUSD +=
          t.balances.cream * priceData[t.symbol];
        _borrowData.cream.maxLTV +=
          t.balances.cream * priceData[t.symbol] * lendingAsset.creamData.ltv;
      }
    });

    _borrowData.aave.maxLTV /= _borrowData.aave.totalCollateralUSD;
    _borrowData.cream.maxLTV /= _borrowData.cream.totalCollateralUSD;

    const borrowAdapter = loadContract('AdapterBorrow', chain.id, provider);

    const dataFromProtocols = [];

    const markets = [
      '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
      '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
      '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
      '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    ];

    for (const market of markets) {
      const { dataAssetAave, dataAssetCream } =
        await borrowAdapter.getDataAssetOfProtocols(market, wallet);

      if (dataAssetAave !== undefined && dataAssetCream !== undefined) {
        const formatResult = {
          aaveData: {
            currentATokenBalance:
              dataAssetAave.currentATokenBalance /
              10 ** getTokenDecimals(dataAssetAave.symbol.slice(2), tokenList),
            currentVariableDebt:
              dataAssetAave.currentVariableDebt /
              10 ** getTokenDecimals(dataAssetAave.symbol.slice(2), tokenList),
            symbol:
              dataAssetAave.symbol.slice(2) === 'WMATIC'
                ? 'MATIC'
                : dataAssetAave.symbol.slice(2),
          },
          creamData: {
            totalUnderlyingSupplied:
              dataAssetCream.totalUnderlyingSupplied /
              10 ** getTokenDecimals(dataAssetCream.symbol.slice(2), tokenList),
            storedBorrowBalance:
              dataAssetCream.storedBorrowBalance /
              10 ** getTokenDecimals(dataAssetCream.symbol.slice(2), tokenList),
            symbol:
              dataAssetAave.symbol.slice(2) === 'WMATIC'
                ? 'MATIC'
                : dataAssetAave.symbol.slice(2),
          },
        };

        dataFromProtocols.push(formatResult);
      }
    }

    if (dataFromProtocols.length > 0) {
      dataFromProtocols.forEach((t) => {
        const { creamData } = t;

        const amount = creamData.storedBorrowBalance;

        if (amount > 0 && borrowData.length > 0) {
          const _symbol =
            creamData.symbol === 'MATIC' ? 'WMATIC' : creamData.symbol;
          const lendingAsset = borrowData.filter(
            (item: BorrowData) => item.symbol === creamData.symbol,
          )[0];

          const amountUSD = amount * priceData[_symbol];
          const amountWei = formatDecimals(_symbol, amount, tokenList);

          _borrowData.loans.push({
            symbol: _symbol,
            icon: tokenList.filter(
              (token: Token) => token.symbol === _symbol,
            )[0].logoURI,
            protocol: 'cream',
            amount,
            amountUSD,
            amountWei,
            token: tokenList.filter(
              (token: Token) => token.symbol === _symbol,
            )[0].address,
            ...lendingAsset.creamData,
          });

          _borrowData.cream.totalBorrowedUSD += amountUSD;
        }
      });
    }

    _borrowData.cream.userLTV =
      _borrowData.cream.totalBorrowedUSD / _borrowData.cream.totalCollateralUSD;

    if (dataFromProtocols.length > 0) {
      dataFromProtocols.forEach((t) => {
        const { aaveData } = t;

        const amount = aaveData.currentVariableDebt;
        const _symbol =
          aaveData.symbol === 'MATIC' ? 'WMATIC' : aaveData.symbol;
        const lendingAsset = borrowData.filter(
          (item: BorrowData) => item.symbol === aaveData.symbol,
        )[0];

        if (amount > 0 && borrowData.length > 0) {
          const amountUSD = amount * priceData[_symbol];
          const amountWei = formatDecimals(_symbol, amount, tokenList);

          _borrowData.loans.push({
            symbol: _symbol,
            icon: tokenList.filter(
              (token: Token) => token.symbol === _symbol,
            )[0].logoURI,
            protocol: 'aave',
            amount,
            amountUSD,
            amountWei,
            token: tokenList.filter(
              (token: Token) => token.symbol === _symbol,
            )[0].address,
            ...lendingAsset.aaveData,
          });

          _borrowData.aave.totalBorrowedUSD += amountUSD;
        }
      });
    }

    _borrowData.aave.userLTV =
      _borrowData.aave.totalBorrowedUSD / _borrowData.aave.totalCollateralUSD;

    return _borrowData;
  }, [chain, borrowData, priceData, lendingData]);

  useEffect(() => {
    setLoadingUserBorrowingData((pastLoadingUserBorrowingData) => ({
      ...pastLoadingUserBorrowingData,
      isLoading: true,
    }));

    fetchUserBorrowingData()
      .then((borrowData) => {
        setUserBorrowingData(borrowData);
        dispatch({
          type: 'SET_BORROW_DATA',
          payload: borrowData,
        });
        setLoadingUserBorrowingData({
          error: null,
          refetch: () => setRefetch(true),
          isLoading: false,
        });
        if (refetch) setRefetch(false);
      })
      .catch(() => {
        const errorToShow = new Error('Error fetching user borrow data');
        setLoadingUserBorrowingData((pastLoadingUserBorrowingData) => ({
          ...pastLoadingUserBorrowingData,
          refetch: () => setRefetch(true),
          error: errorToShow,
        }));
        if (refetch) setRefetch(false);
      });
  }, [chain, borrowData, priceData, lendingData, fetchUserBorrowingData]);

  return [loadingUserBorrowingData, userBorrowingData];
}
