import { ReactNode, useEffect, useState } from 'react';

import { icons } from '../../atoms/Icons/index';
import { Overlay } from '../../atoms/Overlay/index';
import { CustomScrollbar } from '../CustomScrollbar';

export const Modal = ({
  size,
  closeButton,
  close,
  children,
  overlayClickable,
  type,
  verticalAlign,
  pending,
}: {
  size?: 'xl' | 'lg' | 'sm' | 'xs';
  closeButton?: boolean;
  close: () => void;
  children?: ReactNode;
  overlayClickable?: boolean;
  type?: string;
  verticalAlign?: 'default' | 'center';
  pending?: boolean;
}) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 50);
    const mainBody = document.body;
    mainBody.setAttribute('data-modal', 'active');
    return () => {
      mainBody.setAttribute('data-modal', '');
    };
  }, []);

  return (
    <div
      className={`modal-main ${
        show ? 'show' : ''
      } modal-${size} ${verticalAlign}`}
      data-type={type}
    >
      <CustomScrollbar isScrollable={true} height={'100%'} theme={'light'}>
        <div className="modal-main-inner">
          <div className="modal-outer">
            <div className="modal-inner">
              {closeButton && (
                <span
                  className={`close-btn ${!pending ? '' : 'disabled'}`}
                  onClick={close}
                >
                  {icons.close}
                </span>
              )}
              <div className="modal-body">{children}</div>
            </div>
          </div>
          {overlayClickable ? <Overlay onClick={close} /> : <Overlay />}
        </div>
      </CustomScrollbar>
    </div>
  );
};
