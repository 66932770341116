import { ContractTransaction, ethers } from 'ethers';
import React, { useContext, useEffect, useState } from 'react';
import { useAccount, useNetwork, useProvider } from 'wagmi';

import { GlobalContext, UserContext } from '../../../../context';
import {
  formatRate,
  formatWei,
  getCurveId,
  toBigNumber,
} from '../../../../context/utils';
import { useContract } from '../../../../hooks/useContract';
import { Button } from '../../../atoms/Button';
import { Modal } from '../../../atoms/Modal';
import { RangeSlider } from '../../../atoms/Slider';
import { Symbol } from '../../../atoms/Symbol';
import { Tooltip, TooltipContent, TooltipIcon } from '../../../atoms/Tooltip';
import { AssetSelect } from '../../../molecules/AssetsSelect/AssetSelect';
import { TypeEnum } from '../../Toast';

type Props = {
  close: () => void;
  reFetch: () => void;
  data: any;
};

const sliderLables = {
  0: '0%',
  25: '25%',
  50: '50%',
  75: '75%',
  100: '100%',
};

const WithdrawLP: React.FC<Props> = ({ close, reFetch, data }) => {
  const {
    state: { priceData, tokenList, actions, contracts },
    setToast,
    dispatch,
  } = useContext(GlobalContext);

  const {
    state: { wallet },
    transactions,
    setTransactions,
  } = useContext(UserContext);

  const provider = useProvider();
  const { address: account } = useAccount();
  const { chain } = useNetwork();

  const [selected, setSelected] = useState<any>(() => data.deposit[0]);
  const [amount, setAmount] = useState<number>(0);
  const [pending, setPending] = useState<boolean>(false);
  const [options] = useState(data.deposit);
  const [sliderValue, setSliderValue] = useState<number>(0);
  const [priceImpact, setPriceImpact] = useState<number>(0);
  const [withdrawStatus, setWithdrawStatus] = useState<string>('');

  const handleSelect = async (item) => {
    setSliderValue(0);
    setAmount(0);

    let balance = 0;

    // @REMOVE: Curve new vault, when we find a dinamic solution
    if (data.protocol === 'Curve' && data.id !== '17') {
      const curvePoolContract = useContract(
        'CurvePoolRead',
        provider,
        contracts,
        dispatch,
        true,
        chain.id,
      );

      balance = await curvePoolContract.calc_withdraw_one_coin(
        data.lpBalanceWei,
        getCurveId(item.symbol),
      );
      balance = formatWei(item.address, balance, tokenList);
    } else {
      balance = data.balance / priceData[item.symbol];
    }

    item.balance = balance;

    setSelected({
      ...data,
      ...item,
    });
  };

  const handleSliderComplete = async () => {
    setAmount((selected.balance * sliderValue) / 100);
  };

  const formatValue = (p) => p + '%';

  const calculatePriceImpact = () => {
    if (selected && amount > 0 && data?.reserves) {
      const tokens = Object.keys(data.reserves).map((t) => t);
      const otherToken = tokens[0] === selected.symbol ? tokens[1] : tokens[0];
      const constantProduct =
        +data.reserves[tokens[0]] * data.reserves[tokens[1]];
      const marketPrice =
        +data.reserves[selected.symbol] / data.reserves[otherToken];

      const newReserveOtherToken =
        constantProduct /
        (Number(data.reserves[selected.symbol]) + Number(amount / 2));

      const pricePaid = +data.reserves[selected.symbol] / newReserveOtherToken;

      setPriceImpact(pricePaid / marketPrice - 1);
    }
  };

  const executeWithdrawal = async () => {
    try {
      setPending(true);
      let initialTx: ContractTransaction;

      const _amountLp = toBigNumber(data.lpBalanceWei)
        .mul(toBigNumber(sliderValue))
        .div(toBigNumber(100));

      if (data.type === 'Vault') {
        if (selected.version.isLp) {
          setWithdrawStatus('1/2 Removing liquidity');
        } else {
          setWithdrawStatus('Withdrawing...');
        }

        const firstTx = await actions.handleVaultWithdraw(
          selected,
          _amountLp,
          selected.address,
        );

        setToast({
          open: true,
          type: TypeEnum.Pending,
          title: 'Pending transaction',
          timer: 14000,
          message: (
            <a
              href={`${chain.blockExplorers['default'].url}/tx/${firstTx.hash}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Check on block explorer
            </a>
          ),
        });

        transactions[firstTx.hash] = {
          from: firstTx.from,
          hash: firstTx.hash,
          titleTx: 'Withdraw from eVault',
        };

        setTransactions(transactions);

        const receiptFirstTx = await firstTx.wait();

        reFetch();

        transactions[firstTx.hash].receipt = {
          blockHash: receiptFirstTx.blockHash,
          blockNumber: receiptFirstTx.blockNumber,
          contractAddress: receiptFirstTx.contractAddress,
          from: receiptFirstTx.from,
          status: receiptFirstTx.status,
          to: receiptFirstTx.to,
          transactionHash: receiptFirstTx.transactionHash,
          transactionIndex: receiptFirstTx.transactionIndex,
        };

        setTransactions(transactions);

        if (selected.protocol !== 'Curve' && selected.version.isLp) {
          const abi = [
            'event LogLiquidityRemove(address indexed tokenA, address indexed tokenB, uint256 amountA, uint256 amountB)',
          ];
          const iface = new ethers.utils.Interface(abi);

          let resultFilter = null;

          for (const log of receiptFirstTx.logs) {
            try {
              const parsedLog = iface.parseLog(log);
              if (parsedLog.name === 'LogLiquidityRemove') {
                resultFilter = parsedLog;
              }
            } catch (e) {
              console.error(e);
            }
          }

          const fromAssets = [
            {
              address: resultFilter.args.tokenB,
              amountWei: String(resultFilter.args.amountB),
            },
          ];

          const dest = selected.address;

          setWithdrawStatus('2/2 Swapping to ' + selected.symbol);

          const secondTx = await actions.handleSwap(
            fromAssets,
            dest,
            wallet,
            account,
            chain.id,
          );

          setToast({
            open: true,
            type: TypeEnum.Pending,
            title: 'Pending transaction',
            timer: 14000,
            message: (
              <a
                href={`${chain.blockExplorers['default'].url}/tx/${secondTx.hash}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Check on block explorer
              </a>
            ),
          });

          transactions[secondTx.hash] = {
            from: secondTx.from,
            hash: secondTx.hash,
            titleTx: 'Swap of Assets',
          };

          setTransactions(transactions);

          const receiptSecondTx = await secondTx.wait();

          reFetch();

          transactions[secondTx.hash].receipt = {
            blockHash: receiptSecondTx.blockHash,
            blockNumber: receiptSecondTx.blockNumber,
            contractAddress: receiptSecondTx.contractAddress,
            from: receiptSecondTx.from,
            status: receiptSecondTx.status,
            to: receiptSecondTx.to,
            transactionHash: receiptSecondTx.transactionHash,
            transactionIndex: receiptSecondTx.transactionIndex,
          };

          setTransactions(transactions);
        }

        setToast({
          open: true,
          type: TypeEnum.Success,
          title: 'Success in the withdraw!',
          timer: 14000,
        });
      }

      if (data.type === 'Staking') {
        initialTx = await actions.handleUnstake(_amountLp, selected);

        setToast({
          open: true,
          type: TypeEnum.Pending,
          title: 'Pending transaction',
          timer: 14000,
          message: (
            <a
              href={`${chain.blockExplorers['default'].url}/${initialTx.hash}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Check on block explorer
            </a>
          ),
        });

        transactions[initialTx.hash] = {
          from: initialTx.from,
          hash: initialTx.hash,
          titleTx: 'Withdraw from Staking',
        };

        setTransactions(transactions);

        const receipt = await initialTx.wait();

        transactions[initialTx.hash].receipt = {
          blockHash: receipt.blockHash,
          blockNumber: receipt.blockNumber,
          contractAddress: receipt.contractAddress,
          from: receipt.from,
          status: receipt.status,
          to: receipt.to,
          transactionHash: receipt.transactionHash,
          transactionIndex: receipt.transactionIndex,
        };

        setTransactions(transactions);

        setToast({
          open: true,
          type: TypeEnum.Success,
          title: 'Success in the withdraw!',
          timer: 14000,
        });
      }

      reFetch();
      setPending(false);
      close();
    } catch (error) {
      setPending(false);
      setToast({
        open: true,
        type: TypeEnum.Error,
        title: 'Error in the withdraw...',
        timer: 14000,
        message: error.message,
      });
      console.error(error);
    }
  };

  useEffect(() => {
    calculatePriceImpact();
  }, [amount]);

  useEffect(() => {
    handleSelect(data.deposit[0]);
  }, []);

  return (
    <Modal close={close} closeButton size={'sm'} pending={pending}>
      <div className="heading">
        <span className="flex items-center justify-center">
          <Symbol src={data.earnIcon} alt={data.earnIcon} />
          <span>
            {data.earn + (data.type === 'Staking' ? ' LP' : ' eVault')}
          </span>
        </span>
      </div>
      <div className="swap-modal-box">
        <div className="group">
          <div className="from-wallet-box">
            <div className="from-option-box">
              <AssetSelect
                label={'Estimated Amount'}
                options={options}
                amount={amount}
                selected={selected}
                isSelectable
                showBalance
                handleSelect={handleSelect}
              />
            </div>
          </div>
          <div className="flex-1 fee-info-right" style={{ marginTop: '20px' }}>
            <div className="flex">
              <div className="title5">Price impact</div>
              <Tooltip>
                <TooltipIcon />
                <TooltipContent>
                  {`Price Impact gives you an idea on what slippage to expect
                  based on the size of the order you're placing and current
                  market conditions`}
                </TooltipContent>
              </Tooltip>
            </div>
            <div
              // className={`title4 ${
              // 	priceImpact > 0.02 ? "color-red" : "color-green"
              // }`}
              className="title4"
            >
              {priceImpact < 0.0001 ? '<0.01' : formatRate(priceImpact)}%
            </div>
          </div>

          <div className="slider-box">
            <RangeSlider
              value={sliderValue}
              type={'horizontal'}
              labels={sliderLables}
              format={formatValue}
              handleChange={(val) => setSliderValue(val)}
              handleSliderComplete={handleSliderComplete}
              sliderValue={String(sliderValue)}
              tooltip={false}
            />
          </div>
          <div className="info-modal-box">
            {selected.protocol !== 'Curve' &&
            data.type === 'Vault' &&
            data.version.isLp &&
            amount > 0 ? (
              <span>
                To withdraw you need to remove your liquidity and then swap, a
                process that requires two transactions. The protocol utilizes
                Paraswap routing to minimize the price impact.
              </span>
            ) : (
              ''
            )}
          </div>
          <div className="swap-button-box">
            <Button
              className={pending || amount <= 0 ? 'disabled' : ''}
              handleClick={executeWithdrawal}
              disabled={pending || amount <= 0}
              size={'lg'}
            >
              {pending
                ? selected.protocol !== 'Curve' && data.type === 'Vault'
                  ? withdrawStatus
                  : 'Withdrawing'
                : 'Withdraw'}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default WithdrawLP;
