import React, { useContext, useState } from 'react';
import { useNetwork } from 'wagmi';

import { GlobalContext, UserContext } from '../../../../context';
import { formatDecimals, formatWeiSymbol } from '../../../../context/utils';
import { Button } from '../../../atoms/Button';
import { Modal } from '../../../atoms/Modal';
import { DecimalAssetSelect } from '../../../molecules/AssetsSelect/Decimal';
import { TypeEnum } from '../../Toast';

type Props = {
  close: () => void;
  reFetch: () => void;
  startingAsset: any;
};

const Withdraw: React.FC<Props> = ({ close, startingAsset, reFetch }) => {
  const {
    state: { tokenList, actions },
    setToast,
  } = useContext(GlobalContext);
  const { chain } = useNetwork();
  const {
    state: { balances },
    transactions,
    setTransactions,
  } = useContext(UserContext);

  const [selected, setSelected] = useState<any>(
    balances.wallet.filter((item) => item.symbol === startingAsset)[0],
  );
  const [amount, setAmount] = useState<number | string>('');
  const [amountWei, setAmountWei] = useState<string>('');
  const [pending, setPending] = useState<boolean>(false);
  const [noBalance, setNoBalance] = useState<boolean>(false);

  const handleSelect = (item) => {
    setSelected(item);
  };

  const handleAmount = (amt: string | number) => {
    if (amt === -1) {
      setAmount(selected.balance / 10 ** selected.decimals);
      setAmountWei(selected.balance);
    } else {
      setAmount(amt);
      setAmountWei(formatDecimals(selected.symbol, Number(amt), tokenList));

      if (amt > formatWeiSymbol(selected.symbol, selected.balance, tokenList)) {
        setNoBalance(true);
      } else {
        setNoBalance(false);
      }
    }
  };

  const executeWithdrawal = async () => {
    try {
      setPending(true);
      const initialTx = await actions.handleWithdraw(
        selected.symbol,
        amountWei,
      );

      setToast({
        open: true,
        type: TypeEnum.Pending,
        title: 'Pending transaction',
        timer: 14000,
        message: (
          <a
            href={`${chain.blockExplorers['default'].url}/tx/${initialTx.hash}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Check on block explorer
          </a>
        ),
      });

      transactions[initialTx.hash] = {
        from: initialTx.from,
        hash: initialTx.hash,
        titleTx: `Withdraw Asset ${selected.symbol}`,
      };

      setTransactions(transactions);

      const receiptTx = await initialTx.wait();

      transactions[initialTx.hash].receipt = {
        blockHash: receiptTx.blockHash,
        blockNumber: receiptTx.blockNumber,
        contractAddress: receiptTx.contractAddress,
        from: receiptTx.from,
        status: receiptTx.status,
        to: receiptTx.to,
        transactionHash: receiptTx.transactionHash,
        transactionIndex: receiptTx.transactionIndex,
      };

      setTransactions(transactions);
      reFetch();

      setToast({
        open: true,
        type: TypeEnum.Success,
        title: 'Success in the withdraw!',
        timer: 14000,
      });
      setPending(false);
      close();
    } catch (error) {
      setToast({
        open: true,
        type: TypeEnum.Error,
        title: 'Error in the withdraw...',
        timer: 14000,
        message: error.message,
      });
      console.error(error);
      setPending(false);
    }
  };

  return (
    <Modal close={close} closeButton size={'sm'} pending={pending}>
      <div className="heading text-center">Withdraw</div>
      <div className="swap-modal-box">
        <div className="group">
          <div className="from-wallet-box">
            <div className="from-option-box">
              <DecimalAssetSelect
                label={'Amount'}
                options={balances.wallet}
                amount={amount}
                selected={selected}
                isInput={!pending}
                isSelectable={balances.wallet?.length > 1}
                showBalance
                handleSelect={handleSelect}
                handleAmount={handleAmount}
              />
            </div>
          </div>
          <div className="swap-button-box">
            <Button
              className={
                noBalance || pending || amountWei === '' || amountWei === '0'
                  ? 'disabled'
                  : ''
              }
              handleClick={executeWithdrawal}
              disabled={pending}
              size={'lg'}
            >
              {pending
                ? 'Withdrawing'
                : noBalance
                ? 'Not enough funds'
                : 'Withdraw'}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Withdraw;
