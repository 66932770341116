import React from 'react';

const InvestFromLoading = () => {
  return (
    <>
      <div className="group group-invest-from flex items-start">
        <div className="from-group">
          <div className="radio-button-outer theme-radio undefined">
            <label className="radio-button">
              <span>
                <span
                  className="skeleton inline-block"
                  style={{ width: '80%', height: '20px' }}
                ></span>
                <span
                  className="skeleton inline-block"
                  style={{ width: '25%', height: '20px' }}
                ></span>
              </span>{' '}
            </label>
          </div>
        </div>
        <div className="input-arrow">
          <i className="arrow-right"></i>
        </div>
      </div>
      {/* INVEST FROM METAMASK WALLET BALANCE */}
      <div className="group group-invest-from flex items-start">
        <div className="from-group">
          <div className="radio-button-outer theme-radio undefined">
            <label className="radio-button">
              <span>
                <span
                  className="skeleton inline-block"
                  style={{ width: '80%', height: '20px' }}
                ></span>
                <span
                  className="skeleton inline-block"
                  style={{ width: '25%', height: '20px' }}
                ></span>
              </span>{' '}
            </label>
          </div>
        </div>
        <div className="input-arrow">
          <i className="arrow-right"></i>
        </div>
      </div>
    </>
  );
};

export default InvestFromLoading;
