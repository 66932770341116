import React, { useState } from 'react';

function ActivitiesListing() {
  const [data] = useState([1, 2, 3, 4]);
  return (
    <>
      {data.map((item, i) => {
        return (
          <div
            className="table-row list-card activity-list-row activity-skeleton-row"
            key={i}
          >
            <div className="table-col col-1 list-col-1">
              <div className="asset-info">
                <span className="asset-symbol skeleton"></span>
                <span className="asset-title flex-auto">
                  <span className="skeleton-text-area skeleton">&nbsp;</span>
                </span>
              </div>
            </div>
            <div className="table-col col-2 list-col-2">
              <div className="flex items-center">
                <span>
                  <span className="skeleton-text-area skeleton">
                    {' '}
                    &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </span>
              </div>
            </div>

            <div
              className="table-col col-3 list-col-3"
              style={{ marginLeft: '15px' }}
            >
              <div className="flex items-center">
                <span className="text-light text-light-flat">
                  <span className="skeleton-text-area skeleton">
                    {' '}
                    &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </span>
              </div>
            </div>
            <div className="table-col col-4 list-col-4">
              <div className="flex items-center">
                <div className="button etherscan skeleton">
                  <span className="hidden-text">Check on Polygonscan</span>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}
export default ActivitiesListing;
