import './index.scss';

import PropTypes from 'prop-types';
import React from 'react';
import Pagination from 'react-js-pagination';

type Props = {
  slice: number;
  totalItems: number;
  currentPage: number;
  setOffset: (offset: number) => void;
  setCurrentPage: (page: number) => void;
  resultQuery: unknown[];
};

const Pager: React.FC<Props> = ({
  slice,
  totalItems,
  currentPage,
  setOffset,
  setCurrentPage,
  resultQuery,
}) => {
  const width = document.documentElement.clientWidth;
  const handlePageChange = (e) => {
    setOffset((e - 1) * slice);
    setCurrentPage(e);
  };

  return (
    <div className="text-center">
      {resultQuery.length <= 10 ? null : (
        <Pagination
          activePage={currentPage}
          onChange={handlePageChange}
          itemsCountPerPage={slice}
          totalItemsCount={totalItems}
          pageRangeDisplayed={width < 330 ? 3 : 5}
          prevPageText="Prev"
          nextPageText="Next"
          firstPageText="<<"
          lastPageText=">>"
        />
      )}
    </div>
  );
};

Pager.propTypes = {
  slice: PropTypes.number,
  totalItems: PropTypes.number,
  currentPage: PropTypes.number,
  setOffset: PropTypes.func,
  setCurrentPage: PropTypes.func,
  resultQuery: PropTypes.arrayOf(PropTypes.object),
};

Pager.defaultProps = {
  slice: 8,
  totalItems: 0,
  currentPage: 1,
  setOffset: () => null,
  setCurrentPage: () => null,
  resultQuery: [],
};

export default Pager;
