import { SVGProps } from 'react';

const IconCookie = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={58}
    height={57}
    viewBox="0 -20 70 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#a)">
      <path
        d="M51.713 38.776c-4.142 4.531-10.435 7.772-17.383 8.514-14.016 1.497-26.488-7.444-27.855-19.969C5.108 14.796 15.363 3.43 29.38 1.933c2.3-.246 4.557-.21 6.731.076"
        stroke="url(#b)"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <g filter="url(#c)">
      <path
        d="M44.851 32.555c-2.042.876-4.764.448-5.601-1.462-.838-1.91.14-4.17 2.181-5.045"
        stroke="url(#d)"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <g filter="url(#e)">
      <path
        d="M41.42 26.05c-3.843.834-7.789-.935-9.346-4.432-1.614-3.622-.113-7.864 3.355-10.047"
        stroke="url(#f)"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <g filter="url(#g)">
      <path
        d="M45.167 32.316c-.07 1.56.962 3.29 1.904 4.303 1.248 1.344 2.528 2.257 4.786 1.952"
        stroke="url(#h)"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <g filter="url(#i)">
      <path
        d="M36.218 2.022a4.702 4.702 0 0 0-1.758.379c-2.427 1.038-3.519 3.88-2.44 6.35.667 1.523 1.912 2.36 3.409 2.678"
        stroke="url(#j)"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <path
      d="M25.554 14.458c0 .582-.481 1.073-1.097 1.073a1.085 1.085 0 0 1-1.097-1.073c0-.583.48-1.074 1.097-1.074.616 0 1.097.491 1.097 1.074Z"
      stroke="url(#k)"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.366 41.604c0 .583-.481 1.074-1.097 1.074a1.085 1.085 0 0 1-1.097-1.074c0-.582.48-1.074 1.097-1.074.616 0 1.097.492 1.097 1.074Z"
      stroke="url(#l)"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.415 30.67c0 1-.822 1.828-1.859 1.828s-1.859-.829-1.859-1.828c0-.999.822-1.828 1.859-1.828s1.859.83 1.859 1.828Z"
      stroke="url(#m)"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <mask id="o" fill="#fff">
      <ellipse cx={15.689} cy={28.447} rx={1.715} ry={1.697} />
    </mask>
    <path
      d="M15.405 28.447c0-.188.148-.303.284-.303v4c2.032 0 3.716-1.634 3.716-3.697h-4Zm.284-.303c.137 0 .285.115.285.303h-4c0 2.063 1.684 3.697 3.715 3.697v-4Zm.285.303a.294.294 0 0 1-.285.304v-4c-2.03 0-3.715 1.634-3.715 3.696h4Zm-.285.304a.294.294 0 0 1-.284-.304h4c0-2.062-1.684-3.696-3.716-3.696v4Z"
      fill="url(#n)"
      mask="url(#o)"
    />
    <path
      d="M53 30.105c0 .831-.755 1.639-1.859 1.639-1.103 0-1.859-.808-1.859-1.64 0-.83.756-1.639 1.859-1.639 1.104 0 1.859.808 1.859 1.64Z"
      stroke="url(#p)"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M50.072 11.786c-.715-.95-.922-1.429-2.5-1.429a2.867 2.867 0 0 0-2.858 2.857c0 1.434 1.072 1.072 1.786 2.143"
      stroke="url(#q)"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M50.379 11.786c2 0 3.621 2.286 3.621 4.368 0 2.083-1.621 3.77-3.621 3.77s-3.622-1.687-3.622-3.77c0 0-.047-.133 0-.377"
      stroke="url(#r)"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="b"
        x1={25.74}
        y1={2.321}
        x2={30.584}
        y2={47.69}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ffffff" />
        <stop offset={1} stopColor="#ffffff" />
      </linearGradient>
      <linearGradient
        id="d"
        x1={39.821}
        y1={26.738}
        x2={42.709}
        y2={33.474}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ffffff" />
        <stop offset={1} stopColor="#ffffff" />
      </linearGradient>
      <linearGradient
        id="f"
        x1={32.151}
        y1={13}
        x2={38.408}
        y2={27.363}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ffffff" />
        <stop offset={1} stopColor="#ffffff" />
      </linearGradient>
      <linearGradient
        id="h"
        x1={44.837}
        y1={32.615}
        x2={50.977}
        y2={39.371}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ffffff" />
        <stop offset={1} stopColor="#ffffff" />
      </linearGradient>
      <linearGradient
        id="j"
        x1={33.074}
        y1={2.994}
        x2={36.488}
        y2={10.976}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ffffff" />
        <stop offset={1} stopColor="#ffffff" />
      </linearGradient>
      <linearGradient
        id="k"
        x1={24.457}
        y1={12.384}
        x2={24.457}
        y2={16.531}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ffffff" />
        <stop offset={1} stopColor="#ffffff" />
      </linearGradient>
      <linearGradient
        id="l"
        x1={28.269}
        y1={39.53}
        x2={28.269}
        y2={43.678}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ffffff" />
        <stop offset={1} stopColor="#ffffff" />
      </linearGradient>
      <linearGradient
        id="m"
        x1={30.556}
        y1={27.842}
        x2={30.556}
        y2={33.498}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ffffff" />
        <stop offset={1} stopColor="#ffffff" />
      </linearGradient>
      <linearGradient
        id="n"
        x1={15.689}
        y1={26.751}
        x2={15.689}
        y2={30.144}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ffffff" />
        <stop offset={1} stopColor="#ffffff" />
      </linearGradient>
      <linearGradient
        id="p"
        x1={51.141}
        y1={27.465}
        x2={51.141}
        y2={32.744}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ffffff" />
        <stop offset={1} stopColor="#ffffff" />
      </linearGradient>
      <linearGradient
        id="q"
        x1={47.393}
        y1={10.357}
        x2={47.393}
        y2={15.357}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ffffff" />
        <stop offset={1} stopColor="#ffffff" />
      </linearGradient>
      <linearGradient
        id="r"
        x1={50.368}
        y1={11.786}
        x2={50.368}
        y2={19.925}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ffffff" />
        <stop offset={1} stopColor="#ffffff" />
      </linearGradient>
      <filter
        id="a"
        x={0.852}
        y={0.269}
        width={56.362}
        height={56.683}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_5_48" />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_5_48"
          result="shape"
        />
      </filter>
      <filter
        id="c"
        x={33.442}
        y={24.552}
        width={16.914}
        height={17.934}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_5_48" />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_5_48"
          result="shape"
        />
      </filter>
      <filter
        id="e"
        x={25.918}
        y={10.076}
        width={21.006}
        height={25.673}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_5_48" />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_5_48"
          result="shape"
        />
      </filter>
      <filter
        id="g"
        x={39.656}
        y={30.824}
        width={17.71}
        height={17.298}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_5_48" />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_5_48"
          result="shape"
        />
      </filter>
      <filter
        id="i"
        x={26.101}
        y={0.526}
        width={15.622}
        height={20.399}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_5_48" />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_5_48"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default IconCookie;
