import { Contract } from 'ethers';

import { loadContract } from '../context/utils';
import { ChainIdType } from '../interfaces';

export function useContract(
  contractName: string,
  provider,
  contracts: { [key: string]: Contract },
  dispatch: React.Dispatch<{ type: string; payload: any }>,
  network = false,
  chainId: ChainIdType,
): Contract {
  const nameOfContract = network ? `${contractName}Network` : contractName;

  let contractInstance = contracts[nameOfContract];

  if (contractInstance !== undefined) return contractInstance;

  // Dispatch the contract to the global state
  contractInstance = loadContract(contractName, chainId, provider);

  if (provider !== undefined)
    dispatch({
      type: 'SET_CONTRACTS',
      payload: { ...contracts, [nameOfContract]: contractInstance },
    });

  return contractInstance;
}
