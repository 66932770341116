import React, { useContext } from 'react';

import { GlobalContext } from '../../../../context';
import { Button } from '../../../atoms/Button';
import { Modal } from '../../../atoms/Modal';

type Props = {
  close: () => void;
  endTime?: string;
  setPending?: (value: boolean) => void;
  pending?: boolean;
  vaultInvestments: any;
  unusedAssets: any;
};

const ZapperWithdraw: React.FC<Props> = ({
  close,
  // endTime,
  // setPending,
  pending,
  vaultInvestments,
  unusedAssets,
}) => {
  const {
    state: { actions },
  } = useContext(GlobalContext);

  const handleWithdrawAll = async () => {
    try {
      //setPending(true);
      const tx = await actions.handleWithdrawAll(
        vaultInvestments,
        unusedAssets,
      );
      await tx.wait();
      //setPending(false);
    } catch (error) {
      //setPending(false);
      console.error(error);
    }
  };

  return (
    <Modal close={close} closeButton size={'sm'} pending={pending}>
      <div className="flex justify-center">
        <p className="" style={{ color: '#A16AE8', fontSize: '16px' }}>
          {' '}
          Protocol Usage Migration
        </p>
      </div>
      <p></p>
      <div className="pb-5 text-center">
        <span>
          Important: To make the UX more seamless, we are migrating ETHA
          Lend&apos;s usage from the smart contract wallet to the Web3 wallet.
          Please withdraw your funds from the smart contract wallet to your Web3
          wallet. To make the migration process easy, we have implemented the
          Withdraw All button below.
        </span>
      </div>
      <div className="swap-button-box">
        <Button
          handleClick={() => {
            handleWithdrawAll();
            close();
          }}
          size={'lg'}
        >
          Withdraw All
        </Button>
      </div>
    </Modal>
  );
};

export default ZapperWithdraw;
