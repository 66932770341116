import { useCallback, useContext, useEffect, useState } from 'react';

import { api } from '../axios';
import { GlobalContext } from '../context';
import { nativeTokenByNetwork } from '../context/constants';
import { getTokenIcon, getTokenInfo } from '../context/utils';
import { ChainIdType, GlobalLoading, RefetchState } from '../interfaces';

const isProd = process.env.REACT_APP_ENV === 'prod';

export function useGlobalData(chainId: ChainIdType): [GlobalLoading, any] {
  const { dispatch } = useContext(GlobalContext);

  const [loader, setLoader] = useState<boolean>(true);
  const [globalData, setGlobalData] = useState<any>({});
  const [loadingTokenListData, setLoadingTokenListData] =
    useState<RefetchState>({
      isLoading: true,
      error: null,
      refetch: null,
    });
  const [loadingVaultData, setLoadingVaultData] = useState<RefetchState>({
    isLoading: true,
    error: null,
    refetch: null,
  });
  const [loadingStakeData, setLoadingStakeData] = useState<RefetchState>({
    isLoading: true,
    error: null,
    refetch: null,
  });
  const [loadingPriceData, setLoadingPriceData] = useState<RefetchState>({
    isLoading: true,
    error: null,
    refetch: null,
  });
  const [loadingLendingData, setLoadingLendingData] = useState<RefetchState>({
    isLoading: true,
    error: null,
    refetch: null,
  });
  const [loadingBorrowingData, setLoadingBorrowingData] =
    useState<RefetchState>({
      isLoading: true,
      error: null,
      refetch: null,
    });

  const fetchGlobalData = useCallback(async () => {
    setLoadingTokenListData((pastLoadingTokenListData) => ({
      ...pastLoadingTokenListData,
      isLoading: true,
    }));

    let tokenList = [];
    let customAssets = [];

    try {
      const parsedArr = JSON.parse(localStorage.getItem('custom_assets'));
      if (parsedArr) customAssets = parsedArr;
    } catch (error) {
      console.error(error);
    }

    try {
      const response = await api.get('/strategy/tokenList');

      tokenList = response.data.data[chainId];

      for (const asset of customAssets) {
        if (asset.chainId === chainId) tokenList.push(asset);
      }

      tokenList.push({
        decimals: 18,
        isStablecoin: false,
        logoURI: 'https://dev.ethalend.com/assets/recycle.png',
        name: 'Compounded',
        symbol: 'COMPOUNDED',
        address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
      });

      setGlobalData((currentState) => ({ ...currentState, tokenList }));
      dispatch({ type: 'SET_TOKEN_LIST', payload: tokenList });
      setLoadingTokenListData((pastLoadingTokenListData) => ({
        ...pastLoadingTokenListData,
        isLoading: false,
      }));
    } catch (error) {
      const errorToShow = new Error('Error fetching the token list');
      setLoadingTokenListData((pastLoadingTokenListData) => ({
        ...pastLoadingTokenListData,
        error: errorToShow,
      }));
    }

    setLoadingPriceData((pastLoadingPriceData) => ({
      ...pastLoadingPriceData,
      isLoading: true,
    }));

    let priceData;

    try {
      const {
        data: { data },
      } = await api.get('/strategy/prices');

      const newPriceData = {};

      tokenList.forEach((item) => {
        const dataToken = data.find((token) => token.id === item.id);

        if (dataToken !== undefined && item.symbol !== 'MATIC') {
          newPriceData[item.symbol] = dataToken.price;
        }
      });

      priceData = newPriceData;

      setGlobalData((currentState) => ({ ...currentState, priceData }));
      dispatch({ type: 'SET_PRICE_DATA', payload: priceData });
      setLoadingPriceData((pastLoadingPriceData) => ({
        ...pastLoadingPriceData,
        isLoading: false,
      }));
    } catch (error) {
      const errorToShow = new Error('Error fetching the price data');
      setLoadingPriceData((pastLoadingPriceData) => ({
        ...pastLoadingPriceData,
        error: errorToShow,
      }));
    }

    setLoadingVaultData((pastLoadingVaultData) => ({
      ...pastLoadingVaultData,
      isLoading: true,
    }));

    let vaults = [];

    try {
      const response = await api.get('/vaults/vaultInfo');
      vaults = response.data.data.filter(
        (vault) => Number(vault.chainId) === chainId,
      );

      for (let i = 0; i < vaults.length; i++) {
        const { rewardApy, feeApy, performanceFee, withdrawalFee } = vaults[i];

        vaults[i].performanceFee = Number(performanceFee) / 10000;
        vaults[i].fee = Number(withdrawalFee) / 10000;
        vaults[i].fapy = feeApy;
        vaults[i].rapy = rewardApy;
        vaults[i].rapy = vaults[i].rapy * (1 - vaults[i].performanceFee);
      }

      const filterVaults = vaults.filter((vault) =>
        isProd
          ? vault.version.enabled && vault.version.prod
          : vault.version.enabled,
      );

      setGlobalData((currentState) => ({
        ...currentState,
        vaultData: filterVaults,
      }));
      dispatch({
        type: 'SET_VAULT_DATA',
        payload: filterVaults,
      });
      setLoadingVaultData((pastLoadingVaultData) => ({
        ...pastLoadingVaultData,
        isLoading: false,
      }));
    } catch (error) {
      const errorToShow = new Error('Error fetching the vault data');
      setLoadingVaultData((pastLoadingVaultData) => ({
        ...pastLoadingVaultData,
        error: errorToShow,
      }));
    }

    setLoadingStakeData((pastLoadingStakeData) => ({
      ...pastLoadingStakeData,
      isLoading: true,
    }));

    let stakesInfo;

    try {
      const response = await api.get('/stakingInfo');
      stakesInfo = response.data.data;
      setGlobalData((currentState) => ({
        ...currentState,
        stakingData: stakesInfo,
      }));
      dispatch({ type: 'SET_STAKING_DATA', payload: stakesInfo });
      setLoadingStakeData((pastLoadingStakeData) => ({
        ...pastLoadingStakeData,
        isLoading: false,
      }));
    } catch (error) {
      const errorToShow = new Error('Error fetching the stake data');
      setLoadingStakeData((pastLoadingStakeData) => ({
        ...pastLoadingStakeData,
        error: errorToShow,
      }));
    }

    setLoadingLendingData((pastLoadingLendingData) => ({
      ...pastLoadingLendingData,
      isLoading: true,
    }));

    let tokensData;
    const lendingData = [];

    try {
      const response = await api.get('/strategy/protocolData');
      tokensData = response.data.data[0].data;

      Object.keys(tokensData).forEach((symbol: string) => {
        const realSymbol =
          symbol === nativeTokenByNetwork[chainId].symbol
            ? nativeTokenByNetwork[chainId].wrappedSymbol
            : symbol;

        tokensData[symbol].aggregate.liquidityUsd =
          priceData[realSymbol] *
          (tokensData[symbol].creamData.liquidity +
            tokensData[symbol].aaveData.liquidity);

        lendingData.push({
          ...tokensData[symbol],
          symbol,
        });
      });

      setGlobalData((currentState) => ({
        ...currentState,
        tokensData: lendingData,
      }));
      dispatch({ type: 'SET_TOKENS_DATA', payload: lendingData });
      setLoadingLendingData((pastLoadingLendingData) => ({
        ...pastLoadingLendingData,
        isLoading: false,
      }));
    } catch (error) {
      const errorToShow = new Error('Error fetching the lending data');
      setLoadingLendingData((pastLoadingLendingData) => ({
        ...pastLoadingLendingData,
        error: errorToShow,
      }));
    }

    setLoadingBorrowingData((pastLoadingBorrowingData) => ({
      ...pastLoadingBorrowingData,
      isLoading: true,
    }));

    let borrowingInfo = {};

    try {
      const {
        data: { data },
      } = await api.get('/strategy/protocolData');
      const protocolsData = data[0].data;

      const response = [];

      Object.keys(protocolsData).forEach((key) => {
        response.push({
          ...protocolsData[key],
          symbol: key,
          icon: getTokenIcon(key, tokenList),
          token: getTokenInfo(key, tokenList).address,
        });
      });

      borrowingInfo = response;

      setGlobalData((currentState) => ({
        ...currentState,
        borrowData: borrowingInfo,
      }));
      dispatch({ type: 'SET_BORROW_DATA', payload: borrowingInfo });
      setLoadingBorrowingData((pastLoadingBorrowingData) => ({
        ...pastLoadingBorrowingData,
        isLoading: false,
      }));
    } catch (error) {
      const errorToShow = new Error('Error fetching the borrowing data');
      setLoadingBorrowingData((pastLoadingBorrowingData) => ({
        ...pastLoadingBorrowingData,
        error: errorToShow,
      }));
    }
  }, [chainId]);

  useEffect(() => {
    setLoader(true);
    fetchGlobalData().then(() => setLoader(false));
  }, [chainId]);

  return [
    {
      loadingBorrowingData,
      loadingLendingData,
      loadingPriceData,
      loadingStakeData,
      loadingVaultData,
      loadingTokenListData,
      loader,
    },
    globalData,
  ];
}
