// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_radioContent__35zRl {\n  display: flex;\n  align-items: center;\n}\n\n.styles_radioWrapper__2RXee {\n  display: flex;\n  justify-content: center;\n  grid-gap: 20px;\n}\n\n.styles_radioWrapper__2RXee label span {\n  font-size: 16px !important;\n}", "",{"version":3,"sources":["webpack://src/components/molecules/RadioSelector/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,cAAA;AACF;;AAEA;EACE,0BAAA;AACF","sourcesContent":[".radioContent {\n  display: flex;\n  align-items: center;\n}\n\n.radioWrapper {\n  display: flex;\n  justify-content: center;\n  grid-gap: 20px;\n}\n\n.radioWrapper label span {\n  font-size: 16px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radioContent": "styles_radioContent__35zRl",
	"radioWrapper": "styles_radioWrapper__2RXee"
};
export default ___CSS_LOADER_EXPORT___;
