import React, { useContext, useEffect, useState } from 'react';
import { useNetwork } from 'wagmi';

import { DecimalAssetSelect } from '../../../../components/molecules/AssetsSelect/Decimal';
import { GlobalContext, UserContext } from '../../../../context';
import {
  formatDecimals,
  formatRate,
  formatWei,
  getTokenDecimals,
} from '../../../../context/utils';
import { Button } from '../../../atoms/Button';
import { Modal } from '../../../atoms/Modal';
import { Tooltip, TooltipContent, TooltipIcon } from '../../../atoms/Tooltip';
import { TypeEnum } from '../../Toast';

type Props = {
  close: () => void;
  reFetch?: () => void;
  data: any;
};

const WithdrawInvest: React.FC<Props> = ({ close, reFetch, data }) => {
  const {
    state: { tokensData, priceData, tokenList, actions },
    setToast,
  } = useContext(GlobalContext);
  const { chain } = useNetwork();
  const { transactions, setTransactions } = useContext(UserContext);

  const [selected, setSelected] = useState<any>({
    address: '',
    balance: '',
    symbol: '',
  });
  const [amount, setAmount] = useState<number>(0);
  const [amountWei, setAmountWei] = useState<string>('');
  const [pending, setPending] = useState<boolean>(false);
  const [options, setOptions] = useState<any[]>([]);
  const [noBalance, setNoBalance] = useState<boolean>(false);
  const [priceImpact, setPriceImpact] = useState<number>(0);

  const handleSelect = async (item) => {
    setAmount(0);
    if (data.type === 'Staking') {
      item.balance =
        (data.balance / priceData[item.symbol]) * 10 ** item.decimals;
    }

    setSelected({
      ...data,
      ...item,
    });
  };

  const handleAmount = (amt) => {
    if (amt === -1) {
      setAmount(formatWei(selected.address, selected.balance, tokenList));
      setAmountWei(selected.balance);
    } else {
      setAmountWei(formatDecimals(selected.symbol, amt, tokenList));
      setAmount(amt);

      if (amt > formatWei(selected.address, selected.balance, tokenList))
        setNoBalance(true);
      else setNoBalance(false);
    }
  };

  const executeWithdrawal = async () => {
    if (typeof tokensData === 'object') {
      try {
        setPending(true);
        let initialTx;

        if (selected.type === 'Lending') {
          initialTx = await actions.handleRedeem(
            amountWei,
            selected,
            tokensData,
          );

          setToast({
            open: true,
            type: TypeEnum.Pending,
            title: 'Pending transaction',
            timer: 14000,
            message: (
              <a
                href={`${chain.blockExplorers['default'].url}/tx/${initialTx.hash}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Check on block explorer
              </a>
            ),
          });

          transactions[initialTx.hash] = {
            from: initialTx.from,
            hash: initialTx.hash,
            titleTx: 'Withdraw from Lending',
          };

          setTransactions(transactions);

          const receiptTx = await initialTx.wait();

          transactions[initialTx.hash].receipt = {
            blockHash: receiptTx.blockHash,
            blockNumber: receiptTx.blockNumber,
            contractAddress: receiptTx.contractAddress,
            from: receiptTx.from,
            status: receiptTx.status,
            to: receiptTx.to,
            transactionHash: receiptTx.transactionHash,
            transactionIndex: receiptTx.transactionIndex,
          };

          setTransactions(transactions);
        }

        if (data.type === 'Staking') {
          initialTx = await actions.handleUnstake(amountWei, selected);

          setToast({
            open: true,
            type: TypeEnum.Pending,
            title: 'Pending transaction',
            timer: 14000,
            message: (
              <a
                href={`${chain.blockExplorers['default'].url}/tx/${initialTx.hash}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Check on block explorer
              </a>
            ),
          });

          transactions[initialTx.hash] = {
            from: initialTx.from,
            hash: initialTx.hash,
            titleTx: 'Withdraw from Staking',
          };

          setTransactions(transactions);

          const receiptTx = await initialTx.wait();

          transactions[initialTx.hash].receipt = {
            blockHash: receiptTx.blockHash,
            blockNumber: receiptTx.blockNumber,
            contractAddress: receiptTx.contractAddress,
            from: receiptTx.from,
            status: receiptTx.status,
            to: receiptTx.to,
            transactionHash: receiptTx.transactionHash,
            transactionIndex: receiptTx.transactionIndex,
          };

          setTransactions(transactions);
          reFetch();
        }

        setToast({
          open: true,
          type: TypeEnum.Success,
          title: 'Success in the withdraw!',
          timer: 14000,
        });
        setPending(false);
        close();
      } catch (error) {
        setPending(false);
        setToast({
          open: true,
          type: TypeEnum.Error,
          title: 'Error in the withdraw...',
          timer: 14000,
          message: error.message,
        });
        console.error(error.message);
      }
    }
  };

  const calculatePriceImpact = () => {
    if (selected && amount > 0 && data?.reserves) {
      const tokens = Object.keys(data.reserves).map((t) => t);
      const otherToken = tokens[0] === selected.symbol ? tokens[1] : tokens[0];
      const constantProduct =
        +data.reserves[tokens[0]] * data.reserves[tokens[1]];
      const marketPrice =
        +data.reserves[selected.symbol] / data.reserves[otherToken];

      const newReserveOtherToken =
        constantProduct /
        (Number(data.reserves[selected.symbol]) + Number(amount / 2));

      const pricePaid = +data.reserves[selected.symbol] / newReserveOtherToken;

      setPriceImpact(pricePaid / marketPrice - 1);
    }
  };

  useEffect(() => {
    calculatePriceImpact();
  }, [amount]);

  useEffect(() => {
    if (data.type === 'Lending') {
      setSelected({
        ...data,
        balance: String(data.savingsBN),
        decimals: getTokenDecimals(data.symbol, tokenList),
        address: data.token,
      });
    }

    if (data.type === 'Staking') {
      const decimals = getTokenDecimals(data.deposit[0].symbol, tokenList);

      setSelected({
        ...data,
        symbol: data.deposit[0].symbol,
        address: data.deposit[0].address,
        icon: data.deposit[0].icon,
        savings: data.balance,
        balance:
          (data.balance / priceData[data.deposit[0].symbol]) * 10 ** decimals,
        decimals,
      });
      setOptions(data.deposit);
    }
  }, []);

  return (
    <Modal close={close} closeButton size={'sm'} pending={pending}>
      <div className="heading text-center">{data.type}</div>
      <div className="swap-modal-box">
        <div className="group">
          <div className="from-wallet-box">
            <div className="from-option-box">
              <DecimalAssetSelect
                label={'Amount'}
                options={options}
                amount={amount}
                selected={selected}
                isInput
                isSelectable={data.type === 'Staking'}
                showBalance
                handleSelect={handleSelect}
                handleAmount={handleAmount}
              />
            </div>
          </div>
          <div className="flex-1 fee-info-right" style={{ marginTop: '20px' }}>
            <div className="flex">
              <div className="title5">Price impact</div>
              <Tooltip>
                <TooltipIcon />
                <TooltipContent>
                  {`Price Impact gives you an idea on what slippage to expect
                  based on the size of the order you're placing and current
                  market conditions`}
                </TooltipContent>
              </Tooltip>
            </div>
            <div className="title4">
              {priceImpact < 0.0001 ? '<0.01' : formatRate(priceImpact)}%
            </div>
          </div>
          <div className="swap-button-box">
            <Button
              className={noBalance || pending || amount <= 0 ? 'disabled' : ''}
              handleClick={executeWithdrawal}
              disabled={pending || amount <= 0}
              size={'lg'}
            >
              {pending
                ? 'Withdrawing'
                : noBalance
                ? 'Not enough funds'
                : 'Withdraw'}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default WithdrawInvest;
