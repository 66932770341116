import React, { useEffect, useState } from 'react';

import { formatNumber } from '../../../../context/utils';
import { Button } from '../../../atoms/Button';
import { Symbol } from '../../../atoms/Symbol';

type Props = {
  label?: string;
  options?: any;
  isInput: boolean;
  isSelectable?: boolean;
  amount: number | string;
  selected: any;
  handleAmount: (number) => void;
  handleSelect?: (item) => void;
  showBalance: boolean;
};

export const DecimalAssetSelect: React.FC<Props> = ({
  label,
  options,
  isInput,
  isSelectable,
  amount,
  selected,
  handleAmount,
  handleSelect,
  showBalance,
}) => {
  const [optionsActive, setOptionsActive] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const handleOptionsActive = () => {
    setOptionsActive((prev) => !prev);
  };

  const handleSelectOption = (item) => {
    handleSelect(item);
    setOptionsActive(false);
  };

  const handleMaxAmount = () => {
    handleAmount(-1);
  };

  const formatBalance = () => {
    if (!isNaN(selected.balance)) {
      return (
        formatNumber(selected.balance / 10 ** selected.decimals) +
        ' ' +
        selected.symbol
      );
    }
    return formatNumber(0) + ' ' + selected.symbol;
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <div className="input-outer flex-1">
      <label className="label">{label}</label>
      {loading ? (
        <div className="input-box flex">
          <div className="input">
            <span className="asset-symbol skeleton"></span>
            <div className="input-style skeleton"></div>
          </div>
        </div>
      ) : (
        <div className="input-box flex">
          {isSelectable && (
            <div className="asset-select">
              {selected.symbol && (
                <div className="asset-label" onClick={handleOptionsActive}>
                  <Symbol
                    src={selected?.icon}
                    alt={selected?.symbol}
                    size={'md2'}
                  />
                </div>
              )}
              {optionsActive && (
                <>
                  <ul className="select-options">
                    <div className="select-asset-outer">
                      {options.map((item, i) => {
                        return (
                          <li
                            className={
                              selected.symbol === item.symbol ? 'selected' : ''
                            }
                            key={i}
                          >
                            <div
                              className="select-option flex items-center"
                              onClick={() => handleSelectOption(item)}
                            >
                              <Symbol
                                src={item.icon}
                                alt={item.symbol}
                                size={'sm2'}
                              />
                              <span className="asset-title">{item.symbol}</span>
                            </div>
                          </li>
                        );
                      })}
                    </div>
                  </ul>
                  <div
                    className="page-overlay"
                    onClick={handleOptionsActive}
                  ></div>
                </>
              )}
            </div>
          )}
          <div className="input">
            {!isSelectable && (
              <Symbol
                src={selected?.icon}
                alt={selected?.symbol}
                size={'md2'}
              />
            )}
            {isInput && (
              <Button
                className={'btn-max default'}
                size={'xs'}
                handleClick={handleMaxAmount}
              >
                MAX
              </Button>
            )}
            <input
              type="number"
              className="input-style"
              value={amount}
              onChange={(e) => {
                if (isInput) handleAmount(e.target.value);
              }}
              placeholder="0.00"
              readOnly={!isInput}
            />
          </div>
        </div>
      )}

      {showBalance && selected.balance && selected.symbol && (
        <div className="balance-text text-sm">Available: {formatBalance()}</div>
      )}
    </div>
  );
};
