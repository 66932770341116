import React, { useContext } from 'react';

import { GlobalContext } from '../../../../context';
import { Button } from '../../../atoms/Button';
import { Modal } from '../../../atoms/Modal';
import { Tooltip, TooltipContent, TooltipIcon } from '../../../atoms/Tooltip';
// COMPONENTS

type Props = {
  close: () => void;
  endTime: string;
  setPending: (value: boolean) => void;
  pending: boolean;
  penalty: number;
};

const WarningWithdraw: React.FC<Props> = ({
  close,
  endTime,
  setPending,
  pending,
  penalty,
}) => {
  const {
    state: { actions },
  } = useContext(GlobalContext);

  const handleTx = async () => {
    try {
      setPending(true);
      const tx = await actions.handleLockWithdraw(endTime);
      await tx.wait();
      setPending(false);
    } catch (error) {
      setPending(false);
      console.error(error);
    }
  };

  return (
    <Modal close={close} closeButton size={'sm'} pending={pending}>
      <div className="flex justify-center">
        <Tooltip>
          <TooltipIcon />
          <TooltipContent></TooltipContent>
        </Tooltip>
      </div>
      <p></p>
      <div className="pb-5 text-center">
        <span>
          If you proceed, the early withdrawal penalty will be {penalty}% of the
          total amount locked
        </span>
      </div>
      <div className="swap-button-box">
        <Button
          handleClick={() => {
            handleTx();
            close();
          }}
          size={'lg'}
        >
          Continue
        </Button>
      </div>
    </Modal>
  );
};

export default WarningWithdraw;
