import React from 'react';

const StrategyLoading = () => {
  return (
    <div className="card-new undefined style-3">
      <div className="card-new-body">
        <div className="strategy-card-box">
          <div className="title1 skeleton" style={{ width: '20%' }}>
            &nbsp;
          </div>
          <p className="text-md">
            <span
              className="skeleton inline-block"
              style={{ width: '100%', height: '15px' }}
            >
              &nbsp;
            </span>
            <span
              className="skeleton inline-block"
              style={{ width: '40%', height: '15px' }}
            >
              &nbsp;
            </span>
          </p>
          <div className="asset-supply-box flex">
            <div className="strategy-asset-box">
              {/* <SymbolGroup data={investment.deposit} size={'lg'} />
            <Symbol
              src={investment.earnIcon}
              alt={investment.earn}
              size={'lg'}
            /> */}
            </div>
            <div className="flex-auto">
              <div className="content-box flex">
                <div className="content-left">
                  <div className="title4">
                    <span
                      className="skeleton inline-block"
                      style={{ width: '70%' }}
                    >
                      &nbsp;
                    </span>
                  </div>
                </div>
                <div className="content-right flex-auto">
                  <p>
                    <span
                      className="skeleton inline-block"
                      style={{ width: '70%' }}
                    >
                      &nbsp;
                    </span>
                  </p>
                </div>
              </div>
              <div className="content-box flex">
                <div className="content-left">
                  <div className="title4">
                    <span
                      className="skeleton inline-block"
                      style={{ width: '70%' }}
                    >
                      &nbsp;
                    </span>
                  </div>
                </div>
                <div className="content-right flex-auto">
                  <p>
                    <span
                      className="skeleton inline-block"
                      style={{ width: '70%' }}
                    >
                      &nbsp;
                    </span>{' '}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default StrategyLoading;
