import { ReactNode } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

export const CustomScrollbar = ({
  children,
  theme,
  isScrollable,
  height,
}: {
  children?: ReactNode;
  theme?: string;
  isScrollable?: boolean;
  height?: string;
}) => {
  const scrollHeight = height;
  return (
    <div
      className={`custom-scrollbar-box theme-${theme} ${
        isScrollable ? 'active' : 'inactive'
      }`}
      style={{ height: scrollHeight }}
    >
      <Scrollbars style={{ width: '100%', height: '100%' }}>
        {children}
      </Scrollbars>
    </div>
  );
};
