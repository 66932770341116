import { useCallback, useContext, useEffect, useState } from 'react';
import { useAccount, useNetwork, useProvider } from 'wagmi';

import { GlobalContext, UserContext } from '../context';
import { nativeTokenByNetwork } from '../context/constants';
import { getBalancesMultiCall, normalize } from '../context/utils';
import { RefetchState, Token } from '../interfaces';

export function useGetBalances(loadingTokenList: boolean): [RefetchState, any] {
  const { chain } = useNetwork();
  const {
    state: { wallet },
    dispatch,
  } = useContext(UserContext);
  const {
    state: { tokenList },
  } = useContext(GlobalContext);

  const { address: account } = useAccount();
  const provider = useProvider();
  const [refetch, setRefetch] = useState<boolean>(false);
  const [balances, setBalances] = useState<any>(null);
  const [loadingBalances, setLoadingBalances] = useState<RefetchState>({
    isLoading: false,
    error: null,
    refetch: null,
  });

  const fetchBalances = useCallback(async () => {
    const finalBalancesWallet = [];
    const finalBalancesAccount = [];

    if (loadingTokenList && wallet === '' && !chain)
      return { finalBalancesWallet, finalBalancesAccount };

    const balancesPromises = [];

    balancesPromises.push(
      getBalancesMultiCall(provider, wallet, tokenList),
      getBalancesMultiCall(provider, account, tokenList),
    );
    const results = await Promise.all(balancesPromises);

    const resultWallet = results[0];
    const resultAccount = results[1];
    const tokensAddresses =
      resultWallet.length > 0
        ? Object.keys(resultWallet[1])
        : Object.keys(resultAccount[1]);

    for (let i = 0; i < tokensAddresses.length; i++) {
      const tokenResult = tokenList.filter(
        (item: Token) => item.address === tokensAddresses[i],
      );

      if (tokenResult.length > 0) {
        if (resultWallet.length > 0) {
          const resultBalance = resultWallet[1][tokensAddresses[i]];
          const finalBalance =
            Number(normalize(resultBalance)) / 10 ** tokenResult[0].decimals <
            1e-8
              ? '0'
              : normalize(resultWallet[1][tokensAddresses[i]]); // dont show dust

          finalBalancesWallet.push({
            address: tokenResult[0].address,
            decimals: tokenResult[0].decimals,
            symbol: tokenResult[0].symbol,
            icon: tokenResult[0].logoURI,
            custom: tokenResult[0].custom,
            balance: finalBalance,
          });
        }

        if (resultAccount.length > 0) {
          const resultBalance = resultAccount[1][tokensAddresses[i]];
          const finalBalance =
            Number(normalize(resultBalance)) / 10 ** tokenResult[0].decimals <
            1e-8
              ? '0'
              : normalize(resultAccount[1][tokensAddresses[i]]); // dont show dust

          finalBalancesAccount.push({
            address: tokenResult[0].address,
            decimals: tokenResult[0].decimals,
            symbol: tokenResult[0].symbol,
            custom: tokenResult[0].custom,
            icon: tokenResult[0].logoURI,
            balance: finalBalance,
          });
        }
      }
    }

    if (resultWallet.length > 0) {
      const nativeBalance = await provider.getBalance(wallet);
      const finalBalance =
        Number(normalize(Number(nativeBalance))) / 10 ** 18 < 1e-8
          ? '0'
          : normalize(Number(nativeBalance)); // dont show dust

      finalBalancesWallet.push({
        ...nativeTokenByNetwork[chain.id],
        balance: finalBalance,
      });
    }

    if (resultAccount.length > 0) {
      const nativeBalance = await provider.getBalance(account);
      const finalBalance =
        Number(normalize(Number(nativeBalance))) / 10 ** 18 < 1e-8
          ? '0'
          : normalize(Number(nativeBalance)); // dont show dust

      finalBalancesAccount.push({
        ...nativeTokenByNetwork[chain.id],
        balance: finalBalance,
      });
    }

    return { finalBalancesWallet, finalBalancesAccount };
  }, [chain, refetch, wallet]);

  useEffect(() => {
    setLoadingBalances((pastLoadingBalances) => ({
      ...pastLoadingBalances,
      isLoading: true,
    }));

    fetchBalances()
      .then((balancesData) => {
        setBalances(balancesData);
        dispatch({
          type: 'SET_BALANCES',
          payload: {
            wallet: balancesData.finalBalancesWallet,
            account: balancesData.finalBalancesAccount,
          },
        });
        setLoadingBalances((pastLoadingBalances) => ({
          ...pastLoadingBalances,
          refetch: () => setRefetch(true),
          isLoading: false,
        }));
        if (refetch) setRefetch(false);
      })
      .catch(() => {
        const errorToShow = new Error('Error fetching balances for the wallet');
        setLoadingBalances((pastLoadingBalances) => ({
          ...pastLoadingBalances,
          refetch: () => setRefetch(true),
          error: errorToShow,
        }));
        if (refetch) setRefetch(false);
      });
  }, [chain, refetch, fetchBalances]);

  return [loadingBalances, balances];
}
