import { useContext, useEffect, useState } from 'react';
import { useAccount, useNetwork } from 'wagmi';

import { GlobalContext, UserContext } from '../../../../context/index';
import {
  formatDecimals,
  formatRate,
  formatWei,
} from '../../../../context/utils';
import { Stake } from '../../../../interfaces';
import { Button } from '../../../atoms/Button';
import { Modal } from '../../../atoms/Modal';
import { SymbolGroup } from '../../../atoms/SymbolGroup';
import { Tooltip, TooltipContent, TooltipIcon } from '../../../atoms/Tooltip';
import { AssetSelect } from '../../../molecules/AssetsSelect/AssetSelect';
import { TypeEnum } from '../../Toast';

type Props = {
  close: () => void;
  reFetch?: () => void;
  stakingData: Stake;
};

const Staking = ({ close, reFetch, stakingData }: Props) => {
  const { address: account } = useAccount();
  const { chain } = useNetwork();
  const {
    state: { tokenList, actions },
    setToast,
  } = useContext(GlobalContext);
  const {
    state: { wallet, balances },
  } = useContext(UserContext);

  const [selected, setSelected] = useState<any>(
    balances.wallet.filter((item) =>
      stakingData.deposit.map((i) => i.symbol).includes(item.symbol),
    )[0],
  );
  const [options] = useState(
    balances.wallet.filter((item) =>
      stakingData.deposit.map((i) => i.symbol).includes(item.symbol),
    ),
  );
  const [amount, setAmount] = useState<number>(0);
  const [amountWei, setAmountWei] = useState<string>('');
  const [pending, setPending] = useState<boolean>(false);
  const [noBalance, setNoBalance] = useState<boolean>(false);
  const [priceImpact, setPriceImpact] = useState<number>(0);

  const handleAmount = (amt) => {
    if (amt === -1) {
      setAmountWei(selected.balance);
      setAmount(formatWei(selected.address, selected.balance, tokenList));
    } else {
      setAmountWei(formatDecimals(selected.symbol, amt, tokenList));
      setAmount(amt);

      if (amt > formatWei(selected.address, selected.balance, tokenList))
        setNoBalance(true);
      else setNoBalance(false);
    }
  };

  const handleSelect = (item) => {
    setAmount(0);
    setSelected(item);
  };
  const calculatePriceImpact = () => {
    if (selected && amount > 0 && stakingData?.reserves) {
      const tokens = Object.keys(stakingData.reserves).map((t) => t);
      const otherToken = tokens[0] === selected.symbol ? tokens[1] : tokens[0];
      const constantProduct =
        +stakingData.reserves[tokens[0]] * stakingData.reserves[tokens[1]];
      const marketPrice =
        +stakingData.reserves[selected.symbol] /
        stakingData.reserves[otherToken];

      const newReserveOtherToken =
        constantProduct /
        (Number(stakingData.reserves[selected.symbol]) + Number(amount / 2));

      const pricePaid =
        +stakingData.reserves[selected.symbol] / newReserveOtherToken;

      setPriceImpact(pricePaid / marketPrice - 1);
    }
  };

  useEffect(() => {
    calculatePriceImpact();
  }, [amount]);

  const executeStaking = async () => {
    try {
      setPending(true);
      const initialTx = await actions.handleStake(
        selected.address,
        amountWei,
        stakingData,
        wallet,
        account,
        chain.id,
      );

      setToast({
        open: true,
        type: TypeEnum.Pending,
        title: 'Pending transaction',
        timer: 14000,
        message: (
          <a
            href={`${chain.blockExplorers['default'].url}/tx/${initialTx.hash}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Check on block explorer
          </a>
        ),
      });

      await initialTx.wait();
      reFetch();

      setToast({
        open: true,
        type: TypeEnum.Success,
        title: 'Success in the stake!',
        timer: 14000,
      });
      setPending(false);
      close();
    } catch (error) {
      setToast({
        open: true,
        type: TypeEnum.Error,
        title: 'Error in the stake...',
        timer: 14000,
        message: error.message,
      });
      console.error(error);
      setPending(false);
    }
  };

  return (
    <Modal close={close} closeButton size={'sm'} pending={pending}>
      <div className="heading">
        <span className="flex items-center justify-center">
          <SymbolGroup data={stakingData.deposit} />
          <span>{stakingData.earn + ' Staking'}</span>
        </span>
      </div>
      <div className="swap-modal-box">
        <div className="group">
          <div className="from-wallet-box">
            <div className="from-option-box">
              {selected && (
                <AssetSelect
                  label={'Amount'}
                  options={options}
                  amount={amount}
                  selected={selected}
                  isSelectable={options.length > 1}
                  isInput
                  showBalance
                  handleAmount={handleAmount}
                  handleSelect={handleSelect}
                />
              )}
            </div>
          </div>
          <div className="flex-1 fee-info-right" style={{ marginTop: '20px' }}>
            <div className="flex">
              <div className="title5">Price impact</div>
              <Tooltip>
                <TooltipIcon />
                <TooltipContent>
                  {`Price Impact gives you an idea on what slippage to expect
                  based on the size of the order you're placing and current
                  market conditions.`}
                </TooltipContent>
              </Tooltip>
            </div>
            <div className="title4">
              {priceImpact < 0.0001 ? '<0.01' : formatRate(priceImpact)}%
            </div>
          </div>
          <div className="swap-button-box">
            <Button
              size={'lg'}
              className={pending || noBalance ? 'disabled' : ''}
              handleClick={executeStaking}
              disabled={pending || noBalance}
            >
              {pending ? 'Staking' : noBalance ? 'Not enough funds' : 'Stake'}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Staking;
