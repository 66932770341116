import React, { useContext, useState } from 'react';

import { GlobalContext } from '../../../../context';
import { formatNumber, formatRate } from '../../../../context/utils';
import { ChainIdType } from '../../../../interfaces';
import { Button } from '../../../atoms/Button/index';
import { Card } from '../../../atoms/Card';
import { NoRecord } from '../../../atoms/NoRecord/index';
import { Symbol } from '../../../atoms/Symbol/index';
import { SymbolGroup } from '../../../atoms/SymbolGroup/index';
import { Tooltip, TooltipContent, TooltipLabel } from '../../../atoms/Tooltip';
import Claim from '../../../organisms/Modals/Claim';
import WithdrawLP from '../../../organisms/Modals/WithdrawLP';

const MIN_AMOUNT = 1e-3;

export const InvestedVaults = ({
  investedType,
  loadingInvestedType,
  loading,
  reFetch,
  chainId,
}: {
  investedType: any;
  loadingInvestedType: boolean;
  loading: boolean;
  reFetch: () => void;
  chainId: ChainIdType;
}) => {
  const {
    state: { actions },
  } = useContext(GlobalContext);
  const [pending, setPending] = useState<boolean>(false);
  const [withdrawModal, setWithdrawModal] = useState<{
    open: boolean;
    data?: any;
  }>({ open: false });
  const [claimModal, setClaimModal] = useState<{ open: boolean; data?: any }>({
    open: false,
  });

  const handleMigrate = async (vault: any) => {
    setPending(true);
    try {
      const tx = await actions.handleVaultMigrate(vault);
      await tx.wait();
      reFetch();
      setPending(false);
    } catch (e) {
      setPending(false);
      console.error(e);
    }
  };

  const renderRewards = (item) => {
    let rewards;
    let ethaRewards;

    // Volatile Asset Rewards
    if (item.type === 'Vault') {
      if (!item?.version?.isCompounding) {
        if (item.earned >= MIN_AMOUNT) {
          rewards = Number(formatNumber(item.earned));
        } else if (item.earned > 0 && item.earned < MIN_AMOUNT) {
          rewards = '<0.001';
        }
      }

      if (item.ethaRewards >= MIN_AMOUNT) {
        ethaRewards = Number(formatNumber(item.ethaRewards));
      }
    }

    return (
      <>
        {ethaRewards && chainId === ChainIdType.POLYGON && (
          <div className="invest-title color-green text-sm">
            {typeof ethaRewards == 'number' ? '+' : ''} {ethaRewards} ETHA
          </div>
        )}
        <div className="invest-title">
          {rewards}{' '}
          {rewards ? (item.type === 'Vault' ? item.earn : 'MATIC') : ''}
        </div>
      </>
    );
  };

  const dispayEarnings = (item) => {
    // Volatile Asset Rewards
    if (item.type === 'Vault') {
      if (!item?.version?.isCompounding) {
        if (item.earned >= MIN_AMOUNT) {
          return true;
        } else if (item.earned > 0 && item.earned < MIN_AMOUNT) {
          return true;
        }
      }

      if (item.ethaRewards >= MIN_AMOUNT) {
        return true;
      }
    }
    return false;
  };

  const renderAPYs = (item) => {
    let tooltipData;

    if (item.type === 'Vault') {
      tooltipData = (
        <TooltipContent>
          <div className="tooltip-title">Yields</div>
          <div>LP: {formatRate(item.fapy)}% APY</div>
          <div>
            {item.earn}: {formatRate(item.rapy)}% APR
          </div>
          <div>ETHA: {formatRate(item.dApy)}% APR</div>
          <div>Protocol: {item.protocol}</div>
        </TooltipContent>
      );
    }

    return (
      <Tooltip>
        <TooltipLabel>
          <span className="color-green" style={{ cursor: 'pointer' }}>
            {item.rapy > 10 ? '>1000' : formatRate(item.rapy)}%
          </span>
        </TooltipLabel>
        {tooltipData}
      </Tooltip>
    );
  };

  return (
    <>
      {!loading && !loadingInvestedType ? (
        investedType.length > 0 ? (
          investedType
            .sort((a, b) => Number(b.apy) - Number(a.apy))
            .filter((t) =>
              t.type === 'Vault'
                ? t.balance > MIN_AMOUNT || t.ethaRewards > MIN_AMOUNT
                : t.invested > MIN_AMOUNT || t.ethaRewards > MIN_AMOUNT,
            ) // remove dust
            .map((item, i) => {
              return (
                <Card className="invest-card" key={i}>
                  <div className="invest-card-body">
                    <div className="invest-top">
                      <div className="asset-info asset-exchange">
                        <SymbolGroup data={item.deposit} />
                        <Symbol
                          src={item.earnIcon}
                          alt={item.earn}
                          size={'md'}
                        />
                        <span className="asset-title">
                          {(item.balance > 0.001
                            ? formatNumber(item.balance)
                            : '<0.001') + ' USD'}
                        </span>
                      </div>
                    </div>
                    <div className="invest-info-body">
                      <div className="invest-info">
                        <div className="invest-item-row flex">
                          <div className="invest-item-col flex-1">
                            <div className="invest-item-body">
                              <div className="invest-title">
                                {item?.version?.isCompounding
                                  ? 'Compounded'
                                  : item.earn}
                              </div>
                              <div className="invest-label">eVault</div>
                            </div>
                          </div>
                          <div className="invest-item-col flex-1">
                            <div className="invest-item-body">
                              <div className="invest-title">
                                {renderAPYs(item)}
                              </div>
                              <div className="invest-label">Current APY</div>
                            </div>
                          </div>
                          {dispayEarnings(item) && !item.version.isCompounding && (
                            <div className="invest-item-col flex-1">
                              <div className="invest-item-body">
                                {renderRewards(item)}
                                <div className="invest-label">Earnings</div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="invest-actions">
                        <div className="invest-action-inner">
                          <div className="btn-group flex">
                            <Button
                              className="default"
                              size={'md'}
                              handleClick={() =>
                                setWithdrawModal({ open: true, data: item })
                              }
                            >
                              WITHDRAW
                            </Button>
                            {dispayEarnings(item) &&
                              !item.version.isCompounding && (
                                <Button
                                  className="default"
                                  size={'md'}
                                  handleClick={() =>
                                    setClaimModal({ open: true, data: item })
                                  }
                                >
                                  CLAIM
                                </Button>
                              )}
                          </div>
                          <div className="btn-group flex">
                            <Button
                              size={'md'}
                              type={'link'}
                              to={`/invest/vault/${item.id}`}
                              className={!item.active ? 'disabled' : ''}
                            >
                              INVEST
                            </Button>
                            {item.newVaultAddress && (
                              <Button
                                className={pending ? 'disabled' : ''}
                                size={'md'}
                                handleClick={() => handleMigrate(item)}
                              >
                                {pending ? 'MIGRATING...' : 'MIGRATE'}
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              );
            })
        ) : (
          <NoRecord>No investments yet!</NoRecord>
        )
      ) : (
        <NoRecord>
          <div className="no-record flex justify-center">
            <div className="mr-3">Loading vaults information...</div>
            <div
              style={{ borderTopColor: 'transparent' }}
              className="animate-spin"
            ></div>
          </div>
        </NoRecord>
      )}

      {withdrawModal.open && (
        <WithdrawLP
          close={() => setWithdrawModal({ open: false })}
          reFetch={reFetch}
          data={withdrawModal.data}
        />
      )}
      {claimModal.open && (
        <Claim
          close={() => setClaimModal({ open: false })}
          reFetch={reFetch}
          data={claimModal.data}
        />
      )}
    </>
  );
};
