import { useContext, useEffect } from 'react';
import { useAccount, useNetwork } from 'wagmi';

import { api } from '../../axios';
import { UserContext } from '../../context';

export default function WalletManager({ children }: { children: JSX.Element }) {
  const { dispatch } = useContext(UserContext);
  const { chain } = useNetwork();
  const { address: account, isConnected } = useAccount();

  const fetchData = async () => {
    let response = null;

    try {
      response = await api.get(`wallet/${account}/${chain.id}`);
    } catch (error) {
      console.error(error);
    }

    const smartWallet = response ? response.data.data.smartWallet : '';

    if (smartWallet !== '' && !chain.unsupported) {
      if (process.env.REACT_APP_USER_CONNECTED !== undefined) {
        dispatch({
          type: 'SET_WALLET',
          payload: process.env.REACT_APP_USER_CONNECTED,
        });
      } else {
        dispatch({ type: 'SET_WALLET', payload: smartWallet });
      }
    }
  };

  useEffect(() => {
    if (account && chain) {
      fetchData();
    }
  }, [account, isConnected, chain]);

  return children;
}
