import './index.scss';

import cls from 'classnames';
import { useContext } from 'react';

import { GlobalContext } from '../../../context';
import { addToken, getTokenIcon } from '../../../context/utils';
import { Token } from '../../../interfaces';
import { Symbol } from '../../atoms/Symbol';

export const PriceShow = ({
  symbol,
  price,
  className,
  loading = false,
}: {
  symbol: string;
  price: string;
  className?: string | string[];
  loading?: boolean;
}) => {
  const {
    state: { tokenList },
  } = useContext(GlobalContext);

  async function handleOnClick() {
    const token = tokenList.filter(
      (token: Token) => token.symbol === symbol,
    )[0];
    await addToken(token);
  }

  return (
    <div className={cls('price-show', className)} onClick={handleOnClick}>
      {!loading ? (
        <div className="price-show-wrapper">
          <Symbol
            src={getTokenIcon(symbol, tokenList)}
            size={'sm2'}
            alt={'ETHA LEND'}
          />
          <span className="price">
            {symbol} <span>{price}</span>
          </span>
        </div>
      ) : (
        <div className="price-show-wrapper">
          <span className="skeleton">
            <span className="hidden-text">Loading...</span>
          </span>
        </div>
      )}
    </div>
  );
};
