// eslint-disable-next-line simple-import-sort/imports
import './styles/style.scss';

import { useContext, useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import {
  useAccount,
  useNetwork,
  useProvider,
  useSigner,
  useSwitchNetwork,
} from 'wagmi';

import { Header } from './components/organisms/Header/index';
import WalletManager from './components/WalletManager';
import Activities from './container/Activities';
import Governance from './container/Governance';
import Home from './container/Home/index';
import { Invest } from './container/Invest';
import { Portfolio } from './container/Portfolio';
import { Actions } from './context/actions';
import { GlobalContext, UserContext } from './context/index';
import { useGetBalances } from './hooks/useGetBalances';
import { useGlobalData } from './hooks/useGlobalData';
import { ChainIdType } from './interfaces';
import useGraphData from './hooks/useGraphData';
import Web3ReactManager from './components/Web3ReactManager';
import { useUserVaultData } from './hooks/userHooks/useUserVaultData';
import { useUserLendingData } from './hooks/userHooks/useUserLendingData';
import { useUserStakeData } from './hooks/userHooks/useUserStakeData';
import { useUserBorrowingData } from './hooks/userHooks/useUserBorrowingData';

function App() {
  const {
    state: { wallet },
  } = useContext(UserContext);
  const {
    state: { priceData, tokenList },
    dispatch,
  } = useContext(GlobalContext);

  const { address: account } = useAccount();
  const { chain } = useNetwork();
  const { data: signer } = useSigner();
  const provider = useProvider();
  const { error: errorSwitch, switchNetworkAsync: switchNetwork } =
    useSwitchNetwork();

  const [chainId, setChainId] = useState<ChainIdType>(ChainIdType.POLYGON);

  // Fetching global data
  const [loadingGlobal] = useGlobalData(chain ? chain.id : chainId);
  const [loadingGraphData] = useGraphData();

  // Fetching user data (if user connected to the app)
  const [loadingBalances] = useGetBalances(
    loadingGlobal.loadingTokenListData.isLoading,
  );
  const [loadingUserVaultData] = useUserVaultData();
  const [loadingUserLendingData] = useUserLendingData();

  const [loadingUserStakeData] = useUserStakeData();
  const [loadingUserBorrowingData] = useUserBorrowingData();

  const loadingUser = {
    loadingUserVaultData,
    loadingUserLendingData,
    loadingUserStakeData,
    loadingUserBorrowingData,
  };

  useEffect(() => {
    if (
      provider !== undefined &&
      signer !== undefined &&
      account !== undefined &&
      chain
    ) {
      const actions = new Actions(
        tokenList,
        wallet,
        account,
        signer,
        priceData,
        provider,
        chain.id,
      );

      if (wallet !== '') {
        actions.initializeWalletContract();
      }

      dispatch({ type: 'SET_ACTIONS', payload: actions });
    }
  }, [wallet, account, provider, signer, chain]);

  return (
    <div className="app-main">
      <Web3ReactManager>
        <WalletManager>
          <BrowserRouter>
            <Header
              setChainId={setChainId}
              errorSwitch={errorSwitch}
              switchNetwork={switchNetwork}
              loadingGlobal={loadingGlobal.loader}
            />
            <Routes>
              <Route
                path="/"
                element={<Home loadingGlobal={loadingGlobal} />}
              />
              <Route
                path="/invest/:type/:id"
                element={
                  <Invest
                    loadingGlobal={loadingGlobal}
                    chainId={chainId}
                    loadingBalances={loadingBalances.isLoading}
                  />
                }
              />
              {wallet !== '' && chain ? (
                <>
                  <Route
                    path="/activities"
                    element={
                      <Activities
                        loadingGraphData={loadingGraphData.isLoading}
                      />
                    }
                  />
                  <Route
                    path="/portfolio"
                    element={
                      <Portfolio
                        loadingUser={loadingUser}
                        loadingGlobal={loadingGlobal}
                        loadingBalances={loadingBalances}
                        chainId={chain.id}
                      />
                    }
                  />
                  {chainId === ChainIdType.POLYGON && (
                    <Route
                      path="/governance"
                      element={
                        <Governance
                          loadingGlobal={loadingGlobal}
                          loadingBalances={loadingBalances}
                        />
                      }
                    />
                  )}
                </>
              ) : null}

              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </BrowserRouter>
        </WalletManager>
      </Web3ReactManager>
    </div>
  );
}

export default App;
