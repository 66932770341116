import { utils } from 'ethers';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNetwork, useProvider } from 'wagmi';

import { GlobalContext, UserContext } from '../../context';
import {
  formatDecimals,
  formatWei,
  loadContract,
  toBigNumber,
} from '../../context/utils';
import { ChainIdType, RefetchState, Token, TokenData } from '../../interfaces';

export function useUserLendingData(): [RefetchState, any] {
  const { chain } = useNetwork();
  const {
    state: { wallet },
    dispatch,
  } = useContext(UserContext);
  const {
    state: { tokenList, tokensData, priceData, graphData },
  } = useContext(GlobalContext);
  const provider = useProvider();

  const [refetch, setRefetch] = useState<boolean>(false);
  const [userLendingData, setUserLendingData] = useState<any>(null);
  const [loadingUserLendingData, setLoadingUserLendingData] =
    useState<RefetchState>({
      isLoading: true,
      error: null,
      refetch: null,
    });

  const fetchUserLendingData = useCallback(async () => {
    const lendingData = {
      investments: [],
      totalSavingsUSD: 0,
      totalEarnedUSD: 0,
      totalInvestedUSD: 0,
    };

    if (!chain && chain.id !== ChainIdType.POLYGON) return lendingData;

    const investAdapter = loadContract(
      'AdapterInvestments',
      chain.id,
      provider,
    );

    const balances: any[] = await investAdapter.getBalances(
      tokenList.map((item: Token) => utils.getAddress(item.address)),
      wallet,
    );

    let totalSavingsUSD = 0;
    let totalEarnedUSD: number | string = 0;

    for (let i = 0; i < tokenList.length; i++) {
      if (tokenList[i].symbol === 'MATIC') {
        continue;
      }

      const tokenData = tokensData.filter(
        (item: TokenData) =>
          item.symbol ===
          (tokenList[i].symbol === 'WMATIC' ? 'MATIC' : tokenList[i].symbol),
      )[0];

      if (tokenData === undefined) {
        continue;
      }

      if (
        Object.keys(graphData.totalData).indexOf(
          tokenList[i].symbol === 'WMATIC' ? 'MATIC' : tokenList[i].symbol,
        ) < 0
      ) {
        continue;
      }

      const totalData =
        graphData.totalData[
          tokenList[i].symbol === 'WMATIC' ? 'MATIC' : tokenList[i].symbol
        ];

      const totalSupplied = Number(totalData.totalSupplied);
      const totalRedeemed = Number(totalData.totalRedeemed);

      const totalSuppliedWei = formatDecimals(
        tokenList[i].symbol,
        totalData.totalSupplied,
        tokenList,
      );
      const totalRedeemedWei = formatDecimals(
        tokenList[i].symbol,
        totalData.totalRedeemed,
        tokenList,
      );

      const totalSuppliedBN = toBigNumber(totalSuppliedWei);
      const totalRedeemedBN = toBigNumber(totalRedeemedWei);

      const aaveBal = formatWei(
        tokenList[i].address,
        balances[i].aave,
        tokenList,
      );
      const aaveBalBN = toBigNumber(balances[i].aave);

      const creamBal = formatWei(
        tokenList[i].address,
        balances[i].cream,
        tokenList,
      );
      const creamBalBN = toBigNumber(balances[i].cream);

      const savings = aaveBal + creamBal;
      const savingsBN = aaveBalBN.add(creamBalBN);

      let invested = totalSupplied - totalRedeemed;
      let investedBN = totalSuppliedBN.sub(totalRedeemedBN);

      invested = invested > 0 ? invested : 0;

      investedBN = investedBN.gt(toBigNumber(0)) ? investedBN : toBigNumber(0);

      const earned = +savings - invested;
      const earnedBN = savingsBN.sub(investedBN);

      const userApy =
        savings > 0
          ? (tokenData.aaveData.supplyRate * aaveBal +
              tokenData.creamData.supplyRate * creamBal) /
            savings
          : 0;

      const aaveSupplyRateBN = toBigNumber(
        formatDecimals(
          tokenList[i].symbol,
          tokenData.aaveData.supplyRate,
          tokenList,
        ),
      );

      const creamSupplyRateBN = toBigNumber(
        formatDecimals(
          tokenList[i].symbol,
          tokenData.creamData.supplyRate,
          tokenList,
        ),
      );

      const aaveValue = aaveSupplyRateBN.mul(aaveBalBN);
      const creamValue = creamSupplyRateBN.mul(creamBalBN);
      const totalValue = aaveValue.add(creamValue);

      const userApyBN = savingsBN.gt(toBigNumber(0))
        ? totalValue.div(savingsBN)
        : toBigNumber(0);

      const memory = loadContract('Memory', chain.id, provider);
      const aaveIncentives = loadContract(
        'AdapterAaveIncentives',
        chain.id,
        provider,
      );

      const aToken = await memory.getAToken(tokenList[i].address);
      const _rewards = await aaveIncentives.getRewardsBalance([aToken], wallet);

      const rewards = formatWei(
        tokenList.filter((item: Token) => item.symbol === 'WMATIC')[0].address,
        _rewards,
        tokenList,
      );
      const rewardsBN = toBigNumber(_rewards);
      const liquidityBN = toBigNumber(
        formatDecimals(
          tokenList[i].symbol,
          tokenData.aggregate.liquidity,
          tokenList,
        ),
      );

      lendingData.investments.push({
        token: tokenList[i].address,
        symbol: tokenList[i].symbol,
        savings,
        savingsBN,
        invested,
        investedBN,
        earn: tokenList[i].symbol,
        earnedBN,
        rewardsBN: earnedBN.gt(toBigNumber(0)) ? rewardsBN : toBigNumber(0),
        earned: earned > 0 ? earned : 0,
        rewards,
        apy: tokenData.aggregate.supplyRate,
        rapy: tokenData.aggregate.rewardsRate,
        dApy: tokenData.aggregate.distributionRate,
        bapy: tokenData.aggregate.borrowRate,
        liquidity: tokenData.aggregate.liquidity,
        liquidityBN,
        userApy,
        userApyBN,
        icon: tokenList[i].logoURI,
        type: 'Lending',
        balances: {
          aave: aaveBal,
          aaveBN: aaveBalBN,
          cream: creamBal,
          creamBN: creamBalBN,
        },
      });

      totalSavingsUSD += savings * priceData[tokenList[i].symbol];
      totalEarnedUSD += earned * priceData[tokenList[i].symbol];
    }

    lendingData.totalSavingsUSD = totalSavingsUSD;
    lendingData.totalEarnedUSD = totalEarnedUSD;
    lendingData.totalInvestedUSD = totalSavingsUSD + totalEarnedUSD;

    return lendingData;
  }, [chain, tokensData, graphData]);

  useEffect(() => {
    setLoadingUserLendingData((pastLoadingUserLendingData) => ({
      ...pastLoadingUserLendingData,
      isLoading: true,
    }));

    fetchUserLendingData()
      .then((lendingData) => {
        setUserLendingData(lendingData);
        dispatch({
          type: 'SET_LENDING_DATA',
          payload: { data: lendingData },
        });
        setLoadingUserLendingData((pastLoadingUserLendingData) => ({
          ...pastLoadingUserLendingData,
          refetch: () => setRefetch(true),
          isLoading: false,
        }));
        if (refetch) setRefetch(false);
      })
      .catch(() => {
        const errorToShow = new Error('Error fetching user lending data');
        setLoadingUserLendingData((pastLoadingUserLendingData) => ({
          ...pastLoadingUserLendingData,
          refetch: () => setRefetch(true),
          error: errorToShow,
        }));
      });
    if (refetch) setRefetch(false);
  }, [chain, tokensData, graphData, fetchUserLendingData]);

  return [loadingUserLendingData, userLendingData];
}
