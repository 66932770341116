import React, { ReactNode, useEffect, useState } from 'react';

export const ContentBox = ({
  lineCount,
  children,
}: {
  lineCount: number;
  children?: ReactNode;
}) => {
  const [line, setLine] = useState<Array<any>>([]);

  useEffect(() => {
    if (lineCount) {
      const count = [];
      for (let i = 0; i < lineCount; i++) {
        count[i] = i;
      }
      setLine(count);
    }

    return () => {
      setLine([]);
    };
  }, [lineCount]);
  return (
    <p className="flex-1">
      {lineCount && (
        <span className="line-box">
          {line.map((i, key) => {
            return <i key={key}></i>;
          })}
        </span>
      )}
      {children}
    </p>
  );
};
