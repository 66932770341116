import './index.scss';

export const Table = ({
  children,
  className,
}: {
  children: any;
  className?: string;
}) => {
  return (
    <div className={`table-box table-listing-view ${className}`}>
      {children}
    </div>
  );
};
export const TableHead = ({ children }: { children: any }) => {
  return <div className="table-head">{children}</div>;
};
export const TableBody = ({ children }: { children: any }) => {
  return <div className="table-body">{children}</div>;
};
export const Row = ({
  children,
  className,
}: {
  children?: any;
  className?: string;
}) => {
  return <div className={`table-row ${className}`}>{children}</div>;
};
export const Col = ({
  children,
  className,
  title,
}: {
  children?: any;
  className?: string;
  title?: string;
}) => {
  return (
    <div className={`table-col ${className}`} data-title={title}>
      {children}
    </div>
  );
};
