import React, { useState } from 'react';

function InvestedAssets() {
  const [data] = useState([1, 2, 3, 4]);
  return (
    <>
      <div className="portfolio-body-top">
        <div className="radio-btn-outer">
          <div className="radio-btn skeleton">
            <label className="radio">
              <input name="assets" type="radio" />{' '}
              <span>
                <span className="hidden-text">Invested Assets</span>
              </span>
            </label>
            <label className="radio">
              <input name="assets" type="radio" />{' '}
              <span>
                <span className="hidden-text">Unused Assets</span>
              </span>
            </label>
          </div>
        </div>
      </div>
      <div className="portfolio-table-box invested-table-view">
        <div className="table-box invested-table">
          <div className="table-head">
            <div className="table-row">
              <div className="table-col col-1">
                <div className="th-item flex">
                  <span className="th-text flex-1">
                    <span className="skeleton-text-area skeleton">&nbsp;</span>
                  </span>
                </div>
              </div>
              <div className="table-col col-2"></div>
              <div className="table-col col-3">
                <div className="th-item flex">
                  <span className="th-text flex-1">
                    <span className="skeleton-text-area skeleton">&nbsp;</span>
                  </span>
                </div>
              </div>
              <div className="table-col col-4">
                <div className="th-item flex">
                  <span className="th-text flex-1">
                    <span className="skeleton-text-area skeleton">&nbsp;</span>
                  </span>
                </div>
              </div>
              <div className="table-col col-5">
                <div className="th-item flex">
                  <span className="th-text flex-1">
                    <span className="skeleton-text-area skeleton">&nbsp;</span>
                  </span>
                </div>
              </div>
              <div className="table-col col-6"></div>
            </div>
          </div>
          <div className="table-body">
            {data.map((item, i) => {
              return (
                <div className="table-row list-card" key={i}>
                  <div className="table-col col-1 list-col-1">
                    <div className="asset-info">
                      <span className="asset-symbol skeleton"></span>
                      <span className="asset-title">
                        <span
                          className="skeleton-text-area skeleton"
                          style={{ width: '80px' }}
                        >
                          &nbsp;
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="table-col col-2 list-col-2">
                    <span className="btn">
                      <span className="skeleton">
                        <span className="hidden-text">Invest</span>
                      </span>
                    </span>
                  </div>
                  <div className="table-col col-3 list-col-3">
                    <div className="flex items-center">
                      <span className="text-md2">
                        <span
                          className="skeleton-text-area skeleton"
                          style={{ width: '60px' }}
                        >
                          &nbsp;
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="table-col col-4 list-col-4">
                    <span>
                      <span className="skeleton-text-area skeleton">
                        &nbsp;
                      </span>
                    </span>
                  </div>
                  <div className="table-col col-5 list-col-5">
                    <span>
                      <span
                        className="skeleton-text-area skeleton"
                        style={{ width: '40px' }}
                      >
                        &nbsp;
                      </span>
                    </span>
                    <div className="color-green text-sm">
                      <span
                        className="skeleton-text-area skeleton"
                        style={{ height: '10px', marginTop: '5px' }}
                      >
                        &nbsp;
                      </span>
                    </div>
                  </div>
                  <div className="table-col col-6 list-col-6">
                    <span className="btn">
                      <span className="skeleton">
                        <span className="hidden-text">Invest</span>
                      </span>
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
export default InvestedAssets;
