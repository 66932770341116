import React from 'react';

const HeaderSkeleton = () => {
  return (
    <nav className="nav-full">
      <div className="nav-inner flex">
        <div className="nav-left flex-auto">
          <span className="logo skeleton"></span>
        </div>
        <div className="nav-right flex">
          <div className="nav-main-box">
            <ul className="nav-main">
              <li>
                <span className="nav-item active">
                  <span className="skeleton d-inline-block">
                    <span className="hidden-text">Invest</span>
                  </span>
                </span>
              </li>
            </ul>
          </div>
          <div className="nav-address-box">
            <div className="nav-address">
              <div className="nav-address-inner skeleton">
                <span className="address-info">
                  <span className="address-text">
                    <span className="hidden-text">Create a Smart Wallet</span>
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div className="menu-toggle-box">
            <span className="menu-toggle-icon">
              <i></i>
              <i></i>
              <i></i>
            </span>
          </div>
        </div>
      </div>
    </nav>
  );
};
export default HeaderSkeleton;
