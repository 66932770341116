import './index.scss';

import { useContext, useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useDebouncedCallback } from 'use-debounce';
import { useAccount, useNetwork } from 'wagmi';

import { GlobalContext, UserContext } from '../../../../context';
import { nativeTokenByNetwork } from '../../../../context/constants';
import {
  expToString,
  formatDecimals,
  formatNumber,
  formatRate,
  formatWeiSymbol,
  getExpectedRate,
} from '../../../../context/utils';
import { icons } from '../../../../icons';
import { ChainIdType } from '../../../../interfaces';
import { Button } from '../../../atoms/Button';
import { Modal } from '../../../atoms/Modal';
import { Tooltip, TooltipContent, TooltipIcon } from '../../../atoms/Tooltip';
import { AssetSelect } from '../../../molecules/AssetsSelect/Swap';
import { TypeEnum } from '../../../organisms/Toast';

export const Swap = ({
  close,
  reFetch,
  startingAsset,
  chainId,
}: {
  close: () => void;
  reFetch: () => void;
  startingAsset: any;
  chainId: ChainIdType;
}) => {
  const { address: account } = useAccount();
  const { chain } = useNetwork();

  const {
    state: { balances, wallet },
    transactions,
    setTransactions,
  } = useContext(UserContext);

  const {
    state: { tokenList, actions },
    setToast,
  } = useContext(GlobalContext);

  const [showDetails, setShowDetails] = useState(false);
  const [options, setOptions] = useState([]);
  const [options2, setOptions2] = useState([]);
  const assetFrom = balances.wallet.filter((item) => {
    if (item.symbol === startingAsset) return { ...item, amount: '' };
  })[0];
  const [fromAssets, setFromAssets] = useState([{ ...assetFrom, amount: '' }]);
  const [toAsset, setToAsset] = useState<any>({});
  const [pending, setPending] = useState(false);
  const [insufficient, setInsufficient] = useState(false);

  /**
   * Price Impact gives you an idea on what slippage to expect based on the size of the order you're placing and
   * current market conditions
   */
  const [priceImpact, setPriceImpact] = useState(0);

  /**
   * Handle when adding an asset to the list that will e used on the swap.
   */
  const addAsset = () => {
    if (pending) return;

    const selectedSymbols = fromAssets
      .map((t) => t.symbol)
      .concat([toAsset.symbol]);

    const _available = balances.wallet.filter(
      (t) => !selectedSymbols.includes(t.symbol),
    );

    if (_available.length > 0) {
      const _fromAssets = [...fromAssets, { ..._available[0], amount: '' }];
      setFromAssets(_fromAssets);
    }
  };

  const executeSwap = async (): Promise<void> => {
    setPending(true);

    try {
      const withAmount = fromAssets.filter(
        (item) => item.amount && (item.amount > 0 || item.amount !== ''),
      );

      const initialTx = await actions.handleSwap(
        withAmount,
        toAsset.address,
        wallet,
        account,
        chain.id,
      );

      setToast({
        open: true,
        type: TypeEnum.Pending,
        title: 'Pending transaction',
        timer: 14000,
        message: (
          <a
            href={`${chain.blockExplorers['default'].url}/tx/${initialTx.hash}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Check on block explorer
          </a>
        ),
      });

      transactions[initialTx.hash] = {
        from: initialTx.from,
        hash: initialTx.hash,
        titleTx: 'Swap of Assets',
      };

      setTransactions(transactions);

      const receiptTx = await initialTx.wait();

      transactions[initialTx.hash].receipt = {
        blockHash: receiptTx.blockHash,
        blockNumber: receiptTx.blockNumber,
        contractAddress: receiptTx.contractAddress,
        from: receiptTx.from,
        status: receiptTx.status,
        to: receiptTx.to,
        transactionHash: receiptTx.transactionHash,
        transactionIndex: receiptTx.transactionIndex,
      };

      setTransactions(transactions);
      reFetch();

      setToast({
        open: true,
        type: TypeEnum.Success,
        title: 'Success in the swap',
        timer: 14000,
      });
      setPending(false);
      close();
    } catch (error) {
      setToast({
        open: true,
        type: TypeEnum.Error,
        title: 'Error in the swap...',
        timer: 14000,
        message: error.message,
      });
      console.error(error);
      setPending(false);
    }
  };

  const handleSelectFrom = (current, item) => {
    const assetSymbols = fromAssets.map((asset) => asset.symbol);
    const isSingleSymbol =
      assetSymbols.filter((symbol) => item.symbol === symbol).length === 0;

    if (isSingleSymbol) {
      const assetIndex = assetSymbols.indexOf(current.symbol);

      if (toAsset.symbol === item.symbol) {
        setToAsset(current);
      }

      const updatedAssets = fromAssets.map((asset, index) => {
        if (index === assetIndex) {
          return { ...item, amount: '' };
        } else {
          return asset;
        }
      });

      setFromAssets(updatedAssets);
    }
  };

  const handleSelectTo = (current, item) => {
    setToAsset(item);

    const _assetsFrom = balances.wallet.filter((t) => t.symbol !== item.symbol);

    if (_assetsFrom.length > 0) {
      const withSelectedItem = fromAssets.filter(
        (asset) => asset.symbol === item.symbol,
      );
      if (withSelectedItem.length > 0) {
        setFromAssets(
          fromAssets
            .filter((asset) => asset.symbol !== item.symbol)
            .concat({ ...toAsset, amount: '' }),
        );
      } else {
        setFromAssets(
          fromAssets.filter((asset) => asset.symbol !== item.symbol),
        );
      }

      setOptions(_assetsFrom);
    }
  };

  const handleFromAmount = (amt, asset) => {
    let amount = '';
    let amountWei = '';

    if (amt === -1) {
      amount = String(formatWeiSymbol(asset.symbol, asset.balance, tokenList));
      amountWei = String(asset.balance);
    } else {
      amount = amt;
      amountWei = formatDecimals(asset.symbol, amt, tokenList);
    }

    if (amt > formatWeiSymbol(asset.symbol, asset.balance, tokenList))
      setInsufficient(true);
    else setInsufficient(false);

    const _assets = fromAssets.map((t) => {
      const _amount = asset.symbol === t.symbol ? amount : t.amount;
      const _amountWei = asset.symbol === t.symbol ? amountWei : t.amountWei;

      return {
        ...t,
        amount: _amount,
        amountWei: _amountWei,
      };
    });

    setFromAssets(_assets);
  };

  const removeItem = (item) =>
    setFromAssets((prev) =>
      prev.filter((asset) => asset.symbol !== item.symbol),
    );

  const getPriceImpact = () => {
    let _impact = 0;
    let totalSrcUsd = 0;
    let totalDestUsd = 0;

    fromAssets.forEach((t) => {
      if (t.path) {
        totalSrcUsd += Number(t.path.srcUSD);
        totalDestUsd += Number(t.path.destUSD);
      }
    });

    if (totalSrcUsd && totalDestUsd) {
      _impact = Number(formatNumber(totalSrcUsd / totalDestUsd - 1)) * 100;
      setPriceImpact(_impact);
    }
  };

  /**
   * Calculate exchange rate, using toAsset and fromAmount to communicate with 1inch api.
   */
  const getRate = useDebouncedCallback(async () => {
    setPending(true);
    if (fromAssets.length > 0) {
      const _assets = await Promise.all(
        fromAssets.map(async (item) => {
          if (item.amount && (item.amount > 0 || item.amount !== '')) {
            const data = await getExpectedRate(
              item.address,
              toAsset.address,
              item.amount,
              wallet,
              false,
              chain.id,
              tokenList,
            );

            const { result, path, impact, realSrc, realDest, proxy } = data;

            const destAmt = result;

            return {
              ...item,
              destAmt,
              path,
              impact,
              realSrc,
              realDest,
              proxy,
            };
          }

          return item;
        }),
      );
      setFromAssets(_assets);
    }
    setPending(false);
  }, 1000);

  /**
   * Format amount destination
   */
  const amountDest = () => {
    const res = fromAssets.reduce(
      (acc, asset) => acc + (asset.destAmt ? +asset.destAmt : 0),
      0,
    );
    return res;
  };

  /**
   * Estimated value displayed to the client
   */
  const clientDisplayedValue = () => {
    const res = amountDest();
    const preValue = 1 - priceImpact / 100;
    const total = res * preValue * 0.9975; // add swap fee
    return total;
  };

  useEffect(() => {
    setToAsset(
      balances.wallet.filter((item) => item.symbol !== fromAssets[0].symbol)[0],
    );
    setOptions(balances.wallet);
    setOptions2(balances.wallet);
  }, []);

  useEffect(() => {
    if (fromAssets.length > 0) getPriceImpact();
  }, [fromAssets, toAsset]);

  useEffect(() => {
    getRate();
  }, [fromAssets.length, toAsset]);

  let buttonText = 'Swap assets';

  if (!pending && insufficient) buttonText = 'Insufficient balance';
  if (pending) buttonText = 'Calculating...';

  if (
    fromAssets.length === 1 &&
    fromAssets[0].symbol === nativeTokenByNetwork[chainId].wrappedSymbol &&
    toAsset.symbol === nativeTokenByNetwork[chainId].symbol
  )
    buttonText = `Unwrap ${nativeTokenByNetwork[chainId].symbol}`;

  if (
    fromAssets.length === 1 &&
    fromAssets[0].symbol === nativeTokenByNetwork[chainId].symbol &&
    toAsset.symbol === nativeTokenByNetwork[chainId].wrappedSymbol
  )
    buttonText = `Wrap ${nativeTokenByNetwork[chainId].symbol}`;

  return (
    <Modal close={close} closeButton size={'sm'} pending={pending}>
      <div className="heading text-center">Swap assets</div>
      <div className="swap-modal-inner">
        <div className="swap-asses-box">
          <div className="swap-custom-scrollbar">
            <div className="modal-scrollable">
              <Scrollbars style={{ width: '100%', height: '100%' }}>
                <div className="swap-assets-inner">
                  <div className="group">
                    <div className="from-wallet-box">
                      <div className="from-option-box">
                        {fromAssets.map((asset, i) => {
                          return (
                            <div
                              className="asset-select-box flex items-center"
                              key={i}
                            >
                              <AssetSelect
                                label={i === 0 ? 'From' : ''}
                                options={options}
                                amount={expToString(
                                  fromAssets[i] ? fromAssets[i].amount : '',
                                )}
                                selected={fromAssets[i]}
                                isInput
                                isSelectable
                                isSwap
                                handleSelect={handleSelectFrom}
                                handleAmount={(_amount) =>
                                  handleFromAmount(_amount, asset)
                                }
                                getRate={() => getRate()}
                                pending={pending}
                              />

                              <div
                                className="remove-asset"
                                style={{
                                  display:
                                    fromAssets.length > 1 ? 'block' : 'none',
                                  alignSelf: i === 0 ? 'auto' : 'flex-start',
                                  position: 'absolute',
                                  cursor: 'pointer',
                                  right: -29,
                                  width: 20,
                                  height: 20,
                                  marginTop: 23,
                                  transform: 'rotate(43deg)',
                                }}
                                onClick={() => removeItem(asset)}
                              >
                                {icons.plus}
                              </div>
                            </div>
                          );
                        })}
                        <div className="add-another-box">
                          <span
                            className={`add-another ${
                              pending ? 'disabled pointer-events-none' : ''
                            }`}
                            onClick={addAsset}
                          >
                            {icons.plus}
                            <span className="ml-2">
                              Add another asset to your swap
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="swap-toggle">
                      <span className="swap-toggle-btn">
                        <span>{icons.downArrow}</span>
                      </span>
                    </div>
                    <div className="asset-select-box flex items-center swap-to-box">
                      {balances.wallet.length > 0 && (
                        <AssetSelect
                          label={'To (estimated)'}
                          options={options2}
                          amount={expToString(amountDest())}
                          selected={toAsset}
                          isSelectable
                          handleSelect={handleSelectTo}
                          pending={pending}
                        />
                      )}
                    </div>
                    <div className="show-detail-btn">
                      <div
                        className="btn-quaternary"
                        onClick={() => {
                          setShowDetails(!showDetails);
                        }}
                      >
                        {showDetails ? 'Show less' : 'Show Details'}
                      </div>
                    </div>
                    {showDetails && (
                      <div className="swap-bottom-info">
                        <div className="fee-info flex">
                          <div className="flex-1 fee-info-left">
                            <div className="flex">
                              <div className="title5">Minimum Received</div>
                              <Tooltip>
                                <TooltipIcon />
                                <TooltipContent>
                                  The swap output is calculated; you will get at
                                  least the Minimum Received based on the price
                                  impact or slippage
                                </TooltipContent>
                              </Tooltip>
                            </div>
                            <div className="title4">
                              {expToString(
                                Number(formatNumber(clientDisplayedValue())),
                              )}{' '}
                              {toAsset.symbol || ''}
                            </div>
                          </div>
                          {priceImpact > 0 && (
                            <div className="flex-1 fee-info-right">
                              <div className="flex">
                                <div className="title5">Price impact</div>
                                <Tooltip>
                                  <TooltipIcon />
                                  <TooltipContent>
                                    {`Price Impact gives you an idea on what slippage to expect based on the size of the order you're placing and current market conditions`}
                                  </TooltipContent>
                                </Tooltip>
                              </div>
                              <div className="title4 color-green">
                                {formatRate(priceImpact / 100)}%
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="group swap-button-box">
                    <Button
                      disabled={pending || insufficient || amountDest() <= 0}
                      className={
                        pending || insufficient || amountDest() <= 0
                          ? 'disabled'
                          : ''
                      }
                      handleClick={executeSwap}
                      size={'lg'}
                    >
                      {buttonText}
                    </Button>
                  </div>
                </div>
              </Scrollbars>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
