import React from 'react';

const InvestInfo = () => {
  return (
    <>
      <div className="info-data flex">
        <div className="flex-1">
          <div className="info-box">
            <label className="label-text">
              <span className="skeleton inline-block" style={{ width: '20%' }}>
                &nbsp;
              </span>
            </label>
            <div className="apy-data-outer">
              <div className="apy-data">
                <div className="asset-info">
                  <span
                    className="skeleton inline-block"
                    style={{ width: '100%' }}
                  >
                    &nbsp;
                  </span>
                </div>
                <div className="asset-info">
                  <span
                    className="skeleton inline-block"
                    style={{ width: '100%' }}
                  >
                    &nbsp;
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default InvestInfo;
