import { icons } from '../../atoms/Icons/index';

export const Search = ({
  value,
  handleSearch,
  placeholder,
  loading,
}: {
  value?: any;
  handleSearch?: any;
  placeholder?: string;
  loading?: boolean;
}) => {
  return (
    <div className="search">
      {loading ? (
        <div className="search-inner">
          <span className="input-search-area skeleton"></span>
        </div>
      ) : (
        <div className="search-inner">
          <span className="search-icon">{icons.search}</span>
          <input
            type="text"
            className="input-search"
            placeholder={placeholder}
            value={value}
            onChange={handleSearch}
          />
        </div>
      )}
    </div>
  );
};
