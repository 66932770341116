import { ReactNode } from 'react';

export const Card = ({
  children,
  className,
  theme,
}: {
  children: ReactNode;
  className?: any;
  theme?: 'style-1' | 'style-2' | 'style-3';
}) => {
  return (
    <div className={`card-new ${className} ${theme}`}>
      <div className="card-new-body">{children}</div>
    </div>
  );
};
