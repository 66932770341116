import './index.scss';

import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAccount, useNetwork, useProvider, useSigner } from 'wagmi';

import { BackButton } from '../../components/atoms/BackButton';
import { Symbol } from '../../components/atoms/Symbol';
import { SymbolGroup } from '../../components/atoms/SymbolGroup/index';
import { InvestFrom } from '../../components/organisms/InvestFrom';
import { ProjectedReturns } from '../../components/organisms/ProjectedReturns';
import { Strategy } from '../../components/organisms/Strategy';
import { TypeEnum } from '../../components/organisms/Toast';
import InvestInfo from '../../components/Skeleton/InvestInfo';
import StrategyLoading from '../../components/Skeleton/Strategy';
import { GlobalContext, UserContext } from '../../context';
import { nativeTokenByNetwork } from '../../context/constants';
import {
  approvalToken,
  checkAllowance,
  formatDecimals,
  formatRate,
  formatWei,
  formatWeiSymbol,
  getIcon,
  renderDepositSymbols,
} from '../../context/utils';
import { ChainIdType, GlobalLoading, Vault } from '../../interfaces';

export const Invest = ({
  loadingGlobal: { loadingVaultData, loadingLendingData, loadingPriceData },
  loadingBalances,
  chainId,
}: {
  loadingGlobal: GlobalLoading;
  loadingBalances: boolean;
  chainId: ChainIdType;
}) => {
  const {
    state: { borrowData, vaultData, tokenList, priceData, actions },
    setToast,
  } = useContext(GlobalContext);
  const {
    state: { wallet, balances },
    transactions,
    setTransactions,
  } = useContext(UserContext);
  const { address: account } = useAccount();
  const { chain } = useNetwork();
  const { data: signer } = useSigner();
  const provider = useProvider();

  const [pending, setPending] = useState(false);
  const [investment, setInvestment] = useState<any>({});
  const [amount, setAmount] = useState<number | string>('');
  const [amountWei, setAmountWei] = useState('');
  const [investFrom, setInvestFrom] = useState(
    wallet !== '' ? 'wallet' : 'account',
  );

  const navigate = useNavigate();
  const { type, id } = useParams();

  // METAMASK WALLET
  const [selectedAccount, setSelectedAccount] = useState<any>({});
  const [optionsAccount, setOptionsAccount] = useState([]);

  // ETHA WALLET
  const [selected, setSelected] = useState<any>({});
  const [options, setOptions] = useState([]);

  const handleInvest = async () => {
    try {
      setPending(true);

      let approvalCheck = false;

      if (
        investFrom === 'account' &&
        selectedAccount.symbol !== nativeTokenByNetwork[chainId].symbol
      ) {
        const allowance = await checkAllowance(
          selectedAccount.symbol,
          account,
          wallet,
          tokenList,
          provider,
        );

        if (allowance.lt(amountWei)) {
          approvalCheck = await approvalToken(
            selectedAccount.symbol,
            wallet,
            tokenList,
            signer,
          );
        } else {
          approvalCheck = true;
        }
      } else {
        approvalCheck = true;
      }

      if (!approvalCheck) return;

      const initialTx = await actions.handleVaultDeposit(
        investment,
        amountWei,
        investFrom,
        investFrom === 'account' ? selectedAccount.address : selected.address,
        investment.protocol,
        wallet,
        account,
      );

      setToast({
        open: true,
        type: TypeEnum.Pending,
        title: 'Pending transaction',
        timer: 14000,
        message: (
          <a
            href={`${chain.blockExplorers['default'].url}/tx/${initialTx.hash}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Check on block explorer
          </a>
        ),
      });

      transactions[initialTx.hash] = {
        from: initialTx.from,
        hash: initialTx.hash,
        titleTx: 'Invest into eVault',
      };

      setTransactions(transactions);

      const receiptTx = await initialTx.wait();

      transactions[initialTx.hash].receipt = {
        blockHash: receiptTx.blockHash,
        blockNumber: receiptTx.blockNumber,
        contractAddress: receiptTx.contractAddress,
        from: receiptTx.from,
        status: receiptTx.status,
        to: receiptTx.to,
        transactionHash: receiptTx.transactionHash,
        transactionIndex: receiptTx.transactionIndex,
      };

      setTransactions(transactions);

      setToast({
        open: true,
        type: TypeEnum.Success,
        title: 'Success in the Investment!',
        timer: 14000,
      });
      setPending(false);

      navigate('/portfolio', { state: { fetch: true } });
    } catch (error) {
      setToast({
        open: true,
        type: TypeEnum.Error,
        title: 'Error in the investment...',
        timer: 14000,
        message: error.message,
      });
      console.error(error);
      setPending(false);
    }
  };

  const handleSelect = (item) => {
    if (investFrom === 'wallet') {
      setSelected(item);
    } else if (investFrom === 'account') {
      setSelectedAccount(item);
    } else {
      if (item.symbol) {
        setSelected(item);
      }
    }

    setAmount(0);
    setAmountWei('');
  };

  const handleAmount = (amt: number | string) => {
    const _selected = investFrom === 'wallet' ? selected : selectedAccount;

    if (amt === -1) {
      setAmount(formatWeiSymbol(_selected.symbol, selected.balance, tokenList));
      setAmountWei(selected.balance);
    } else {
      setAmount(amt);
      setAmountWei(formatDecimals(_selected.symbol, Number(amt), tokenList));

      if (
        Number(amt) >
          formatWei(_selected.address, _selected.balance, tokenList) ||
        Number(amt) === 0
      )
        setPending(true);
      else {
        setPending(false);
      }
    }
  };

  const initializeInvestments = async () => {
    const vault = vaultData.find((vault: Vault) => vault.id === id);

    if (vault !== undefined) {
      setInvestment({
        ...vault,
        symbol: vault.deposit[0].symbol,
        icon: getIcon(vault.deposit[0].symbol, tokenList),
        token: vault.deposit[0].address,
        totalApy: vault.apy + vault.dApy,
      });
    }
  };

  useEffect(() => {
    if (type && id) {
      initializeInvestments();
    }
  }, [
    type,
    id,
    loadingVaultData.isLoading,
    loadingLendingData.isLoading,
    loadingBalances,
  ]);

  useEffect(() => {
    const nativeData = nativeTokenByNetwork[chainId];
    if (
      investment.symbol &&
      balances.wallet.length > 0 &&
      options.length === 0
    ) {
      const hasWrapped = investment.deposit.some(
        (i) => i.symbol === nativeData.wrappedSymbol,
      );
      let depositAssets = investment.deposit.map((t) => t.symbol);

      if (hasWrapped) depositAssets = [...depositAssets, nativeData.symbol];

      setOptions(
        balances.wallet.filter((t) => depositAssets.includes(t.symbol)),
      );
      const mainAsset = balances.wallet.filter(
        (t) => t.symbol === investment.symbol,
      )[0];

      setSelected(mainAsset);
    }

    if (
      investment.symbol &&
      balances.account.length > 0 &&
      optionsAccount.length === 0
    ) {
      const hasWrapped = investment.deposit.some(
        (i) => i.symbol === nativeData.wrappedSymbol,
      );
      let _balances = balances.account;
      let depositAssets = investment.deposit.map((t) => t.symbol);
      if (hasWrapped) depositAssets = [...depositAssets, nativeData.symbol];
      _balances = _balances.filter((t) => depositAssets.includes(t.symbol));

      setOptionsAccount(_balances);
      setSelectedAccount(_balances[0]);
    }
  }, [balances, investment, type]);

  useEffect(() => {
    if (
      chain &&
      investment.chainId &&
      chain.id !== Number(investment.chainId) &&
      investment.active
    )
      navigate('/');
  }, [chain]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <main className="page-main">
        <div className="container">
          <div className="page-holder">
            <div className="btn-back-outer">
              {!loadingVaultData.isLoading && !loadingLendingData.isLoading ? (
                <BackButton to={'/'}>BACK</BackButton>
              ) : (
                <span className="back-button-dummy skeleton"></span>
              )}
            </div>
            <div className="page-data">
              {/* TITLE */}
              <div className="page-top">
                <div className="asset-group-outer lg">
                  {!loadingVaultData.isLoading &&
                  !loadingLendingData.isLoading ? (
                    <div className="flex items-center">
                      <Symbol
                        src={
                          type === 'vault'
                            ? investment.earnIcon
                            : investment.icon
                        }
                        alt={investment.symbol}
                        size={'lg'}
                      />

                      <span className="asset-title lg">
                        {' '}
                        {type === 'vault'
                          ? `${
                              investment?.version?.isCompounding
                                ? renderDepositSymbols(investment.deposit)
                                : investment.earn
                            } eVault`
                          : `${investment.symbol} yield optimization`}{' '}
                      </span>
                    </div>
                  ) : (
                    <div className="flex items-center">
                      <div className="asset-info">
                        <span className="asset-symbol skeleton"></span>
                        <span className="asset-title lg">
                          <span
                            className="skeleton-text-area skeleton"
                            style={{ width: '200px', height: '30px' }}
                          >
                            &nbsp;
                          </span>
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="invest-box flex">
                <div className="invest-left">
                  <div className="invest-left-inner">
                    <InvestFrom
                      handleSelect={handleSelect}
                      selected={selected}
                      selectedAccount={selectedAccount}
                      options={options}
                      optionsAccount={optionsAccount}
                      investFrom={investFrom}
                      setInvestFrom={setInvestFrom}
                      handleAmount={handleAmount}
                      amount={amount}
                      globalBorrowData={borrowData}
                      loadingVaults={loadingVaultData.isLoading}
                      loadingTokensData={loadingLendingData.isLoading}
                      loadingPriceData={loadingPriceData.isLoading}
                      loadingBalances={chain ? loadingBalances : true}
                    />

                    <div className="group">
                      {!loadingVaultData.isLoading &&
                      !loadingLendingData.isLoading ? (
                        <>
                          <div className="info-data flex">
                            <div className="flex-1">
                              <div className="info-box">
                                <label className="label-text">
                                  Current APY
                                </label>
                                <div className="apy-data-outer">
                                  <div className="apy-data">
                                    <div className="asset-info">
                                      <span className="color-green">
                                        {formatRate(
                                          type === 'vault'
                                            ? investment.rapy
                                            : investment.apy,
                                        )}
                                        %
                                      </span>
                                      <span className="in-text">in</span>
                                      {investment?.version?.isCompounding ? (
                                        <SymbolGroup
                                          data={investment.deposit}
                                          hasArrow={false}
                                        />
                                      ) : (
                                        <Symbol
                                          src={
                                            type === 'vault' &&
                                            !investment?.version?.isCompounding
                                              ? investment.earnIcon
                                              : investment.icon
                                          }
                                          alt={investment.symbol}
                                          size={'sm'}
                                        />
                                      )}
                                    </div>
                                    {investment.dApy > 0 && (
                                      <div className="asset-info">
                                        <span>+</span>
                                        <span className="color-green">
                                          {formatRate(investment.dApy)}%
                                        </span>
                                        <span className="in-text">in</span>
                                        <Symbol
                                          src={getIcon('ETHA', tokenList)}
                                          alt={'ETHA'}
                                          size={'sm'}
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <InvestInfo />
                      )}
                    </div>
                  </div>
                </div>
                <div className="invest-right">
                  <ProjectedReturns
                    handleInvest={handleInvest}
                    investment={investment}
                    amount={amount}
                    priceData={priceData}
                    disabled={pending}
                    selected={
                      investFrom === 'wallet' ? selected : selectedAccount
                    }
                    loadingVaults={loadingVaultData.isLoading}
                    loadingTokensData={loadingLendingData.isLoading}
                    chainId={chainId}
                  />
                </div>
              </div>
              {type === 'vault' &&
                (investment.symbol ? (
                  <Strategy investment={investment} chainId={chainId} />
                ) : (
                  <StrategyLoading />
                ))}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
