import { ethers } from 'ethers';
import { useEffect, useState } from 'react';
import { useNetwork, useProvider } from 'wagmi';

import {
  addToken,
  formatCurrency,
  formatNumber,
  getERC20Contract,
} from '../../../context/utils';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { RefetchState } from '../../../interfaces';
import { Button } from '../../atoms/Button';
import { Col, Row, Table, TableBody } from '../../atoms/ListTable';
import { NoRecord } from '../../atoms/NoRecord';
import { Symbol } from '../../atoms/Symbol';
import { Search } from '../../molecules/Search';

export const UnusedTable = ({
  data,
  showAll,
  handleSwapModal,
  handleDepositModal,
  handleWithdrawModal,
  loadingBalances,
}: {
  data: any;
  showAll: any;
  handleSwapModal: any;
  handleDepositModal: any;
  handleWithdrawModal: any;
  loadingBalances: RefetchState;
}) => {
  const provider = useProvider();
  const { chain } = useNetwork();

  const [assets, setAssets] = useState([]);
  const [search, setSearch] = useState<string>('');
  const [loading, setLoading] = useState(true);
  const [customAssets, setCustomAssets] = useLocalStorage('custom_assets', []);

  useEffect(() => {
    if (data.length > 0 && !loadingBalances.isLoading) {
      if (showAll) setAssets(data);
      else setAssets(data.filter((t) => t.usdValue > 1e-2 || t.custom));
    }
  }, [data, showAll]);

  useEffect(() => {
    setLoading(false);
  }, []);

  const addCustomToken = (item) => {
    setCustomAssets([...customAssets, item]);
    location.reload();
  };

  const handleSearch = (e) => {
    const searchText = e.target.value.toLowerCase();

    if (
      ethers.utils.isAddress(searchText) &&
      !data.some((t) => t.address.toLowerCase() === searchText.toLowerCase())
    ) {
      const tokenInstance = getERC20Contract(searchText, provider);
      const arrPR = [tokenInstance.symbol(), tokenInstance.decimals()];

      Promise.all(arrPR)
        .then((results) => {
          setAssets([
            {
              address: ethers.utils.getAddress(searchText),
              logoURI: 'https://dev.ethalend.com/assets/custom-icon.png',
              symbol: results[0],
              decimals: results[1],
              custom: true,
              chainId: chain.id,
            },
          ]);
        })
        .catch((error) => console.error(error));
    } else if (ethers.utils.isAddress(searchText)) {
      const filtered = data.filter((item: any) => {
        return item.address.toLowerCase().search(searchText) !== -1;
      });

      if (!searchText.length && !showAll) {
        setAssets(data.filter((t) => t.usdValue > 1e-2));
      } else {
        setAssets(filtered);
      }
    } else {
      const filtered = data.filter((item: any) => {
        return item.symbol.toLowerCase().search(searchText) !== -1;
      });

      if (!searchText.length && !showAll) {
        setAssets(data.filter((t) => t.usdValue > 1e-2));
      } else {
        setAssets(filtered);
      }
    }

    setSearch(searchText);
  };

  return (
    <>
      <div className="flex unused-assets-search">
        <div className="flex-auto"></div>
        <div className="search-box">
          <Search
            value={search}
            handleSearch={handleSearch}
            placeholder={'Search or paste token address'}
            loading={loading}
          />
        </div>
      </div>

      <Table className="unused-table">
        <TableBody>
          {!loadingBalances.isLoading ? (
            assets.length > 0 ? (
              assets
                .sort((a, b) => b.usdValue - a.usdValue)
                .map((item, i) => {
                  const balance = item.balance
                    ? formatNumber(Number(item.balance / 10 ** item.decimals))
                    : formatNumber(0);
                  const isCustom = item['custom'];

                  return (
                    <Row className="list-card" key={i}>
                      <Col className="table-col col-1 list-col-1">
                        <div className="flex items-center">
                          <span
                            className="clickable flex items-center"
                            onClick={() => addToken(item)}
                          >
                            <Symbol
                              src={
                                item.icon ||
                                'https://dev.ethalend.com/assets/custom-icon.png'
                              }
                              alt={item.symbol}
                            />
                          </span>
                          <span className="asset-title">
                            {balance + ' ' + item.symbol}{' '}
                            {!isCustom && (
                              <span className="color-light">
                                ({formatCurrency(item.usdValue)})
                              </span>
                            )}
                          </span>
                        </div>
                      </Col>
                      <Col className="table-col flex-auto">
                        {isCustom &&
                        !customAssets.some(
                          (t) =>
                            t.address.toLowerCase() ===
                            item.address.toLowerCase(),
                        ) ? (
                          <>
                            <Button handleClick={() => addCustomToken(item)}>
                              Add Token
                            </Button>
                          </>
                        ) : (
                          <>
                            <Button
                              handleClick={() =>
                                handleDepositModal(item.symbol)
                              }
                            >
                              Deposit
                            </Button>
                            <Button
                              className={item.symbol === 'DQUICK' && 'disabled'}
                              disabled={item.symbol === 'DQUICK'}
                              handleClick={() =>
                                item.symbol === 'DQUICK'
                                  ? ''
                                  : handleSwapModal(item.symbol)
                              }
                            >
                              Swap
                            </Button>
                            <Button
                              handleClick={() =>
                                handleWithdrawModal(item.symbol)
                              }
                            >
                              Withdraw
                            </Button>
                          </>
                        )}
                      </Col>
                    </Row>
                  );
                })
            ) : (
              <NoRecord>No assets found!</NoRecord>
            )
          ) : (
            <NoRecord>
              <div className="no-record flex justify-center">
                <div className="mr-3">Loading assets...</div>
                <div
                  style={{ borderTopColor: 'transparent' }}
                  className="w-6 h-6 border-4 border-blue-400 border-solid rounded-full animate-spin"
                ></div>
              </div>
            </NoRecord>
          )}
        </TableBody>
      </Table>
    </>
  );
};
