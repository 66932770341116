import { Link } from 'react-router-dom';

import { ChainIdType } from '../../../interfaces';
import { Dropdown, Item } from '../Dropdown/index';
export const MainMenu = ({
  currentPage,
  handleSelect,
  wallet,
  chainId,
}: {
  currentPage: any;
  handleSelect: any;
  wallet: any;
  chainId: ChainIdType;
}) => {
  return (
    <ul className="nav-main">
      {wallet !== '' && (
        <>
          <li className="dropdown-parent">
            <Link
              to={'/'}
              className={
                currentPage === '/' || currentPage.includes('/invest')
                  ? 'nav-item active'
                  : 'nav-item'
              }
              onClick={handleSelect}
            >
              Invest
            </Link>
            <Dropdown>
              <Item href={'/#vaults'}>eVaults</Item>
            </Dropdown>
          </li>
          <li>
            <Link
              to={'/portfolio'}
              className={
                currentPage === '/portfolio' ? 'nav-item active' : 'nav-item'
              }
              onClick={handleSelect}
            >
              My Portfolio
            </Link>
          </li>
          <li>
            <Link
              to={'/activities'}
              className={
                currentPage === '/activities' ? 'nav-item active' : 'nav-item'
              }
              onClick={handleSelect}
            >
              Activities
            </Link>
          </li>
          {chainId === 137 && (
            <li>
              <Link
                to={'/governance'}
                className={
                  currentPage === '/governance' ? 'nav-item active' : 'nav-item'
                }
                onClick={handleSelect}
              >
                Governance
              </Link>
            </li>
          )}
        </>
      )}
    </ul>
  );
};
