import React, { useContext, useState } from 'react';
//import { useNavigate } from 'react-router-dom';
import { useNetwork } from 'wagmi';

import { GlobalContext } from '../../../../context';
import { ChainIdType } from '../../../../interfaces';
import { Button } from '../../../atoms/Button';
// COMPONENTS
import { Modal } from '../../../atoms/Modal/index';
import { TypeEnum } from '../../../organisms/Toast';

type Props = {
  close: () => void;
};

export const CreateWallet: React.FC<Props> = ({ close }) => {
  const [pending, setPending] = useState<boolean>(false);
  //const navigation = useNavigate();
  const { chain } = useNetwork();
  const {
    state: { actions },
    setToast,
  } = useContext(GlobalContext);

  const executeWalletCreation = async (): Promise<void> => {
    setPending(true);

    try {
      const initialTx = await actions.handleCreateWallet();

      setToast({
        open: true,
        type: TypeEnum.Pending,
        title: 'Pending transaction',
        timer: 14000,
        message: (
          <a
            href={
              chain
                ? `${chain.blockExplorers['default'].url}/tx/${initialTx.hash}`
                : `https://polygonscan.com/tx/${initialTx.hash}`
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            Check on block explorer
          </a>
        ),
      });

      await initialTx.wait();

      setToast({
        open: true,
        type: TypeEnum.Success,
        title: 'Success in your Smart Wallet creation !',
        timer: 14000,
      });

      setPending(false);
      window.location.href = '/';
      close();
    } catch (error) {
      setToast({
        open: true,
        type: TypeEnum.Error,
        title: 'Error creating your Smart Wallet...',
        timer: 14000,
        message: error.message,
      });
      setPending(false);
      console.error(error);
    }
  };

  return (
    <Modal close={close} closeButton pending={pending}>
      <div className="heading text-center">
        First, let’s create your ETHA Wallet
      </div>
      <div className="abstract-new"></div>
      <div className="benefits-box">
        <div className="title4 font-normal">Benefits</div>
        <ul className="check-list check-list-green check-list-2-col">
          <li>Grouping of transactions reduces overall gas costs</li>
          <li>Interoperability with all protocols </li>
          <li>No need to create accounts on various protocols </li>
          <li>
            No need to pay approval gas fees when interacting with protocols{' '}
          </li>
          <li>
            The wallet is owned and controlled by your MetaMask wallet only
          </li>
        </ul>
      </div>
      <div className="btn-outer">
        {!pending ? (
          <Button handleClick={executeWalletCreation} size={'lg'}>
            Create your Smart Wallet
          </Button>
        ) : (
          <Button size={'lg'} className={'disabled'}>
            Creating the smart wallet...
          </Button>
        )}
      </div>
      {chain.id === ChainIdType.POLYGON && (
        <div className="high-gas-help">
          High gas fees? Here&apos;s a helping hand:{' '}
          <a href="#">
            <strong>get gas fees back in ETHA tokens</strong>
          </a>
        </div>
      )}
    </Modal>
  );
};
