import * as ABIS from './abis';

// ASSETS
export const ETHA_ADDRESS = '0x59E9261255644c411AfDd00bD89162d09D862e38'; // Temporal
export const MATIC_ADDRESS = '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE';
export const WMATIC_ADDRESS = '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270';
export const DAI_ADDRESS = '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063';
export const USDC_ADDRESS = '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174';
export const USDT_ADDRESS = '0xc2132D05D31c914a87C6611C10748AEb04B58e8F';
export const MAI_ADDRESS = '0xa3Fa99A148fA48D14Ed51d610c367C61876997F1';
export const QUICK_ADDRESS = '0x831753DD7087CaC61aB5644b308642cc1c33Dc13';
export const DQUICK_ADDRESS = '0xf28164A485B0B2C90639E47b0f377b4a438a16B1';

export const A3CRV_ADDRESS = '0xE7a24EF0C5e95Ffb0f6684b813A78F2a3AD7D171';
export const QUICK_LP = '0xf04adBF75cDFc5eD26eeA4bbbb991DB002036Bdd';
export const QUICK_LP2 = '0x2cF7252e74036d1Da831d11089D326296e64a728';
export const QUICK_LP3 = '0x59153f27eeFE07E5eCE4f9304EBBa1DA6F53CA88';
export const QUICK_LP4 = '0xb417Da294ae7c5CbD9176d1a7A0c7D7364AE1c4E'; // USDC-ETHA
export const QUICK_LP5 = '0x8167D3156FccDBAf3E43Ae019a0e842e5d1F1aC1'; // ETHA-QUICK
export const ETH_QUICK_LP = '0x1Bd06B96dd42AdA85fDd0795f3B4A79DB914ADD5';

export const LENDING_DISTRIBUTIONS: { [key: string]: string } = {
  MATIC: '0xB39132b577674033Fe7D58050E0ffF8C4e0e1fA6',
  WMATIC: '0xB39132b577674033Fe7D58050E0ffF8C4e0e1fA6',
  DAI: '0x3850E1313f526d1ad4FD2Ef4957060F56f13F164',
  USDC: '0x18c3Ee725e614689601479BD2D0CA47295611eE3',
  USDT: '0x907821addC19d9fe065B93830fdD1D37723eE67b',
};

export const NEW_LENDING_DISTRIBUTIONS: { [key: string]: string } = {
  MATIC: '0x339b8254a23eF4A48BAC832d35E0a6421c28A15e',
  WMATIC: '0x339b8254a23eF4A48BAC832d35E0a6421c28A15e',
  DAI: '0xa9906AC30A327E5854E34EAc4A26f7924491F31D',
  USDC: '0xa11BDf9dD12a841C6F383cdD8Cb6aA937446BA73',
  USDT: '0x1E53E4A9Ade6FE1A26E02423f37ECf7774f41D2a',
  WBTC: '0xaD4a6F6E764Cc1101F207E6753c72f5D7fB7c1EB',
  WETH: '0x4D0FE9beFbc2b8C2017D4cB2880C11413608E8C1',
};

export const VAULT_DISTRIBUTIONS: { [key: string]: string } = {
  '0xE7a24EF0C5e95Ffb0f6684b813A78F2a3AD7D171':
    '0x6411b1109d613Ae279b27422C08f1B7Cca996356',
  '0xf04adBF75cDFc5eD26eeA4bbbb991DB002036Bdd':
    '0x170b6A99EDf60f22ca741a26cfc7833E6b9fD6E6',
  '0x2cF7252e74036d1Da831d11089D326296e64a728':
    '0xA7785329917333e82b9E3707B012a2C7821c2CAC',
  '0x59153f27eeFE07E5eCE4f9304EBBa1DA6F53CA88':
    '0x84029DFbBbdE8a0a34819A5D91e121Fa6661dfF3',
  '0x1Bd06B96dd42AdA85fDd0795f3B4A79DB914ADD5':
    '0xAAcb9E46892faEC497130F80B68eDB6f8A70F8D2',
  '0xE89faE1B4AdA2c869f05a0C96C87022DaDC7709a':
    '0x590958e2D532E174A44351A59548bB8fF53dB5c7',
};

export const TOKENS: string[] = [
  'MATIC',
  'DAI',
  'USDC',
  'USDT',
  'WETH',
  'WBTC',
];

export const COINGECKO_IDS =
  'matic-network,quick,dai,usd-coin,tether,mimatic,weth,wrapped-bitcoin,etha-lend,curve-dao-token,chainlink,uniswap,qi-dao,aavegotchi,the-sandbox,aave,quickswap';

export const ONE_YEAR = 60 * 60 * 24 * 365;

export const AAVE_DEBT_TOKENS: { [key: string]: string } = {
  MATIC: '0x59e8E9100cbfCBCBAdf86b9279fa61526bBB8765',
  WMATIC: '0x59e8E9100cbfCBCBAdf86b9279fa61526bBB8765',
  DAI: '0x75c4d1Fb84429023170086f06E682DcbBF537b7d',
  USDC: '0x248960A9d75EdFa3de94F7193eae3161Eb349a12',
  USDT: '0x8038857FD47108A07d1f6Bf652ef1cBeC279A2f3',
};

export const nativeTokenByNetwork: {
  [key: number]: {
    address: string;
    decimals: number;
    symbol: string;
    icon: string;
    wrappedAddress: string;
    wrappedSymbol: string;
  };
} = {
  137: {
    address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
    decimals: 18,
    symbol: 'MATIC',
    icon: 'https://assets.coingecko.com/coins/images/4713/small/matic-token-icon.png?1624446912',
    wrappedAddress: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    wrappedSymbol: 'WMATIC',
  },
  43114: {
    address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
    decimals: 18,
    symbol: 'AVAX',
    icon: 'https://assets.coingecko.com/coins/images/12559/small/coin-round-red.png?1604021818',
    wrappedAddress: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
    wrappedSymbol: 'WAVAX',
  },
};

type ContractInfo = { address: string; abi: unknown };

export const logicByNetwork: {
  [key: number]: { [key: string]: string };
} = {
  137: {
    AaveLogic: '0xe9Ff351477A6771A8818d30cE7e9cCDBe0b64379',
    CreamLogic: '0x694c9DC8AB01fB7e24aA83b472777F48B52B9c11',
    CurveLogic: '0x609A473Ff247d0884a54530bBcD954c95c8DA3c8',
    ClaimLogic: '0x19a812DA12D50D917D7d4078228E646eDcc38B20',
    //TransferLogic: '0xA3Fbd6075e0bc735f45a57A26E5e22a214C12e11',
    TransferLogic: '0x473D5188D4D994FcdC0a02a2c0E55072f7503796',
    QuickswapLogic: '0xa37Df4adD5048D9522FC17a6Fe644b0F8b529e21',
    QILogic: '0xa37Df4adD5048D9522FC17a6Fe644b0F8b529e21',
    SushiLogic: '0x21E58057E5C7B2d720339D44d74FFf6bf9C06C70',
    VaultLogic: '0x0F8b4f7F1601089CB6B780c52F43E6deeFC3879A',
    VaultLogicV2: '0xc78944A83265CA1D37AdC8c8493AA9D2ee2161a3',
    VaultCompLogic: '0xaDeC9FA6973c8ade1a1F7C0e4EeEb8b2d2302e72',
    VoteEscrowLogic: '0x3C7F38AbDA6F442CC8F5309Bea25F8DA92A6d262',
    EthwrapLogic: '0xB285c13FC930Ce023506206073E30142F01847B6',
    WrapVGhstLogic: '0x6F202b72149611bDA4ea2015c401aA94B0d3677f',
    StakingLogic: '0x2461B2e15818f6D893993Adc35d5ffaD20cd4B5e',
    MemoryLogic: '0x00f47e2b8E6322E7a25d41E189F4aa0311C90F93',
    MultiFeeLogic: '0x749bA48461c3B9d68B40A275b0D95d098B289ae5',
    ParaswapLogic: '0x234fbF35587753068C306E592Ef4582B6811a97f',
  },
  43114: {
    CurveLogic: '',
    ClaimLogic: '',
    TransferLogic: '0xa8a18a56Fc50Ef8daBB1238Ea20CFf8Cef0ffAc1',
    JoeLogic: '0x89dd3793bebe4759094155833e0D3Fd0F72E6D61',
    VaultLogic: '0x9A89c624beAe555575c4897Cd16FB05A338452c4',
    VaultCompLogic: '0xFb2783e074a6eE3380236cD4eb6405F18572f51D',
    EthwrapLogic: '0x469cdaF2db4712239A33015493e4430314946BD1',
    MemoryLogic: '0xA550c1fa1C632828b93321431C449a7646C2D081',
    ParaswapLogic: '0x39CC3c141dE5978c12C8E84aA673B95c19C0e171',
  },
};

export const contractByNetwork: {
  [key: number]: { [key: string]: ContractInfo };
} = {
  137: {
    AdapterInvestments: {
      address: '0x0Fc8333D23AD3e2AC9d09601208AcC14BB774c01',
      abi: ABIS.AdapterInvestments,
    },
    AdapterBorrow: {
      address: '0x1b2870A24BaC9AA4D3450364C4fc7245058C34fA',
      abi: ABIS.AdapterBorrow,
    },
    AdapterProtocols: {
      address: '0x3FDe39fA7ec70Fa4280ebe55ce23a19B951e32D1',
      abi: ABIS.AdapterProtocols,
    },
    AdapterVaults: {
      address: '0x9F03524fE00bb663E3143C1985eE121596D6E40c',
      abi: ABIS.AdapterVaults,
    },
    AdapterAaveIncentives: {
      address: '0x357D51124f59836DeD84c8a1730D72B749d8BC23',
      abi: ABIS.AdapterAaveIncentives,
    },
    GlobalAdapter: {
      address: '0x8e1755992daAdD5Bbb5A672FcEd5e7A0509e35f6',
      abi: ABIS.GlobalAdapter,
    },
    QuickStaking: {
      address: '',
      abi: ABIS.QuickStaking,
    },
    DistributionSynthethix: {
      address: '',
      abi: ABIS.Distribution,
    },
    Memory: {
      address: '0x7f3584b047e3c23fC7fF1Fb2aC55130ac2162e20',
      abi: ABIS.Memory,
    },
    MasterChefEtha: {
      address: '',
      abi: ABIS.MasterChefEtha,
    },
    MiniChefSushi: {
      address: '',
      abi: ABIS.MiniChefSushi,
    },
    MasterChef: {
      address: '',
      abi: ABIS.MasterChef,
    },
    MultiFeeDistribution: {
      address: '0x8107bf25E61f6e8caF9E2cAe0952D45569dA77e9',
      abi: ABIS.MultiFeeDistribution,
    },
    EthaUsdcStaking: {
      address: '0x2f4de75A8E591cBD4D2C0d3AEE7C36fe62A64f79',
      abi: ABIS.StakingEthaUsdc,
    },
    EthaRegistry: {
      address: '0x583B965462e11Da63D1d4bC6D2d43d391F79af1f',
      abi: ABIS.EthaRegistry,
    },
    FeeManager: {
      address: '0x2A5275D911D3e2e3A8498D789078c94C6e283d32',
      abi: ABIS.FeeManager,
    },
    CurveLpPrice: {
      address: '0x445FE580eF8d70FF569aB36e80c647af338db351',
      abi: ABIS.Curve,
    },
    CurveReward: {
      address: '0xC48f4653dd6a9509De44c92beb0604BEA3AEe714',
      abi: ABIS.CurveReward,
    },
    CurveLpVault: {
      address: '0xE7a24EF0C5e95Ffb0f6684b813A78F2a3AD7D171',
      abi: ABIS.ERC20,
    },
    ComplexSushi: {
      address: '0xa3378Ca78633B3b9b2255EAa26748770211163AE',
      abi: ABIS.ComplexSushi,
    },
    CurveGauge: {
      address: '',
      abi: ABIS.CurveGauge,
    },
    CurvePoolRead: {
      address: '0x445FE580eF8d70FF569aB36e80c647af338db351',
      abi: ABIS.CurvePool,
    },
    CurvePool: {
      address: '0xE7a24EF0C5e95Ffb0f6684b813A78F2a3AD7D171',
      abi: ABIS.CurvePool,
    },
    DragonLair: {
      address: '0xf28164A485B0B2C90639E47b0f377b4a438a16B1',
      abi: ABIS.DragonLair,
    },
    VoteEscrow: {
      address: '0x56bA9a2f00A4F581a05bfE9Fd0b16277eD130349',
      abi: ABIS.VoteEscrow,
    },
    VaultV2: {
      address: '',
      abi: ABIS.VaultV2,
    },
    VaultVRC20: {
      address: '',
      abi: ABIS.VRC20Vault,
    },
    QuickStratV2: {
      address: '',
      abi: ABIS.QuickStratV2,
    },
    UniswapV2ERC20: {
      address: '',
      abi: ABIS.UniswapV2ERC20,
    },
    QiDAOSimpleStrat: {
      address: '',
      abi: ABIS.StrategySimpleQiDao,
    },
    SmartWallet: {
      address: '',
      abi: ABIS.SmartWallet,
    },
    SushiDualLpStrat: {
      address: '',
      abi: ABIS.StrategySushiNativeDualLp,
    },
    SushiDualStrat: {
      address: '',
      abi: ABIS.StrategySushiDual,
    },
    QiDAOStrat: {
      address: '',
      abi: ABIS.StrategyQiDao,
    },
    StakingRewardsQi: {
      address: '',
      abi: ABIS.QiStakingRewards,
    },
    StakingRewards: {
      address: '',
      abi: ABIS.StakingRewards,
    },
    IStrategy: {
      address: '',
      abi: ABIS.IStrategy,
    },
    IStrat: {
      address: '',
      abi: ABIS.IStrat,
    },
  },
  43114: {
    GlobalAdapter: {
      address: '0xc14D994fe7C5858c93936cc3bD42bb9467d6fB2C',
      abi: ABIS.GlobalAdapter,
    },
    Memory: {
      address: '0xcb543A9174F24c26cd3CdbcA94e0052c849891E0',
      abi: ABIS.Memory,
    },
    MasterChefEtha: {
      address: '',
      abi: ABIS.MasterChefEtha,
    },
    MasterChef: {
      address: '',
      abi: ABIS.MasterChef,
    },
    EthaRegistry: {
      address: '0x639aB0CD258559d77C5C799ded0e077Ca8969861',
      abi: ABIS.EthaRegistry,
    },
    FeeManager: {
      address: '0x5d477CA330b0EE011655998C1f808cd218d13b87',
      abi: ABIS.FeeManager,
    },
    VaultV2: {
      address: '',
      abi: ABIS.VaultV2,
    },
    VaultVRC20: {
      address: '',
      abi: ABIS.VRC20Vault,
    },
    UniswapV2ERC20: {
      address: '',
      abi: ABIS.UniswapV2ERC20,
    },
    SmartWallet: {
      address: '',
      abi: ABIS.SmartWallet,
    },
  },
};
