import { BigNumber, Contract, ContractTransaction, ethers } from 'ethers';

import { ChainIdType, Token, UserWallet } from '../interfaces';
import { PriceData, TokenData } from '../interfaces/index';
import {
  contractByNetwork,
  ETHA_ADDRESS,
  logicByNetwork,
  MATIC_ADDRESS,
  nativeTokenByNetwork,
  WMATIC_ADDRESS,
} from './constants';
import { Encode } from './encoding';
import {
  formatDecimals,
  getAddressToken,
  getERC20Contract,
  getTokenDecimals,
  getTokenSymbol,
  loadContract,
  toBigNumber,
  toWei,
} from './utils';

export class Actions {
  tokenList: Token[];
  encode: Encode;
  wallet: any;
  account: any;
  signer: ethers.providers.JsonRpcSigner;
  walletContract: Contract;
  priceData: PriceData;
  library: ethers.providers.Web3Provider;
  chainId: ChainIdType;

  constructor(
    tokenList: Token[],
    wallet,
    account,
    signer,
    priceData,
    library,
    chainId: ChainIdType,
  ) {
    this.tokenList = tokenList;
    this.encode = new Encode(tokenList);
    this.wallet = wallet;
    this.account = account;
    this.signer = signer;
    this.library = library;
    this.priceData = priceData;
    this.chainId = chainId;
  }

  initializeWalletContract() {
    this.walletContract = loadContract(
      'SmartWallet',
      this.chainId,
      this.signer || this.library,
      this.wallet,
    );
  }

  async handleVaultDeposit(
    investment: any,
    amount: any,
    investFrom: any,
    depositAddress: any,
    protocol: any,
    wallet: string,
    account: string,
  ): Promise<ContractTransaction> {
    let targets = [];
    let datas = [];

    const { vaultAddress, deposit, version } = investment;

    const depositSymbol = getTokenSymbol(depositAddress, this.tokenList);

    if (
      investFrom === 'account' &&
      depositSymbol !== nativeTokenByNetwork[this.chainId].symbol
    ) {
      datas.push(this.encode.encodeDeposit(depositSymbol, amount, true));
      targets.push(logicByNetwork[this.chainId]['TransferLogic']);
    }

    if (!version.isLp && deposit[0].symbol === 'GHST') {
      datas.push(this.encode.encodeVGhstWrap(amount, 0, 1, true));
      targets.push(logicByNetwork[this.chainId]['WrapVGhstLogic']);
    }

    const realToken =
      depositAddress === nativeTokenByNetwork[this.chainId].address
        ? nativeTokenByNetwork[this.chainId].wrappedAddress
        : depositAddress;

    if (protocol === 'Curve') {
      if (depositAddress === nativeTokenByNetwork[this.chainId].address) {
        datas.push(this.encode.encodeWrap(amount, true));
        targets.push(logicByNetwork[this.chainId]['EthwrapLogic']);
      }

      const curveData = this.encode.encodeCurveAddLiquidity(
        realToken,
        amount,
        0,
        1,
        1,
        this.chainId,
      );

      datas.push(curveData);
      targets.push(logicByNetwork[this.chainId]['CurveLogic']);
    } else {
      if (investment.version.isLp) {
        const tokenA = depositAddress;
        const tokenB =
          realToken.toLowerCase() === deposit[0].address.toLowerCase()
            ? deposit[1].address
            : deposit[0].address;

        const res = await this.encode.encodeSwaps(
          tokenA,
          tokenB,
          String(toBigNumber(amount).div(ethers.BigNumber.from(2))),
          wallet,
          account,
          this.chainId,
        );

        datas = datas.concat(res.datas);
        targets = targets.concat(res.targets);

        //Add liquidity to uniswap fork
        const data = this.encode.encodeUniAddLiquidity(
          tokenA,
          tokenB,
          toBigNumber(amount).div(ethers.BigNumber.from(2)) /*amtA */,
          0 /*amtB */,
          0 /*getId */,
          1 /*getId2 */,
          1 /*setId */,
          1,
          true,
        );

        if (protocol === 'QI' && this.chainId === ChainIdType.AVALANCHE) {
          datas.push(data);
          targets.push(logicByNetwork[this.chainId]['JoeLogic']);
        } else {
          datas.push(data);
          targets.push(logicByNetwork[this.chainId][`${protocol}Logic`]);
        }
      }
    }

    // Compounded vault
    if (investment?.version?.isCompounding) {
      datas.push(
        this.encode.encodeVaultDeposit(
          vaultAddress,
          ethers.constants.AddressZero,
          0,
          1,
        ),
      );
      targets.push(logicByNetwork[this.chainId]['VaultCompLogic']);
    } else {
      datas.push(
        this.encode.encodeVaultDeposit(
          vaultAddress,
          ethers.constants.AddressZero,
          0,
          1,
        ),
      );

      targets.push(logicByNetwork[this.chainId]['VaultLogic']);
    }

    if (
      investFrom === 'account' &&
      depositSymbol === nativeTokenByNetwork[this.chainId].symbol
    )
      return await this.walletContract.execute(targets, datas, {
        value: amount,
      });

    return await this.walletContract.execute(targets, datas);
  }

  async handleVaultMigrate(vault: any): Promise<ContractTransaction> {
    const datas = [];
    const targets = [];

    const {
      vaultAddress,
      // deposit,
      // stakingToken,
      lpBalanceWei,
      // protocol,
      id,
      newVaultAddress,
    } = vault;

    const formattedAmt = String(lpBalanceWei);

    let data = this.encode.encodeVaultWithdraw(
      vaultAddress,
      formattedAmt,
      0,
      1,
    );
    datas.push(data);

    // @REMOVE: after we get this normalize with the new vault logic
    if (parseInt(id) >= 15) {
      targets.push(logicByNetwork[this.chainId]['VaultLogicV2']);
    } else {
      targets.push(logicByNetwork[this.chainId]['VaultLogic']);
    }

    // const asset = deposit[0].address;
    // const tokenA = asset;
    // const tokenB =
    //   asset.toLowerCase() === deposit[0].address.toLowerCase()
    //     ? deposit[1].address
    //     : deposit[0].address;

    // if (
    //   tokenA.toLowerCase() === '0xb5c064f955d8e7f38fe0460c556a72987494ee17' ||
    //   tokenB.toLowerCase() === '0xb5c064f955d8e7f38fe0460c556a72987494ee17'
    // ) {
    //   // Deposit into the new vault
    //   data = this.encode.encodeVaultDeposit(
    //     newVaultAddress,
    //     ethers.constants.AddressZero,
    //     0,
    //     1,
    //   );
    //   datas.push(data);
    //   targets.push(logicByNetwork[this.chainId]['VaultLogic']);

    //   return await this.walletContract.execute(targets, datas);
    // }

    // // Remove liquidity from quickswap
    // data = this.encode.encodeUniRemoveLiquidity(
    //   tokenA,
    //   tokenB,
    //   stakingToken,
    //   0, // formattedAmt,
    //   1, // getId
    //   1, // setId1
    //   2, // setId2 store in memory the amount of tokenB received
    // );
    // datas.push(data);
    // targets.push(logicByNetwork[this.chainId][`${protocol}Logic`]);

    // // QUICK (new) => swap
    // data = this.encode.encodeQuickswap(
    //   tokenB,
    //   '0xB5C064F955D8e7F38fE0460C556a72987494eE17',
    //   0,
    //   2,
    //   2,
    //   1,
    //   false,
    //   [tokenB, WMATIC_ADDRESS, '0xB5C064F955D8e7F38fE0460C556a72987494eE17'],
    // );
    // datas.push(data);
    // targets.push(logicByNetwork[this.chainId]['QuickswapLogic']);

    // // Add liquidity to quickswap
    // data = await this.encode.encodeUniAddLiquidity(
    //   tokenA,
    //   '0xB5C064F955D8e7F38fE0460C556a72987494eE17',
    //   0 /*amtA */,
    //   0 /*amtB */,
    //   1 /*getId */,
    //   2 /*getId2 */,
    //   1 /*setId */,
    //   1 /*divider*/,
    //   false, // isWei
    // );
    // datas.push(data);
    // targets.push(logicByNetwork[this.chainId]['QuickswapLogic']);

    // Deposit into the new vault
    data = this.encode.encodeVaultDeposit(
      newVaultAddress,
      ethers.constants.AddressZero,
      0,
      1,
    );
    datas.push(data);
    targets.push(logicByNetwork[this.chainId]['VaultLogicV2']);

    return await this.walletContract.execute(targets, datas);
  }

  async handleDeposit(
    symbol: string,
    amount: string,
  ): Promise<ContractTransaction> {
    let initialTx;

    if (symbol === nativeTokenByNetwork[this.chainId].symbol) {
      initialTx = await this.signer.sendTransaction({
        from: this.account,
        to: this.wallet,
        value: ethers.BigNumber.from(amount),
      });
    } else {
      const tokenInstance = getERC20Contract(
        getAddressToken(symbol, this.tokenList),
        this.signer || this.library,
      );

      initialTx = await tokenInstance.transfer(this.wallet, amount);
    }

    return initialTx;
  }

  async handleVaultClaim(
    investment,
    ethaRewards,
  ): Promise<ContractTransaction> {
    const targets = [];
    const datas = [];
    const { vaultAddress, version } = investment;
    let vaultContract;
    let dividend;

    if (!version.isCompounding) {
      vaultContract = loadContract(
        'VaultV2',
        this.chainId,
        this.signer || this.library,
        vaultAddress,
      );
      dividend = await vaultContract.dividendOf(this.wallet);

      const data = this.encode.encodeVaultClaim(vaultAddress);
      datas.push(data);
      targets.push(logicByNetwork[this.chainId]['VaultLogic']);
    } else {
      dividend = 1;

      const data = this.encode.encodeVaultClaim(vaultAddress);
      datas.push(data);
      targets.push(logicByNetwork[this.chainId]['VaultCompLogic']);
    }

    if (dividend === 0 && ethaRewards === 0) {
      throw Error('No dividend to withdraw');
    }

    return await this.walletContract.execute(targets, datas);
  }

  async handleRedeem(
    amount: string,
    data,
    tokensData: TokenData[],
  ): Promise<ContractTransaction> {
    if (Number(amount) > 0) {
      const datas = [];
      const targets = [];

      const { balances, ethaRewards, rewards, symbol, token } = data;

      const { aaveData, creamData } = tokensData.filter(
        (item: TokenData) =>
          item.symbol === (symbol === 'WMATIC' ? 'MATIC' : symbol),
      )[0];

      aaveData.name = 'aaveBN';
      creamData.name = 'creamBN';

      const aaveRate = aaveData.supplyRate;
      const creamRate = creamData.supplyRate;

      // Compare what protocol is yielding more
      const bestRate = aaveRate > creamRate ? aaveData : creamData;
      const lowRate = bestRate === aaveData ? creamData : aaveData;

      const realToken = token === MATIC_ADDRESS ? WMATIC_ADDRESS : token;

      const totalAmt = toBigNumber(amount);
      let amountLow = toBigNumber(0);
      let amountBest = toBigNumber(0);

      const minAmount = toBigNumber(1).mul(
        toBigNumber(10 ** (getTokenDecimals(symbol, this.tokenList) / 2)),
      );

      // If user has balance in low rate market
      if (balances[lowRate.name].gt(minAmount)) {
        // If not enough with low rate balance, use best rate balance too
        if (totalAmt.gt(balances[lowRate.name])) {
          amountLow = balances[lowRate.name];
          amountBest = totalAmt.sub(amountLow);
        } else {
          amountLow = totalAmt;
        }
      }
      // Not enough funds in low rate, use best rate balances only
      else {
        amountBest = totalAmt;
      }

      if (lowRate.name === 'creamBN') {
        if (amountBest.gt(toBigNumber(0))) {
          datas.push(
            this.encode.encodeAave('redeemAToken', amountBest, realToken),
          );
          targets.push(logicByNetwork[this.chainId]['AaveLogic']);
        }
        if (amountLow.gt(toBigNumber(0))) {
          datas.push(
            this.encode.encodeCream('redeemUnderlying', amountLow, realToken),
          );
          targets.push(logicByNetwork[this.chainId]['CreamLogic']);
        }
      } else {
        if (amountLow.gt(toBigNumber(0))) {
          datas.push(
            this.encode.encodeAave('redeemAToken', amountLow, realToken),
          );
          targets.push(logicByNetwork[this.chainId]['AaveLogic']);
        }
        if (amountBest.gt(toBigNumber(0))) {
          datas.push(
            this.encode.encodeCream('redeemUnderlying', amountBest, realToken),
          );
          targets.push(logicByNetwork[this.chainId]['CreamLogic']);
        }
      }

      if (Number(ethaRewards) > 0) {
        const memoryContract = loadContract(
          'Memory',
          this.chainId,
          this.signer || this.library,
        );
        const aToken = await memoryContract.getAToken(data.token);

        datas.push(
          this.encode.encodeLendingClaimMatic(
            [aToken],
            formatDecimals('MATIC', rewards, this.tokenList),
          ),
        );
        targets.push(logicByNetwork[this.chainId]['ClaimLogic']);
      }

      if (Number(rewards) > 0) {
        datas.push(this.encode.encodeLendingClaimEtha(data.token));
        targets.push(logicByNetwork[this.chainId]['ClaimLogic']);
      }

      return await this.walletContract.execute(targets, datas);
    }
  }

  async handleLendingClaim(
    tokens,
    amount,
    distToken,
  ): Promise<ContractTransaction> {
    const datas = [];
    const targets = [];

    datas.push(this.encode.encodeLendingClaimMatic(tokens, toWei(amount)));
    targets.push(logicByNetwork[this.chainId]['ClaimLogic']);

    datas.push(this.encode.encodeLendingClaimEtha(distToken));
    targets.push(logicByNetwork[this.chainId]['ClaimLogic']);

    return await this.walletContract.execute(targets, datas);
  }

  async handleUnstake(amount, data): Promise<ContractTransaction> {
    const targets: string[] = [];
    const datas: string[] = [];

    const { stakingContract, deposit, stakingToken } = data;

    let _data = this.encode.encodeStakingUnstake(
      stakingContract,
      stakingToken,
      amount,
    );
    datas.push(_data);
    targets.push(logicByNetwork[this.chainId]['StakingLogic']);

    if (stakingToken !== ETHA_ADDRESS) {
      const tokenA = data.address;
      const tokenB =
        data.address.toLowerCase() === deposit[0].address.toLowerCase()
          ? deposit[1].address
          : deposit[0].address;

      // Remove liquidity from dex
      _data = await this.encode.encodeUniRemoveLiquidity(
        tokenA,
        tokenB,
        stakingToken,
        amount,
        0, // getId
        0, //setId1
        1, //setId2
      );
      datas.push(_data);
      targets.push(logicByNetwork[this.chainId]['QuickswapLogic']);

      // Swap received tokenB for tokenA
      _data = await this.encode.encodeQuickswap(
        tokenB,
        tokenA,
        0,
        1, // read value from memory
        0, // dont store result in memory
      );
      datas.push(_data);
      targets.push(logicByNetwork[this.chainId]['QuickswapLogic']);
    }

    return await this.walletContract.execute(targets, datas);
  }

  async handleStakingClaim(data): Promise<ContractTransaction> {
    const targets = [];
    const datas = [];

    const { stakingContract } = data;

    const _data = this.encode.encodeStakingClaim(stakingContract);
    datas.push(_data);
    targets.push(logicByNetwork[this.chainId]['StakingLogic']);

    return await this.walletContract.execute(targets, datas);
  }

  async handleBorrowingClaim(
    tokens: string[],
    amount,
  ): Promise<ContractTransaction> {
    const datas = [];
    const targets = [];

    datas.push(this.encode.encodeLendingClaimMatic(tokens, toWei(amount)));
    targets.push(logicByNetwork[this.chainId]['ClaimLogic']);

    return await this.walletContract.execute(targets, datas);
  }

  async handleBorrow({ token, amount, protocol }) {
    try {
      const datas = [];
      const targets = [];

      const realToken = token === MATIC_ADDRESS ? WMATIC_ADDRESS : token;

      if (protocol === 'aave') {
        datas.push(this.encode.encodeAave('borrow', amount, realToken));
        targets.push(logicByNetwork[this.chainId]['AaveLogic']);
      } else {
        datas.push(this.encode.encodeCream('borrow', amount, realToken));
        targets.push(logicByNetwork[this.chainId]['CreamLogic']);
      }

      return await this.walletContract.execute(targets, datas);
    } catch (error) {
      console.error(error);
    }
  }

  async handleVaultWithdraw(
    investment,
    amount,
    asset,
  ): Promise<ContractTransaction> {
    const targets = [];
    const datas = [];

    const { vaultAddress, deposit, stakingToken, protocol, version, id } =
      investment;

    const formattedAmt = String(amount);

    if (version.distributionType === 'MASTERCHEF' && version.isCompounding) {
      const vaultContract = loadContract(
        'VaultVRC20',
        this.chainId,
        this.library,
        vaultAddress,
      );

      const formattedShares = String(
        await vaultContract.convertToShares(amount), // we convert the assetsToShares
      );

      const data = this.encode.encodeVaultRedeem(
        vaultAddress,
        formattedShares,
        0,
        1,
      );
      datas.push(data);
      targets.push(logicByNetwork[this.chainId]['VaultCompLogic']);
    } else {
      const data = this.encode.encodeVaultWithdraw(
        vaultAddress,
        formattedAmt,
        0,
        1,
      );
      datas.push(data);

      // @REMOVE: after we get this normalize with the new vault logic
      if (parseInt(id) >= 15 && this.chainId === ChainIdType.POLYGON) {
        targets.push(logicByNetwork[this.chainId]['VaultLogicV2']);
      } else {
        targets.push(logicByNetwork[this.chainId]['VaultLogic']);
      }
    }

    if (!version.isLp && deposit[0].symbol === 'GHST') {
      datas.push(this.encode.encodeVGhstUnWrap(amount, 1, 0, true));
      targets.push(logicByNetwork[this.chainId]['WrapVGhstLogic']);
    }

    if (investment.version.isLp) {
      const tokenA = asset;
      const tokenB =
        asset.toLowerCase() === deposit[0].address.toLowerCase()
          ? deposit[1].address
          : deposit[0].address;

      if (protocol === 'Curve') {
        const curveData = this.encode.encodeCurveRemoveLiquidity(
          asset,
          0,
          1,
          1,
          1,
          this.chainId,
        );

        datas.push(curveData); // store LP tokens received in memory
        targets.push(logicByNetwork[this.chainId]['CurveLogic']);
      } else {
        // Remove liquidity from quickswap
        const data = await this.encode.encodeUniRemoveLiquidity(
          tokenA,
          tokenB,
          stakingToken,
          0, // formattedAmt,
          1, // getId
          0, // setId1
          1, // setId2 store in memory the amount of tokenB received
        );

        if (protocol === 'QI' && this.chainId === ChainIdType.AVALANCHE) {
          datas.push(data);
          targets.push(logicByNetwork[this.chainId]['JoeLogic']);
        } else {
          datas.push(data);
          targets.push(logicByNetwork[this.chainId][`${protocol}Logic`]);
        }
      }
    }

    return await this.walletContract.execute(targets, datas);
  }

  async handleCreateWallet(): Promise<ContractTransaction> {
    const registry = loadContract(
      'EthaRegistry',
      this.chainId,
      this.signer || this.library,
    );

    return await registry.deployWallet();
  }

  async handleWithdraw(
    symbol: string,
    amount: string,
  ): Promise<ContractTransaction> {
    const data = this.encode.encodeWithdraw(symbol, amount);
    return await this.walletContract.execute(
      [logicByNetwork[this.chainId]['TransferLogic']],
      [data],
    );
  }

  async handleClaimAll(
    vaultInvestments,
    lendingInvestments,
    stakingInvestments,
  ): Promise<ContractTransaction> {
    const datas = [];
    const targets = [];

    // Vault Claims
    for (let i = 0; i < vaultInvestments.length; i++) {
      const { earned, ethaRewards, vaultAddress } = vaultInvestments[i];

      if (earned !== 0 || ethaRewards !== 0) {
        datas.push(this.encode.encodeVaultClaim(vaultAddress));
        targets.push(logicByNetwork[this.chainId]['VaultLogic']);
      }
    }

    const aTokens = [];
    let rewards = toBigNumber(0);

    const memoryContract = loadContract(
      'Memory',
      this.chainId,
      this.signer || this.library,
    );
    const aaveIncentivesContract = loadContract(
      'AdapterAaveIncentives',
      this.chainId,
      this.signer || this.library,
    );

    for (let i = 0; i < lendingInvestments.length; i++) {
      const { token } = lendingInvestments[i];

      const aToken = await memoryContract.getAToken(token);
      const _rewards = await aaveIncentivesContract.getRewardsBalance(
        [aToken],
        this.wallet,
      );

      if (_rewards.gt(0)) {
        rewards = rewards.add(_rewards);
        aTokens.push(aToken);

        datas.push(this.encode.encodeLendingClaimEtha(token));
        targets.push(logicByNetwork[this.chainId]['ClaimLogic']);
      }
    }

    if (aTokens.length > 0) {
      datas.push(this.encode.encodeLendingClaimMatic(aTokens, rewards));
      targets.push(logicByNetwork[this.chainId]['ClaimLogic']);
    }

    const currentTime = Math.floor(Date.now() / 1000);

    for (let i = 0; i < stakingInvestments.length; i++) {
      const { earnedBN, stakingContract, claimTime } = stakingInvestments[i];

      if (earnedBN.gt(0) && currentTime >= claimTime) {
        const _data = this.encode.encodeStakingClaim(stakingContract);
        datas.push(_data);
        targets.push(logicByNetwork[this.chainId]['StakingLogic']);
      }
    }

    return await this.walletContract.execute(targets, datas);
  }

  async handleRepay({ token, amount, protocol }) {
    try {
      const datas = [];
      const targets = [];

      const realToken = token === MATIC_ADDRESS ? WMATIC_ADDRESS : token;

      if (protocol === 'aave') {
        datas.push(this.encode.encodeAave('repay', amount, realToken));
        targets.push(logicByNetwork[this.chainId]['AaveLogic']);
      } else {
        datas.push(this.encode.encodeCream('repay', amount, realToken));
        targets.push(logicByNetwork[this.chainId]['CreamLogic']);
      }

      const tx = await this.walletContract.execute(targets, datas);
      await tx.wait();
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async handleStake(
    depositAddress,
    amount,
    data,
    wallet: string,
    account: string,
    chainId: ChainIdType,
  ): Promise<ContractTransaction> {
    let targets = [];
    let datas = [];

    const { stakingContract, deposit } = data;

    if (deposit.length === 1) {
      const _data = this.encode.encodeStakingStake(
        stakingContract,
        depositAddress,
        amount,
      );
      datas.push(_data);
      targets.push(logicByNetwork[this.chainId]['StakingLogic']);
    } else {
      const tokenA = depositAddress;
      const tokenB =
        depositAddress.toLowerCase() === deposit[0].address.toLowerCase()
          ? deposit[1].address
          : deposit[0].address;

      const { datas: _datas, targets: _targets } =
        await this.encode.encodeSwaps(
          tokenA,
          tokenB,
          String(toBigNumber(amount).div(2)),
          wallet,
          account,
          chainId,
        );

      datas = datas.concat(_datas);
      targets = targets.concat(_targets);

      // Add liquidity to quickswap
      let _data = await this.encode.encodeUniAddLiquidity(
        tokenA,
        tokenB,
        toBigNumber(amount).div(toBigNumber(2)) /*amtA */,
        0 /*amtB */,
        0 /*getId */,
        1 /*getId2 */,
        1 /*setId */,
        1 /*divider*/,
        true, // isWei
      );
      datas.push(_data);
      targets.push(logicByNetwork[this.chainId]['QuickswapLogic']);

      // Stake LP in contract
      _data = this.encode.encodeStakingStake(
        stakingContract,
        data.stakingToken,
        0,
        1,
      );
      datas.push(_data);
      targets.push(logicByNetwork[this.chainId]['StakingLogic']);
    }

    return await this.walletContract.execute(targets, datas);
  }

  async handleSwap(
    fromAssets,
    dest,
    wallet: string,
    account: string,
    chainId: ChainIdType,
  ): Promise<ContractTransaction> {
    const encodeSwapsPromises = [];
    let datas = [];
    let targets = [];

    for (let i = 0; i < fromAssets.length; i++) {
      const { address, amountWei } = fromAssets[i];

      encodeSwapsPromises.push(
        this.encode.encodeSwaps(
          address,
          dest,
          amountWei,
          wallet,
          account,
          chainId,
        ),
      );
    }

    const swapsPromises = await Promise.all(encodeSwapsPromises);

    for (const swapPromise of swapsPromises) {
      const { datas: _datas, targets: _targets } = swapPromise;

      datas = datas.concat(_datas);
      targets = targets.concat(_targets);
    }

    if (targets.length === 0) return;

    const estimativeOfGas: BigNumber =
      await this.walletContract.estimateGas.execute(targets, datas);

    return await this.walletContract.execute(targets, datas, {
      gasLimit: estimativeOfGas.mul(2),
    });
  }

  async handleLock(
    totalAmount,
    days,
    typeOfWallet: UserWallet,
  ): Promise<ContractTransaction> {
    const voteEscrowContract = loadContract(
      'VoteEscrow',
      this.chainId,
      this.signer || this.library,
    );

    const { amount } = await voteEscrowContract.locked(this.wallet);
    const lockedBalance = Number(amount) / 10 ** 18;

    const targets = [];
    const datas = [];
    let data = '';

    if (typeOfWallet === UserWallet.web3Wallet && totalAmount !== '') {
      datas.push(
        this.encode.encodeDeposit(
          'ETHA',
          ethers.utils.parseEther(totalAmount),
          true,
        ),
      );
      targets.push(logicByNetwork[this.chainId]['TransferLogic']);
    }

    if (lockedBalance > 0) {
      if (Number(totalAmount) > 0 && days === '') {
        data = this.encode.encodeIncreaseAmountLock(
          contractByNetwork[this.chainId]['VoteEscrow'].address,
          ethers.utils.parseEther(totalAmount),
          0,
        );
      } else {
        data = this.encode.encodeIncreaseTimeLock(
          contractByNetwork[this.chainId]['VoteEscrow'].address,
          days,
        );
      }
    } else {
      data = this.encode.encodeLock(
        contractByNetwork[this.chainId]['VoteEscrow'].address,
        ethers.utils.parseEther(totalAmount),
        days,
        0,
      );
    }

    datas.push(data);
    targets.push(logicByNetwork[this.chainId]['VoteEscrowLogic']);

    return await this.walletContract.execute(targets, datas);
  }
  async handleLockWithdraw(endTime): Promise<ContractTransaction> {
    const msEndTime = new Date(endTime).getTime();
    const today = Date.now();

    const targets = [];
    const datas = [];
    let data = '';

    if (today > msEndTime) {
      //Regular withdraw
      data = this.encode.encodeLockWithdraw(
        contractByNetwork[this.chainId]['VoteEscrow'].address,
      );
    } else {
      // Emergency withdraw
      data = this.encode.encodeLockEmergencyWithdraw(
        contractByNetwork[this.chainId]['VoteEscrow'].address,
      );
    }

    datas.push(data);
    targets.push(logicByNetwork[this.chainId]['VoteEscrowLogic']);

    return await this.walletContract.execute(targets, datas);
  }

  async handleFeeClaim(): Promise<ContractTransaction> {
    const targets = [];
    const datas = [];

    const multiFeeInstance = loadContract(
      'MultiFeeDistribution',
      this.chainId,
      this.signer || this.library,
    );
    const rewardsMultiFee = await multiFeeInstance.getRewardTokens();

    const data = this.encode.encodeFeeClaim(
      contractByNetwork[this.chainId]['MultiFeeDistribution'].address,
      this.wallet,
      rewardsMultiFee,
    );

    datas.push(data);
    targets.push(logicByNetwork[this.chainId]['MultiFeeLogic']);

    return await this.walletContract.execute(targets, datas);
  }

  async handleWithdrawAll(
    vaultInvestments,
    unusedAssets,
  ): Promise<ContractTransaction> {
    const datas = [];
    const targets = [];

    const assetsToWithdrawSymbols = new Set(['ETHA']);
    const assetsToWithdrawAdresses = [];

    for (let i = 0; i < vaultInvestments.length; i++) {
      console.log(vaultInvestments);
      const { vaultAddress, rewards, balance, version } = vaultInvestments[i];

      if (!version.isCompounding) {
        datas.push(this.encode.encodeVaultClaim(vaultAddress));
        targets.push(logicByNetwork[this.chainId]['VaultLogicV2']);
        assetsToWithdrawSymbols.add(rewards[0]);
      }

      //Vault Investment
      if (balance > 0) {
        assetsToWithdrawAdresses.push(vaultAddress);
      }
    }

    //Unused assets
    for (let i = 0; i < unusedAssets.length; i++) {
      const { balance, symbol } = unusedAssets[i];

      if (Number(balance) > 0) {
        assetsToWithdrawSymbols.add(symbol);
      }
    }

    // Converts the symbols into addresses
    assetsToWithdrawSymbols.forEach((element) =>
      assetsToWithdrawAdresses.push(getAddressToken(element, this.tokenList)),
    );

    for (let i = 0; i < assetsToWithdrawAdresses.length; i++) {
      const data = this.encode.encodeWithdrawAll(assetsToWithdrawAdresses[i]);
      datas.push(data);
      targets.push(logicByNetwork[this.chainId]['TransferLogic']);
    }

    return await this.walletContract.execute(targets, datas);
  }
}
