import { Button } from '../Button';

export const BackButton = ({
  to,
  children,
  className,
}: {
  to?: string;
  children: any;
  className?: string;
}) => {
  return (
    <Button
      type={'link'}
      to={to}
      className={`default back ${className}`}
      size={'sm'}
    >
      <i className="icon-back"></i> {children}
    </Button>
  );
};
